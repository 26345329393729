export const UPDATE_MONEY_TRANSACTION = "UPDATE_MONEY_TRANSACTION";
export const DELETE_MONEY_TRANSACTION = "DELETE_MONEY_TRANSACTION";

export const UPDATE_MONEY_TRANSACTIONS = "UPDATE_MONEY_TRANSACTIONS";
export const GET_MONEY_TRANSACTIONS = "GET_MONEY_TRANSACTIONS";
export const GET_MONEY_TRANSACTION = "GET_MONEY_TRANSACTION";

export const RECEIVE_PAYMENT_BY_FUNCTION = "RECEIVE_PAYMENT_BY_FUNCTION";
export const GET_TRANSACTION_FOR_FOR_INVOICE_ID =
  "GET_TRANSACTION_FOR_FOR_INVOICE_ID";
export const VERIFY_TRIANSACTION_BY_ID = "VERIFY_TRIANSACTION_BY_ID";
export const SET_TRIANSACTION = "SET_TRIANSACTION";
export const DELETE_TRANSACTION_BY_ID = "DELETE_TRANSACTION_BY_ID";
export const RECEIVE_PAYMENT_BY_FUNCTION_SUCCESS =
  "RECEIVE_PAYMENT_BY_FUNCTION_SUCCESS";
export const GET_ALL_TRIANSACTION_FOR_FILTER =
  "GET_ALL_TRIANSACTION_FOR_FILTER";
export const GET_VERIFIED_STATUS_FOR_INVOICES =
  "GET_VERIFIED_STATUS_FOR_INVOICES";
export const SET_VERIFIED_FOR_PENDING_INVOICE =
  "SET_VERIFIED_FOR_PENDING_INVOICE";
export const UPDATE_MONEY_TRANSACTIONS_UPDATE =
  "UPDATE_MONEY_TRANSACTIONS_UPDATE";
export const GET_ALL_TRIANSACTION_COUNT_FOR_FILTER =
  "GET_ALL_TRIANSACTION_COUNT_FOR_FILTER";
export const SET_ALL_TRIANSACTION_COUNT_FOR_FILTER =
  "SET_ALL_TRIANSACTION_COUNT_FOR_FILTER";

export const RECIVE_TRIANSACTION_DISPLAY_SUCCESS =
  "RECIVE_TRIANSACTION_DISPLAY_SUCCESS";

export const GET_TRIANSACTION_FOR_INVOICE_IDS =
  "GET_TRIANSACTION_FOR_INVOICE_IDS";

export const SET_TRIANSACTION_FOR_INVOICE_IDS =
  "SET_TRIANSACTION_FOR_INVOICE_IDS";
export const REMOVE_FROM_PENDING_TRANSACTIONS = "REMOVE_FROM_PENDING_TRANSACTIONS";
export const RECHECK_TRANSACTION_STATE = "RECHECK_TRANSACTION_STATE";
