import Parse from "parse";


export const getLearningSessionForSchoolId = (id, filters) => {
  try {
    return new Promise((resolve, reject) => {
      var LearningSession = Parse.Object.extend("LearningSession");
      var query = new Parse.Query(LearningSession);

      if (filters && filters.startDate && filters.endDate) {
        const startOfDay = new Date(
          filters.startDate.getFullYear(),
          filters.startDate.getMonth(),
          filters.startDate.getDate()
        );
        const endOfDay = new Date(
          filters.endDate.getFullYear(),
          filters.endDate.getMonth(),
          filters.endDate.getDate(),
          23,
          59,
          59,
          999
        );
        query.greaterThanOrEqualTo('createdAt', startOfDay);
        query.lessThan('createdAt', endOfDay);
              
        if (filters.type == "teacher") {
          query.equalTo('createdBy', filters.teacherId);
        }
        if (filters.type == "kid") {
          query.contains('recipients', filters.kidId);
        }
      }
      else {
        const date = new Date();

        var startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

        query.greaterThanOrEqualTo('createdAt', startOfDay);
        query.lessThan('createdAt', endOfDay);
      }

      query.equalTo('schoolId', id);
      query.notEqualTo('isDeleted', true);

      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
          // console.log(result)
        },
        (error) => {
          resolve(error);
          console.log(error)
        }
      );
    });
  } catch (error) {
    // Handle error
  }
};

export const getOnlineLearingSessionForSchoolId = (id, filters) => {
  try {
    return new Promise((resolve, reject) => {
      var OnlineLearningSession = Parse.Object.extend("OnlineLearningSession");
      var query = new Parse.Query(OnlineLearningSession);

      if (filters.startDate && filters.endDate) {
        const startOfDay = new Date(
          filters.startDate.getFullYear(),
          filters.startDate.getMonth(),
          filters.startDate.getDate()
        );
        const endOfDay = new Date(
          filters.endDate.getFullYear(),
          filters.endDate.getMonth(),
          filters.endDate.getDate(),
          23,
          59,
          59,
          999
        );
        query.greaterThanOrEqualTo('createdAt', startOfDay);
        query.lessThan('createdAt', endOfDay);
        if (filters.type == "teacher") {
          query.equalTo('createdBy', filters.teacherId);
        }
        if (filters.type == "kid") {
          query.contains('recipients', filters.kidId);
        }
      }
      else {
        const date = new Date();

        var startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

        query.greaterThanOrEqualTo('createdAt', startOfDay);
        query.lessThan('createdAt', endOfDay);
      }

      query.equalTo('schoolId', id);

      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
          // console.log(result)
        },
        (error) => {
          resolve(error);
          console.log(error)
        }
      );
    });
  } catch (error) {
    // Handle error
  }
};

export const getLearningSessionFromParse = (id) => {
  
  try {
    return new Promise((resolve, reject) => {
      var LearningSession = Parse.Object.extend("LearningSession");
      var query = new Parse.Query(LearningSession);
      query.get(id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}

export const getSelectedOnlineLearningSessionFromParse = (id) => {
  
  try {
    return new Promise((resolve, reject) => {
      var OnlineLearningSession = Parse.Object.extend("OnlineLearningSession");
      var query = new Parse.Query(OnlineLearningSession);
      query.get(id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}

export const getOnlineLearningTrackForSessionId = (sessionId) => {
  
  try {
    return new Promise((resolve, reject) => {
      var OnlineLearningTracking = Parse.Object.extend("OnlineLearningTracking");
      var query = new Parse.Query(OnlineLearningTracking);

      query.equalTo('learningSessionId', sessionId);
      query.find().then(
        (result) => {
          resolve(result);
          // console.log(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}

export const getLearningTrackForSessionId = (sessionId) => {
  
  try {
    return new Promise((resolve, reject) => {
      var LearningTracking = Parse.Object.extend("LearningTracking");
      var query = new Parse.Query(LearningTracking);

      query.equalTo('learningSessionId', sessionId);
      query.find().then(
        (result) => {
          resolve(result);
          // console.log(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}