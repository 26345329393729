import Parse from "parse";
import school from "../../store/model/school/reducer";
import { printLog } from "./loghelper";
export const getReminderFromParse = (reminderId) => {
  printLog("HL: reminder_helper : getReminderFromParse")

  try {
    return new Promise((resolve, reject) => {
      var Reminder = Parse.Object.extend("Reminder");
      var query = new Parse.Query(Reminder);
      query.get(reminderId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const deleteReminderFromParse = (reminder,emailNotification) => {
  printLog("HL: reminder_helper : deleteReminderFromParse")
  try {
    return new Promise((resolve, reject) => {
     
      reminder.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        reminder.set(
          "deletedBy",
          Parse.User.current().attributes.teacherId
        );
      } else {
        reminder.set("deletedBy", Parse.User.current().id);
      }

      reminder.save().then(
        (result) => {
          if (emailNotification) {
            let courseIds = (reminder && reminder.courseIds.length == 0 )?[]:reminder.courseIds;
            Parse.Cloud.run("function_sendEmail", {
              schoolId: reminder.attributes.schoolId,
              type:"event",
              reminderId:result.id,
              emailType: "delete",
              courseIds:courseIds,
            }).then((res) => {
                // console.log("function_sendEmail r",res)
            }, (error) => {
                // console.log("function_sendEmail e",error)
            });
          }
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const saveAllReminders = (objects) => {
  printLog("HL: reminder_helper : saveAllReminders")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
          console.log("Error", error);
        }
      );

    });

  } catch (error) {
    console.log("Error", error);
  }
}

export const editReminder = (reminder,emailNotification) => {
  printLog("HL: reminder_helper : editReminder")
  try {
    return new Promise((resolve, reject) => {
      let isNew = reminder.id?false:true;
      reminder.save().then(
        (result) => {
          

          if (emailNotification) {
            let courseIds = (reminder && reminder.courseIds.length == 0 )?[]:reminder.courseIds;
            Parse.Cloud.run("function_sendEmail", {
              schoolId: reminder.attributes.schoolId,
              type:"event",
              reminderId:result.id,
              emailType: isNew ? "initial" : "update",
              courseIds:courseIds
            }).then((res) => {
                // console.log("function_sendEmail r",res)
            }, (error) => {
                // console.log("function_sendEmail e",error)
            });
          }

          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    });
  } catch (error) { }
}


export const getRemindersForSchool = (schoolId, classIds, groupIds, familyIds, updatedDate, isLocal) => {
  printLog("HL: reminder_helper : getRemindersForSchool")
  try {
    return new Promise((resolve, reject) => {
      var Reminder = Parse.Object.extend("Reminder");


      var query1 = new Parse.Query(Reminder);
      query1.equalTo("schoolId", schoolId)

      var query2 = new Parse.Query(Reminder);
      // query2.containedIn("classId", classIds)
      query2.equalTo("defaultSchoolId",schoolId)

      var query3 = new Parse.Query(Reminder);
      query3.containedIn("familyId", familyIds);
      query3.equalTo("defaultSchoolId",schoolId)

      var query4 = new Parse.Query(Reminder);
      query4.containedIn("groupId", groupIds);
      query4.equalTo("defaultSchoolId",schoolId)


      var mainQuery = Parse.Query.or(query1, query2);
      if (updatedDate) {
        mainQuery.greaterThanOrEqualTo("updatedAt", new Date(updatedDate))
      } else {
        mainQuery.notEqualTo("isDeleted", true);
        mainQuery.limit(1000)
      }

      if (isLocal) {
        mainQuery.fromLocalDatastore();
        mainQuery.notEqualTo("isDeleted", true);
      }

      mainQuery.descending("createdAt");
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
          console.log("Error" + error)
        }
      );
    });
  } catch (error) { }
};

export const getRemindersForSchoolId = () => {
  printLog("HL: reminder_helper : getRemindersForSchoolId")
  return false
}


