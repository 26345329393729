import React from 'react';
import { Container } from "reactstrap";
import Parse from 'parse'

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MT from './MoneyTransactions'

const ToolsFee = (props) => {

    const email = Parse.User.current().attributes.username
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Fee Tools" breadcrumbItem="Starter Page" />
                    {(email == "vinay.anna@liltriangle.com" || email == "omprakash@liltriangle.com") ? <MT>
                    </MT> : <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <p className="text-primary" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                            UNDER DEVELOPMENT
                        </p>
                    </div>}
                </Container>
            </div>


        </React.Fragment>
    );
}

export default ToolsFee;