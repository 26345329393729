import React, { useState, useEffect } from 'react'
import Topbar from '../Topbar'
import { Card, CardBody, Row, Col, Label, CardTitle, CardHeader, Button, Badge, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "react-switch";
import ItemImage from "../../../assets/images/school-bag.png"
import Parse from "parse";
import { setSelecetdSchool, getInventoryKitsForOwnerId, deleteInventoryKit, editInventoryKit } from '../../../store/actions';

const AllItemGroups = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/inventory-item-group/:id",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-inventory-item-group/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;


    const [showDelete, setShowDelete] = useState(false);
    const [deleteObject, setDeleteObject] = useState(undefined);
    const [showPriceOnCategory, setShowPriceOnCategory] = useState(false);
    const [pricesForCategory, setPricesForCategory] = useState([])
    useEffect(() => {
        if (schoolId && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getInventoryKitsForOwnerId(schoolId, 'school')
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryKitsForOwnerId(franchiseId, 'franchise')
        }
    }, [franchiseId]);

    const handleDeleteItem = () => {
        props.deleteInventoryKit(deleteObject)
        setShowDelete(false);
    };

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };

    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };

    const getShowInStore = (isChecked, kit) => {
        kit.set("showInStore", isChecked);
        props.editInventoryKit(kit);
    }

    const getKitPricesForAllCategory = (kitId) => {

        Parse.Cloud.run("function_getKitPricesForAllCategory", {
            kitId: kitId,
        }).then((result) => {
            setPricesForCategory(result.kitCategoryPriceMap)
        },
            (error) => {
                console.log(error);
            }
        );
    }


    return (
        <div className='page-content'>
            <Topbar
                title={"Inventory Kits"}
                buttonName={"New Kit"}
                hideButton={false}
                link={schoolId ? `/new-items-group/${schoolId}` : `/franchise-new-items-group/${franchiseId}`}
            />
            <Modal isOpen={showPriceOnCategory}>
                <ModalHeader
                    toggle={() => {
                        setShowPriceOnCategory(!showPriceOnCategory)
                    }}>
                    Kit Prices Based On Region/Category
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <Table className="table table-striped table-bordered table-centered table-nowrap mb-0 table-sm m-1">
                                        <Thead className="thead-light">
                                            <Tr>
                                                <Th>#</Th>
                                                <Th>Category/Region</Th>
                                                <Th>Kit Price</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {pricesForCategory.length > 0 && (
                                                pricesForCategory.map((item, key) => (
                                                    <Tr key={key}>
                                                        <Td>{key + 1}</Td>

                                                        <Td>
                                                            <h6>{item.category.attributes.name}</h6>
                                                        </Td>
                                                        <Td>
                                                            <h6>{item.sellingPrice}</h6>
                                                        </Td>
                                                    </Tr>
                                                ))
                                            )}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='secondary'
                        className='btn'
                        onClick={() => {
                            setShowPriceOnCategory(!showPriceOnCategory)
                        }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
            {showDelete && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDelete(false);
                        setDeleteObject(undefined)
                    }}
                    onConfirm={() => {
                        handleDeleteItem();

                    }}
                >
                    {"Are you sure you want to delete"}
                    <br></br>
                </SweetAlert>
            )}
            {props.inventoryKits && props.inventoryKits.length > 0 ? (<Card>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Kit Name</th>
                                    <th>Cost</th>
                                    <th>Cost Based On Category</th>
                                    <th className='text-center'>Show in store</th>
                                    <th style={{ width: "20px" }}>
                                        Edit
                                    </th>
                                    <th style={{ width: "20px" }}>
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.inventoryKits.map((kit, key) =>
                                        <tr key={key}>
                                            <th>
                                                {kit.attributes.displayImage ?
                                                    <div>
                                                        <img className="rounded-circle avatar-xs" src={kit.attributes.displayImage._url} alt="" />
                                                    </div> :
                                                    <div className="maintenance-img">
                                                        <img src={ItemImage} alt="" className="rounded-circle avatar-xs" />
                                                    </div>

                                                }
                                            </th>
                                            <td>

                                                <Link to={match ? `/edit-inventory-items-group/${schoolId}/${kit.id}` : `/franchise-edit-inventory-items-group/${franchiseId}/${kit.id}`}>
                                                    {kit.attributes.name}
                                                </Link>


                                            </td>

                                            <td>
                                                <h6>{kit.attributes.sellingPrice ? kit.attributes.sellingPrice : "-"}</h6>
                                            </td>
                                            <td>
                                                <u className='text-primary'><p style={{ cursor: "pointer" }} className='text-primary' onClick={() => {
                                                    getKitPricesForAllCategory(kit.id)
                                                    setShowPriceOnCategory(true)
                                                }}>Show Prices</p></u>
                                            </td>

                                            <td className='text-center'>
                                                {/* <Switch
                                                    uncheckedIcon={<Offsymbol />}
                                                    checkedIcon={<OnSymbol />}
                                                    onColor="#626ed4"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        getShowInStore(e.target.checked, kit)
                                                    }}
                                                    checked={kit.attributes.showInStore || false}
                                                /> */}
                                                <div className="custom-control custom-switch mb-2" dir="ltr">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={"recurringModal" + kit.id}
                                                        checked={kit.attributes.showInStore == true}
                                                        onChange={(e) => getShowInStore(e.target.checked, kit)}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={"recurringModal" + kit.id}
                                                        onClick={(e) => e.stopPropagation()}
                                                    ></label>
                                                </div>

                                            </td>

                                            <td>

                                                <div className="flex-fill text-center ">
                                                    <Link to={match ? `/edit-inventory-items-group/${schoolId}/${kit.id}` : `/franchise-edit-inventory-items-group/${franchiseId}/${kit.id}`}>
                                                        <i className="bx bx-edit-alt"></i>
                                                    </Link>
                                                </div>

                                            </td>

                                            <td>
                                                <div
                                                    className="flex-fill text-center"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setDeleteObject(kit)
                                                        setShowDelete(true);
                                                    }}
                                                >
                                                    <i className="bx bxs-trash text-danger"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>) : (<div>
                {props.inventoryKits && props.inventoryKits.length <= 0 && <Card style={{ height: "400px" }}>
                    <CardBody>
                        <h4 style={{ marginTop: "150px" }} className='text-info text-center'>No Kits found .</h4>
                    </CardBody>
                </Card>}
            </div>)}
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryKits } = state.InventoryKits;
    return { selectedSchool, inventoryKits };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryKitsForOwnerId, deleteInventoryKit, editInventoryKit })(AllItemGroups)));