import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Container,
} from "reactstrap";
import Parse from "parse";
import toastr from "toastr";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { setSelecetdSchool } from "../../store/actions";
import Rating from "react-rating";
import RatingTooltip from "react-rating-tooltip";
import { use } from "echarts";

const KidExitFeedbackForm = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/exit-feedback-form/:kidId",
        exact: true,
        strict: false,
    });

    const kidId = match && match.params.kidId

    const [schoolObject, setSchoolObject] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [visitingPurpose, setVisitingPurpose] = useState([]);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [alldetails, setAlldetails] = useState(
        []
    );

    const [kidObject, setKidObject] = useState()
    const [kidName, setKidName] = useState();
    const [exitReason, setExitReason] = useState()
    const [feedback, setFeedBack] = useState()
    const [rating, setrating] = useState()
    const [serviceSatisfaction, setServiceSatisfaction] = useState()
    const [recommend, setRecommend] = useState()
    const [exitReasonError, setExitReasonError] = useState()
    const [feedbackError, setFeedBackError] = useState()
    const [ratingError, setratingError] = useState()
    const [serviceSatisfactionError, setServiceSatisfactionError] = useState()
    const [recommendError, setRecommendError] = useState()


    const [invalidForm, setInvalidForm] = useState(false)


    let YesorNo = ["Yes", "No"];
    let reason = ["Passed out", "Drop out", "Others"];
    const tooltipContentMiddle = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
    ];




    useEffect(() => {
        if (kidId) {
            fetchKidFromServer(kidId)
        }

    }, [kidId])

    useEffect(() => {

        if (kidObject) {
            setKidName(kidObject.kid.attributes.Name)
        }
    }, [kidObject])


    const fetchKidFromServer = (kidId,isSave) => {
        Parse.Cloud.run("function_exitFeedbackDetails", {
            kidId: kidId,
        }).then(
            (result) => {
                setIsLoading(false)
                console.log(result)
                if (result == "invalid") {
                    setInvalidForm("Invalid Link")
                } else if (result == "already submitted") {
                    setInvalidForm("Form already submitted")
                } else {
                    if(isSave){
                        saveForm()
                    }else{
                        setKidObject(result)
                    }
                    
                }


            },
            (error) => {
                console.log(error);
            }
        );
    };



    const isFormValid = () => {

        let canProceedToSave = true


        if (exitReason == undefined || exitReason.length === 0) {
            canProceedToSave = false
            setExitReasonError(true)
        } else {
            setExitReasonError(false)
        }

        if (recommend == undefined || recommend.length === 0) {
            canProceedToSave = false
            setRecommendError(true)
        } else {
            setRecommendError(false)
        }
        // if (serviceSatisfaction == undefined || serviceSatisfaction.length === 0) {
        //     canProceedToSave = false
        //     setServiceSatisfactionError(true)
        // } else {
        //     setServiceSatisfactionError(false)
        // }
        if (rating == undefined || rating.length === 0) {
            canProceedToSave = false
            setratingError(true)
        } else {
            setratingError(false)
        }
        if (feedback == undefined || feedback.length === 0) {
            canProceedToSave = false
            setFeedBackError(true)
        } else {
            setFeedBackError(false)
        }

        if (canProceedToSave) {
            fetchKidFromServer(kidId,true)
            
        }
    }

    const saveForm = () => {
        setSubmittingForm(true)
        const exitFeedback = Parse.Object.extend('KidExitFeedback');
        var exit = new exitFeedback();

        exit.set("kidId", kidId)
        exit.set("exitReason", exitReason)
        exit.set("feedback", feedback)
        exit.set("rating", rating)
        exit.set("serviceSatisfaction", serviceSatisfaction == "Yes" ? true : false)
        exit.set("recommend", recommend == "Yes" ? true : false)
        exit.set("schoolId", kidObject.school.id);
        // console.log(exit)
        exit.save().then(
            (result) => {
                setSubmittingForm(false)
                setIsFormSubmitted(true)
            },
            (error) => {
                setSubmittingForm(false)
            }
        );
    }

    //// Utilities functions
    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }


    const borderStyle = {
        border: "2px solid lightGray",
        borderRadius: "50px 50px",
        minHeight: `calc(100vh - 170px)`,
        position: "relative"
    }

    const getTextUI = (leftLabel, rightLabel, key) => {
        return (<React.Fragment key={key}><Row lg={5} md={8} sm={12} xs={12} className="justify-content-center mt-1"><div className="text-left col-6 "> {leftLabel} < Label className="float-right" >:</Label ></div > <div className="text-left col-6">{rightLabel}</div></Row></React.Fragment>)
    }


    return (
        <React.Fragment>
            <Container
                fluid={true}
                className={match ? "" : "page-content"}
            >
                {!isLoading && !isFormSubmitted && !invalidForm && (
                    <Row>
                        <Col lg={!match ? 12 : 8} className="mx-auto">
                            {(match) && (
                                <div className="text-center mt-3">
                                    {kidObject && kidObject.school.attributes.logo && (
                                        <img
                                            src={kidObject && kidObject.school.attributes.logo._url}
                                            width="100"
                                            alt=""
                                            className="img-thumbnail rounded-circle"
                                        />
                                    )}
                                    <h3>
                                        {kidObject
                                            ? props.t(`Welcome to ${kidObject && kidObject.school.attributes.Name}`)
                                            : props.t("Welcome")}{" "}
                                    </h3>
                                </div>
                            )}
                            <Card>
                                <CardBody>
                                    <AvForm
                                        className="outer-repeater"
                                        style={{ border: "1px" }}
                                        onValidSubmit={isFormValid}
                                    >
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                                <h5>Dear Parent!!</h5>
                                                <h6>Please take a moment to share your thoughts through this feedback form on our institutes.</h6>
                                                <hr />
                                                <FormGroup>
                                                    <Label htmlFor="vf-name">
                                                        {"Name"}{" "}
                                                    </Label>
                                                    <AvField
                                                        type="text"
                                                        name={"vf-name-f"}
                                                        className="form-control"
                                                        value={kidName}
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                                <div className="widget mb-2 pb-2">
                                                    <Label className="">{"Reason for Discontinued"}</Label>
                                                    <Label className="text-danger">*</Label>

                                                    {exitReasonError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <div>
                                                        {reason.map((choice, keyInside) => (
                                                            <div
                                                                className="form-check  ml-3"
                                                                key={"typesOfVisitor" + keyInside}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id={"radio" + "typesOfVisitor" + keyInside}
                                                                    value={choice}
                                                                    checked={exitReason === choice}
                                                                    onChange={() => {
                                                                        if (exitReasonError) {
                                                                            setExitReasonError(false)
                                                                        }
                                                                        setExitReason(choice)
                                                                    }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={"radio" + "typesOfVisitor" + keyInside}
                                                                >
                                                                    {choice}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="widget mb-2 pb-2">
                                                    <Label className="">{"Do you Recommend our Intitute for your friends and families"}</Label>
                                                    <Label className="text-danger">*</Label>

                                                    {recommendError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <div>
                                                        {YesorNo.map((choice, keyInside) => (
                                                            <div
                                                                className="form-check  ml-3"
                                                                key={"typesOfVisitor" + keyInside}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id={"radio" + "typesOfVisitor" + keyInside}
                                                                    value={choice}
                                                                    checked={recommend === choice}
                                                                    onChange={() => {
                                                                        if (recommendError) {
                                                                            setRecommendError(false)

                                                                        }
                                                                        setRecommend(choice)
                                                                    }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={"radio" + "typesOfVisitor" + keyInside}
                                                                >
                                                                    {choice}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                {false &&<div className="widget mb-2 pb-2">
                                                    <Label className="">{"Are you Satisfaction our Services"}</Label>
                                                    <Label className="text-danger">*</Label>

                                                    {serviceSatisfactionError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <div>
                                                        {YesorNo.map((choice, keyInside) => (
                                                            <div
                                                                className="form-check  ml-3"
                                                                key={"typesOfVisitor" + keyInside}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id={"radio" + "typesOfVisitor" + keyInside}
                                                                    value={choice}
                                                                    checked={serviceSatisfaction === choice}
                                                                    onChange={() => {
                                                                        if (serviceSatisfactionError) {
                                                                            setServiceSatisfactionError(false)

                                                                        }
                                                                        setServiceSatisfaction(choice)
                                                                    }}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={"radio" + "typesOfVisitor" + keyInside}
                                                                >
                                                                    {choice}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>}

                                                <FormGroup>
                                                    <Label htmlFor="vf-meetingPerson">
                                                        {"How satisfied are you with our service:"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-meetingPerson" className="text-danger">
                                                        *
                                                    </Label>
                                                    {ratingError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}

                                                    <Col lg={2}>
                                                        <RatingTooltip
                                                            className="float-left"
                                                            max={10}
                                                            tooltipContent={tooltipContentMiddle}
                                                            ActiveComponent={
                                                                <i
                                                                    key={"active_9"}
                                                                    className="mdi mdi-star text-primary"
                                                                    style={{}}
                                                                />
                                                            }
                                                            InActiveComponent={
                                                                <i
                                                                    key={"active_09"}
                                                                    className="mdi mdi-star-outline text-muted"
                                                                    style={{}}
                                                                />
                                                            }
                                                            onChange={(r) => { setrating(r) }}
                                                        />
                                                    </Col>

                                                </FormGroup>

                                                <FormGroup>
                                                    <Label htmlFor="vf-phone"> {"Feedback:"}</Label>
                                                    <Label htmlFor="vf-phone" className="text-danger">*</Label>
                                                    {feedbackError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}

                                                    <textarea
                                                        name={"vf-phone-f"}
                                                        rows={4}
                                                        className="inner form-control"
                                                        placeholder={props.t("Feedback")}
                                                        value={feedback}
                                                        onChange={(e) => {
                                                            if (feedbackError) {
                                                                setFeedBackError(false)

                                                            }
                                                            setFeedBack(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>

                                                <div style={{ display: "flex" }}>
                                                    <Button
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            width: "180px",
                                                        }}
                                                        type="submit"
                                                        color="primary"
                                                        disabled={submittingForm}
                                                    >
                                                        {submittingForm && < i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>}
                                                        {props.t("SUBMIT")}
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}

                {isFormSubmitted && !invalidForm && (

                    <Card style={{ height: `calc(100vh)` }}>
                        <CardBody>
                            <center>
                                <div style={borderStyle} className="mt-5 pt-5 mb-5 pb-5">
                                    <h1 className="mt-1 pt-1">
                                        Thank you for submitting feedback<i style={{ fontSize: "50px" }}
                                            className="fa fa-check-circle align-middle bx-flashing text-success ml-2 pb-2"
                                            aria-hidden="true"
                                        ></i>
                                    </h1>


                                </div>
                            </center>
                        </CardBody>
                    </Card>
                )}

                {invalidForm && (

                    <Card style={{ height: `calc(100vh)` }}>
                        <CardBody>
                            <center>
                                <div style={borderStyle} className="mt-5 pt-5 mb-5 pb-5">
                                    <h1 className="mt-1 pt-1">
                                        {invalidForm}
                                    </h1>


                                </div>
                            </center>
                        </CardBody>
                    </Card>
                )}



                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Form...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment >
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
        })(KidExitFeedbackForm)
    )
);