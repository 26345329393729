import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Table, Nav, NavItem, NavLink, TabContent, TabPane, Button, Form, FormGroup, Label, Input, Progress, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import classnames from 'classnames';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import Moment from 'moment';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import TopBar from '../Inventory/Topbar';
import { setSelecetdSchool } from '../../store/actions';
import Compressor from 'compressorjs';
import { IMAGE_COMPRESSION_RATE } from '../../Constents';
import { v4 as uuidv4 } from "uuid";
import * as WhiteLabel from '../../components/Common/WhiteLable'

function addBlog(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/add-blog/:schoolId/:id",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-add-blog/:franchiseId/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const frachiseId = match && match.params.frachiseId;
    const id = (franchiseMatch ?? match).params.id;

    const Content = ["VIDEO", "LINK", "TEXT"];

    const [activeTraType, setActiveTraType] = useState("VIDEO");
    const [actTab, setactTab] = useState("VIDEO");
    const [textPhoto, setTextPhoto] = useState();
    const [textDocs, setTextDocs] = useState();

    const [textPhotoUploadProgress, setTextPhotoUploadProgress] = useState(0);
    const [textDocsUploadProgress, setTextDocsUploadProgress] = useState(0);

    const [videoTitle, setVideoTitle] = useState();
    const [linkTitle, setLinkTitle] = useState();
    const [textTitle, setTextTitle] = useState();

    const [videoTitleError, setVideoTitleError] = useState();
    const [linkTitleError, setLinkTitleError] = useState();
    const [textTitleError, setTextTitleError] = useState();

    const [videoDescription, setVideoDescription] = useState();
    const [linkDescription, setLinkDescription] = useState();
    const [textDescription, setTextDescription] = useState();

    const [videoDescriptionError, setVideoDescriptionError] = useState();
    const [linkDescriptionError, setLinkDescriptionError] = useState();
    const [textDescriptionError, setTextDescriptionError] = useState();

    const [videoUrl, setVideoUrl] = useState();
    const [linkUrl, setLinkUrl] = useState();

    const [videoUrlError, setVideoUrlError] = useState();
    const [linkUrlError, setLinkUrlError] = useState();

    const [isInvalidUrl, setIsInvalidUrl] = useState(false);
    const [mediaCategories, setMediaCategories] = useState([])
    const [showSelfDelete, setShowSelfDelete] = useState(false)

    const [albumImageList, setAlbumImageList] = useState([]);
    const [albumUUIDId, setAlbumUUIDId] = useState();
    const [imagesList, setImagesList] = useState();
    const [currentIndexForUpload, setCurrentIndexForUpload] = useState(0);
    const [progressText, setProgressText] = useState();

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);


    const inputStyle = {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    };

    function getFileTypeImage(fileExtension) {
        if (fileExtension === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtension === "ppt" || fileExtension === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "doc" || fileExtension === "docx") {
            return "bx bxs-file-doc";
        }

        return "bx bxs-file-blank";
    }

    const showTextPhotoUploadProgress = () => {
        if (textPhoto && textPhotoUploadProgress >= 1 && textPhotoUploadProgress < 100) {
            return true
        } else if (textPhoto && !textPhoto._url) {
            return true
        } else {
            return false
        }
    }

    const showTextDocsUploadProgress = () => {
        if (textDocs && textDocsUploadProgress >= 1 && textDocsUploadProgress < 100) {
            return true
        } else if (textDocs && !textDocs._url) {
            return true
        } else {
            return false
        }
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
        };
    }

    function handleTextPhotoUploadChange(e, files) {
        setTextPhoto(undefined)
        setTextPhotoUploadProgress(1)
        let fileExtension = e.target.files[0].name.split(".").pop();

        let tempFile = {
            actualFileName: e.target.files[0].name,
            fileExtension: fileExtension.toLowerCase(),
            fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
            file: e.target.files[0],
        };

        var name = tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + tempFile.fileExtension;
        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            setTextPhotoUploadProgress(value * 100)
                        }
                    },
                })
                .then(
                    (result) => {

                        setTextPhoto(result)
                        // console.log("text photo", result)
                        setTextPhotoUploadProgress(false)
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        });
        setTextPhoto(tempFile.actualFileName)
    }


    function handleImageUploadChange(e) {
        let tempFileList = [];
        // console.log(e.target.files.length);
        for (const i in e.target.files) {

            if (e.target.files.length > i) {
                let tempFile = e.target.files[i];
                if (tempFile && tempFile.name) {
                    let tempfileExtension = tempFile.name.split(".");
                    let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
                    let newFile = {
                        id: "fiele_" + i,
                        file: tempFile,
                        type: "Image",
                        downloadeble: true,
                        actualFileName: tempFile.name,
                        name: tempfileExtension[0],
                        extention: tempExt,
                        extentionIcon: getFileTypeImage(tempExt),
                        preview: URL.createObjectURL(tempFile)
                    }
                    tempFileList.push(newFile);
                }
            }
        }
        setCurrentIndexForUpload(0);
        setImagesList(tempFileList);
    }

    useEffect(() => {
        if (currentIndexForUpload >= 0 && imagesList) {
            if (imagesList.length > currentIndexForUpload) {
                onImageListUpload(imagesList[currentIndexForUpload], currentIndexForUpload);
            } else {
                setCurrentIndexForUpload(-1);
                setProgressText()
                setImagesList([]);
            }
        }
    }, [imagesList, currentIndexForUpload]);

    function onImageListUpload(file, count) {
        setProgressText(props.t('Uploading ') + (count + 1) + " of " + imagesList.length)
        new Compressor(file.file, {
            quality: IMAGE_COMPRESSION_RATE, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                convertImageToBase64(file, count, compressedResult)
            },
        });
    }

    function convertImageToBase64(file, count, compressedResult) {
        var albumId = albumUUIDId ?? uuidv4()
        setAlbumUUIDId(albumId)
        var name = `file`;
        if (file.type === "Image") {
            name = name + "." + file.extention;
        }
        getBase64(compressedResult, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setProgressText(`${props.t('Uploading ') + (count + 1) + " of " + imagesList.length + " Images "} ( ${(value * 100).toFixed(2)}% )`)
                    }
                }
            }).then(
                result => {
                    let parseImagesList = [...albumImageList]
                    var Files = Parse.Object.extend("Album");
                    var fileObject = new Files();
                    fileObject.set("albumId", albumId);
                    fileObject.set("image", result);
                    // fileObject.set("schoolId", schoolId)
                    parseImagesList.push(fileObject)
                    setAlbumImageList(parseImagesList)
                    if (imagesList.length > currentIndexForUpload) {
                        setCurrentIndexForUpload((currentIndexForUpload + 1))
                    } else {
                        setCurrentIndexForUpload(-1);
                        setProgressText()
                        setImagesList([]);
                    }
                },
                error => {
                    setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                }
            );
        });
    }

    function handleTextDocsUploadChange(e) {
        setTextDocs(undefined);
        setTextDocsUploadProgress(1);

        let fileExtension = e.target.files[0].name.split(".").pop().toLowerCase();
        let tempFile = {
            actualFileName: e.target.files[0].name,
            fileExtension: fileExtension,
            fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
            file: e.target.files[0],
        };

        var name = tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + tempFile.fileExtension;
        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            setTextDocsUploadProgress(value * 100);
                        }
                    },
                })
                .then(
                    (result) => {
                        setTextDocs(result);
                        setTextDocsUploadProgress(false);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        });

        setTextDocs(tempFile.actualFileName);
    }


    useEffect(() => {
        let MediaCategories = Parse.Object.extend("MediaCategories");
        let query = new Parse.Query(MediaCategories);

        if (franchiseMatch) {
            query.equalTo('targetAudienceId', frachiseId)
        } else {
            query.equalTo('targetAudienceId', schoolId)
        }
        query.descending('createdAt')
        query.find().then(
            (result) => {
                // console.log(result)
                setMediaCategories(result)
            },
            (error) => {
                console.log(error)
            }
        );

    }, [])

    function getDateTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }

    const validateYouTubeUrl = (url) => {
        const youtubeUrlRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        return youtubeUrlRegex.test(url);
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        setVideoUrl(inputValue);
        setIsInvalidUrl(!validateYouTubeUrl(inputValue));
    };

    // console.log(textPhoto)
    const handleSave = () => {

        let checkSendMessage = true;

        if (actTab === 'VIDEO') {
            if (!videoTitle) {
                setVideoTitleError(true);
                checkSendMessage = false;
            }

            if (!videoDescription) {
                setVideoDescriptionError(true);
                checkSendMessage = false;
            }
            if (!videoUrl) {
                setVideoUrlError(true);
                checkSendMessage = false;
            }
        } else if (actTab === 'LINK') {
            if (!linkTitle) {
                setLinkTitleError(true);
                checkSendMessage = false;
            }
            if (!linkDescription) {
                setLinkDescriptionError(true);
                checkSendMessage = false;
            }
            if (!linkUrl) {
                setLinkUrlError(true);
                checkSendMessage = false;
            }
        } else if (actTab === 'TEXT') {
            if (!textTitle) {
                setTextTitleError(true);
                checkSendMessage = false;
            }
            if (!textDescription) {
                setTextDescriptionError(true);
                checkSendMessage = false;
            }
        }
        if (!checkSendMessage) {
            return
        }


        let MediaCategories = Parse.Object.extend("MediaCategories");
        let query = new Parse.Query(MediaCategories);

        query.equalTo('objectId', id);
        query.first().then(
            (rMediaCategory) => {
                // console.log(rMediaCategory)

                const MediaLink = Parse.Object.extend("MediaLink");
                const mediaLink = new MediaLink();

                if (actTab === 'VIDEO') {
                    mediaLink.set("title", videoTitle);
                    mediaLink.set("description", videoDescription);
                    mediaLink.set("url", videoUrl);
                    mediaLink.set("mediaType", "YouTube");
                }
                if (actTab === 'LINK') {
                    mediaLink.set("title", linkTitle);
                    mediaLink.set("description", linkDescription);
                    mediaLink.set("url", linkUrl);
                    mediaLink.set("mediaType", "URL");
                }
                if (actTab === 'TEXT') {
                    mediaLink.set("title", textTitle);
                    mediaLink.set("description", textDescription);
                    if (albumUUIDId) {
                        mediaLink.set('albumId', albumUUIDId)
                        mediaLink.set("previewImage", albumImageList[0].attributes.image);
                        Parse.Object.saveAll(albumImageList).then(
                            (results) => { },
                            (error) => {
                                console.log("Error", error);
                            }
                        );
                    } else if (textPhoto) {
                        mediaLink.set("file", textPhoto);
                    } else {
                        mediaLink.set("file", textDocs);
                    }
                    mediaLink.set("mediaType", "TEXT");
                }

                if (textPhoto) {
                    mediaLink.set("previewImage", textPhoto);
                }

                mediaLink.set("selfDelete", showSelfDelete);

                if (franchiseMatch) {
                    mediaLink.set('targetAudienceId', frachiseId)
                } else {
                    mediaLink.set("targetAudienceId", schoolId);
                }
                mediaLink.set("tags", rMediaCategory.attributes.filters);
                mediaLink.set("createdBy", Parse.User.current().attributes.teacherId);

                mediaLink.save().then(
                    (rMediaLink) => {

                        // console.log("SUCCESS", rMediaLink);
                        window.history.back()
                    },
                    (error) => {
                        console.log("ERROR", error);
                    }
                );
            },
            (error) => {
                console.log("ERROR", error);
            }
        );
    }

    return (
        <div className='page-content'>
            <TopBar
                title={"All Card"}
                buttonName={"Create Card"}
                link={franchiseMatch ? `/frachise-add-card/${frachiseId}` : `/add-card/${schoolId}`}
                hideButton={false}
            />
            <Card>
                <CardBody>
                    <div className="ml-4" style={{ minHeight: '60vh' }}>
                        <Form>
                            <h4 className="mb-2">{props.t('Choose Your Content:')} </h4>
                            <Row>
                                <Col lg={7}>
                                    <div>
                                        <Nav pills className="bg-light rounded justify-content-center" role="tablist">
                                            {Content.map((val, key) => (
                                                <NavItem key={key} className="mt-3  mb-3" style={{ marginRight: '130px' }}>
                                                    <NavLink
                                                        className={classnames({ active: actTab === val })}
                                                        onClick={() => {
                                                            setActiveTraType(val);
                                                            setactTab(val);
                                                        }}
                                                    >
                                                        {val}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>

                                        {activeTraType === "VIDEO" && (
                                            <Form>
                                                <FormGroup className="mb-1 mt-3">
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >Title:<span className="text-danger">*</span></Label>
                                                                <Input type="text"
                                                                    className="form-control"
                                                                    placeholder='Title'
                                                                    value={videoTitle}
                                                                    onChange={(e) => {
                                                                        setVideoTitle(e.target.value)
                                                                        setVideoTitleError(false)
                                                                    }} />
                                                                {videoTitleError && <p className='text-danger'>* Enter Title</p>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >Description:<span className="text-danger">*</span></Label>
                                                                <Input
                                                                    type="textarea"
                                                                    rows="10"
                                                                    className="form-control"
                                                                    placeholder='Description/Content'
                                                                    value={videoDescription}
                                                                    onChange={(e) => {
                                                                        setVideoDescription(e.target.value)
                                                                        setVideoDescriptionError(false)
                                                                    }} />
                                                                {videoDescriptionError && <p className='text-danger'>* Enter description</p>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >Link/URL:<span className="text-danger">*</span></Label>
                                                                <Input type="text"
                                                                    className="form-control"
                                                                    placeholder='URL: http://youtute.com'
                                                                    value={videoUrl}
                                                                    onChange={(e) => {
                                                                        handleChange(e)
                                                                        setVideoUrlError(false)
                                                                    }} />
                                                                {videoUrlError && <p className='text-danger'>* Enter Video URL</p>}
                                                                {isInvalidUrl ? (
                                                                    <p className="text-danger">Please enter a valid YouTube URL.</p>
                                                                ) : null}                                                                    </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <small className='text-danger'>*Disclaimer: {WhiteLabel.getAppName()} is not liable for the ads or content found on above websites/Link.</small>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Col lg='12'>
                                                        <div className="custom-checkbox ml-3 py-2">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                onChange={() => {
                                                                    setShowSelfDelete(!showSelfDelete);
                                                                }}
                                                                checked={showSelfDelete}
                                                            />
                                                            <label className="custom-control-label m-1" id="selfDelete" onClick={() => { setShowSelfDelete(!showSelfDelete) }}>
                                                                Self Delete
                                                            </label>
                                                            <span>
                                                                <i className="fas fa-question-circle ml-2" id="selfDelete"></i>
                                                                <UncontrolledTooltip placement="top" target="selfDelete">
                                                                    On Enabling Only you can delete this card.
                                                                </UncontrolledTooltip>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        )}
                                        {activeTraType === "LINK" && (
                                            <Form>
                                                <FormGroup className="mb-1 mt-3">
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >Title:<span className="text-danger">*</span></Label>
                                                                <Input type="text"
                                                                    className="form-control"
                                                                    placeholder='Title'
                                                                    value={linkTitle}
                                                                    onChange={(e) => {
                                                                        setLinkTitle(e.target.value)
                                                                        setLinkTitleError(false)
                                                                    }} />
                                                                {linkTitleError && <p className='text-danger'>* Enter Title</p>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >Description:<span className="text-danger">*</span></Label>
                                                                <Input
                                                                    type="textarea"
                                                                    rows="10"
                                                                    className="form-control"
                                                                    placeholder='Description/Content'
                                                                    value={linkDescription}
                                                                    onChange={(e) => {
                                                                        setLinkDescription(e.target.value)
                                                                        setLinkDescriptionError(false)
                                                                    }} />
                                                                {linkDescriptionError && <p className='text-danger'>* Enter Description</p>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >Link/URL:<span className="text-danger">*</span></Label>
                                                                <Input type="text"
                                                                    className="form-control"
                                                                    placeholder='URL: http://story.com'
                                                                    value={linkUrl}
                                                                    onChange={(e) => {
                                                                        setLinkUrl(e.target.value)
                                                                        setLinkUrlError(false)
                                                                    }} />
                                                                {linkUrlError && <p className='text-danger'>* Enter URL</p>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <small className='text-danger'>*Disclaimer: {WhiteLabel.getAppName()} is not liable for the ads or content found on above websites/Link.</small>
                                                </FormGroup>
                                                <Row>
                                                    <Col>
                                                        <div className="popup-gallery">
                                                            <div className="img-fluid float-left">
                                                                <Row>
                                                                    <Col>
                                                                        <img
                                                                            src={textPhoto ? textPhoto._url : ""}
                                                                            onClick={() => {
                                                                                // setImagePopup(true);
                                                                                // setphotoIndex(key)
                                                                            }}
                                                                            alt=""
                                                                            width="50"
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <h5>{textPhoto ? textPhoto._name : ''}</h5>
                                                                    </Col>
                                                                    <Col className='float-right'>
                                                                        {textPhoto && textPhoto._url && <h5><i className="bx bxs-trash  text-danger" onClick={() => { setTextPhoto(undefined) }} style={{ cursor: 'pointer' }}></i></h5>}
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        </div>
                                                    </Col>

                                                </Row>


                                                {
                                                    showTextPhotoUploadProgress() && (
                                                        <div className="d-flex">
                                                            <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                                <div className="border p-3">
                                                                    <Row>
                                                                        <div
                                                                            className="avatar ml-3"
                                                                            style={{ width: "5rem", height: "5rem" }}
                                                                        >
                                                                            <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                                <i
                                                                                    className={getFileTypeImage(textPhoto)}
                                                                                ></i>
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="float-right ml-4"
                                                                            style={{ width: "200px" }}
                                                                        >
                                                                            <div className="text-muted font-weight-bold">
                                                                                {textPhoto}
                                                                            </div>
                                                                            <div className=" mt-3">
                                                                                <Progress
                                                                                    color="primary"
                                                                                    value={textPhotoUploadProgress}
                                                                                ></Progress>
                                                                            </div>
                                                                        </div>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    )}


                                                <Row>
                                                    <Col>
                                                        <FormGroup className="mt-2 mb-2 p-1">

                                                            <Label
                                                                htmlFor={"input-photo-expense"}
                                                                color="primary"
                                                                className="btn btn-primary"
                                                            >
                                                                Add priview image
                                                                <input
                                                                    id={"input-photo-expense"}
                                                                    style={inputStyle}
                                                                    type="file"
                                                                    onChange={(e) => {
                                                                        handleTextPhotoUploadChange(e);
                                                                    }}
                                                                    accept="image/*"
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <FormGroup>
                                                    <Col lg='12'>
                                                        <div className="custom-checkbox ml-3 py-2">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                onChange={() => {
                                                                    setShowSelfDelete(!showSelfDelete);
                                                                }}
                                                                checked={showSelfDelete}
                                                            />
                                                            <label className="custom-control-label m-1" id="selfDelete" onClick={() => { setShowSelfDelete(!showSelfDelete) }}>
                                                                Self Delete
                                                            </label>
                                                            <span>
                                                                <i className="fas fa-question-circle ml-2" id="selfDelete"></i>
                                                                <UncontrolledTooltip placement="top" target="selfDelete">
                                                                    On Enabling Only you can delete this card.
                                                                </UncontrolledTooltip>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        )}
                                        {activeTraType === "TEXT" && (
                                            <Form>
                                                <FormGroup className="mb-1 mt-3">
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >Title:<span className="text-danger">*</span></Label>
                                                                <Input type="text"
                                                                    className="form-control"
                                                                    placeholder='Title'
                                                                    value={textTitle}
                                                                    onChange={(e) => {
                                                                        setTextTitle(e.target.value)
                                                                        setTextTitleError(false)
                                                                    }} />
                                                                {textTitleError && <p className='text-danger'>* Enter Title</p>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >Description:<span className="text-danger">*</span></Label>
                                                                <Input
                                                                    type="textarea"
                                                                    rows="10"
                                                                    className="form-control"
                                                                    placeholder='Description/Content'
                                                                    value={textDescription}
                                                                    onChange={(e) => {
                                                                        setTextDescription(e.target.value)
                                                                        setTextDescriptionError(false)
                                                                    }} />
                                                                {textDescriptionError && <p className='text-danger'>* Enter Description</p>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>


                                                    <Row>
                                                        <Col>
                                                            <div className="popup-gallery">
                                                                <div className="img-fluid float-left">
                                                                    {albumImageList && albumImageList.map((img, key) => (
                                                                        <Row className='m-2' key={key}>
                                                                            <Col>
                                                                                <img
                                                                                    src={img.attributes.image ? img.attributes.image._url : ""}
                                                                                    onClick={() => {
                                                                                        // setImagePopup(true);
                                                                                        // setphotoIndex(key)
                                                                                    }}
                                                                                    alt=""
                                                                                    width="50"
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <h5>{img.attributes.image ? img.attributes.image._name : ''}</h5>
                                                                            </Col>
                                                                            <Col className='d-flex justify-content-end'>
                                                                                {img.attributes.image && img.attributes.image._url && (
                                                                                    <h5>
                                                                                        <i
                                                                                            className="bx bxs-trash text-danger"
                                                                                            onClick={() => {
                                                                                                let list = [...albumImageList];
                                                                                                // console.log(list)
                                                                                                list.splice(key, 1);
                                                                                                // console.log(list)
                                                                                                setAlbumImageList(list)
                                                                                            }}
                                                                                            style={{ cursor: 'pointer' }}
                                                                                        ></i>
                                                                                    </h5>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="popup-gallery">
                                                                <div className="img-fluid float-left">
                                                                    <Row>
                                                                        <Col>
                                                                            <img
                                                                                src={textDocs ? textDocs._url : ""}
                                                                                onClick={() => {
                                                                                    // setImagePopup(true);
                                                                                    // setphotoIndex(key)
                                                                                }}
                                                                                alt=""
                                                                                width="50"
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <h5>{textDocs ? textDocs._name : ''}</h5>
                                                                        </Col>
                                                                        <Col className='d-flex justify-content-end'>
                                                                            {textDocs && textDocs._url && <h5><i className="bx bxs-trash  text-danger" onClick={() => { setTextDocs(undefined) }} style={{ cursor: 'pointer' }}></i></h5>}
                                                                        </Col>
                                                                    </Row>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    {
                                                        showTextPhotoUploadProgress() && (
                                                            <div className="d-flex">
                                                                <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                                    <div className="border p-3">
                                                                        <Row>
                                                                            <div
                                                                                className="avatar ml-3"
                                                                                style={{ width: "5rem", height: "5rem" }}
                                                                            >
                                                                                <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                                    <i
                                                                                        className={getFileTypeImage(textPhoto)}
                                                                                    ></i>
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                className="float-right ml-4"
                                                                                style={{ width: "200px" }}
                                                                            >
                                                                                <div className="text-muted font-weight-bold">
                                                                                    {textPhoto}
                                                                                </div>
                                                                                <div className=" mt-3">
                                                                                    <Progress
                                                                                        color="primary"
                                                                                        value={textPhotoUploadProgress}
                                                                                    ></Progress>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                        )}
                                                    {
                                                        showTextDocsUploadProgress() && (
                                                            <div className="d-flex">
                                                                <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                                    <div className="border p-3">
                                                                        <Row>
                                                                            <div
                                                                                className="avatar ml-3"
                                                                                style={{ width: "5rem", height: "5rem" }}
                                                                            >
                                                                                <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                                    <i
                                                                                        className={getFileTypeImage(textDocs)}
                                                                                    ></i>
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                className="float-right ml-4"
                                                                                style={{ width: "200px" }}
                                                                            >
                                                                                <div className="text-muted font-weight-bold">
                                                                                    {textDocs}
                                                                                </div>
                                                                                <div className=" mt-3">
                                                                                    <Progress
                                                                                        color="primary"
                                                                                        value={textDocsUploadProgress}
                                                                                    ></Progress>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                        )}

                                                    <div className='text-center' style={{ height: "30px" }}>
                                                        {progressText ? <label style={{ fontSize: "3mm" }} className=" mb-1">{progressText}<i className='align-middle font-size-16 ml-2 text-dark bx bx-loader bx-spin'></i></label> :
                                                            <>
                                                                {albumImageList && albumImageList.length > 0 &&
                                                                    <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                                                                        {`${albumImageList.length} images attached`}
                                                                        <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                                                                            setAlbumImageList([])
                                                                        }}></i>
                                                                    </div>
                                                                }
                                                            </>}
                                                    </div>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup className="mt-2 mb-2 p-1">
                                                                <Label
                                                                    htmlFor={"input-file-expense"}
                                                                    color="primary"
                                                                    className="btn btn-primary m-2"
                                                                >
                                                                    Attach Document
                                                                    <input
                                                                        id={"input-file-expense"}
                                                                        style={inputStyle}
                                                                        type="file"
                                                                        onChange={(e) => {
                                                                            handleTextDocsUploadChange(e);
                                                                        }}
                                                                        accept=".doc,.docx,.pdf,.xlsx,.mp3,.wav,.ogg,.mpeg,.mpg"
                                                                    />
                                                                </Label>
                                                                <Label
                                                                    htmlFor={"input-photo-blog"}
                                                                    color="primary"
                                                                    className="btn btn-primary m-2"
                                                                >
                                                                    Attach Photo
                                                                    <input
                                                                        type="file"
                                                                        name="upload-images-card"
                                                                        className="sr-only"
                                                                        id="input-photo-blog"
                                                                        accept="image/*"
                                                                        onChange={(e) => {
                                                                            handleImageUploadChange(e);
                                                                        }}
                                                                        multiple
                                                                    />
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Col lg='12'>
                                                        <div className="custom-checkbox ml-3 py-2">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                onChange={() => {
                                                                    setShowSelfDelete(!showSelfDelete);
                                                                }}
                                                                checked={showSelfDelete}
                                                            />
                                                            <label className="custom-control-label m-1" id="selfDelete" onClick={() => { setShowSelfDelete(!showSelfDelete) }}>
                                                                Self Delete
                                                            </label>
                                                            <span>
                                                                <i className="fas fa-question-circle ml-2" id="selfDelete"></i>
                                                                <UncontrolledTooltip placement="top" target="selfDelete">
                                                                    On Enabling Only you can delete this card.
                                                                </UncontrolledTooltip>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className='ml-3 py-3 d-flex justify-content-end'>
                        <Button color='primary' onClick={() => { handleSave() }} >
                            Post
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div >
    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    return { selectedSchool, schools };
}

export default withRouter(connect(mapStatetoProps, { setSelecetdSchool })(withNamespaces()(addBlog)));
