import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import { setSelecetdSchool, getClassroomsForSchoolId, getAllKids, } from "../../store/actions";
import {
  Col,
  Nav,
  NavItem,
  Row,
  NavLink,
  Card,
  CardBody,
  TabContent,
  TabPane,
  CardTitle, 
  CardSubtitle,
  Label,
  Form,
  FormGroup,
  Button,

} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import AttendanceReport from "./attendanceReport";
import EnquirySchoolReport from "./EnquiryReoprts/EnquirySchoolReport";
import SchoolFeeReports from "./FeeReports/SchoolFeeReports";
import SchoolReport from "./schoolReport";
import ExpenseReports from "./ExpenseReports";
import RandomReports from "./RandomReports";
import ProgramReport from "./ProgramReport";

const ReportList = (props) => {
  const match1 = matchPath(props.history.location.pathname, {
    path: "/reports/:schoolId",
    exact: true,
    strict: false,
  });
  const matchStaff = matchPath(props.history.location.pathname, {
    path: "/reports-staff/:schoolId",
    exact: true,
    strict: false,
  });
  const matchDaycare = matchPath(props.history.location.pathname, {
    path: "/reports-daycare/:schoolId",
    exact: true,
    strict: false,
  });
  const matchClass = matchPath(props.history.location.pathname, {
    path: "/reports-class/:schoolId",
    exact: true,
    strict: false,
  });

  const matchClassWise = matchPath(props.history.location.pathname, {
    path: "/reports-classwise/:schoolId",
    exact: true,
    strict: false,
  });

  const match = match1 ?? matchStaff ?? matchDaycare ?? matchClass ?? matchClassWise;

  const schoolId = match.params.schoolId;

  const [showUI, setShowUI] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  const [classroomList, setClassroomList] = useState([]);

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);
  useEffect(() => {
    if (showUI) {
      if (schoolId) {
        props.getClassroomsForSchoolId(schoolId);
        props.getAllKids(schoolId);
      }
    } else {
      setTimeout(myFunction, 1000);
    }
    
  }, [schoolId,showUI]);

  const myFunction = () => {
    setShowUI(true);
  }

  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();
  const [selectedStudentError, setSelectedStudentError] = useState(false);

  const allClassObject = () => {
    let tempSelectObj = {}

    tempSelectObj.id = "all"
    tempSelectObj.value = "all"
    tempSelectObj.label = "All Classrooms"
    return tempSelectObj;
  }
  const [selectedClassroom, setSelectedClassroom] = useState(allClassObject());


  

  useEffect(() => {
    if (props.classrooms && props.classrooms.length > 0) {
      let tempSelectList = []
      tempSelectList.push(allClassObject())
      for (const classRoom of props.classrooms) {
        tempSelectList.push(getSelectedClass(classRoom))
      }
      setClassroomList(tempSelectList)
    }
  }, [props.classrooms]);


  const getSelectedClass = (classRoom) => {
    let tempSelectObj = {}
    let obj = classRoom.attributes
    tempSelectObj.id = classRoom.id
    tempSelectObj.name = obj.Name ?? ""
    tempSelectObj.value = classRoom.id
    tempSelectObj.label = obj.Name ?? ""
    tempSelectObj.classroomType = obj.classroomType;
    tempSelectObj.parseObject = classRoom
    return tempSelectObj;
  }

  useEffect(() => {

    if (props.kids.length > 0 && classroomList.length > 0) {
      let kidList = [];
      for (const val of props.kids) {

        if (selectedClassroom.id == 'all') {
          kidList.push(getKidObject(val));
        } else if (selectedClassroom.classroomType == 2) {
          if (selectedClassroom.id == val.attributes.ClassRoomID || selectedClassroom.id == val.attributes.daycareClassId) {
            kidList.push(getKidObject(val));
          }
        } else if (selectedClassroom.id == val.attributes.ClassRoomID) {
          kidList.push(getKidObject(val));
        }
      }
      setStudentList(kidList)
    }

  }, [props.kids, selectedClassroom]);

  const getKidObject = (val) => {
    let temp = {};
    temp.id = val.id;
    temp.label = val.attributes.Name;
    temp.value = val.id;
    temp.object = val;

    return temp
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg={8} md={8} xs={12}>
            <h4>{props.t("Reports")}</h4>
          </Col>
        </Row>
        <Row className="checkout-tabs">
          <Col lg="2">
            <Nav className="flex-column" pills>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" },"mb-2")}
                  onClick={() => {
                    setactiveTab("1");
                  }}
                >
                  <i className="bx bxs-calendar-week d-block check-nav-icon mt-0 mb-2"></i>
                  <p className="font-weight-bold mb-0 ">
                    {props.t("Attendance")}
                  </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" },"mb-2")}
                  onClick={() => {
                    setactiveTab("2");
                  }}
                >
                  <i className="bx bx-clipboard d-block check-nav-icon mt-0 mb-2"></i>
                  <p className="font-weight-bold mb-0">{props.t("Enquiry")}</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" },"mb-2")}
                  onClick={() => {
                    setactiveTab("3");
                  }}
                >
                  <i className="bx bx-calculator d-block check-nav-icon mt-0 mb-2"></i>
                  <p className="font-weight-bold mb-0">{props.t("Fee")}</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" },"mb-2")}
                  onClick={() => {
                    setactiveTab("4");
                  }}
                >
                  <i className="bx bx-receipt d-block check-nav-icon mt-0 mb-2"></i>
                  <p className="font-weight-bold mb-0">{props.t("Expenses")}</p>
                </NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" },"mb-2")}
                  onClick={() => {
                    setactiveTab("5");
                  }}
                >
                  <i className="bx bx-book-reader d-block check-nav-icon mt-0 mb-2"></i>
                  <p className="font-weight-bold mb-0">{props.t("LMS")}</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "6" })}
                  onClick={() => {
                    setactiveTab("6");
                  }}
                >
                  <i className="bx bx bx-buildings d-block check-nav-icon mt-0 mb-2"></i>
                  <p className="font-weight-bold mb-0">{props.t("School")}</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col lg="10">
            <Card>
              <CardBody>
              {showUI ? <TabContent activeTab={activeTab}>
                  {activeTab=="1" && <TabPane tabId="1">
                    <AttendanceReport
                      schoolId={schoolId}
                      matchStaff={matchStaff}
                      matchClass={matchClass}
                      matchDaycare={matchDaycare}
                      matchClassWise={matchClassWise}
                      selectedSchool={props.selectedSchool} />
                  </TabPane>}
                  {activeTab=="2" && <TabPane
                    tabId="2"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <EnquirySchoolReport schoolId={schoolId} />
                  </TabPane>}
                 {activeTab=="3" && <TabPane
                    tabId="3"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <SchoolFeeReports schoolId={schoolId} />
                  </TabPane>}
                  {activeTab=="4"&&<TabPane
                    tabId="4"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <ExpenseReports schoolId={schoolId} />
                  </TabPane>}
                  {/* <TabPane
                    tabId="5"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <ProgramReport schoolId={schoolId}
                      groupId={groupId}
                    ></ProgramReport>
                  </TabPane> */}
                 {activeTab=="5" && <TabPane
                    tabId="5"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <CardTitle>LMS Monthly Report</CardTitle>
                    <CardSubtitle className="mb-3">
                      Select a classroom and student to generate the LMS Monthly Report
                    </CardSubtitle>
                    <Row className="bg-light">
                      <Col>
                        <FormGroup >
                          <Label htmlFor="formname">Select Class Room </Label>
                          <Select
                            onChange={(e) => {
                              setSelectedClassroom(e)
                            }}
                            value={selectedClassroom}
                            options={classroomList}
                            className="BuyerTypeSelection"
                            classNamePrefix="BuyerType"
                          />
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup >
                          <Label htmlFor="formname">Select Student </Label>
                          <Select
                            onChange={(v) => {
                              setSelectedStudent(v)
                              setSelectedStudentError(false)
                            }}
                            value={selectedStudent}
                            options={studentList}
                            className="BuyerTypeSelection"
                            classNamePrefix="BuyerType"
                          />
                          {selectedStudentError && <small className='text-danger'>Please select student</small>}
                        </FormGroup>
                      </Col>
                      <Col >
                        <div className="mt-4">
                          {selectedStudent ? <Link target={"_blank"} to={`/lms-monthly-progress-report/${schoolId}/${selectedStudent.id}`}
                            className="btn btn-primary "
                          >Generate
                          </Link>
                            :
                            <Button
                              color="primary"
                              onClick={() => {
                                setSelectedStudentError(true)
                              }}
                              className=" ">
                              Generate
                            </Button>}
                        </div>
                      </Col>



                    </Row>
                  </TabPane>}
                  {activeTab =="6"&&<TabPane
                    tabId="6"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <SchoolReport schoolId={schoolId} />
                    {/* <RandomReports></RandomReports> */}
                  </TabPane>}
                </TabContent>
                : <Row>
                  <Col xs="12">
                    <div className="text-center my-3">
                      <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
                    </div>
                  </Col>
                </Row>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  return { selectedSchool, classrooms, kids };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getClassroomsForSchoolId,
      getAllKids,
    })(ReportList)
  )
);
