import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table, Label, Form, Input, Collapse, ButtonGroup } from "reactstrap";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ItemImage from "../../../assets/images/stationery.png";
import toastr from 'toastr'
import "toastr/build/toastr.min.css";
import {
    setSelecetdSchool,
    setSelectedInventoryKit, setSelectedInventoryKitItems, updateInventorySaleOrderItems, updateSelectedSaleOrder,
    getInventoryItemForItemIds, setSelectedInventoryItem, getInventorySubItemForItemIds,
    getCartForForOwnerId, updateCartForOwnerId, addInventoryCart, getInventoryItemMatrixForItemId,
    getInventoryItemMatrixForItemIds, getInventoryItemCategoryPriceForItemIds, getInventoryItemForOwnerId
} from '../../../store/actions';
import Parse from 'parse';
import DocumentViewerModal from '../../Messaging/DocumentViewerModal';

const Products = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/inventory-product-details/:schoolId/:type/:kitId",
        exact: true,
        strict: false,
    });

    const schoolId = props.component ? props.schoolId : match && match.params.schoolId;
    const kitId = props.component ? props.kitId : match && match.params.kitId;
    const type = props.component ? props.type : match && match.params.type;


    const [activeTab, setactiveTab] = useState(0);
    const [kitTableData, setKitTableData] = useState([]);
    const [itemTableData, setItemTableData] = useState([]);
    const [displayImages, setDisplayImages] = useState([]);

    const [cartObject, setCartObject] = useState(undefined);
    const [clickedFile, setClickedFile] = useState(false);
    const [showItemMatrix, setShowItemMatrix] = useState({});
    const [kitQuantity, setKitQuantity] = useState(0);
    const [itemQuantity, setItemQuantity] = useState(0);
    const [totalSelectedQuantity, setTotalSelectedQuantity] = useState(0);
    const [showUpdateCartButton, setShowUpdateCartButton] = useState(false)
    const [categoryId, setCategoryId] = useState();
    const [categoryKitPrice, setCategoryKitPrice] = useState(0);

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
        if (props.component) {
            setCategoryId(props.categoryId)
        } else {
            setCategoryId(props.selectedSchool && props.selectedSchool.attributes.inventoryCategories[0])
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (kitId && type == "kit") {
            props.setSelectedInventoryKit(kitId);
            props.setSelectedInventoryKitItems(kitId);
        } else if (kitId && type == "item") {
            props.setSelectedInventoryItem(kitId);
            props.getInventoryItemMatrixForItemId(kitId)
            props.getInventorySubItemForItemIds([kitId])
            props.getInventoryItemMatrixForItemIds([kitId])
        }
    }, [kitId]);

    useEffect(() => {
        if (props.selectedSchool && props.selectedSchool.attributes.franchiseId) {
            props.getInventoryItemForOwnerId(props.selectedSchool && props.selectedSchool.attributes.franchiseId, 'franchise');
        }
    }, [props.selectedSchool])

    useEffect(() => {
        props.getCartForForOwnerId(schoolId, "school");
    }, [schoolId]);

    useEffect(() => {
        if (kitId && props.selectedInventoryKitItems && props.selectedInventoryKitItems.length > 0) {
            var itemIds = []
            for (const si of props.selectedInventoryKitItems) {
                if (si.attributes.kitId == kitId) {
                    itemIds.push(si.attributes.itemId)
                }
            }

            props.getInventoryItemForItemIds(itemIds);
            props.getInventorySubItemForItemIds(itemIds)
            props.getInventoryItemMatrixForItemIds(itemIds)


            let subItemIds = []
            for (const subItems of props.inventorySubItems) {
                for (const items of props.inventoryItemsOfItemIds)
                    if (subItems.attributes.itemId == items.id) {
                        subItemIds.push(subItems.id)
                    }
            }
            const allItemIds = [...subItemIds, itemIds];
            props.getInventoryItemCategoryPriceForItemIds(allItemIds);
        } else {
            props.getInventoryItemCategoryPriceForItemIds(itemIds);
        }




    }, [props.selectedInventoryKitItems])

    useEffect(() => {
        if (kitId && type == "item") {
            if (props.inventorySubItems && props.inventorySubItems.length > 0) {
                let itemsIds = []
                for (const subItems of props.inventorySubItems) {
                    if (subItems.attributes.itemId == kitId) {
                        itemsIds.push(subItems.id)
                    }
                }
                const allItemIds = [...itemsIds, kitId];
                props.getInventoryItemCategoryPriceForItemIds(allItemIds);
            } else {
                props.getInventoryItemCategoryPriceForItemIds([kitId]);
            }
        }
    }, [kitId, props.inventorySubItems]);

    const getMatricItemForId = (type, matrixColorId, matrixSizeId) => {

        if (type == "b") {
            let colnSizeAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixColorId == matrixColorId && si.attributes.matrixSizeId == matrixSizeId)
            if (colnSizeAry.length > 0) {
                return colnSizeAry[0];
            }
        } else if (type == "c") {
            let colAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixColorId == matrixColorId)
            if (colAry.length > 0) {
                return colAry[0];
            }
        } else if (type == "s") {
            let sizAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixSizeId == matrixSizeId)
            if (sizAry.length > 0) {
                return sizAry[0];
            }
        }
        return undefined
    }


    useEffect(() => {
        if (kitId && props.selectedInventoryKitItems && props.selectedInventoryKitItems.length > 0) {
            const items = [];
            const itemIds = [];
            for (const ki of props.selectedInventoryKitItems) {
                let kitItem = getCartKitItem(ki);
                if (ki.attributes.kitId === kitId) {
                    const newItem = {
                        id: ki.id,
                        item: ki,
                        itemId: ki.attributes.itemId,
                        itemName: ki.attributes.itemName,
                        units: ki.attributes.unit,
                        tax: ki.attributes.tax,
                        quantity: ki.attributes.quantity,
                        unitPrice: getInventoryItemCategoryPrice(ki?.attributes?.itemId) ? getInventoryItemCategoryPrice(ki?.attributes?.itemId) : ki.attributes.unitCost,
                        showMatrixButton: false,
                        selectedQuntity: kitItem ? kitItem.selectedQuntity : 0,
                        finalPrice: 0,
                    };

                    const colors = [];
                    const sizes = [];

                    for (const im of props.inventoryMatrixs) {
                        if (im.attributes.itemId === ki.attributes.itemId) {
                            if (im.attributes.type === 'COLOR') {
                                colors.push(im);
                            } else if (im.attributes.type === 'SIZE') {
                                sizes.push(im);
                            }
                        }
                    }

                    const combinations = [];
                    let totalItemPrice = 0;

                    // If both colors and sizes exist
                    if (colors.length > 0 && sizes.length > 0) {
                        newItem.showMatrixButton = true;
                        newItem.matrixButtonName = "Pick color and size";
                        newItem.matrixType = 1;

                        for (const color of colors) {
                            for (const size of sizes) {
                                let sq = getCombQantity(kitItem, color.id + size.id);
                                let sItem = getMatricItemForId("b", color.id, size.id);
                                let itemCategoryPrice = getInventoryItemCategoryPrice(sItem?.id);
                                const cost = itemCategoryPrice !== undefined ? itemCategoryPrice : (sItem?.attributes?.sellingPrice || 0);
                                let finalPrice = sq * cost;

                                totalItemPrice += finalPrice;

                                combinations.push({
                                    id: color.id + size.id,
                                    color: color,
                                    size: size,
                                    selectedQuntity: sq,
                                    cost: cost,
                                    finalPrice: finalPrice,
                                    subItemId: sItem && sItem.id,
                                    subItem: sItem
                                });
                            }
                        }
                    }
                    // If only colors exist
                    else if (colors.length > 0) {
                        newItem.showMatrixButton = true;
                        newItem.matrixButtonName = "Pick color";
                        newItem.matrixType = 2;

                        for (const color of colors) {
                            let sq = getCombQantity(kitItem, color.id);
                            let sItem = getMatricItemForId("c", color.id);
                            let cost = sItem && sItem.attributes && sItem.attributes.sellingPrice || 0;
                            let finalPrice = sq * cost;

                            totalItemPrice += finalPrice;

                            combinations.push({
                                id: color.id,
                                color: color,
                                selectedQuntity: sq,
                                cost: cost,
                                finalPrice: finalPrice,
                                subItemId: sItem && sItem.id,
                                subItem: sItem
                            });
                        }
                    }
                    // If only sizes exist
                    else if (sizes.length > 0) {
                        newItem.showMatrixButton = true;
                        newItem.matrixButtonName = "Pick size";
                        newItem.matrixType = 3;

                        for (const size of sizes) {
                            let sq = getCombQantity(kitItem, size.id);
                            let sItem = getMatricItemForId("s", undefined, size.id);
                            let cost = sItem && sItem.attributes && sItem.attributes.sellingPrice || 0;
                            let finalPrice = sq * cost;

                            totalItemPrice += finalPrice;

                            combinations.push({
                                id: size.id,
                                size: size,
                                selectedQuntity: sq,
                                cost: cost,
                                finalPrice: finalPrice,
                                subItemId: sItem && sItem.id,
                                subItem: sItem
                            });
                        }
                    }

                    if (combinations.length === 0) {
                        newItem.finalPrice = newItem.unitPrice * newItem.selectedQuntity;
                    } else {
                        newItem.combinations = combinations;
                        newItem.finalPrice = totalItemPrice;
                    }

                    items.push(newItem);
                    itemIds.push(newItem.id);
                }
            }

            setKitTableData(items);
        }
    }, [kitId, props.selectedInventoryKitItems, props.inventoryMatrixs, props.inventorySubItems]);


    useEffect(() => {
        if ((props.selectedInventoryItem && props.selectedInventoryItem) || (props.saleOrderItemobject && props.saleOrderItemobject) && (props.inventoryItemMatrixs && props.inventoryItemMatrixs.length > 0)) {
            let colors = [];
            let sizes = [];
            let totalFinalPrice = 0;
            let selectedItem = props.selectedInventoryItem && props.selectedInventoryItem
            let newItem = {
                id: selectedItem.id,
                itemName: selectedItem.attributes.name,
                units: selectedItem.attributes.unit,
                tax: selectedItem.attributes.tax,
                quantity: itemQuantity,
                showColorColumn: false,
                showSizeColumn: false,
                showColorSizeColumn: false,
                showMatrixTable: false,
                combinations: [],
            };

            for (const im of props.inventoryItemMatrixs) {
                if (im.attributes.itemId === selectedItem.id) {
                    if (im.attributes.type === 'COLOR') {
                        colors.push(im);
                    } else if (im.attributes.type === 'SIZE') {
                        sizes.push(im);
                    }
                }
            }

            const combinations = [];
            if (props.inventorySubItems && props.inventorySubItems.length > 0) {
                if (colors.length > 0 && sizes.length > 0) {
                    newItem.showColorSizeColumn = true;
                    newItem.showMatrixTable = true;
                    newItem.matrixButtonName = "Pick color and size";
                    for (const color of colors) {
                        for (const size of sizes) {
                            let sq = 0;
                            if (cartObject) {
                                sq = getItemCombQantity(cartObject.attributes.kitItems, color.id + size.id);
                            } else if (props.saleOrderItemobject) {
                                sq = getItemCombQantity(props.saleOrderItemobject.attributes.kitItemMatrix, color.id + size.id);
                            }
                            let sItem = getMatricItemForId("b", color.id, size.id);
                            let itemCategoryPrice = getInventoryItemCategoryPrice(sItem?.id);
                            const cost = itemCategoryPrice !== undefined ? itemCategoryPrice : (sItem?.attributes?.sellingPrice || 0);
                            const finalPrice = sq * cost;

                            totalFinalPrice += finalPrice;

                            combinations.push({
                                id: color.id + size.id,
                                color: color,
                                size: size,
                                selectedQuntity: sq,
                                cost: cost,
                                finalPrice: finalPrice,
                                subItemId: sItem?.id,
                                subItem: sItem
                            });
                        }
                    }
                } else if (colors.length > 0) {
                    newItem.showColorColumn = true;
                    newItem.showMatrixTable = true;
                    newItem.matrixButtonName = "Pick color";
                    for (const color of colors) {
                        let sq = 0;
                        if (cartObject) {
                            sq = getItemCombQantity(cartObject.attributes.kitItems, color.id);
                        } else if (props.saleOrderItemobject) {
                            sq = getItemCombQantity(props.saleOrderItemobject.attributes.kitItemMatrix, color.id);
                        }
                        let sItem = getMatricItemForId("c", color.id);
                        let itemCategoryPrice = getInventoryItemCategoryPrice(sItem?.id);
                        const cost = itemCategoryPrice !== undefined ? itemCategoryPrice : (sItem?.attributes?.sellingPrice || 0);
                        const finalPrice = sq * cost;

                        totalFinalPrice += finalPrice;

                        combinations.push({
                            id: color.id,
                            color: color,
                            selectedQuntity: sq,
                            cost: cost,
                            finalPrice: finalPrice,
                            subItemId: sItem?.id,
                            subItem: sItem
                        });
                    }
                } else if (sizes.length > 0) {
                    newItem.showSizeColumn = true;
                    newItem.showMatrixTable = true;
                    newItem.matrixButtonName = "Pick size";
                    for (const size of sizes) {
                        let sq = 0;
                        if (cartObject) {
                            sq = getItemCombQantity(cartObject.attributes.kitItems, size.id);
                        } else if (props.saleOrderItemobject) {
                            sq = getItemCombQantity(props.saleOrderItemobject.attributes.kitItemMatrix, size.id);
                        }
                        let sItem = getMatricItemForId("s", undefined, size.id);
                        let itemCategoryPrice = getInventoryItemCategoryPrice(sItem?.id);
                        const cost = itemCategoryPrice !== undefined ? itemCategoryPrice : (sItem?.attributes?.sellingPrice || 0);
                        const finalPrice = sq * cost;

                        totalFinalPrice += finalPrice;

                        combinations.push({
                            id: size.id,
                            size: size,
                            selectedQuntity: sq,
                            cost: cost,
                            finalPrice: finalPrice,
                            subItemId: sItem?.id,
                            subItem: sItem
                        });
                    }
                }
            }

            newItem.combinations = combinations;
            newItem.totalFinalPrice = totalFinalPrice;

            setItemTableData(newItem);
            setTotalSelectedQuantity(combinations.reduce((total, combination) => total + combination.selectedQuntity, 0));
        }
    }, [props.selectedInventoryItem, props.inventoryItemMatrixs, cartObject, props.saleOrderItemobject]);

    useEffect(() => {
        let imageList = [];
        if (props.selectedInventoryKit && props.selectedInventoryKit.id == kitId && props.selectedInventoryKit.attributes.displayImage) {
            imageList.push(props.selectedInventoryKit.attributes.displayImage)
        }
        for (const item of props.inventoryItemsOfItemIds) {
            imageList.push(item.attributes.displayImage);
        }
        setDisplayImages(imageList)
    }, [props.selectedInventoryKit, props.inventoryItemsOfItemIds]);

    useEffect(() => {
        for (const ic of props.inventoryCart) {
            if (ic.attributes.productId == kitId) {
                setCartObject(ic);
                setKitQuantity(ic.attributes.quantity)
                setItemQuantity(ic.attributes.quantity)
            }
        }
    }, [props.inventoryCart])

    useEffect(() => {
        if (props.saleOrderItemobject) {
            setKitQuantity(props.saleOrderItemobject.attributes.quantity)
            setItemQuantity(props.saleOrderItemobject.attributes.quantity)
        }
    }, [props.saleOrderItemobject])

    useEffect(() => {
        if (props.component) {
            setKitQuantity(props.saleOrderItemobject.attributes.quantity)
            setItemQuantity(props.saleOrderItemobject.attributes.quantity)
        }
    }, [props.inventoryCart])

    const getCartKitItem = (kitItem) => {
        let items = props.component ? props.saleOrderItemobject : cartObject;
        if (items) {
            let kitItems = props.component ? props.saleOrderItemobject.attributes.kitItemMatrix : cartObject.attributes.kitItems
            if (kitItems) {
                for (const i of kitItems) {
                    if (i.kitItemId == kitItem.id) {
                        return i;
                    }
                }
            }
        }
        return undefined;
    }

    const getCombQantity = (item, combId) => {
        if (item) {
            for (const comb of item.combinations) {
                if (comb.id == combId) {
                    return comb.selectedQuntity
                }
            }
        }
        return 0;
    }

    const getItemCombQantity = (item, combId) => {
        if (item && type == "item") {
            for (const comb of item[0].combinations) {
                if (comb.id === combId) {
                    return comb.selectedQuntity;
                }
            }
        }
        return 0;
    };


    const addToKitCart = () => {


        if (kitQuantity <= 0) {
            toastr.error("set kit quantity", { timeOut: 500 });
            return;
        }

        let canSave = true;

        for (const item of kitTableData) {

            if (item.showMatrixButton) {
                if (item.selectedQuntity != item.quantity * kitQuantity) {
                    canSave = false
                    toastr.error(" Please Review Items and quantity", { timeOut: 500 });
                }
            }
        }

        if (canSave) {

            let allItems = []

            console.log("kitTableData.......", kitTableData)
            for (const item of kitTableData) {
                let ni = {
                    itemId: item.itemId,
                    kitItemId: item.id,
                    isMatrixItem: item.showMatrixButton,
                    tax: item.tax,
                    selectedQuntity: item.showMatrixButton == true ? (item.selectedQuntity ?? 0) : (item.quantity * kitQuantity),
                    matrixType: item.matrixType,
                    finalPrice: item.showMatrixButton == true ? (item.finalPrice) : (item.unitPrice * getFinalQuantity(item.quantity)),

                }

                if (item.showMatrixButton) {
                    let combinations = []

                    for (const comb of item.combinations) {
                        let ci = {
                            id: comb.id,
                            selectedQuntity: comb.selectedQuntity ?? 0,
                            unitCost: comb.cost,
                            finalPrice: comb.finalPrice,
                            subItemId: comb.subItemId
                        };
                        combinations.push(ci)
                    }
                    ni.combinations = combinations;
                }

                allItems.push(ni)
            }
            const ShoppingCart = Parse.Object.extend("ShoppingCart");
            var cart = new ShoppingCart();
            if (cartObject) {
                cart = cartObject
            } else {
                cart.set('schoolId', schoolId);
                cart.set('productId', kitId);
                cart.set('userId', Parse.User.current().attributes.teacherId);
                cart.set('productType', type == 'kit' ? "inventoryKit" : "inventoryItem");
            }
            cart.set('quantity', kitQuantity);
            cart.set('kitItems', allItems);

            cart.save().then((rCart) => {
                // console.log(rCart)
                props.updateCartForOwnerId([rCart]);
                setCartObject(rCart);
                setShowUpdateCartButton(false)
                toastr.success("Added to cart", { timeOut: 3000 });
            }, (error) => {
                console.log(error)
            })
        }

        cart.save().then((rCart) => {
            console.log(rCart)
            props.updateCartForOwnerId([rCart]);
            setCartObject(true);
            toastr.success("Added to cart", { timeOut: 3000 });
        }, (error) => {
            console.log(error)
        })

    }

    const addToItemCart = () => {


        if (itemQuantity <= 0) {
            toastr.error("set Item quantity", { timeOut: 500 });
            return;
        }

        let canSave = false;

        if (itemTableData.showMatrixTable) {
            if (itemQuantity == totalSelectedQuantity) {
                canSave = true;
            } else {
                toastr.error(" Please Review Item and quantity", { timeOut: 500 });
            }
        } else {
            const ShoppingCart = Parse.Object.extend("ShoppingCart");
            var cart = new ShoppingCart();

            cart.set('schoolId', schoolId);
            cart.set('productId', kitId);
            cart.set('userId', Parse.User.current().attributes.teacherId);
            cart.set('productType', type == 'kit' ? "inventoryKit" : "inventoryItem");
            cart.set('quantity', itemQuantity);

            cart.save().then((rCart) => {
                props.updateCartForOwnerId([rCart]);
                setCartObject(rCart);
                setShowUpdateCartButton(false)
                toastr.success("Added to cart", { timeOut: 3000 });
            }, (error) => {
                console.log(error)
            })
        }


        if (canSave) {

            let allItems = []

            if (props.selectedInventoryItem && props.selectedInventoryItem) {

                if (itemTableData && itemTableData) {
                    let ni = {
                        id: props.selectedInventoryItem.id,
                        itemName: props.selectedInventoryItem.attributes.name,
                        units: props.selectedInventoryItem.attributes.unit,
                        tax: props.selectedInventoryItem.attributes.tax,
                        quantity: itemQuantity,
                        showColorColumn: itemTableData.showColorColumn,
                        showSizeColumn: itemTableData.showSizeColumn,
                        showColorSizeColumn: itemTableData.showColorSizeColumn,
                        showMatrixTable: itemTableData.showMatrixTable,
                        finalPrice: itemTableData.totalFinalPrice
                    }

                    if (itemTableData.showMatrixTable) {
                        let combinations = []

                        for (const comb of itemTableData.combinations) {
                            let ci = {
                                id: comb.id,
                                selectedQuntity: comb.selectedQuntity ?? 0,
                                unitCost: comb.cost,
                                finalPrice: comb.finalPrice,
                                subItemId: comb.subItemId
                            };
                            combinations.push(ci)
                        }
                        ni.combinations = combinations;
                    }

                    allItems.push(ni)
                }

            }

            const ShoppingCart = Parse.Object.extend("ShoppingCart");
            var cart = new ShoppingCart();
            if (cartObject) {
                cart = cartObject
            } else {
                cart.set('schoolId', schoolId);
                cart.set('productId', kitId);
                cart.set('userId', Parse.User.current().attributes.teacherId);
                cart.set('productType', type == 'kit' ? "inventoryKit" : "inventoryItem");
            }
            cart.set('quantity', itemQuantity);
            cart.set('kitItems', allItems);

            cart.save().then((rCart) => {
                // console.log(rCart)
                props.updateCartForOwnerId([rCart]);
                setCartObject(rCart);
                setShowUpdateCartButton(false)
                toastr.success("Added to cart", { timeOut: 3000 });
            }, (error) => {
                console.log(error)
            })
        }


    }

    const removeCart = (cart) => {
        cart.set('isDeleted', true);
        cart.set('deletedBy', Parse.User.current().attributes.teacherId);
        props.addInventoryCart(cart, 'removed');
        // console.log(cart)
        //setCartObject(undefined);
    }



    const kitcountDown = () => {
        if (kitQuantity > 1) {
            setKitQuantity(kitQuantity - 1);
        }
    };

    const kitCountUp = () => {
        setKitQuantity(kitQuantity + 1);
    };

    const getFinalQuantity = (quantity) => {
        return quantity * kitQuantity;
    };

    const itemCountDown = () => {
        if (itemQuantity > 1) {
            setItemQuantity(itemQuantity - 1);
        }
    };

    const itemCountUp = () => {
        setItemQuantity(itemQuantity + 1);
    };

    const handleItemCountDown = (combinationIndex) => {
        let updatedCombinations = [...itemTableData.combinations];
        let selectedCombination = updatedCombinations[combinationIndex];
        let itemCategoryPrice = getInventoryItemCategoryPrice(selectedCombination.subItemId);
        let price = itemCategoryPrice !== undefined ? itemCategoryPrice : selectedCombination.cost;

        if (selectedCombination.selectedQuntity > 0) {
            selectedCombination.selectedQuntity = selectedCombination.selectedQuntity - 1;
            selectedCombination.finalPrice = selectedCombination.selectedQuntity * price;

            updatedCombinations[combinationIndex] = selectedCombination;

            let totalFinalPrice = 0;
            let quantity = 0
            for (let i = 0; i < updatedCombinations.length; i++) {
                totalFinalPrice += updatedCombinations[i].finalPrice;
                quantity += updatedCombinations[i].selectedQuntity;
            }
            setTotalSelectedQuantity(totalSelectedQuantity - 1);
            setItemTableData({ ...itemTableData, combinations: updatedCombinations, totalFinalPrice, quantity });
        }
    };
    const handleItemCountUp = (combinationIndex) => {
        let updatedCombinations = [...itemTableData.combinations];
        let selectedCombination = updatedCombinations[combinationIndex];
        let itemCategoryPrice = getInventoryItemCategoryPrice(selectedCombination.subItemId);
        let price = itemCategoryPrice !== undefined ? itemCategoryPrice : selectedCombination.cost

        if (totalSelectedQuantity < itemQuantity) {
            selectedCombination.selectedQuntity += 1;
            selectedCombination.finalPrice = selectedCombination.selectedQuntity * price;

            updatedCombinations[combinationIndex] = selectedCombination;

            let totalFinalPrice = 0;
            let quantity = 0;
            for (let i = 0; i < updatedCombinations.length; i++) {
                totalFinalPrice += updatedCombinations[i].finalPrice;
                quantity += updatedCombinations[i].selectedQuntity
            }
            setTotalSelectedQuantity(totalSelectedQuantity + 1);
            setItemTableData({ ...itemTableData, combinations: updatedCombinations, totalFinalPrice, quantity });
        } else {
            toastr.error("Total item matrix quantity cannot exceed item final quantity.", { timeOut: 500 });
        }
    };



    const handleKitCountDown = (item, itemIndex, combination, itemQuantity) => {
        if (item.selectedQuntity - 1 >= 0) {
            combination.selectedQuntity = combination.selectedQuntity - 1;
            item.selectedQuntity = item.selectedQuntity - 1;

            let itemCategoryPrice = getInventoryItemCategoryPrice(combination.subItemId);
            let price = itemCategoryPrice !== undefined ? itemCategoryPrice : combination.cost

            if (combination) {
                combination.finalPrice = combination.selectedQuntity * price;
            }

            if (item.combinations && item.combinations.length > 0) {
                item.finalPrice = item.combinations.reduce((total, comb) => total + (comb.finalPrice || 0), 0);
            } else {
                let itemPrice = getInventoryItemCategoryPrice(item.itemId) !== undefined ? getInventoryItemCategoryPrice(item.itemId) : item.unitPrice
                item.finalPrice = itemPrice * item.selectedQuntity;
            }

            let td = [...kitTableData];
            td[itemIndex] = item;
            setKitTableData(td);
        }
    };

    const handleKitCountUp = (item, itemIndex, combination, itemQuantity) => {
        if (item.selectedQuntity + 1 <= itemQuantity) {
            combination.selectedQuntity = combination.selectedQuntity + 1;
            item.selectedQuntity = item.selectedQuntity + 1;

            let itemCategoryPrice = getInventoryItemCategoryPrice(combination.subItemId);
            let price = itemCategoryPrice !== undefined ? itemCategoryPrice : combination.cost

            if (combination) {
                combination.finalPrice = combination.selectedQuntity * price;
            }

            if (item.combinations && item.combinations.length > 0) {
                item.finalPrice = item.combinations.reduce((total, comb) => total + (comb.finalPrice || 0), 0);
            } else {
                let itemPrice = getInventoryItemCategoryPrice(item.itemId) !== undefined ? getInventoryItemCategoryPrice(item.itemId) : item.unitPrice
                item.finalPrice = itemPrice * item.selectedQuntity;
            }

            let td = [...kitTableData];
            td[itemIndex] = item;
            setKitTableData(td);
        } else {
            toastr.error("Total item matrix quantity cannot exceed item final quantity.", { timeOut: 500 });
        }
    };


    const updateKitSaleOrders = () => {

        console.log("kitTableData", kitTableData)


        if (kitQuantity <= 0) {
            toastr.error("set kit quantity", { timeOut: 500 });
            return;
        }

        let canSave = true;

        for (const item of kitTableData) {
            if (item.showMatrixButton) {
                if (item.selectedQuntity != item.quantity * kitQuantity) {
                    canSave = false;
                    toastr.error(" Please Review Items and quantity", { timeOut: 500 });
                    return;
                }
            }
        }



        if (canSave) {

            let Invoice = Parse.Object.extend("Invoice");
            let query = new Parse.Query(Invoice);

            query.equalTo("objectId", props.saleOrderobject.attributes.invoiceId);

            query.first().then(
                (invoice) => {
                    // console.log("Invoice:", invoice);
                    if (invoice) {
                        let InvoiceItems = Parse.Object.extend("InvoiceItems");
                        let query1 = new Parse.Query(InvoiceItems);
                        query1.equalTo("invoiceId", invoice.id);
                        query1.notEqualTo("deleted", true);

                        query1.find().then(
                            (invoiceItems) => {

                                // console.log("Invoice Items:", invoiceItems);

                                let allItems = [];

                                for (const item of kitTableData) {
                                    let ni = {
                                        itemId: item.itemId,
                                        kitItemId: item.id,
                                        isMatrixItem: item.showMatrixButton,
                                        selectedQuntity: item.selectedQuntity ?? 0,
                                        matrixType: item.matrixType,
                                        finalPrice: item.finalPrice,
                                    };

                                    if (item.showMatrixButton) {
                                        let combinations = [];

                                        for (const comb of item.combinations) {
                                            let ci = {
                                                id: comb.id,
                                                selectedQuntity: comb.selectedQuntity ?? 0,
                                                unitCost: comb.cost,
                                                finalPrice: comb.finalPrice,
                                                subItemId: comb.subItemId
                                            };
                                            combinations.push(ci);
                                        }
                                        ni.combinations = combinations;
                                    } else {
                                        ni.finalPrice = item.unitPrice * (item.selectedQuntity || 0);
                                    }

                                    allItems.push(ni);
                                }


                                console.log("allItems", allItems)
                                const InventorySaleOrderItems = Parse.Object.extend("InventorySaleOrderItems");
                                let saleOrderItem = new InventorySaleOrderItems();
                                saleOrderItem = props.saleOrderItemobject;

                                let totalFinalCost = 0;

                                if (allItems && allItems.length > 0) {
                                    if (allItems.length == 1) {
                                        totalFinalCost = allItems[0].finalPrice || 0;
                                    } else {
                                        allItems.forEach((kitItem) => {
                                            totalFinalCost += kitItem.finalPrice || 0;
                                        });
                                    }
                                }

                                let unitCost = 0;
                                if (kitQuantity > 0) {
                                    unitCost = totalFinalCost / kitQuantity;
                                }
                                saleOrderItem.set("totalCost", totalFinalCost);
                                saleOrderItem.set("unitCost", unitCost);
                                saleOrderItem.set("quantity", kitQuantity);
                                saleOrderItem.set("kitItemMatrix", allItems);

                                saleOrderItem.save().then(
                                    (soItem) => {
                                        // console.log("Sale Order Item:", soItem);

                                        if (soItem) {
                                            const InventorySaleOrders = Parse.Object.extend("InventorySaleOrders");
                                            let saleOrder = new InventorySaleOrders();
                                            saleOrder = props.saleOrderobject;

                                            let soov = 0;
                                            let tempSois = []
                                            for (const soi of props.selectedInventorySaleOrderItems) {
                                                if (soi.id != soItem.id) {
                                                    soov = soov + soi.attributes.totalCost;
                                                    tempSois.push(soi)
                                                }
                                                if (soi.id == soItem.id) {
                                                    tempSois.push(soi)
                                                }
                                            }

                                            soov = soov + soItem.attributes.totalCost;
                                            saleOrder.set('orderValue', soov);

                                            saleOrder.save().then(
                                                (soResult) => {
                                                    // console.log("Sale Order Result:", soResult);
                                                    // console.log("Invoice Items in for loop:", invoiceItems);

                                                    //update sel so
                                                    props.updateSelectedSaleOrder(soResult)
                                                    //update sel sois => tempSois
                                                    props.updateInventorySaleOrderItems(tempSois)

                                                    for (let i = 0; i < invoiceItems.length; i++) {
                                                        const InvoiceItem = Parse.Object.extend("InvoiceItems");
                                                        let invoiceItem = new InvoiceItem();
                                                        invoiceItem = invoiceItems[i];

                                                        let soitem = props.selectedInventorySaleOrderItems.filter((item) => item.attributes.itemId == invoiceItems[i].attributes.productId);
                                                        invoiceItem.set("totalAmount", soitem[0].attributes.totalCost);
                                                        invoiceItem.set("amount", soitem[0].attributes.totalCost);

                                                        invoiceItem.save().then(
                                                            (invcItem) => {
                                                                // console.log("Invoice Item:", invcItem);

                                                                const Invoice = Parse.Object.extend("Invoice");
                                                                let invc = new Invoice();
                                                                invc = invoice;

                                                                invc.set("amount", soResult.attributes.orderValue + "");

                                                                invc.save().then(
                                                                    (invc) => {
                                                                        props.handelClose()
                                                                        // console.log("Updated Invoice:", invc);
                                                                    },
                                                                    (error) => {
                                                                        console.log("Error updating invoice:", error);
                                                                    }
                                                                );
                                                            },
                                                            (error) => {
                                                                console.log("Error saving invoice item:", error);
                                                            }
                                                        );
                                                    }
                                                },
                                                (error) => {
                                                    console.log("Error saving sale order:", error);
                                                }
                                            );
                                        }
                                    },
                                    (error) => {
                                        console.log("Error saving sale order item:", error);
                                    }
                                );
                            },
                            (error) => {
                                console.log("Error finding invoice items:", error);
                            }
                        );
                    }
                },
                (error) => {
                    console.log("Error finding invoice:", error);
                }
            );
        }
    };

    const updateItemSaleOrders = () => {

        console.log("1")
        console.log("itemTableData update", itemTableData)
        if (itemQuantity <= 0) {
            toastr.error("set Item quantity", { timeOut: 500 });
            return;
        }

        let canSave = false;

        if (itemTableData.showMatrixTable) {
            if (itemQuantity == totalSelectedQuantity) {
                canSave = true;
            } else {
                toastr.error(" Please Review Item and quantity", { timeOut: 500 });
            }
        }

        if (canSave) {

            let Invoice = Parse.Object.extend("Invoice");
            let query = new Parse.Query(Invoice);

            query.equalTo("objectId", props.saleOrderobject.attributes.invoiceId);

            query.first().then(
                (invoice) => {
                    // console.log("Invoice:", invoice);
                    if (invoice) {
                        let InvoiceItems = Parse.Object.extend("InvoiceItems");
                        let query1 = new Parse.Query(InvoiceItems);
                        query1.equalTo("invoiceId", invoice.id);
                        query1.notEqualTo("deleted", true);

                        query1.find().then(
                            (invoiceItems) => {

                                // console.log("Invoice Items:", invoiceItems);

                                let allItems = []

                                if (props.selectedInventoryItem && props.selectedInventoryItem) {
                                    console.log("itemTableData", itemTableData)
                                    if (itemTableData && itemTableData) {

                                        let ni = {
                                            id: props.selectedInventoryItem.id,
                                            itemName: props.selectedInventoryItem.attributes.name,
                                            units: props.selectedInventoryItem.attributes.unit,
                                            quantity: itemQuantity,
                                            showColorColumn: itemTableData.showColorColumn,
                                            showSizeColumn: itemTableData.showSizeColumn,
                                            showColorSizeColumn: itemTableData.showColorSizeColumn,
                                            showMatrixTable: itemTableData.showMatrixTable,
                                            finalPrice: itemTableData.totalFinalPrice
                                        }


                                        if (itemTableData.showMatrixTable) {
                                            let combinations = []

                                            for (const comb of itemTableData.combinations) {
                                                let ci = {
                                                    id: comb.id,
                                                    selectedQuntity: comb.selectedQuntity ?? 0,
                                                    unitCost: comb.cost,
                                                    finalPrice: comb.finalPrice,
                                                    subItemId: comb.subItemId
                                                };
                                                combinations.push(ci)
                                            }
                                            ni.combinations = combinations;
                                        }
                                        allItems.push(ni)

                                    }

                                }


                                const InventorySaleOrderItems = Parse.Object.extend("InventorySaleOrderItems");
                                let saleOrderItem = new InventorySaleOrderItems();
                                saleOrderItem = props.saleOrderItemobject;

                                let unitCost = 0;
                                if (itemTableData.quantity > 0) {
                                    unitCost = itemTableData.totalFinalPrice / itemTableData.quantity;
                                }
                                saleOrderItem.set("unitCost", unitCost);
                                saleOrderItem.set("quantity", itemQuantity);
                                saleOrderItem.set("totalCost", itemTableData.totalFinalPrice);
                                saleOrderItem.set("kitItemMatrix", allItems);

                                saleOrderItem.save().then(
                                    (soItem) => {
                                        // console.log("Sale Order Item:", soItem);

                                        if (soItem) {
                                            const InventorySaleOrders = Parse.Object.extend("InventorySaleOrders");
                                            let saleOrder = new InventorySaleOrders();
                                            saleOrder = props.saleOrderobject;

                                            let soov = 0;
                                            let tempSois = []
                                            for (const soi of props.selectedInventorySaleOrderItems) {
                                                if (soi.id != soItem.id) {
                                                    soov = soov + soi.attributes.totalCost;
                                                    tempSois.push(soi)
                                                }
                                                if (soi.id == soItem.id) {
                                                    tempSois.push(soi)
                                                }
                                            }

                                            soov = soov + soItem.attributes.totalCost;
                                            saleOrder.set('orderValue', soov);

                                            saleOrder.save().then(
                                                (soResult) => {
                                                    // console.log("Sale Order Result:", soResult);
                                                    // console.log("Invoice Items in for loop:", invoiceItems);

                                                    //update sel so
                                                    props.updateSelectedSaleOrder(soResult)
                                                    //update sel sois => tempSois
                                                    props.updateInventorySaleOrderItems(tempSois)

                                                    for (let i = 0; i < invoiceItems.length; i++) {
                                                        const InvoiceItem = Parse.Object.extend("InvoiceItems");
                                                        let invoiceItem = new InvoiceItem();
                                                        invoiceItem = invoiceItems[i];

                                                        let soitem = props.selectedInventorySaleOrderItems.filter((item) => item.attributes.itemId == invoiceItems[i].attributes.productId);
                                                        // console.log(soitem)
                                                        invoiceItem.set("totalAmount", soitem[0].attributes.totalCost);
                                                        invoiceItem.set("amount", soitem[0].attributes.totalCost);

                                                        invoiceItem.save().then(
                                                            (invcItem) => {
                                                                // console.log("Invoice Item:", invcItem);

                                                                const Invoice = Parse.Object.extend("Invoice");
                                                                let invc = new Invoice();
                                                                invc = invoice;

                                                                invc.set("amount", soResult.attributes.orderValue + "");

                                                                invc.save().then(
                                                                    (invc) => {
                                                                        props.handelClose()
                                                                        // console.log("Updated Invoice:", invc);
                                                                    },
                                                                    (error) => {
                                                                        console.log("Error updating invoice:", error);
                                                                    }
                                                                );
                                                            },
                                                            (error) => {
                                                                console.log("Error saving invoice item:", error);
                                                            }
                                                        );
                                                    }
                                                },
                                                (error) => {
                                                    console.log("Error saving sale order:", error);
                                                }
                                            );
                                        }
                                    },
                                    (error) => {
                                        console.log("Error saving sale order item:", error);
                                    }
                                );
                            },
                            (error) => {
                                console.log("Error finding invoice items:", error);
                            }
                        );
                    }
                },
                (error) => {
                    console.log("Error finding invoice:", error);
                }
            );
        }
    };

    useEffect(() => {
        if (props.selectedInventoryKitItems && props.selectedInventoryKitItems.length > 0 && props.inventoryItemCategoryPrices && props.inventoryItemCategoryPrices.length > 0 &&
            props.inventoryItems && props.inventoryItems.length > 0) {

            let total = 0;

            for (const kitItem of props.selectedInventoryKitItems) {

                const priceItem = props.inventoryItemCategoryPrices.find(price =>
                    price.attributes.itemId == kitItem.attributes.itemId && price.attributes.categoryId == categoryId
                );

                const inventoryItem = props.inventoryItems.find(item =>
                    item.id == kitItem.attributes.itemId
                );

                const sellingPrice = priceItem ? priceItem.attributes.sellingPrice : inventoryItem.attributes.sellingPrice;
                const quantity = kitItem.attributes.quantity || 1;
                total += sellingPrice * quantity;

            }
            setCategoryKitPrice(total);
        }
    }, [props.selectedInventoryKitItems, props.inventoryItemCategoryPrices, props.inventoryItems]);

    const getInventoryItemCategoryPrice = (itemId) => {
        if (props.inventoryItemCategoryPrices) {
            for (const price of props.inventoryItemCategoryPrices) {
                if (price.attributes.itemId === itemId && price.attributes.categoryId === categoryId) {
                    return price.attributes.sellingPrice;
                }
            }
        }
        return undefined;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <DocumentViewerModal
                        clickedFile={clickedFile}
                        closeModal={() => {
                            setClickedFile();
                        }}
                        hideDownload={true}
                    />
                    {!props.component && <Row className="d-flex align-items-start">
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    window.history.back();
                                }}
                            >
                                <ul className="list-unstyled">
                                    <div className="">
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className="m-2">
                                <h4>Products</h4>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <div className="page-title-right m-1">
                                <Link
                                    className="btn btn-success"
                                    to={"/school-cart/" + schoolId}
                                >
                                    Cart <i className="bx bxs-cart"></i>
                                </Link>
                            </div>
                            <div className="page-title-right m-1">
                                <Link
                                    className="btn btn-success"
                                    to={"/sale-order/" + schoolId}
                                >
                                    Orders <i className="bx bxs-shopping-bag"></i>
                                </Link>
                            </div>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xl="6">
                                            <div className="product-detai-imgs">
                                                <Row>
                                                    <Col md="2" xs="3">
                                                        {type === "item" && (
                                                            <Nav className="flex-column" pills>
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={classnames({ active: activeTab })}
                                                                        onClick={() => {
                                                                            setactiveTab();
                                                                        }}
                                                                    >
                                                                        <img src={props.selectedInventoryItem && props.selectedInventoryItem.attributes.displayImage ? props.selectedInventoryItem.attributes.displayImage._url : ItemImage} alt="" className="img-fluid mx-auto d-block" />
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                        )}
                                                    </Col>
                                                    <Col md={{ size: 7, offset: 1 }} xs="9">
                                                        {type === "kit" && (
                                                            <TabContent activeTab={activeTab}>
                                                                {displayImages.map((img, key) => (
                                                                    <TabPane tabId={key} key={key}>
                                                                        <div>
                                                                            <img
                                                                                src={img ? img._url : ItemImage}
                                                                                alt=""
                                                                                className="img-fluid mx-auto d-block"
                                                                                style={{ width: "10rem", height: "10rem" }}
                                                                                onClick={() => {
                                                                                    setClickedFile(
                                                                                        props.selectedInventoryItem &&
                                                                                        props.selectedInventoryItem.attributes
                                                                                            .displayImage
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </TabPane>
                                                                ))}
                                                            </TabContent>
                                                        )}
                                                        {type === "item" && (
                                                            <TabPane>
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            props.selectedInventoryItem &&
                                                                                props.selectedInventoryItem.attributes
                                                                                    .displayImage ? props.selectedInventoryItem &&
                                                                                    props.selectedInventoryItem.attributes
                                                                                        .displayImage &&
                                                                            props.selectedInventoryItem.attributes
                                                                                .displayImage._url : ItemImage
                                                                        }
                                                                        alt=""
                                                                        className="img-fluid mx-auto d-block"
                                                                        style={{ width: "10rem", height: "10rem" }}
                                                                        onClick={() => {
                                                                            setClickedFile(
                                                                                props.selectedInventoryItem &&
                                                                                props.selectedInventoryItem.attributes
                                                                                    .displayImage
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </TabPane>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                        <Col xl="6">
                                            <div className="mt-4 mt-xl-3">
                                                {type === "kit" && (
                                                    <h4 className="mt-1 mb-3">
                                                        {props.selectedInventoryKit &&
                                                            props.selectedInventoryKit.attributes.name}
                                                    </h4>
                                                )}
                                                {type === "item" && (
                                                    <h4 className="mt-1 mb-3">
                                                        {props.selectedInventoryItem &&
                                                            props.selectedInventoryItem.attributes.name}
                                                    </h4>
                                                )}
                                                {type === "kit" && (
                                                    <h5 className="mb-4">
                                                        Price :{" "}
                                                        <b>
                                                            ₹
                                                            {categoryKitPrice ? categoryKitPrice : props.selectedInventoryKit &&
                                                                props.selectedInventoryKit.attributes.sellingPrice}{" "}
                                                            INR
                                                        </b>
                                                    </h5>
                                                )}
                                                {type === "item" && (
                                                    <h5 className="mb-4">
                                                        Price :{" "}
                                                        <b>
                                                            {
                                                                (() => {
                                                                    const sellingPrice = getInventoryItemCategoryPrice(props.selectedInventoryItem && props.selectedInventoryItem.id);
                                                                    return sellingPrice !== undefined
                                                                        ? `₹${sellingPrice.toFixed(2)} INR`
                                                                        : `₹${props.selectedInventoryItem && props.selectedInventoryItem.attributes.sellingPrice.toFixed(2)} INR`;
                                                                })()
                                                            }
                                                        </b>
                                                    </h5>
                                                )}
                                                {type === "kit" && (
                                                    <p className="text-muted mb-4">
                                                        {props.selectedInventoryKit &&
                                                            props.selectedInventoryKit.attributes.description}
                                                    </p>
                                                )}
                                                {type === "item" && (
                                                    <p className="text-muted mb-4">
                                                        {props.selectedInventoryItem &&
                                                            props.selectedInventoryItem.attributes.description}
                                                    </p>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    {type === "kit" && <hr />}
                                    {type === "kit" && (
                                        <Row>
                                            <Col lg={1}></Col>
                                            <Col>
                                                {type === "kit" && (
                                                    <div style={{ overflowX: "auto" }}>
                                                        <Nav className="flex-row" pills>
                                                            {displayImages.map((img, key) => (
                                                                <NavItem key={key}>
                                                                    <NavLink
                                                                        className={classnames({
                                                                            active: activeTab === key,
                                                                        })}
                                                                        onClick={() => {
                                                                            setactiveTab(key);
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <img
                                                                                src={img ? img._url : ItemImage}
                                                                                onClick={() => {
                                                                                    setactiveTab(key);
                                                                                }}
                                                                                alt=""
                                                                                style={{
                                                                                    width: "40px",
                                                                                    height: "40px",
                                                                                    objectFit: "cover",
                                                                                    borderRadius: "5px",
                                                                                    display: "block",
                                                                                    margin: "auto",
                                                                                }}
                                                                                className="img-fluid"
                                                                            />
                                                                        </div>
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))}
                                                        </Nav>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    {type === "kit" && <hr />}
                                    <Row>
                                        <Col lg="1"></Col>
                                        <Col lg='5'>
                                            <div className="m-2">
                                                <div className="d-flex justify-content-center align-items-center m-2">
                                                    <div style={{ width: "40%" }}>
                                                        <ButtonGroup className="m-2">
                                                            <Button
                                                                color="primary"
                                                                disabled={
                                                                    props.component &&
                                                                    props.saleOrderobject.attributes.isApproved
                                                                }
                                                                onClick={() => {
                                                                    if (type == "kit") {
                                                                        kitcountDown();
                                                                        if (cartObject) {
                                                                            setShowUpdateCartButton(true);
                                                                        }
                                                                    } else {
                                                                        itemCountDown();
                                                                        if (cartObject) {
                                                                            setShowUpdateCartButton(true);
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                -
                                                            </Button>
                                                            <Input
                                                                type="text"
                                                                value={type == "kit" ? kitQuantity : itemQuantity}
                                                                name="demo_vertical"
                                                                readOnly
                                                                className="text-center"
                                                            />
                                                            <Button
                                                                color="primary"
                                                                disabled={
                                                                    props.component &&
                                                                    props.saleOrderobject.attributes.isApproved
                                                                }
                                                                onClick={() => {
                                                                    if (type == "kit") {
                                                                        kitCountUp();
                                                                        if (cartObject) {
                                                                            setShowUpdateCartButton(true);
                                                                        }
                                                                    } else {
                                                                        itemCountUp();
                                                                        if (cartObject) {
                                                                            setShowUpdateCartButton(true);
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                +
                                                            </Button>
                                                        </ButtonGroup>
                                                    </div>
                                                    <div>
                                                        {cartObject && !showUpdateCartButton && !props.component ? (
                                                            <Button
                                                                type="button"
                                                                color="danger"
                                                                className="m-2 btn waves-effect waves-light"
                                                                onClick={() => {
                                                                    removeCart(cartObject);
                                                                }}
                                                            >
                                                                <i className="bx bx-cart mr-2"></i>Remove From Cart
                                                            </Button>
                                                        ) : (
                                                            (!showUpdateCartButton && !props.component) && (
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    className="btn waves-effect waves-light m-2"
                                                                    onClick={() => {
                                                                        if (type === "kit") {
                                                                            addToKitCart();
                                                                        } else {
                                                                            addToItemCart();
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="bx bx-cart mr-2"></i>Add to cart
                                                                </Button>
                                                            )
                                                        )}
                                                        {showUpdateCartButton && (
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="btn waves-effect waves-light m-2"
                                                                onClick={() => {
                                                                    if (type === "kit") {
                                                                        addToKitCart();
                                                                    } else {
                                                                        addToItemCart();
                                                                    }
                                                                }}
                                                            >
                                                                <i className="bx bx-cart mr-2"></i>Update cart
                                                            </Button>
                                                        )}
                                                        {props.component && (
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                disabled={
                                                                    props.component &&
                                                                    props.saleOrderobject.attributes.isApproved
                                                                }
                                                                className="btn waves-effect waves-light m-2"
                                                                onClick={() => {
                                                                    if (type === "kit") {
                                                                        updateKitSaleOrders();
                                                                    } else {
                                                                        updateItemSaleOrders();
                                                                    }
                                                                }}
                                                            >
                                                                <i className="bx bx-cart mr-2"></i>Update Sale Order
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col lg={1}></Col>
                                        <Col >
                                            <u className='text-danger'><p className='text-danger'><b>Note:</b> Prices may vary based on your item selection.</p></u>
                                        </Col>
                                    </Row>
                                    {type === "item" && props.inventoryItemMatrixs.length > 0 && (
                                        <div className="mt-3">
                                            <h5 className="mb-3">Inventory Items :</h5>

                                            <div className="table-responsive">
                                                <table className="table mb-0 table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "4%", textAlign: "center" }}>
                                                                #
                                                            </th>
                                                            {(itemTableData.showColorColumn ||
                                                                itemTableData.showColorSizeColumn) && (
                                                                    <th style={{ width: "20%", textAlign: "center" }}>
                                                                        Color
                                                                    </th>
                                                                )}
                                                            {(itemTableData.showSizeColumn ||
                                                                itemTableData.showColorSizeColumn) && (
                                                                    <th style={{ width: "20%", textAlign: "center" }}>
                                                                        Size
                                                                    </th>
                                                                )}
                                                            <th style={{ width: "20%", textAlign: "center" }}> Price</th>
                                                            {itemTableData.showMatrixTable && (
                                                                <th
                                                                    style={{ width: "25%", textAlign: "center" }}
                                                                    className="text-center"
                                                                >
                                                                    <Row>
                                                                        <Col lg={4}>
                                                                            <p>{"Quantity"}</p>
                                                                        </Col>
                                                                        <Col>
                                                                            <p
                                                                                className={
                                                                                    totalSelectedQuantity == itemQuantity
                                                                                        ? "text-success"
                                                                                        : "text-danger"
                                                                                }
                                                                            >
                                                                                {"(" +
                                                                                    totalSelectedQuantity +
                                                                                    "/" +
                                                                                    itemQuantity +
                                                                                    " )"}
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </th>
                                                            )}
                                                            <th style={{ width: "16%", textAlign: "center" }}> Final Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {itemTableData &&
                                                            itemTableData.combinations &&
                                                            itemTableData.combinations.map((combination, index) => (
                                                                <tr key={index}>
                                                                    <td style={{ width: "4%", textAlign: "center" }}>
                                                                        {index + 1}
                                                                    </td>
                                                                    {combination.color && (
                                                                        <td style={{ width: "20%", textAlign: "center" }}>
                                                                            {combination.color.attributes.value}
                                                                        </td>
                                                                    )}
                                                                    {combination.size && (
                                                                        <td style={{ width: "20%", textAlign: "center" }}>
                                                                            {combination.size.attributes.value}
                                                                        </td>
                                                                    )}
                                                                    <td style={{ width: "20%", textAlign: "center" }}>
                                                                        {
                                                                            (() => {
                                                                                const sellingPrice = getInventoryItemCategoryPrice(combination.subItemId);
                                                                                return sellingPrice !== undefined
                                                                                    ? `₹${sellingPrice.toFixed(2)} INR`
                                                                                    : `₹${combination.cost.toFixed(2)} INR`;
                                                                            })()
                                                                        }
                                                                    </td>
                                                                    <td style={{ width: "25%", textAlign: "center" }}>
                                                                        <div style={{ width: "70%" }}>
                                                                            <ButtonGroup className="m-2">
                                                                                <Button
                                                                                    color="primary"
                                                                                    disabled={
                                                                                        combination.selectedQuntity <= 0 ||
                                                                                        (props.component &&
                                                                                            props.saleOrderobject.attributes
                                                                                                .isApproved)
                                                                                    }
                                                                                    onClick={() => {
                                                                                        handleItemCountDown(index);
                                                                                        if (cartObject) {
                                                                                            setShowUpdateCartButton(true);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    -
                                                                                </Button>
                                                                                <Input
                                                                                    type="text"
                                                                                    value={combination.selectedQuntity}
                                                                                    readOnly
                                                                                    className="text-center"
                                                                                />
                                                                                <Button
                                                                                    color="primary"
                                                                                    disabled={
                                                                                        props.component &&
                                                                                        props.saleOrderobject.attributes
                                                                                            .isApproved
                                                                                    }
                                                                                    onClick={() => {
                                                                                        handleItemCountUp(index);
                                                                                        if (cartObject) {
                                                                                            setShowUpdateCartButton(true);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    +
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ width: "15%", textAlign: "center" }}>
                                                                        {combination.finalPrice}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {type === "kit" && (
                                        <div className="mt-5">
                                            <Card
                                                style={{
                                                    maxHeight: "80vh",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                <CardBody>
                                                    <div className="mt-1">
                                                        <div
                                                            className="border rounded p-3 mb-1"
                                                            style={{ backgroundColor: "white" }}
                                                        >
                                                            <Row
                                                                style={{
                                                                    height: "8px",
                                                                    paddingBottom: "20px",
                                                                }}
                                                            >
                                                                <Col lg={1}>
                                                                    <div>
                                                                        <Label className="mb-1 font-size-14">
                                                                            #
                                                                        </Label>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <div>
                                                                        <Label className="mb-1 font-size-14">
                                                                            Items
                                                                        </Label>
                                                                    </div>
                                                                </Col>
                                                                <Col >
                                                                    <div>
                                                                        <Label className="mb-1 font-size-14">
                                                                            Unit
                                                                        </Label>
                                                                    </div>
                                                                </Col>
                                                                <Col >
                                                                    <div>
                                                                        <Label className="mb-1 font-size-14">
                                                                            Quantity
                                                                        </Label>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div>
                                                                        <Label className="mb-1 font-size-14">
                                                                            Price
                                                                        </Label>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <div>
                                                                        <Label className="mb-1 font-size-14">
                                                                            Final Quantity
                                                                        </Label>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <div>
                                                                        <Label className="mb-1 font-size-14">
                                                                            Final Price
                                                                        </Label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {kitTableData.map((item, key) => (
                                                            <Card className="mb-1 bg-whites" key={item.itemId}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={1}>
                                                                            <div className="d-flex">
                                                                                {key + 1}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={3}>
                                                                            <div className="pt-1">
                                                                                {item.itemName}
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="">{item.units}</div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="">{item.quantity}</div>
                                                                        </Col>
                                                                        <Col >
                                                                            <div className="">{getInventoryItemCategoryPrice(item.itemId) !== undefined ? getInventoryItemCategoryPrice(item.itemId) : item.unitPrice}</div>
                                                                        </Col>
                                                                        <Col lg={2}>
                                                                            <Row>
                                                                                <Col lg={3}>
                                                                                    <p>
                                                                                        {`${getFinalQuantity(item.quantity)}`}
                                                                                    </p>
                                                                                </Col>
                                                                                <Col lg={7}>
                                                                                    {item.showMatrixButton && (
                                                                                        <p
                                                                                            className={
                                                                                                item.selectedQuntity ==
                                                                                                    getFinalQuantity(item.quantity)
                                                                                                    ? "text-success"
                                                                                                    : "text-danger"
                                                                                            }
                                                                                        >
                                                                                            {`${"(" +
                                                                                                item.selectedQuntity +
                                                                                                "/" +
                                                                                                getFinalQuantity(item.quantity) +
                                                                                                " )"}`}
                                                                                        </p>
                                                                                    )}
                                                                                </Col>
                                                                            </Row>
                                                                            {item.showMatrixButton && (
                                                                                <u>
                                                                                    <Label
                                                                                        onClick={() => {
                                                                                            if (kitQuantity > 0) {
                                                                                                setShowItemMatrix(
                                                                                                    showItemMatrix === item.itemId
                                                                                                        ? ""
                                                                                                        : item.itemId
                                                                                                );
                                                                                            } else {
                                                                                                toastr.error("Add kit quantity", {
                                                                                                    timeOut: 500,
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <u
                                                                                            className="text-primary"
                                                                                            style={{ cursor: "pointer" }}
                                                                                        >
                                                                                            {showItemMatrix === item.itemId
                                                                                                ? "Close"
                                                                                                : item.matrixButtonName}
                                                                                        </u>
                                                                                    </Label>
                                                                                </u>
                                                                            )}
                                                                        </Col>
                                                                        <Col lg={2}>
                                                                            <div className="">{item.showMatrixButton == true ? (item.finalPrice) : (item.unitPrice * getFinalQuantity(item.quantity))}</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Collapse
                                                                        isOpen={showItemMatrix === item.itemId}
                                                                    >


                                                                        <div className="m-3">
                                                                            <Table className="table mb-0 table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        {item.combinations && item.combinations.some(comb => comb.color) && <th style={{ width: '20%', textAlign: 'center' }}>Color</th>}
                                                                                        {item.combinations && item.combinations.some(comb => comb.size) && <th style={{ width: '20%', textAlign: 'center' }}>Size</th>}
                                                                                        <th style={{ width: '20%', textAlign: 'center' }} >{"Price"}</th>
                                                                                        <th style={{ width: '25%', textAlign: 'center' }} className='text-center'>{"Quantity (" + item.selectedQuntity + "/" + getFinalQuantity(item.quantity) + " )"}</th>
                                                                                        <th style={{ width: '15%', textAlign: 'center' }} >{"Final Price"}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {item.combinations && item.combinations.map((combination, index) => (
                                                                                        <tr key={index}>
                                                                                            {combination.color && <td style={{ width: '20%', textAlign: 'center' }}>{combination.color.attributes.value}</td>}
                                                                                            {combination.size && <td style={{ width: '20%', textAlign: 'center' }}>{combination.size.attributes.value}</td>}
                                                                                            {<td style={{ width: '20%', textAlign: 'center' }}>{getInventoryItemCategoryPrice(combination.subItemId) !== undefined ? getInventoryItemCategoryPrice(combination.subItemId) : combination.cost}</td>}
                                                                                            <td style={{ width: '25%', textAlign: 'center' }}>
                                                                                                <div style={{ width: '70%' }}>
                                                                                                    <ButtonGroup className="m-2">
                                                                                                        <Button color="primary"
                                                                                                            disabled={combination.selectedQuntity <= 0 ? true : false || props.component && props.saleOrderobject.attributes.isApproved}
                                                                                                            onClick={() => {
                                                                                                                handleKitCountDown(item, key, combination, getFinalQuantity(item.quantity));
                                                                                                                if (cartObject) {
                                                                                                                    setShowUpdateCartButton(true)
                                                                                                                }
                                                                                                            }}>
                                                                                                            -
                                                                                                        </Button>
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            value={combination.selectedQuntity ? combination.selectedQuntity : 0}
                                                                                                            name="demo_vertical"
                                                                                                            readOnly
                                                                                                            className="text-center"
                                                                                                        />
                                                                                                        <Button
                                                                                                            color="primary"
                                                                                                            disabled={props.component && props.saleOrderobject.attributes.isApproved}
                                                                                                            onClick={() => {
                                                                                                                handleKitCountUp(item, key, combination, getFinalQuantity(item.quantity));
                                                                                                                if (cartObject) {
                                                                                                                    setShowUpdateCartButton(true)
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            +
                                                                                                        </Button>
                                                                                                    </ButtonGroup>
                                                                                                </div>
                                                                                            </td>
                                                                                            {<td style={{ width: '15%', textAlign: 'center' }}>{combination.finalPrice}</td>}
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </Collapse>
                                                                </CardBody>
                                                            </Card>
                                                        ))}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>)}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { selectedInventoryKit } = state.InventoryKits;
    const { selectedInventoryKitItems } = state.InventoryKitItems;
    const { inventoryItems, inventoryItemsOfItemIds, selectedInventoryItem, inventoryItemMatrixs, inventoryMatrixs, inventorySubItems, inventoryItemCategoryPrices } = state.InventoryItems;
    const { inventoryCart } = state.InventorySaleOrder;

    return {
        selectedSchool,
        selectedInventoryKit, selectedInventoryKitItems,
        inventoryItems, inventoryItemsOfItemIds, selectedInventoryItem, inventoryItemMatrixs, inventoryMatrixs, inventorySubItems, inventoryItemCategoryPrices,
        inventoryCart, addInventoryCart,
    };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    setSelectedInventoryKit, setSelectedInventoryKitItems,
    getInventoryItemForItemIds, setSelectedInventoryItem, getInventoryItemCategoryPriceForItemIds,
    getCartForForOwnerId, updateCartForOwnerId, getInventoryItemForOwnerId,
    getInventoryItemMatrixForItemId, getInventoryItemMatrixForItemIds,
    updateInventorySaleOrderItems, updateSelectedSaleOrder, getInventorySubItemForItemIds,
})(Products)));
