import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Input, Label, Button, CardText, CardTitle, Container, Form, InputGroup, InputGroupText, UncontrolledTooltip, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Parse from 'parse'
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Constants from "../../../Constents";
import {
    setSelecetdSchool, setSelectedInventoryItem, updateSelectedInventoryItem, getFranchise,
    getInventoryItemMatrixForItemId, getInventoryWarehouseForOwnerId, getInventorySubItemForItemIds,
    updateInventorySubItemForItemIds, getInventoryCategoriesForOwnerId, getInventoryItemCategoryPriceForItemIds,
} from '../../../store/actions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import UpdatePhoto from '../../../components/Common/UpdatePhoto';
import { v4 as uuidv4 } from 'uuid';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

function AddItems(props) {

    const matchAdd = matchPath(props.history.location.pathname, {
        path: "/add-inventory-items/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-add-items/:franchiseId",
        exact: true,
        strict: false,
    });

    const matchEditItem = matchPath(props.history.location.pathname, {
        path: "/edit-inventory-items/:schoolId/:itemId",
        exact: true,
        strict: false,
    });


    const franchiseMatchEditItem = matchPath(props.history.location.pathname, {
        path: "/franchise-edit-inventory-items/:franchiseId/:itemId",
        exact: true,
        strict: false,
    });

    const match = matchEditItem ?? franchiseMatchEditItem;
    const itemId = match && match.params.itemId;

    const schoolId = matchAdd && matchAdd.params.schoolId;
    const franchiseId = franchiseMatch ? franchiseMatch.params.franchiseId : (franchiseMatchEditItem && franchiseMatchEditItem.params.franchiseId)

    const [type, setType] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [sku, setSku] = useState();
    const [unit, setUnit] = useState();
    const [hsnCode, setHsnCode] = useState();
    const [taxPreferences, setTaxPreferences] = useState();
    const [xDimension, setXDimension] = useState();
    const [yDimension, setYDimension] = useState();
    const [weight, setWeight] = useState();
    const [manufacturer, setManufacturer] = useState();
    const [brand, setBrand] = useState();
    const [upc, setUpc] = useState();
    const [mpn, setMpn] = useState();
    const [ean, setEan] = useState();
    const [isbn, setIsbn] = useState();
    const [sellingPrice, setSellingPrice] = useState();
    const [preTaxPrice, setPreTaxPrice] = useState();
    const [costPrice, setCostPrice] = useState();
    const [sellingAccount, setSellingAccount] = useState();
    const [costAccount, setCostAccount] = useState();
    const [sellingDescription, setSellingDescription] = useState();
    const [costDescription, setCostDescription] = useState();
    const [taxRate, setTaxRate] = useState();
    const [minimumStock, setMinimumStock] = useState()
    const [saveLoding, setSaveLoding] = useState(false);

    const [typeError, setTypeError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [unitError, setUnitError] = useState(false);
    const [taxPreferencesError, setTaxPreferencesError] = useState(false);
    const [taxRateError, setTaxRateError] = useState(false);
    const [sellingPriceError, setSellingPriceError] = useState(false);
    const [image, setImage] = useState(undefined);
    const [localImage, setLocalImage] = useState(undefined);

    const [showSizeColor, setShowSizeColor] = useState(false);
    const [colorsArray, setColorsArray] = useState([]);
    const [sizeArray, setSizeArray] = useState([]);
    const [colorSizeList, setColorSizeList] = useState([]);
    const [modalColorSizeList, setModalColorSizeList] = useState([]);
    const [showTableModalEdit, setShowTableModalEdit] = useState(false);
    const [showSaveInEdit, setShowSaveInEdit] = useState(false);
    const [minimumStockError, setMinimumStockError] = useState(false)
    const [schoolFranchiseId, setSchoolFranchiseId] = useState()
    const [mainItemPriceMaps, setMainItemPriceMaps] = useState([]);
    const [subItemCategoryPriceMaps, setSubItemCategoryPriceMaps] = useState([]);

    const units = [
        { value: "box", label: "Box" },
        { value: "Cm", label: "cm(Centimeter)" },
        { value: "dz", label: "dz(Dozen)" },
        { value: "ft", label: "ft(Feet)" },
        { value: "g", label: "g(Grams)" },
        { value: "in", label: "in(Inches)" },
        { value: "kg", label: "kg(Kilograms)" },
        { value: "km", label: "km(Kilometers)" },
        { value: "mg", label: "mg(Milli Grams)" },
        { value: "ml", label: "ml(Milli meter)" },
        { value: "m", label: "m(Meter)" },
        { value: "pcs", label: "pcs(Pieces)" },
    ];

    const taxable = [
        { value: "Taxable", label: "Taxable" },
        { value: "Non-taxable", label: "Non-taxable" },
        { value: "Out of Scope", label: "Out of Scope" },
        { value: "Non GST Supply", label: "Non GST Supply" },
    ];

    useEffect(() => {
        if (franchiseId) {
            if (!props.franchise) {
                props.getFranchise(franchiseId);
            }

            if (!props.inventoryWarehouses || props.inventoryWarehouses.length == 0) {
                props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise')
            }

            props.getInventoryCategoriesForOwnerId(franchiseId, 'franchise');
        }
    }, [franchiseId]);

    useEffect(() => {
        if (schoolFranchiseId) {
            props.getInventoryWarehouseForOwnerId(schoolFranchiseId, 'franchise')
        }
    }, [schoolFranchiseId]);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        setSchoolFranchiseId(props.selectedSchool && props.selectedSchool.attributes.franchiseId);
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.updateSelectedInventoryItem(undefined)
        if (itemId) {
            props.setSelectedInventoryItem(itemId);
            props.getInventoryItemMatrixForItemId(itemId)
            props.updateInventorySubItemForItemIds([])
            props.getInventorySubItemForItemIds([itemId])
        }
    }, [itemId])

    useEffect(() => {
        if (itemId) {
            if (props.inventorySubItems && props.inventorySubItems.length > 0) {
                let itemsIds = []
                for (const subItems of props.inventorySubItems) {
                    if (subItems.attributes.itemId == itemId) {
                        itemsIds.push(subItems.id)
                    }
                }
                const allItemIds = [...itemsIds, itemId];
                props.getInventoryItemCategoryPriceForItemIds(allItemIds);

            } else {
                props.getInventoryItemCategoryPriceForItemIds([itemId]);
            }
        }
    }, [itemId, props.inventorySubItems]);

    const handelSave = () => {

        let checkSave = true;

        if (!type) {
            setTypeError(true);
            checkSave = false;
        }

        if (!name) {
            setNameError(true);
            checkSave = false;
        }

        if (!unit) {
            setUnitError(true);
            checkSave = false;
        }

        if (!sellingPrice) {
            setSellingPriceError(true);
            checkSave = false;
        }

        if (!taxPreferences) {
            setTaxPreferencesError(true);
            checkSave = false;
        }

        if (taxPreferences === "Taxable" && !taxRate) {
            setTaxRateError(true);
            checkSave = false;
        }

        if (!minimumStock) {
            setMinimumStockError(true);
            checkSave = false;
        }

        if (!checkSave) {
            return;
        }

        const InventoryItems = Parse.Object.extend("InventoryItems");
        var item = new InventoryItems();
        if (itemId) {
            item.id = itemId;
        } else {

            if (franchiseMatch) {
                item.set('ownerId', franchiseId);
                item.set('ownerType', "franchise");
            } else {
                item.set('ownerId', schoolId);
                item.set('ownerType', "school");
            }
            item.set('createdBy', Parse.User.current().attributes.teacherId)
            item.set('warehouseId', props.inventoryWarehouses[0].id)
        }

        item.set("type", type);
        item.set("name", name);
        item.set("description", description);
        item.set("sku", sku);
        item.set("unit", unit);
        item.set("hsn", hsnCode);
        item.set("taxPreferences", taxPreferences);
        item.set("xDimension", parseFloat(xDimension));
        item.set("yDimension", parseFloat(yDimension));
        item.set("weight", parseFloat(weight));
        item.set("manufacturer", manufacturer);
        item.set("brand", brand);
        item.set("upc", upc);
        item.set("mpn", mpn);
        item.set("ean", ean);
        item.set("isbn", isbn);
        item.set("sellingPrice", parseFloat(sellingPrice));
        item.set("pretaxPrice", parseFloat(preTaxPrice));
        item.set("costPrice", parseFloat(costPrice));
        item.set("sellingAccount", sellingAccount);
        item.set("costAccount", costAccount);
        item.set("sellingDescription", sellingDescription);
        item.set("costDescription", costDescription);
        item.set("taxRate", parseFloat(taxRate));
        item.set("minimumStock", parseInt(minimumStock));
        if (colorsArray.length > 0 || sizeArray.length > 0) {
            item.set("isMatrix", true);
        }

        setSaveLoding(true);

        if (localImage) {
            saveDisplayImage(item, localImage.imageFile)
        } else {
            saveItemObject(item);
        }

    }

    const saveDisplayImage = (item, imageFile) => {
        imageFile.save().then(
            (result) => {
                item.set('displayImage', result)
                saveItemObject(item)
            },
            (error) => {
                console.log("File:", error);
            },
            (progress) => {
                console.log(progress);
            }
        );
    }

    const saveItemObject = (item) => {
        item.save().then(
            (result) => {
                result.pin();
                console.log(result);
                props.updateSelectedInventoryItem(result);
                setSaveLoding(false)
                if (colorsArray.length > 0 || sizeArray.length > 0) {
                    saveColorsAndSizes(result.id);
                    if (matchEditItem || franchiseMatchEditItem) {
                        savePricesToCategoryMap()
                    }
                } else {
                    if (matchEditItem || franchiseMatchEditItem) {
                        savePricesToCategoryMap()
                    }
                    window.history.back()
                }
            },
            (error) => {
                setSaveLoding(false);
                console.log(error);
            }
        );
    };

    const saveColorsAndSizes = (itemId) => {
        const InventoryItemMatrix = Parse.Object.extend("InventoryItemMatrix");

        let list = [];

        for (let i = 0; i < colorsArray.length; i++) {
            let colorMatrix = new InventoryItemMatrix();
            if (colorsArray[i].object) {
                colorMatrix = colorsArray[i].object
            } else {
                colorMatrix.set("itemId", itemId);
                colorMatrix.set("type", "COLOR");
                colorMatrix.set("matrixId", colorsArray[i].matrixId);
            }

            colorMatrix.set("value", colorsArray[i].value);
            list.push(colorMatrix);
        }
        for (let i = 0; i < sizeArray.length; i++) {
            let sizeMatrix = new InventoryItemMatrix();
            if (sizeArray[i].object) {
                sizeMatrix = sizeArray[i].object
            } else {
                sizeMatrix.set("itemId", itemId);
                sizeMatrix.set("type", "SIZE");
                sizeMatrix.set("matrixId", sizeArray[i].matrixId)
            }
            sizeMatrix.set("value", sizeArray[i].value);
            list.push(sizeMatrix);
        }
        if (list.length > 0) {
            Parse.Object.saveAll(list).then(
                (results) => {
                    if (results.length > 0) {
                        saveSubItems(itemId, results)
                        window.history.back()
                        setSaveLoding(false)
                    }
                    console.log(results)
                },
                (error) => {
                    console.log("Error", error);
                }
            );
        }
    };

    const saveSubItems = (itemId, matrix) => {
        const InventoryItems = Parse.Object.extend("InventoryItems");

        let list = showSizeColor ? modalColorSizeList : colorSizeList;

        for (const subMatrix of list) {
            let item = new InventoryItems();
            if (subMatrix.matrixItem) {
                item = subMatrix.matrixItem;
                item.set("taxRate", taxRate);
                item.set("pretaxPrice", parseFloat(subMatrix.pretaxPrice) || parseFloat(preTaxPrice));
                item.set("sellingPrice", parseFloat(subMatrix.sellingPrice) || parseFloat(sellingPrice));
            } else {
                item.set("itemId", itemId);
                if (props.inventoryWarehouses && props.inventoryWarehouses) {
                    item.set("warehouseId", props.inventoryWarehouses[0].id);
                }
                item.set('ownerId', franchiseId);
                item.set('ownerType', "franchise");

                if (subMatrix.colorId) {
                    const colorSizeObj = matrix.find(
                        (item) => item.attributes.matrixId === subMatrix.colorId
                    );
                    item.set("matrixColorId", colorSizeObj.id);
                }

                if (subMatrix.sizeId) {
                    const colorSizeObj = matrix.find(
                        (item) => item.attributes.matrixId === subMatrix.sizeId
                    );
                    item.set("matrixSizeId", colorSizeObj.id);
                }

                item.set("taxRate", taxRate);
                item.set("pretaxPrice", parseFloat(subMatrix.pretaxPrice) || parseFloat(preTaxPrice));
                item.set("sellingPrice", parseFloat(subMatrix.sellingPrice) || parseFloat(sellingPrice));
            }

            item.save().then(
                (result) => {
                    result.pin();

                    if (props.inventorySubItems.length > 0) {
                        props.updateInventorySubItemForItemIds([...props.inventorySubItems, result]);
                    } else {
                        props.updateInventorySubItemForItemIds([result]);
                    }
                    setShowSizeColor(false);
                    console.log(result);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    const savePricesToCategoryMap = () => {
        const InventoryItemCategoryPriceMap = Parse.Object.extend("InventoryItemCategoryPriceMap");
        let priceMapList = [];

        for (const category of mainItemPriceMaps) {
            let priceMap;

            if (category.categoryPriceObject) {
                priceMap = category.categoryPriceObject;
                if (
                    priceMap.get("pretaxPrice") !== parseFloat(category.priceBeforeTax) ||
                    priceMap.get("sellingPrice") !== parseFloat(category.priceAfterTax) ||
                    priceMap.get("taxRate") !== parseFloat(category.taxRate)
                ) {
                    priceMap.set("pretaxPrice", parseFloat(category.priceBeforeTax));
                    priceMap.set("sellingPrice", parseFloat(category.priceAfterTax));
                    priceMap.set("taxRate", parseFloat(category.taxRate));
                }
            } else {
                priceMap = new InventoryItemCategoryPriceMap();
                priceMap.set("ownerId", franchiseId);
                priceMap.set("ownerType", franchiseMatch ? "franchise" : "school");
                priceMap.set("categoryId", category.inventoryCategory.id);
                priceMap.set("itemId", itemId);
                priceMap.set("pretaxPrice", parseFloat(category.priceBeforeTax));
                priceMap.set("sellingPrice", parseFloat(category.priceAfterTax));
                priceMap.set("taxRate", parseFloat(category.taxRate));
            }

            priceMapList.push(priceMap);
        }

        for (const subItemArray of subItemCategoryPriceMaps) {
            for (const category of subItemArray) {
                let priceMap;

                if (category.categoryPriceObject) {
                    priceMap = category.categoryPriceObject;
                    if (
                        priceMap.get("pretaxPrice") !== parseFloat(category.priceBeforeTax) ||
                        priceMap.get("sellingPrice") !== parseFloat(category.priceAfterTax) ||
                        priceMap.get("taxRate") !== parseFloat(category.taxRate)
                    ) {
                        priceMap.set("pretaxPrice", parseFloat(category.priceBeforeTax));
                        priceMap.set("sellingPrice", parseFloat(category.priceAfterTax));
                        priceMap.set("taxRate", parseFloat(category.taxRate));
                    }
                } else {
                    priceMap = new InventoryItemCategoryPriceMap();
                    priceMap.set("ownerId", franchiseId);
                    priceMap.set("ownerType", franchiseMatch ? "franchise" : "school");
                    priceMap.set("categoryId", category.inventoryCategory.id);
                    priceMap.set("itemId", category.subItemId);
                    priceMap.set("pretaxPrice", parseFloat(category.priceBeforeTax));
                    priceMap.set("sellingPrice", parseFloat(category.priceAfterTax));
                    priceMap.set("taxRate", parseFloat(category.taxRate));
                }

                priceMapList.push(priceMap);
            }
        }

        if (priceMapList.length > 0) {
            Parse.Object.saveAll(priceMapList).then(
                (results) => {
                    console.log("Prices saved to InventoryItemCategoryPriceMap:", results);
                },
                (error) => {
                    console.error("Error saving prices:", error);
                }
            );
        }
    };

    useEffect(() => {
        if (props.selectedInventoryItem && props.selectedInventoryItem.id == itemId) {
            setName(props.selectedInventoryItem.attributes.name);
            setDescription(props.selectedInventoryItem.attributes.description);
            setType(props.selectedInventoryItem.attributes.type);
            setSku(props.selectedInventoryItem.attributes.sku);
            setUnit(props.selectedInventoryItem.attributes.unit);
            setBrand(props.selectedInventoryItem.attributes.brand);
            setHsnCode(props.selectedInventoryItem.attributes.hsnCode);
            setTaxPreferences(props.selectedInventoryItem.attributes.taxPreferences);
            setTaxRate(props.selectedInventoryItem.attributes.taxRate);
            setMinimumStock(props.selectedInventoryItem.attributes.minimumStock)
            setXDimension(props.selectedInventoryItem.attributes.xDimension);
            setYDimension(props.selectedInventoryItem.attributes.yDimension);
            setManufacturer(props.selectedInventoryItem.attributes.manufacturer);
            setUpc(props.selectedInventoryItem.attributes.upc);
            setEan(props.selectedInventoryItem.attributes.ean);
            setIsbn(props.selectedInventoryItem.attributes.isbn);
            setMpn(props.selectedInventoryItem.attributes.mpn);
            setSellingAccount(props.selectedInventoryItem.attributes.sellingAccount);
            setCostAccount(props.selectedInventoryItem.attributes.costAccount);
            setSellingPrice(props.selectedInventoryItem.attributes.sellingPrice);
            setPreTaxPrice(props.selectedInventoryItem.attributes.pretaxPrice);
            setCostPrice(props.selectedInventoryItem.attributes.costPrice);
            setSellingDescription(props.selectedInventoryItem.attributes.sellingDescription);
            setCostDescription(props.selectedInventoryItem.attributes.costDescription);
            setImage(props.selectedInventoryItem.attributes.displayImage ? props.selectedInventoryItem.attributes.displayImage._url : undefined)
        }
    }, [props.selectedInventoryItem])


    useEffect(() => {
        if (match && props.inventorySubItems) {
            if (props.inventoryItemMatrixs) {
                const initialColors = [];
                const initialSizes = [];

                for (const item of props.inventoryItemMatrixs) {
                    if (item.attributes.type === 'COLOR') {
                        initialColors.push({ id: item.id, matrixId: item.attributes.matrixId, value: item.attributes.value, object: item });
                    } else if (item.attributes.type === 'SIZE') {
                        initialSizes.push({ id: item.id, matrixId: item.attributes.matrixId, value: item.attributes.value, object: item });
                    }
                }

                setColorsArray(initialColors);
                setSizeArray(initialSizes);

                getMatrixForColorAndSize(initialColors, initialSizes)
            }
        }
    }, [props.inventoryItemMatrixs, props.inventorySubItems]);

    function handleSubmit() {
        handelSave();
    }

    const handlePicChange = (imageFile, cr) => {

        setLocalImage({ imageFile: imageFile, cr: cr })
        setImage(cr);

    };

    const getMatrixForColorAndSize = (colorsArray, sizeArray, isNew) => {
        let list = []
        let slno = 1;

        if (colorsArray.length > 0 && sizeArray.length > 0) {
            for (let colorIndex = 0; colorIndex < colorsArray.length; colorIndex++) {
                for (let sizeIndex = 0; sizeIndex < sizeArray.length; sizeIndex++) {
                    const color = colorsArray[colorIndex];
                    const size = sizeArray[sizeIndex];

                    let matrixId = color.id + size.id;
                    let matrixItem = match && getMatricItemForId("b", color.id, size.id);

                    let obj = {
                        matrixId: matrixId,
                        itemId: matrixItem ? matrixItem.id : null,
                        matrixItem: matrixItem,
                        slno: slno,
                        color: color.value,
                        size: size.value,
                        colorId: color.matrixId,
                        sizeId: size.matrixId,
                        tax: taxRate,
                        pretaxPrice: matrixItem ? matrixItem.attributes.pretaxPrice : preTaxPrice,
                        sellingPrice: matrixItem ? matrixItem.attributes.sellingPrice : sellingPrice,
                    }

                    if (isNew) {
                        if (!color.object || !size.object) {
                            list.push(obj);
                            slno++;
                        }
                    } else {
                        list.push(obj);
                        slno++;
                    }

                }
            }


        } else if (colorsArray.length > 0) {
            for (let colorIndex = 0; colorIndex < colorsArray.length; colorIndex++) {
                const color = colorsArray[colorIndex];

                let matrixId = color.id;
                let matrixItem = match && getMatricItemForId("c", color.id);

                let obj = {
                    matrixId: matrixId,
                    itemId: matrixItem ? matrixItem.id : null,
                    matrixItem: matrixItem,
                    slno: colorIndex + 1,
                    color: color.value,
                    colorId: color.matrixId,
                    tax: taxRate,
                    pretaxPrice: matrixItem ? matrixItem.attributes.pretaxPrice : preTaxPrice,
                    sellingPrice: matrixItem ? matrixItem.attributes.sellingPrice : sellingPrice,
                };

                if (isNew) {
                    if (!color.object) {
                        list.push(obj);
                        slno++;
                    }
                } else {
                    list.push(obj);
                    slno++;
                }
            }


        } else if (sizeArray.length > 0) {
            for (let sizeIndex = 0; sizeIndex < sizeArray.length; sizeIndex++) {
                const size = sizeArray[sizeIndex];

                let matrixId = size.id;
                let matrixItem = match && getMatricItemForId("s", undefined, size.id);

                let obj = {
                    matrixId: matrixId,
                    itemId: matrixItem ? matrixItem.id : null,
                    matrixItem: matrixItem,
                    slno: sizeIndex + 1,
                    size: size.value,
                    sizeId: size.matrixId,
                    tax: taxRate,
                    pretaxPrice: matrixItem ? matrixItem.attributes.pretaxPrice : preTaxPrice,
                    sellingPrice: matrixItem ? matrixItem.attributes.sellingPrice : sellingPrice,
                };

                if (isNew) {
                    if (!size.object) {
                        list.push(obj);
                        slno++;
                    }
                } else {
                    list.push(obj);
                    slno++;
                }
            }

        }
        if (isNew) {
            setModalColorSizeList(list)
        } else {
            setColorSizeList(list);
        }

    }

    const getMatricItemForId = (type, matrixColorId, matrixSizeId) => {

        if (type == "b") {
            let colnSizeAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixColorId == matrixColorId && si.attributes.matrixSizeId == matrixSizeId)
            if (colnSizeAry.length > 0) {
                return colnSizeAry[0];
            }
        } else if (type == "c") {
            let colAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixColorId == matrixColorId)
            if (colAry.length > 0) {
                return colAry[0];
            }
        } else if (type == "s") {
            let sizAry = props.inventorySubItems && props.inventorySubItems.filter(si => si.attributes.matrixSizeId == matrixSizeId)
            if (sizAry.length > 0) {
                return sizAry[0];
            }
        }
        return undefined
    }

    useEffect(() => {
        if (props.inventoryCategories && props.inventoryCategories.length > 0) {

            let mainItemCategoryPriceMaps = []

            for (const category of props.inventoryCategories) {

                const categoryPrice = props.inventoryItemCategoryPrices.find((cp) => (category.id == cp.attributes.categoryId && cp.attributes.itemId == itemId))

                if (categoryPrice) {
                    let newItem = {
                        itemId: itemId,
                        priceMapId: categoryPrice.id,
                        inventoryCategory: category,
                        categoryPriceObject: categoryPrice,
                        priceBeforeTax: categoryPrice.attributes.pretaxPrice,
                        priceAfterTax: categoryPrice.attributes.sellingPrice,
                        taxRate: categoryPrice.attributes.taxRate ?? props.selectedInventoryItem.attributes.taxRate,
                    }
                    mainItemCategoryPriceMaps.push(newItem)
                } else {
                    let newItem = {
                        itemId: itemId,
                        priceMapId: "no_cat_main" + category.id + "_" + itemId,
                        inventoryCategory: category,
                        categoryPriceObject: undefined,
                        priceBeforeTax: props.selectedInventoryItem ? props.selectedInventoryItem.attributes.pretaxPrice : 0,
                        priceAfterTax: props.selectedInventoryItem ? props.selectedInventoryItem.attributes.sellingPrice : 0,
                        taxRate: props.selectedInventoryItem ? props.selectedInventoryItem.attributes.taxRate : 0,
                    }
                    mainItemCategoryPriceMaps.push(newItem)
                }
            }

            setMainItemPriceMaps(mainItemCategoryPriceMaps)

            if (props.inventorySubItems && props.inventorySubItems.length > 0) {
                let subItemList = []
                for (const si of props.inventorySubItems) {
                    if (si.attributes.itemId == itemId) {
                        subItemList.push(si)
                    }
                }

                let allSubItemCategoryPriceMaps = []
                for (const subItem of subItemList) {

                    let subItemCategoryPriceMaps = []

                    for (const category of props.inventoryCategories) {

                        const subItemcategoryPrice = props.inventoryItemCategoryPrices.find((cp) => (category.id == cp.attributes.categoryId && cp.attributes.itemId == subItem.id))

                        if (subItemcategoryPrice) {
                            let newItem = {
                                itemId: itemId,
                                subItemId: subItem.id,
                                priceMapId: subItemcategoryPrice.id,
                                inventoryCategory: category,
                                categoryPriceObject: subItemcategoryPrice,
                                priceBeforeTax: subItemcategoryPrice.attributes.pretaxPrice,
                                priceAfterTax: subItemcategoryPrice.attributes.sellingPrice,
                                taxRate: subItemcategoryPrice.attributes.taxRate ?? props.selectedInventoryItem.attributes.taxRate,
                            }
                            subItemCategoryPriceMaps.push(newItem)
                        } else {
                            let newItem = {
                                itemId: itemId,
                                subItemId: subItem.id,
                                priceMapId: "no_cat_sub" + category.id + "_" + subItem.id,
                                inventoryCategory: category,
                                categoryPriceObject: undefined,
                                priceBeforeTax: subItem ? subItem.attributes.pretaxPrice : 0,
                                priceAfterTax: subItem ? subItem.attributes.sellingPrice : 0,
                                taxRate: subItem ? subItem.attributes.taxRate : 0,
                            }
                            subItemCategoryPriceMaps.push(newItem)
                        }
                    }
                    allSubItemCategoryPriceMaps.push(subItemCategoryPriceMaps)
                }
                setSubItemCategoryPriceMaps(allSubItemCategoryPriceMaps);
            }
        }

    }, [props.inventoryItemCategoryPrices, props.inventoryCategories, props.selectedInventoryItem, props.inventorySubItems])

    const handleCategoryPriceChange = (categoryPriceMap, field, value, type) => {
        const newValue = parseFloat(value) || 0;

        if (type === "sub") {
            const updatedSubItems = subItemCategoryPriceMaps.map(subItemArray => {
                return subItemArray.map(category => {
                    if (category.priceMapId === categoryPriceMap.priceMapId) {
                        return {
                            ...category,
                            [field]: newValue,
                            ...(field === 'priceBeforeTax' ? { priceAfterTax: calculateSellingPrice(newValue, category.taxRate) } : { priceBeforeTax: calculatePreTaxPrice(newValue, category.taxRate) })
                        };
                    }
                    return category;
                });
            });

            setSubItemCategoryPriceMaps(updatedSubItems);
        } else {
            const updatedMainItems = mainItemPriceMaps.map(up => {
                if (up.priceMapId === categoryPriceMap.priceMapId) {
                    return {
                        ...up,
                        [field]: newValue,
                        ...(field === 'priceBeforeTax' ? { priceAfterTax: calculateSellingPrice(newValue, up.taxRate) } : { priceBeforeTax: calculatePreTaxPrice(newValue, up.taxRate) })
                    };
                }
                return up;
            });

            setMainItemPriceMaps(updatedMainItems);
        }
    };

    const calculateSellingPrice = (pretaxPrice, taxRate) => {
        return taxRate ? (pretaxPrice + (pretaxPrice * (taxRate / 100))).toFixed(2) : pretaxPrice;
    };

    const calculatePreTaxPrice = (sellingPrice, taxRate) => {
        return taxRate ? (sellingPrice / (1 + (taxRate / 100))).toFixed(2) : sellingPrice;
    };

    const getSubItemPriceMapRow = (subItem) => {
        for (const subRow of subItemCategoryPriceMaps) {
            if (subRow[0].subItemId == subItem) {
                return subRow;
            }
        }
    }

    return (
        <div className='page-content'>
            <Modal isOpen={showSizeColor} size='lg'>
                <ModalHeader toggle={() => {
                    const cleanedColorsArray = colorsArray.filter(item => item.value.trim() !== '');
                    const cleanedSizeArray = sizeArray.filter(item => item.value.trim() !== '');
                    setColorsArray(cleanedColorsArray);
                    setSizeArray(cleanedSizeArray);
                    setShowSizeColor(!showSizeColor);
                }}>
                    {match ? "Edit Size and Colors" : "Add Size and Colors"}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={1}></Col>
                        <Col>
                            <div className="flex-fill m-2">
                                <Label className="pb-0" style={{ verticalAlign: "middle" }}>
                                    Add Colors
                                </Label>
                                <Label
                                    className="m-2 text-primary"
                                    onClick={() => {
                                        const tempList = [...colorsArray];
                                        tempList.push({ id: undefined, matrixId: uuidv4(), value: '', error: false });
                                        setColorsArray(tempList);
                                    }}
                                    id="add-time-camera"
                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                >
                                    <i className="bx bx-plus-circle"></i>
                                </Label>
                            </div>
                            <hr />
                            {colorsArray.map((colAry, index) => (
                                <Row className="d-flex" key={colAry.matrixId}>
                                    <Col lg={10}>
                                        <div className="m-2">
                                            <Input
                                                className={`form-control ${colAry.error ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="displayName"
                                                placeholder={`Color ${index + 1}`}
                                                required
                                                value={colAry.value}
                                                onChange={(e) => {
                                                    const selectedColor = e.target.value;
                                                    const tempList = colorsArray.map(item =>
                                                        item.matrixId === colAry.matrixId ? { ...item, value: selectedColor, error: false } : item
                                                    );
                                                    setColorsArray(tempList);
                                                }}
                                            />
                                            {colAry.error && <div className="invalid-feedback">Please enter a value</div>}
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="float-right mt-0" style={{ verticalAlign: "middle" }}>
                                            <Label
                                                className="ml-2 float-right text-danger"
                                                onClick={() => {
                                                    const tempList = colorsArray.filter(item => item.matrixId !== colAry.matrixId);
                                                    setColorsArray(tempList);
                                                }}
                                                id="add-time-camera"
                                                style={{ fontSize: "20px", cursor: "pointer" }}
                                            >
                                                {!match && <i className="bx bx-minus-circle"></i>}
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                        <Col>
                            <div className="flex-fill m-2">
                                <Label className="pb-0" style={{ verticalAlign: "middle" }}>
                                    Add Size
                                </Label>
                                <Label
                                    className="m-2 text-primary"
                                    onClick={() => {
                                        const tempList = [...sizeArray];
                                        tempList.push({ id: undefined, matrixId: uuidv4(), value: '', error: false });
                                        setSizeArray(tempList);
                                    }}
                                    id="add-time-camera"
                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                >
                                    <i className="bx bx-plus-circle"></i>
                                </Label>
                            </div>
                            <hr />
                            {sizeArray.map((sizeAry, index) => (
                                <Row className="d-flex" key={sizeAry.matrixId}>
                                    <Col lg={10}>
                                        <div className="m-2">
                                            <Input
                                                className={`form-control ${sizeAry.error ? 'is-invalid' : ''}`}
                                                type="text"
                                                id="displayName"
                                                placeholder={`Size ${index + 1}`}
                                                required
                                                value={sizeAry.value}
                                                onChange={(e) => {
                                                    const selectedSize = e.target.value;
                                                    const tempList = sizeArray.map(item =>
                                                        item.matrixId === sizeAry.matrixId ? { ...item, value: selectedSize, error: false } : item
                                                    );
                                                    setSizeArray(tempList);
                                                }}
                                            />
                                            {sizeAry.error && <div className="invalid-feedback">Please enter a value</div>}
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="float-right mt-0" style={{ verticalAlign: "middle" }}>
                                            <Label
                                                className="ml-2 float-right text-danger"
                                                onClick={() => {
                                                    const tempList = sizeArray.filter(item => item.matrixId !== sizeAry.matrixId);
                                                    setSizeArray(tempList);
                                                }}
                                                id="add-time-camera"
                                                style={{ fontSize: "20px", cursor: "pointer" }}
                                            >
                                                {!match && <i className="bx bx-minus-circle"></i>}
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                        <Col lg={1}></Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            {showTableModalEdit && modalColorSizeList && modalColorSizeList.length > 0 && <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th style={{ width: "5%" }}>#</th>
                                        {colorsArray.length > 0 && <th>Color</th>}
                                        {sizeArray.length > 0 && <th>Size</th>}
                                        <th style={{ width: "25%" }}>Before Tax</th>
                                        <th style={{ width: "25%" }}>After Tax</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalColorSizeList && modalColorSizeList.length > 0 ? (
                                        modalColorSizeList.map((item, index) => (
                                            <tr key={item.matrixId}>
                                                <td>{item.slno}</td>
                                                {colorsArray.length > 0 && <td>{item.color}</td>}
                                                {sizeArray.length > 0 && <td>{item.size}</td>}
                                                <td>
                                                    <Input
                                                        type="number"
                                                        placeholder="Before Tax"
                                                        value={item.pretaxPrice ? item.pretaxPrice : 0}
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value) || 0;
                                                            const updatedList = modalColorSizeList.map((listItem, i) =>
                                                                i === index
                                                                    ? {
                                                                        ...listItem,
                                                                        pretaxPrice: newValue,
                                                                        sellingPrice: newValue
                                                                            ? (newValue + (newValue * taxRate / 100)).toFixed(2)
                                                                            : listItem.sellingPrice,
                                                                    }
                                                                    : listItem
                                                            );
                                                            setModalColorSizeList(updatedList);
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        placeholder="After Tax"
                                                        value={item.sellingPrice ? item.sellingPrice : 0}
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value) || 0;
                                                            const updatedList = modalColorSizeList.map((listItem, i) =>
                                                                i === index
                                                                    ? {
                                                                        ...listItem,
                                                                        sellingPrice: newValue,
                                                                        pretaxPrice: newValue
                                                                            ? (newValue / (1 + taxRate / 100)).toFixed(2)
                                                                            : listItem.pretaxPrice,
                                                                    }
                                                                    : listItem
                                                            );
                                                            setModalColorSizeList(updatedList);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : null}
                                </tbody>
                            </Table>}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!showSaveInEdit && <Button
                        color='primary'
                        className='btn'
                        onClick={() => {
                            let valid = true;

                            const updatedColorsArray = colorsArray.map(item => {
                                if (item.value.trim() === '') {
                                    valid = false;
                                    return { ...item, error: true };
                                }
                                return item;
                            });

                            const updatedSizeArray = sizeArray.map(item => {
                                if (item.value.trim() === '') {
                                    valid = false;
                                    return { ...item, error: true };
                                }
                                return item;
                            });

                            setColorsArray(updatedColorsArray);
                            setSizeArray(updatedSizeArray);

                            if (valid) {
                                if (matchAdd || franchiseMatch) {
                                    getMatrixForColorAndSize(colorsArray, sizeArray);
                                    setShowSizeColor(false);
                                } else {
                                    setShowTableModalEdit(true)
                                    getMatrixForColorAndSize(colorsArray, sizeArray, true);
                                    setShowSaveInEdit(true)
                                }
                            }
                        }}
                    >
                        Next
                    </Button>}
                    {showSaveInEdit && <Button
                        color='primary'
                        className='btn'
                        onClick={() => {
                            if (match) {
                                saveColorsAndSizes(itemId)
                            }
                        }}
                    >
                        Save
                    </Button>}
                </ModalFooter>
            </Modal>
            <Row className='d-flex align-items-start'>
                <Col className="d-flex align-items-start">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            window.history.back()
                        }}>
                        <ul className=" list-unstyled">
                            <div className=''>
                                <i className="bx bx-chevron-left h1 text-primary"></i>
                            </div>
                        </ul>
                    </div>
                    <div className=' m-2'>
                        <h4>{(matchEditItem || franchiseMatchEditItem) ? "Edit Item" : "New Item"}</h4>
                    </div>
                </Col>
            </Row>

            <Card>
                <CardBody>
                    <AvForm className="needs-validation" onValidSubmit={handleSubmit} >
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="form-group d-flex align-items-center">
                                        <Label htmlFor="example-radio-input" className="col-md-2 col-form-label ">Type<i className="fas fa-question-circle" id="types">
                                            <UncontrolledTooltip placement="right" target="types">
                                                Type
                                            </UncontrolledTooltip></i></Label>
                                        <div className="custom-control custom-radio custom-control-inline m-2">
                                            <Input
                                                type="radio"
                                                id="customRadioInline1"
                                                name="customRadioInline1"
                                                checked={type === "Goods"}
                                                className="custom-control-input m-1"
                                                onChange={(e) => {
                                                    setType("Goods");
                                                    setTypeError(false)
                                                }}
                                                required
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="customRadioInline1"
                                            >
                                                Goods
                                            </Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline m-2" style={{ marginLeft: "30px" }}>
                                            <Input
                                                type="radio"
                                                id="customRadioInline2"
                                                name="customRadioInline1"
                                                checked={type === "Service"}
                                                className="custom-control-input m-1"
                                                onChange={(e) => {
                                                    setType("Service");
                                                    setTypeError(false)
                                                }}
                                            />
                                            <Label
                                                className="custom-control-label"
                                                htmlFor="customRadioInline2"
                                            >
                                                Service
                                            </Label>
                                        </div>
                                    </div>
                                    {typeError && <small className='text-danger'>* Select type of items</small>}
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="example-text-input" className="col-form-label" style={{ color: 'red' }}>Name*</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="displayName"
                                        placeholder="Name"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                            setNameError(false);
                                        }}
                                    />
                                    {nameError && <small className='text-danger'>* Enter item name</small>}
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="example-text-input" className="col-form-label">Description:</Label>
                                    <Input
                                        className="form-control"
                                        type="textarea"
                                        id="description"
                                        placeholder="Write here..."
                                        rows="2"
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="text-center  ">
                                <div >
                                    {!image ? (
                                        <div
                                            className="avatar-lg mx-auto mb-2"
                                            style={{ width: "10rem", height: "10rem" }}
                                        >
                                            <span
                                                className={
                                                    "avatar-title  bg-soft-primary text-primary"
                                                }
                                            // style={{ fontSize: "10px" }}
                                            >
                                                Upload image
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            <img
                                                style={{ width: "10rem", height: "10rem" }}
                                                className="avatar mb-2"
                                                src={image}
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <UpdatePhoto
                                        className="p-1"
                                        isPopupOn={false}
                                        onPicChange={(imageFile, cr) => {
                                            handlePicChange(imageFile, cr);
                                        }}
                                        initialImage={image}
                                    ></UpdatePhoto>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="example-text-input" className="col-form-label">
                                        SKU <i className="fas fa-question-circle" id="SKU">
                                            <UncontrolledTooltip placement="right" target="SKU">
                                                Stock keeping unit
                                            </UncontrolledTooltip>
                                        </i>
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="displayName"
                                        placeholder="Stock keeping unit"
                                        required
                                        value={sku}
                                        onChange={(e) => {
                                            setSku(e.target.value);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="example-text-input" className="col-form-label">Minimum Stock:</Label>
                                    <Input
                                        className="form-control"
                                        type="number"
                                        id="displayName"
                                        placeholder="Minimum Stock"
                                        value={minimumStock}
                                        onChange={(e) => {
                                            setMinimumStock(e.target.value);
                                            setMinimumStockError(false);
                                        }}
                                    />
                                    {minimumStockError && <small className="text-danger">* Minimum stock is required</small>}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="example-text-input" className="col-form-label">Unit:</Label>
                                    <select
                                        className="form-control"
                                        value={unit}
                                        style={{ width: "14rem" }}
                                        onChange={(e) => {
                                            setUnit(e.target.value);
                                            setUnitError(false);
                                        }}
                                    >
                                        <option disabled selected value>
                                            Select Unit
                                        </option>

                                        {units.map((value, key) => (
                                            <option key={value.value} value={value.value}>
                                                {value.label}
                                            </option>
                                        ))}
                                    </select>
                                    {unitError && <small className="text-danger">* Select unit</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="example-text-input" className="col-form-label" style={{ color: 'red' }}>Tax:</Label>
                                    <select
                                        className="form-control"
                                        style={{ width: "14rem" }}
                                        value={taxPreferences}
                                        onChange={(e) => {
                                            setTaxPreferences(e.target.value);
                                            setTaxRate()
                                            setTaxPreferencesError(false);
                                        }}
                                    >
                                        <option disabled selected value>
                                            Select Preference Tax
                                        </option>
                                        {taxable.map((value, key) => (
                                            <option key={value.value} value={value.value}>
                                                {value.label}
                                            </option>
                                        ))}
                                    </select>
                                    {taxPreferencesError && <small className="text-danger">* Please select tax preference</small>}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="taxRate" className="col-form-label">Tax rate (In %):</Label>
                                    <Input
                                        className="form-control"
                                        type="number"
                                        id="taxRate"
                                        placeholder="Tax rate"
                                        required
                                        value={taxPreferences === "Taxable" ? taxRate : ""}
                                        disabled={taxPreferences !== "Taxable"}
                                        onChange={(e) => {
                                            const newTaxRate = parseFloat(e.target.value) || 0;
                                            setTaxRate(newTaxRate);
                                            setTaxRateError(false);

                                            const calculatedAfterTaxPrice = preTaxPrice + (preTaxPrice * (newTaxRate / 100));
                                            setSellingPrice(calculatedAfterTaxPrice.toFixed(2));

                                            if (colorSizeList && colorSizeList.length > 0) {
                                                const updatedColorSizeList = colorSizeList.map((c) => ({
                                                    ...c,
                                                    sellingPrice: c.pretaxPrice + (c.pretaxPrice * (newTaxRate / 100)),
                                                }));
                                                setColorSizeList(updatedColorSizeList);
                                            }
                                        }}
                                    />
                                    {taxRateError && <small className="text-danger">* Tax rate is required</small>}
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <Label htmlFor="sellingPriceBeforeTax" className="col-form-label">Price Before Tax:</Label>
                                    <InputGroup>
                                        <InputGroupText>INR</InputGroupText>
                                        <Input
                                            type="number"
                                            id="sellingPriceBeforeTax"
                                            placeholder="Pre-tax price"
                                            value={preTaxPrice}
                                            required
                                            onChange={(e) => {
                                                const newPreTaxPrice = parseFloat(e.target.value) || 0;
                                                setPreTaxPrice(newPreTaxPrice);
                                                setSellingPriceError(false);

                                                const calculatedAfterTaxPrice = taxRate
                                                    ? newPreTaxPrice + (newPreTaxPrice * (taxRate / 100))
                                                    : newPreTaxPrice;
                                                setSellingPrice(calculatedAfterTaxPrice.toFixed(2));
                                            }}
                                        />
                                    </InputGroup>

                                    {sellingPriceError && <small className='text-danger'>*Enter Pretax price</small>}
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <Label htmlFor="sellingPriceAfterTax" className="col-form-label">Price After Tax:</Label>
                                    <InputGroup>
                                        <InputGroupText>INR</InputGroupText>
                                        <Input
                                            type="number"
                                            id="sellingPriceAfterTax"
                                            placeholder="Selling price"
                                            value={sellingPrice}
                                            onChange={(e) => {
                                                const newSellingPrice = parseFloat(e.target.value) || 0;
                                                setSellingPrice(newSellingPrice);
                                                setSellingPriceError(false);

                                                const calculatedPreTaxPrice = taxRate
                                                    ? newSellingPrice / (1 + (taxRate / 100))
                                                    : newSellingPrice;
                                                setPreTaxPrice(calculatedPreTaxPrice.toFixed(2));
                                            }}
                                        />
                                    </InputGroup>

                                </FormGroup>
                            </Col>


                        </Row>
                        {(matchEditItem || franchiseMatchEditItem) && mainItemPriceMaps.map((categoryPriceMap, index) => (
                            <Row key={index}>
                                <Col></Col>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor={`region-${index}`} className="col-form-label">Region / Category Name:</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            id={`region-${index}`}
                                            placeholder="Region/Category"
                                            required
                                            value={categoryPriceMap.inventoryCategory.attributes.name}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor={`sellingPriceBeforeTax-${index}`} className="col-form-label">Price Before Tax:</Label>
                                        <InputGroup>
                                            <InputGroupText>INR</InputGroupText>
                                            <Input
                                                type="number"
                                                id={`sellingPriceBeforeTax-${index}`}
                                                placeholder="Pre-tax price"
                                                value={categoryPriceMap.priceBeforeTax}
                                                required
                                                onChange={(e) => {
                                                    handleCategoryPriceChange(categoryPriceMap, 'priceBeforeTax', e.target.value);
                                                }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor={`sellingPriceAfterTax-${index}`} className="col-form-label">Price After Tax:</Label>
                                        <InputGroup>
                                            <InputGroupText>INR</InputGroupText>
                                            <Input
                                                type="number"
                                                id={`sellingPriceAfterTax-${index}`}
                                                placeholder="Selling price"
                                                value={categoryPriceMap.priceAfterTax}
                                                onChange={(e) => {
                                                    handleCategoryPriceChange(categoryPriceMap, 'priceAfterTax', e.target.value);
                                                }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        ))}
                        {false && <Row>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Dimensions:</Label>
                                        <div className="col-md-10">
                                            <InputGroup>
                                                <InputGroupText>cm</InputGroupText>
                                                <Input className="form-control"
                                                    type="number"
                                                    id="x"
                                                    placeholder="X"
                                                    value={xDimension}
                                                    required
                                                    onChange={(e) => {
                                                        setXDimension(e.target.value)
                                                    }}
                                                /><Input className="form-control"
                                                    type="number"
                                                    id="y"
                                                    placeholder="Y"
                                                    value={yDimension}
                                                    required
                                                    onChange={(e) => {
                                                        setYDimension(e.target.value)
                                                    }}
                                                /></InputGroup>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Weight:</Label>
                                        <div className="col-md-10">
                                            <InputGroup>
                                                <InputGroupText>Kg</InputGroupText>
                                                <Input className="form-control"
                                                    type="number"
                                                    id="weight"
                                                    placeholder="Weight"
                                                    required
                                                    value={weight}
                                                    onChange={(e) => {
                                                        setWeight(e.target.value)
                                                    }}
                                                /></InputGroup>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>}
                        {false && <Row>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Manufacturer:</Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="Manufacturer"
                                                required
                                                value={manufacturer}
                                                onChange={(e) => {
                                                    setManufacturer(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <div className="form-group row">
                                        <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Brand:</Label>
                                        <div className="col-md-10">
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="Brand"
                                                required
                                                value={brand}
                                                onChange={(e) => {
                                                    setBrand(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>}
                        <hr />
                        {preTaxPrice && <div className='m-2'>
                            <Button
                                color='primary'
                                className='btn btn-sm'
                                onClick={() => {
                                    setShowSizeColor(true);
                                }}>
                                Add Size and Color
                            </Button>
                        </div>}
                        <div>
                            {colorSizeList && colorSizeList.length > 0 && (
                                <Row>
                                    <Col>
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" className="table table-striped table-bordered table-centered table-nowrap mb-0 table-sm m-1">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>SL No.</Th>
                                                            {colorsArray.length > 0 && <Th>Color Name</Th>}
                                                            {sizeArray.length > 0 && <Th>Size Name</Th>}
                                                            <Th>Before Tax</Th>
                                                            <Th>After Tax</Th>
                                                            {(matchEditItem || franchiseMatchEditItem) && props.inventoryCategories.map((category, index) => (
                                                                <React.Fragment key={index}>
                                                                    <Th>{`${category.attributes.name} Before Tax`}</Th>
                                                                    <Th>{`${category.attributes.name} After Tax`}</Th>
                                                                </React.Fragment>
                                                            ))}
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {colorSizeList.map((item, index) => (
                                                            <Tr key={item.matrixId}>
                                                                <Td>{item.slno}</Td>
                                                                {colorsArray.length > 0 && <Td>{item.color}</Td>}
                                                                {sizeArray.length > 0 && <Td>{item.size}</Td>}
                                                                <Td>
                                                                    <Input
                                                                        type="number"
                                                                        placeholder="Before Tax"
                                                                        value={item.pretaxPrice || 0}
                                                                        onChange={(e) => {
                                                                            const newValue = parseFloat(e.target.value) || 0;
                                                                            const updatedList = colorSizeList.map((listItem, i) =>
                                                                                i === index ? { ...listItem, pretaxPrice: newValue, sellingPrice: calculateSellingPrice(newValue, taxRate) } : listItem
                                                                            );
                                                                            setColorSizeList(updatedList);
                                                                        }}
                                                                    />
                                                                </Td>
                                                                <Td>
                                                                    <Input
                                                                        type="number"
                                                                        placeholder="After Tax"
                                                                        value={item.sellingPrice || 0}
                                                                        onChange={(e) => {
                                                                            const newValue = parseFloat(e.target.value) || 0;
                                                                            const updatedList = colorSizeList.map((listItem, i) =>
                                                                                i === index ? { ...listItem, sellingPrice: newValue, pretaxPrice: calculatePreTaxPrice(newValue, taxRate) } : listItem
                                                                            );
                                                                            setColorSizeList(updatedList);
                                                                        }}
                                                                    />
                                                                </Td>
                                                                {(matchEditItem || franchiseMatchEditItem) && getSubItemPriceMapRow(item.itemId) && getSubItemPriceMapRow(item.itemId).map((category) => (
                                                                    <React.Fragment key={category.priceMapId}>
                                                                        <Td>
                                                                            <Input
                                                                                type="number"
                                                                                placeholder={`${category.inventoryCategory.attributes.name} Before Tax`}
                                                                                value={category.priceBeforeTax || 0}
                                                                                onChange={(e) => {
                                                                                    handleCategoryPriceChange(category, 'priceBeforeTax', e.target.value, "sub");
                                                                                }}
                                                                            />
                                                                        </Td>
                                                                        <Td>
                                                                            <Input
                                                                                type="number"
                                                                                placeholder={`${category.inventoryCategory.attributes.name} After Tax`}
                                                                                value={category.priceAfterTax || 0}
                                                                                onChange={(e) => {
                                                                                    handleCategoryPriceChange(category, 'priceAfterTax', e.target.value, "sub");
                                                                                }}
                                                                            />
                                                                        </Td>
                                                                    </React.Fragment>
                                                                ))}
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                        <div className='d-flex justify-content-end'>
                            {
                                saveLoding ?
                                    <Button type="button" color="primary" className=" waves-effect float-right">
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>Saving...
                                    </Button> :
                                    <Button type="submit" className='float-right'
                                        color="primary">
                                        SAVE
                                    </Button>
                            }
                        </div>
                    </AvForm>
                </CardBody>
            </Card >

        </div >
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { selectedInventoryItem, inventoryItemMatrixs, inventorySubItems, inventoryItemCategoryPrices } = state.InventoryItems;
    const { franchise } = state.Franchise;
    const { inventoryWarehouses } = state.InventoryWarehouse;
    const { inventoryCategories } = state.InventoryCategories;

    return {
        selectedSchool, selectedInventoryItem, franchise, inventoryItemMatrixs,
        inventoryWarehouses, inventorySubItems, inventoryCategories, inventoryItemCategoryPrices
    };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, setSelectedInventoryItem, updateSelectedInventoryItem,
    getFranchise, getInventoryItemMatrixForItemId, getInventoryWarehouseForOwnerId,
    getInventorySubItemForItemIds, updateInventorySubItemForItemIds, getInventoryCategoriesForOwnerId
    , getInventoryItemCategoryPriceForItemIds,
})(AddItems)));
