import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Table, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Label, Badge, Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import Image from "../../assets/images/getStartedImages/studentInitial.svg";
import { withNamespaces } from 'react-i18next';
import toastr from 'toastr';
import * as WhiteLable from "../../components/Common/WhiteLable";

const ListKidView = (props) => {
    const [allKids, allKidsOps] = useState([])
    const [selectAll, setSelectAll] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedParentType, setSelectedParentType] = useState('');

    useEffect(() => {
        allKidsOps(props.kids)
        props.moveToChildListOps([])
        props.showMoveToOptionsOps(false)

    }, [props.kids])

    function handelSelectAll(status) {


        let tempKids = Object.assign([], allKids);
        let kidIds = []
        for (const index of tempKids) {
            if (status) {
                index.moveTo = true
                kidIds.push(index.id)
            } else {
                index.moveTo = false
            }

        }
        if (status && kidIds.length > 0) {
            props.moveToChildListOps(kidIds)
        }
        allKidsOps(tempKids)
        setSelectAll(status)

    }

    const getCorporatePartner = (corporateId) => {
        if (props.corporatePartners && corporateId) {
            for (const cp of props.corporatePartners) {
                if (corporateId == cp.id) {
                    return cp.attributes.name;
                }
            }
        }
    }

    function copyAppLink(str, event) {
        document.oncopy = function (event) {
            event.clipboardData.setData("Text", str);
            event.preventDefault();
        };
        document.execCommand("Copy");
        document.oncopy = undefined;
        toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
    }

    function toggleModal(user, parentType) {
        setSelectedUser(user);
        setSelectedParentType(parentType);
        setModal(!modal);
    }

    function sendWhatsAppInvite() {
        if (selectedUser && selectedParentType) {
            const phoneNumber = selectedUser[`${selectedParentType}Number`];
            const parentName = selectedUser[`${selectedParentType}Name`];
            const schoolName = props.selectedSchool.attributes.Name;
            const message = `Dear ${parentName},\n\nPlease download lilTriangle app by clicking this link https://onelink.to/liltriangle to get all the updates from ${schoolName}Please use this phone number to register.\n\nPowered by lilTriangle`;
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl);
        }
    }


    const listViewRender = () => {
        return (
            <div className="table-responsive mt-2">
                <Table className="table-centered table-nowrap table-hover">
                    <thead className="thead-light">
                        <tr>
                            {props.showClassView && <th style={{ width: "50px" }}>
                                {props.showClassView && <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheckboxInKids"
                                        checked={selectAll}
                                        onChange={() => { handelSelectAll(!selectAll) }}
                                    />
                                    <label
                                        className="custom-control-label"
                                        onClick={() => {
                                            // handelSelectAll(!selectAll)

                                        }}
                                    />
                                </div>}

                                {!props.showClassView && <div></div>}

                            </th>}


                            <th scope="col" colSpan="2">{props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 ? "Participant Name" : "Student Name"}</th>
                            {props.selectedSchool && (props.selectedSchool.attributes.schoolType === 10 || props.selectedSchool.attributes.schoolType === 11) ?
                                < th scope="col">Details</th> : < th scope="col">Family</th>
                            }
                            {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <th scope="col">Class</th>}
                            {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && props.showClassView && <th scope="col">Programs</th>}
                            <th scope="col">Action</th>
                        </tr>

                    </thead>
                    <tbody>
                        {/* {allKids.length === 0 && <tr>
                                <td colSpan="6">No Student Founud For The Class</td>
                            </tr>} */}
                        {

                            allKids.map((user, i) =>
                                <tr key={user.id} >
                                    {props.showClassView && <td>
                                        <div className="custom-control custom-checkbox mb-2">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input input-mini"
                                                id={`checkbox${user.id}`}
                                                checked={user.moveTo}
                                                onClick={(e) => {
                                                    let tempKids = allKids
                                                    let hasTrueValue = false
                                                    for (let i in tempKids) {
                                                        if (tempKids[i].id === user.id) {
                                                            tempKids[i].moveTo = e.target.checked
                                                            break
                                                        }
                                                    }
                                                    let temp = []
                                                    for (let i in tempKids) {
                                                        if (tempKids[i].moveTo) {
                                                            temp.push(tempKids[i].id)
                                                        }
                                                    }
                                                    for (let i in tempKids) {
                                                        if (tempKids[i].moveTo) {
                                                            hasTrueValue = true
                                                            break;
                                                        }
                                                    }
                                                    if (hasTrueValue) {
                                                        props.moveToChildListOps(temp)
                                                        props.showMoveToOptionsOps(true)
                                                    } else {
                                                        props.moveToChildListOps([])
                                                        props.showMoveToOptionsOps(false)
                                                    }
                                                    allKidsOps(tempKids)
                                                }
                                                }
                                            />
                                            <Label className="custom-control-label" for={`checkbox${user.id}`}></Label>
                                        </div>
                                    </td>}
                                    <td style={{ width: "50px" }}>
                                        <div className='p-2'>
                                            {
                                                !user.img
                                                    ? <div className="avatar-xs">
                                                        <Link to={`/studentProfile/${user.schoolId}/${user.id}`} ><span className="avatar-title rounded-circle">
                                                            {user.name ? user.name.charAt(0) : ''}
                                                        </span></Link>
                                                    </div>
                                                    : <div>
                                                        <Link to={`/studentProfile/${user.schoolId}/${user.id}`} ><img className="rounded-circle avatar-xs" src={user.img} alt="" /></Link>
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 className="font-size-14  mt-2"><Link to={`/studentProfile/${user.schoolId}/${user.id}`} className="text-dark">{user.name}</Link></h5>
                                            {user.isDisabled == true && <Link className="text-secondary m-1"
                                                to={"#"} id={"kidDisable"}>
                                                <i className="bx bx-block" ></i>
                                                <UncontrolledTooltip placement="top" target={"kidDisable"}>
                                                    Disabled
                                                </UncontrolledTooltip>
                                            </Link>}

                                        </div>
                                        <p>{user.admissionNumber}</p>
                                    </td>
                                    {props.selectedSchool && (props.selectedSchool.attributes.schoolType === 10 || props.selectedSchool.attributes.schoolType === 11) ? <td>
                                        <p>{user.email}</p>
                                        <p>{user.phoneNumber}</p>
                                    </td> : <td>


                                        <ul className="list-inline  contact-links mb-0">
                                            {(user.motherNumber || user.motherEMail) && user.motherUsingApp && <li className="list-inline-item border-1"
                                                style={{ cursor: "pointer" }}
                                                id={"installed" + user.motherId}>
                                                <i className=" font-size-14 text-success bx bx-check-circle"></i>
                                                <UncontrolledTooltip placement="top" target={"installed" + user.motherId}>
                                                    {props.t('Parent is Using App')}
                                                </UncontrolledTooltip>

                                            </li>}
                                            {(user.motherNumber || user.motherEMail) && !user.motherUsingApp && <li className="list-inline-item  border-1"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => { toggleModal(user, 'mother'); }}
                                                id={"notinstalled" + user.motherId}>
                                                <i className="font-size-14 text-warning bx bx-navigation"></i>
                                                <UncontrolledTooltip placement="top" target={"notinstalled" + user.motherId}>
                                                    {props.t('Parent is not Using App, click to send invite!')}
                                                </UncontrolledTooltip>

                                            </li>}
                                            {user.motherName && <li className="list-inline-item text-muted">
                                                {user.motherName} : {user.motherNumber}
                                            </li>}

                                        </ul>
                                        <ul className="list-inline  contact-links mb-0">
                                            {(user.fatherNumber || user.fatherEMail) && user.fatherUsingApp && <li className="list-inline-item border-1"
                                                style={{ cursor: "pointer" }}
                                                id={"installed" + user.fatherId}>
                                                <i className=" font-size-14 text-success bx bx-check-circle"></i>
                                                <UncontrolledTooltip placement="top" target={"installed" + user.fatherId}>
                                                    {props.t('Parent is Using App')}
                                                </UncontrolledTooltip>

                                            </li>}
                                            {(user.fatherNumber || user.fatherEMail) && !user.fatherUsingApp && <li className="list-inline-item  border-1"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => { toggleModal(user, 'father'); }}
                                                id={"notinstalled" + user.fatherId}>
                                                <i className="font-size-14 text-warning bx bx-navigation"></i>
                                                <UncontrolledTooltip placement="top" target={"notinstalled" + user.fatherId}>
                                                    {props.t('Parent is not Using App, click to send invite!')}
                                                </UncontrolledTooltip>

                                            </li>}
                                            {user.fatherName && <li className="list-inline-item text-muted">
                                                {user.fatherName} : {user.fatherNumber}
                                            </li>}

                                        </ul>

                                        {/* {user.motherName && <p className="text-muted mb-0">{user.motherName} : {user.motherNumber}</p>}
                                        {user.fatherName && <p className="text-muted mb-0">{user.fatherName} : { user.fatherNumber }</p>} */}

                                    </td>}
                                    {/* {!props.showClassView &&  */}
                                    {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <td>
                                        <div>
                                            {
                                                user.className.map((cr, key) =>
                                                    <div key={key}>
                                                        <Badge className={`badge font-size-11 m-1 badge-soft-${cr.color}`} color={`soft-${cr.color}`} key={"_skill_" + key}>{cr.name}</Badge>
                                                        {(key + 1) % 3 === 0 && <span />}

                                                    </div>
                                                )
                                            }

                                            {
                                                user.corporateId && getCorporatePartner(user.corporateId) &&
                                                <Badge
                                                    className="badge badge-soft-success font-size-11 m-1" color='success'>
                                                    {getCorporatePartner(user.corporateId)}
                                                </Badge>
                                            }

                                        </div>
                                    </td>}

                                    {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && props.showClassView && <td>
                                        <div>
                                            {
                                                user.programs.map((pr, key) =>
                                                    <div key={pr.id}>
                                                        <Badge className={`badge font-size-11 m-1 badge-soft-primary`} color='soft-primary' key={"_skill_" + pr.id}>{pr.attributes.name}</Badge>
                                                        {(key + 1) % 3 === 0 && <span />}

                                                    </div>
                                                )
                                            }



                                        </div>
                                    </td>}
                                    {/* } */}
                                    {/* {props.showClassView && <td>DC Option</td>} */}
                                    <td>
                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                            {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && props.showIDCard && <li className="list-inline-item px-2">
                                                <Link
                                                    to={"/studentIdcard/" + user.schoolId + "/" + user.id} target="_blank" id={"idCard" + user.id} >
                                                    <i className="bx bx-id-card" ></i>
                                                    <UncontrolledTooltip placement="top" target={"idCard" + user.id}>
                                                        ID Card
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>}
                                            {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && props.showCertificate && <li className="list-inline-item px-2">
                                                <Link
                                                    to={"/bonafide-certificate/" + user.schoolId + "/" + user.id} target="_blank" id={"certificate" + user.id} >
                                                    <i className="bx bx-user-check" ></i>
                                                    <UncontrolledTooltip placement="top" target={"certificate" + user.id}>
                                                        Bonafide Certificate
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>}
                                            <li className="list-inline-item px-2">
                                                <Link to={`/studentProfile/${user.schoolId}/${user.id}`} id={"profile" + user.id}>
                                                    <i className="bx bx-user-circle"></i>
                                                    <UncontrolledTooltip placement="top" target={"profile" + user.id}>
                                                        Profile
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>)
    }


    return (
        <React.Fragment>
            <Col lg="12">
                {!props.showClassView && props.offShowLoader &&
                    <Card>
                        <CardBody>

                            {props.kids.length > 0 && listViewRender()}
                            {props.kids.length === 0 && <div className="text-center" >


                                <Row className="justify-content-center">
                                    <Col sm={4}>
                                        <div className="maintenance-img">
                                            <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                                        </div>
                                    </Col>


                                </Row>
                                <h3 className="mt-5">{'Start adding students for your School'}</h3>
                                <p>{'Add a student just in few steps'}.</p>

                                <button onClick={() => { props.handleAddStudentButtonClick() }} className="btn btn-primary">Add Student</button>

                            </div>}
                        </CardBody>
                    </Card>}
                {props.showClassView && listViewRender()}
            </Col>
            <Modal isOpen={modal} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}>Send Invite</ModalHeader>
                <ModalBody>
                    <div>
                        <h5>APP link</h5>
                        <Card className="border-1 p-2">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <a href={WhiteLable.getAppOneLink()} target="_blank" rel="noopener noreferrer">
                                    {WhiteLable.getAppOneLink()}
                                </a>
                                <div
                                    id="applink"
                                    onClick={() => {
                                        copyAppLink(WhiteLable.getAppOneLink());
                                    }}
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', }}
                                >
                                    <i className="bx bxs-copy text-primary"></i>
                                    <p style={{ marginLeft: '5px', marginBottom: '0' }}>{props.t("copy")}</p>
                                    <UncontrolledTooltip placement="top" target="applink">
                                        {props.t("copy to clipboard")}
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                        </Card>
                    </div>
                    {selectedUser && selectedParentType &&
                        <p className='m-0'>
                            Are you sure you want to send an invite <br /><br />
                            <Button color="primary" className="m-1" onClick={() => {
                                props.sendInvite(selectedUser.id, selectedUser[`${selectedParentType}Id`]);
                            }}>Invite via SMS/Email</Button>
                            <Button color="success" onClick={() => {
                                sendWhatsAppInvite();
                            }}><i className="bx bxl-whatsapp"></i>Invite via WhatsApp</Button>
                        </p>
                    }
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
export default withNamespaces()(ListKidView);