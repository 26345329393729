import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Parse from "parse";
import { setSelecetdSchool, toggleLeftmenu, getSchoolsForTools } from "../../../store/actions";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label } from "reactstrap";
import Moment from "moment";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx/xlsx.mjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MoneyTransactions = (props) => {
    const [selectedDate, setSelctedDate] = useState(new Date());
    const [allTransactions, setAllTransactions] = useState([]);
    const [schoolNames, setSchoolNames] = useState({});
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        props.getSchoolsForTools();
    }, []);

    const getDate = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };

    const getTime = (date) => {
        return Moment(date).format("hh:mm a");
    };

    const getMoneyTransactions = (date) => {
        const MoneyTransaction = Parse.Object.extend("MoneyTransactions");
        const query = new Parse.Query(MoneyTransaction);

        const today = date || selectedDate;
        const startTime = new Date(today);
        startTime.setHours(0, 0, 0, 0);
        const endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999);

        query.greaterThanOrEqualTo("createdAt", startTime);
        query.lessThanOrEqualTo("createdAt", endOfDay);
        query.descending("createdAt");
        query.containedIn("transactionType", ["AirPay", "CCAvenue", "GooglePay", "PayU", "RazorPay", "OnePay"]);
        query.limit(1000);

        setIsGenerating(true);
        query.find().then(results => {
            setAllTransactions(results);
            setIsGenerating(false);
        }).catch(error => {
            // console.error("Error fetching transactions:", error);
            setIsGenerating(false);
        });
    };

    useEffect(() => {
        let allSchools = {};
        if (props.toolsSchools && props.toolsSchools.length > 0) {
            for (const school of props.toolsSchools) {
                if (school.attributes && school.attributes.Name) {
                    allSchools[school.id] = `${school.attributes.Name} ${school.id}`;
                }
            }
        }
        setSchoolNames(allSchools);
    }, [props.toolsSchools]);

    const downloadReport = () => {
        const finalData = [];
        finalData.push(["Sl.no", "Order ID", "Date&Time", "Invoice Id", "Amount", "Gateway", "Status", "School Name"]);

        allTransactions.forEach((transaction, index) => {
            const schoolName = schoolNames[transaction.attributes.schoolId] || "-";
            finalData.push([
                index + 1,
                transaction.id,
                new Date(transaction.createdAt).toLocaleString(),
                transaction.attributes.invoiceId,
                transaction.attributes.amount,
                transaction.attributes.transactionType,
                transaction.attributes.deleted ? "Cancelled" : "Success",
                schoolName,
            ]);
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(finalData);
        ws['!cols'] = [{ wpx: 30 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 100 }, { wpx: 120 }, { wpx: 350 }];
        XLSX.utils.book_append_sheet(wb, ws, "Transactions Report");
        const fileName = `Transactions_Report_${new Date().toLocaleDateString()}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    const handleSaveChanges = (transactionId) => {
        

        let fun = "function_updatePGTransaction"
        if(selectedTransaction.attributes.transactionType=="OnePay"){
            fun = "function_updateOnePayPGTransaction"
        }else{
            if (!transactionId) {
                alert("Please enter a transaction ID.");
                return;
            }
        }

        Parse.Cloud.run(fun, {
            orderId: selectedTransaction.id,
            transactionId: transactionId,
        }).then((res) => {
            if (res === 1) {
                alert("Success");

                const MoneyTransaction = Parse.Object.extend("MoneyTransactions");
                const query = new Parse.Query(MoneyTransaction);
                query.get(selectedTransaction.id).then(results => {
                    let updatedTransactions = [...allTransactions];
                    const index = allTransactions.indexOf(selectedTransaction);
                    updatedTransactions[index] = results;
                    setAllTransactions(updatedTransactions);

                    setSelectedTransaction(null);
                    setUpdateModalOpen(false);
                }).catch(error => {
                    // console.error("Error fetching transactions:", error);
                });
            } else {
                alert(res);
            }
        }).catch((err) => {
            // console.error("Error updating transaction:", err);
        });
    };

    const renderTransactionTable = () => {
        if (isGenerating) {
            return (
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Link to="#" className="text-success">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                Generating Transaction details...
                            </Link>
                        </div>
                    </Col>
                </Row>
            );
        }

        return (
            <div className="table-responsive">
                <table className="table table-nowrap m-0">
                    <thead className="thead-light">
                        <tr>
                            <th>Sl.No</th>
                            <th>Order ID</th>
                            <th>Date&Time</th>
                            <th>Invoice Id</th>
                            <th>Amount</th>
                            <th>Gateway</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTransactions.length === 0 ? (
                            <tr>
                                <td colSpan="8" className="text-center text-primary">No transactions found for this date.</td>
                            </tr>
                        ) : (
                            allTransactions.map((transaction, index) => {
                                const schoolName = schoolNames[transaction.attributes.schoolId] || "-";
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{transaction.id}</td>
                                        <td>{getDate(transaction.createdAt)} <br /> {getTime(transaction.createdAt)}</td>
                                        <td>{transaction.attributes.invoiceId} <br /> {schoolNames[transaction.attributes.schoolId] || "-"}</td>
                                        <td>{transaction.attributes.amount}</td>
                                        <td>{transaction.attributes.transactionType}</td>
                                        <td>{transaction.attributes.deleted ? <p className="text-danger">Cancelled</p> : <p className="text-success">Verified</p>}</td>
                                        <td>
                                            {transaction.attributes.deleted && (
                                                <i className="bx bx-dots-horizontal" onClick={() => {
                                                    setSelectedTransaction(transaction);
                                                    setUpdateModalOpen(true);
                                                }} style={{ cursor: 'pointer' }}></i>
                                            )}
                                        </td>

                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            <div className="row m-0">
                <div className="col-lg-4">
                    <div className="d-flex flex-column align-items-start">
                        <Label className="mb-1">Select Date</Label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={date => setSelctedDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control mb-2"
                        />
                    </div>
                </div>

                <div className="col-lg-4 d-flex align-items-center justify-content-start mt-2">
                    <Button color="primary" onClick={() => getMoneyTransactions(selectedDate)}>
                        Generate
                    </Button>
                </div>

                <div className="col-lg-4 d-flex align-items-center justify-content-end">
                    <Button color="primary" onClick={downloadReport}>
                        <i className='bx bx-download font-size-16 align-middle me-2'></i>Download Report
                    </Button>
                </div>
            </div>

            {renderTransactionTable()}

            <Modal isOpen={updateModalOpen} size="md" toggle={() => setUpdateModalOpen(!updateModalOpen)}>
                <ModalHeader toggle={() => setUpdateModalOpen(!updateModalOpen)}>Update Transaction</ModalHeader>
                <ModalBody>
                    {selectedTransaction && (
                        <div>
                            <p><strong>Order ID:</strong> {selectedTransaction.id}</p>
                            <p><strong>Amount:</strong> {selectedTransaction.attributes.amount}</p>
                            <p><strong>Status:</strong> {selectedTransaction.attributes.deleted ? "Cancelled" : "Success"}</p>
                            {selectedTransaction.attributes.transactionType !="OnePay" &&  <form>
                                <div className="form-group">
                                    <label>Transaction Id</label>
                                    <input
                                        id="transactionId"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter"
                                    />
                                </div>
                            </form>}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => {
                        
                        if(selectedTransaction.attributes.transactionType =="OnePay"){
                            handleSaveChanges("NA")
                        }else{
                            let input = document.getElementById("transactionId");
                            if (input.value) {
                                handleSaveChanges(input.value);
                            } else {
                                alert("Please enter a transaction ID.");
                            }
                        }
                        
                    }}>Check For Update</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool, toolsSchools, schoolAnalyticsForSchoolObject } = state.School;
    return { selectedSchool, toolsSchools, schoolAnalyticsForSchoolObject };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, toggleLeftmenu, getSchoolsForTools,
})(MoneyTransactions);
