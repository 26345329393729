import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip, ModalFooter, Form, FormGroup, Input, Container, Row, TabContent, TabPane, Card, CardText, Nav, NavItem, NavLink, CardBody, Label, CardHeader, Button, Col, Table } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { matchPath } from "react-router";
import moment from 'moment';
import toastr from 'toastr';
import Parse from 'parse';
import Moment from "moment";
import Iframe from "react-iframe";
import DocViewer, { DocViewerRenderers, BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from 'react-datepicker';
import ExpenseDetailModal from './ExpenseDetailModal';
import Select from 'react-select';

import { setSelecetdSchool, editExpense, getExpensesForDate, getExpenseCategoryForSchoolId, getExpenseSupplierForSchoolId, getAllKids, getTeachersForSchool} from '../../store/actions';
import { TEACHER_EXPENSE_INDEX, TEACHER_PAYMENT_ADMINISTRATOR } from '../../Constents';

const ExpenseList = (props) => {

    const CASH = "Cash"
    const CHEQUE = "Cheque"
    const ONLINE = "Online"
    const SUPPLIER = "Supplier"
    const STAFF = "Staff"
    const STUDENT = "Student"

    const [expenseList, setExpenseList] = useState([]);
    const [showFileViewModal, setShowFileViewModal] = useState(false);
    const [clickedFile, setClickedFile] = useState(undefined);
    const [showImagesViewModal, setShowImagesViewModal] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [isLoadingForImagesPopup, setIsLoadingForImagesPopup] = useState(false);
    const [deleteParseObject, setDeleteParseObject] = useState(undefined);
    const [verifyParseObject, setVerifyParseObject] = useState(undefined)
    const [isFinanceAdmin, setIsFinanceAdmin] = useState(false);

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [showFilter, setShowFilter] = useState(false);

    const [expenseDetailsPopup, setExpenseDetailsPopup] = useState(false);
    const [expenseDetails, setExpenseDetails] = useState(undefined);

    const [selectedExpenseCategory, setSelectedExpenseCategory] = useState({ value: "all", label: "All" });

    const [selectedSuppliersType, setSelectedSuppliersType] = useState({ value: "all", label: "All" });
    const [selectedSuppliers, setSelectedSuppliers] = useState({ value: "all", label: "All" });

    const [categoryList, setCategoryList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);
    const [kidList, setKidList] = useState([]);
    
    const supplierTypeList = [{ value: "all", label: "All" }, {value: SUPPLIER, label: SUPPLIER }, { value: STAFF, label: STAFF }, { value: STUDENT, label: STUDENT }]
    
    useEffect(() => {
        if (props.expenseList && props.expenseList.length > 0) {
            setExpenseList(props.expenseList)
        }
    }, [props.expenseList]);

    useEffect(() => {
      if (props.schoolId) {
        getExpenseCategoryForSchoolId(props.schoolId)
        getExpenseSupplierForSchoolId(props.schoolId)
        getAllKids(props.schoolId)
        getTeachersForSchool(props.schoolId)
      }
    }, [props.schoolId])

    useEffect(() => {
        let isFinance = false

        if (props.teacher) {

            if (props.teacher.attributes.featurePermissions) {
                let features = props.teacher.attributes.featurePermissions

                if (features.length > TEACHER_PAYMENT_ADMINISTRATOR) {
                    if (features[TEACHER_PAYMENT_ADMINISTRATOR] && features[TEACHER_PAYMENT_ADMINISTRATOR] === 1) {
                        isFinance = true
                    }
                }
            }
        }

        if (props.isSuperAdmin) {
            isFinance = true
        }

        setIsFinanceAdmin(isFinance)

    }, [props.teacher])

    const getAlbumsForAlbumId = (albumId) => {
        setIsLoadingForImagesPopup(true)

        var query = new Parse.Query('Album');
        query.equalTo('albumId', albumId);
        query.limit(1000);
        query.find().then((results) => {
            setIsLoadingForImagesPopup(false)

            if (results && results.length > 0) {
                let tempImages = []
                for (const i in results) {
                    tempImages.push(results[i].attributes.image ? results[i].attributes.image._url : "")
                }
                setSelectedImages(tempImages)
            }
        })
    }

    function convertDateToString(date) {
        return moment(date).format('DD MMM YYYY')
    }

    function getFileName(file) {
        if (file) {
            let res = file._name.split("_")
            return res[res.length - 1];
        }
    }

    function getURL() {
        if (clickedFile) {
            return clickedFile._url;
        }
    }
    const getFR = (url) => {
        const docs = [
            { uri: url },
        ];
        return docs;
    }

    const handleOnClickDelete = (parseObject) => {
        if (parseObject) {
            parseObject.set("isDeleted", true)
            props.editExpense(parseObject);
            toastr.success("expense removed successfully")
            setDeleteParseObject(undefined)
        }
    }

    const handleOnClickVerify = (parseObject) => {
        if (parseObject) {
            parseObject.set("status", 1)
            parseObject.set("verifiedBy", Parse.User.current().attributes.teacherId)
            props.editExpense(parseObject);
            toastr.success("expense verified successfully")
            setVerifyParseObject(undefined)
        }
    }


    const handleApply = () => {
        let tempFromDate = new Date(
            fromDate.getFullYear(),
            fromDate.getMonth(),
            fromDate.getDate(),
            0,
            0,
            0,
            0,
        );

        let tempToDate = new Date(
            toDate.getFullYear(),
            toDate.getMonth(),
            toDate.getDate(),
            23,
            59,
            59,
            999
        );
        let selectedCategory = selectedExpenseCategory.value;
        let selectedSuppliersTypeList = selectedSuppliersType.value
        let supplier = selectedSuppliers.value;
        
        props.dateFilter(tempFromDate, tempToDate, selectedCategory, selectedSuppliersTypeList, supplier)
    }
    
    const handleOnClickExpenseDetails = (parseObject) => {
        setExpenseDetails(parseObject)
        setExpenseDetailsPopup(true)
    }

    useEffect(() => {
        if (props.expenseCategories && props.expenseCategories.length > 0) {
            const options = props.expenseCategories.map(category => ({
                value: category.id,
                label: category.attributes.name
            }));
            const allOption = { value: "all", label: "All" };
            setCategoryList([allOption, ...options]);
        }
    }, [props.expenseCategories]);

    useEffect(() => {
        if (props.expenseSuppliers && props.expenseSuppliers.length > 0) {
            const options = props.expenseSuppliers.map(supplier => ({
                value: supplier.id,
                label: supplier.attributes.name
            }));
            const allOption = { value: "all", label: "All" };
            setSupplierList([allOption, ...options]);
        }
    }, [props.expenseSuppliers]);

    useEffect(() => {
        if (props.teachers && props.teachers.length > 0) {
            const options = props.teachers.map(teacher => ({
                value: teacher.id,
                label: teacher.attributes.Name
            }));
            const allOption = { value: "all", label: "All" };
            setTeacherList([allOption, ...options]);
        }
    }, [props.teachers]);

    useEffect(() => {
        if (props.kids && props.kids.length > 0) {
            const options = props.kids.map(kid => ({
                value: kid.id,
                label: kid.attributes.Name
            }));
            const allOption = { value: "all", label: "All" };
            setKidList([allOption, ...options]);
        }
    }, [props.kids]);

    return (

        <React.Fragment>
            {deleteParseObject && (

                <SweetAlert
                    title={props.t('Are you sure?')}
                    warning
                    showCancel
                    confirmBtnText="Remove"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        handleOnClickDelete(deleteParseObject)

                    }}
                    onCancel={() => {
                        setDeleteParseObject(undefined);
                    }}>
                    {props.t("You won't be able to revert this!")}

                </SweetAlert>

            )}
            {verifyParseObject && (

                <SweetAlert
                    title={props.t('Are you sure?')}
                    warning
                    showCancel
                    confirmBtnText="Verify"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        handleOnClickVerify(verifyParseObject)
                    }}
                    onCancel={() => {
                        setVerifyParseObject(undefined);
                    }}>
                    {props.t("Are you sure to verify this expense ?")}

                </SweetAlert>

            )}
            <Card>
                <CardBody>
                    {showFilter && <Col xs={10}>
                        <Row className="bg-light ">
                            <Col xs='4'>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">{props.t("From (Date)")} :</Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {
                                            setFromDate(date);
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        maxDate={toDate}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs='4'>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">{props.t("To (Date)")} :</Label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(date) => setToDate(date)}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        minDate={fromDate}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs='4'>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">{props.t("What kind of Expenses")} :</Label>
                                    <Select
                                        value={selectedExpenseCategory}
                                        onChange={option => setSelectedExpenseCategory(option)}
                                        options={categoryList}
                                        placeholder={props.t("Select Category")}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="12" xl="3" lg="3">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">{props.t("Who Did You Pay")} :</Label>
                                    <Select
                                        value={selectedSuppliersType}
                                        onChange={option => {
                                            if (option.value != selectedSuppliersType.value) {
                                                setSelectedSuppliers({ value: "all", label: "All" })
                                            }
                                            setSelectedSuppliersType(option)
                                        }}
                                        options={supplierTypeList}
                                        placeholder={props.t("Select Supplier")}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="12" xl="3" lg="3">
                                {selectedSuppliersType?.value != "all" && <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">{props.t("Select") + " " + selectedSuppliersType?.value} :</Label>
                                    <Select
                                        value={selectedSuppliers}
                                        onChange={option => {
                                            setSelectedSuppliers(option)
                                        }}
                                        options={
                                            selectedSuppliersType?.value === "all" ?
                                                [...supplierList, ...teacherList, ...kidList] :
                                                selectedSuppliersType?.value === SUPPLIER ? supplierList :
                                                    selectedSuppliersType?.value === STAFF ? teacherList :
                                                        selectedSuppliersType?.value === STUDENT ? kidList : []
                                        }
                                        placeholder={props.t("Select")}
                                    />
                                </FormGroup>}
                            </Col>
                            <Col xs='2'>
                                <div className="mt-2 text-center">
                                    <button
                                        onClick={() => {
                                            handleApply()
                                        }}
                                        className="btn btn-primary mt-4 mr-2"
                                    >
                                        Apply
                                    </button>
                                </div>
                            </Col>
                            <Col xs='2'>
                                <div className="mt-2 text-center">
                                    <button
                                        onClick={() => {
                                            props.clearExpence();
                                            setShowFilter(!showFilter);
                                            setToDate(undefined);
                                            setFromDate(undefined);
                                            setSelectedExpenseCategory(undefined);
                                            setSupplierType(null);
                                            setSelectedSuppliers(null);
                                        }}
                                        className="btn btn-primary mt-4 mr-2"
                                    >
                                        Clear
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>}
                    {!showFilter &&
                        <Col className='mt-0 mb-0 py-0 d-flex justify-content-end' >
                            <label
                                onClick={() => {
                                    setShowFilter(true)
                                }}
                                className="d-flex justify-content-end text-success"
                            ><i className='bx bx-filter-alt m-1'></i>
                                <u>Filter</u>
                            </label>
                        </Col>}
                    <div className="table-responsive py-2">
                        <Table className="table-centered">
                            <thead className="thead-light">
                                <tr>
                                    <th style={{ width: "1%" }}>
                                        #
                                    </th>
                                    <th style={{ width: "10%" }}>
                                        {props.t("Method")}
                                    </th>
                                    <th style={{ textAlign: "center", width: "5%" }}>
                                        {props.t("Amount")}
                                    </th>
                                    <th style={{ width: "15%" }}>
                                        {props.t("Payee/Supplier")}
                                    </th>
                                    <th style={{ width: "15%" }}>
                                        {props.t("Category")}
                                    </th>
                                    <th style={{ width: "5%" }}>
                                        {props.t("Status")}
                                    </th>
                                    <th style={{ width: "20%" }}>
                                        {props.t("View Details")}
                                    </th>
                                    <th style={{ width: "10%" }}>
                                        {props.t("Actions")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    expenseList.map((item, key) => (
                                        <tr key={key}>
                                            <td>
                                                {key + 1}
                                            </td>
                                            <td>
                                                <div><Label className='mb-0'>{`${item.paymentType}`}</Label></div>
                                                <div><small className='mb-0'>{item.expenseNumber ? `#${item.expenseNumber}` : ""}</small></div>
                                                <small>{`${convertDateToString(item.paymentDate ?? item.createdAt)}`}</small>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <div><Label style={{ fontFamily: "Arial, sans-serif", fontSize: "17px", fontWeight: "bold" }}>{`${props.currency}${item.amount}`}</Label></div>
                                                {item.referenceNumber && <div><small>#{`${item.referenceNumber}`}</small></div>}
                                                {item.tax ? <div>{`tax - ${item.tax}%`}</div> : ""}
                                                {item.tds ? <div>{`tds - ${item.tds}%`}</div> : ""}
                                                {/* {item.tds ? <div>{`tds - ${item.tds}%`}</div> : <div>{`tds - `}</div>} */}
                                            </td>
                                            <td>
                                                <div><Label>{item.supplierName ?? "-"}</Label></div>
                                                {item.albumId ? <Label className="text-primary justify-content-center text-truncate" style={{ cursor: "pointer" }} onClick={() => {
                                                    setShowImagesViewModal(true)
                                                    getAlbumsForAlbumId(item.albumId)
                                                }}>
                                                    <u>{"View Attachment"}</u>
                                                </Label> :
                                                    (item.attachmentDoc ? <Label className="text-primary justify-content-center text-truncate" style={{ cursor: "pointer" }} onClick={() => {
                                                        setShowFileViewModal(true)
                                                        setClickedFile(item.attachmentDoc)
                                                    }}>
                                                        <u>{"View Attachment"}</u>
                                                    </Label> : "")
                                                }
                                            </td>
                                            <td>
                                                <div><Label>{item.categoryName ?? "-"}</Label></div>
                                            </td>


                                            <td>
                                                <Label className={item.isVerified ? "text-success" : "text-danger"} style={{ fontSize: "11px" }}>{item.isVerified ? "Verified" : "Pending for verification"}</Label>
                                            </td>
                                            <td>
                                                <Link onClick={() => { handleOnClickExpenseDetails(item) }}>
                                                    <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">View Details</Button>
                                                </Link>
                                            </td>
                                            {(props.writePermission || isFinanceAdmin) && <td style={{ textAlign: "center" }}>
                                                {/* <div className='pl-1  ml-1 float-left'>
                                                    <Link onClick={() => { handleOnClickExpenseDetails(item) }}>
                                                        <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">View Details</Button>
                                                    </Link>
                                                </div> */}
                                                {true && <div className="d-flex font-size-18 pl-1  m-1">


                                                    {props.writePermission && <div className="m-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setDeleteParseObject(item.expenseParseObject)
                                                        }}
                                                        id={"expense-delete" + key}>
                                                        <i className="bx bx-trash text-danger"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-delete" + key}>
                                                            {props.t('Delete')}
                                                        </UncontrolledTooltip>

                                                    </div>}

                                                    {props.writePermission && <div
                                                        className="pl-1 float-right m-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            props.onClickDuplicate(item.expenseParseObject)
                                                        }}
                                                        id={"duplicate" + key}
                                                    >
                                                        {<i className="bx bx-copy"></i>}


                                                        <UncontrolledTooltip
                                                            placement="top"
                                                            target={"duplicate" + key}
                                                        >
                                                            {props.t("Duplicate")}
                                                        </UncontrolledTooltip>
                                                    </div>}

                                                    {props.writePermission && !item.isVerified && <div className="pl-1  m-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            props.onClickEdit(item.expenseParseObject)
                                                        }}
                                                        id={"expense-edit" + key}>
                                                        <i className="bx bx-edit-alt text-dark"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-edit" + key}>
                                                            {props.t('Edit')}
                                                        </UncontrolledTooltip>

                                                    </div>}
                                                    {(isFinanceAdmin) && !item.isVerified && <div className=" pl-1  m-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setVerifyParseObject(item.expenseParseObject)
                                                        }}
                                                        id={"expense-verify" + key}>
                                                        <i className="font-size-18 bx bx-check-circle text-dark"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-verify" + key}>
                                                            {props.t('Verify')}
                                                        </UncontrolledTooltip>

                                                    </div>}
                                                </div>}
                                            </td>}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>

            <Modal
                isOpen={showImagesViewModal}
                toggle={() => setShowImagesViewModal(!showImagesViewModal)}
                scrollable={true}
                size='lg'
            >

                {selectedImages && !isLoadingForImagesPopup && <ModalBody>

                    {selectedImages.map((image, key) => (
                        <img key={key} src={image} width="100%px" className='mt-3' height="" alt="image" />
                    ))}

                </ModalBody>
                }
                {
                    isLoadingForImagesPopup && <ModalBody>
                        <Row>
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success" onClick={(e) => {
                                        e.preventDefault()
                                    }}>
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                        Loading...{" "}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                }



                <ModalFooter>


                    <Button color="secondary" onClick={() => { setShowImagesViewModal(false) }}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                size="xl"
                isOpen={showFileViewModal}
                toggle={() => { setShowFileViewModal(!showFileViewModal) }}
                scrollable={true}
            >
                <div className="modal-header">



                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        {clickedFile && getFileName(clickedFile)}
                    </h5>


                    {
                        clickedFile &&
                        <a

                            href={getURL()}
                            download={getFileName(clickedFile)}

                            target="_blank"
                            type="button"
                            className="btn btn-sm btn-primary waves-effect waves-light ml-4">
                            <i className="bx bx-download font-size-14 align-middle"></i> {props.t('Download ')}
                        </a>
                    }
                    <button
                        onClick={() => {
                            setShowFileViewModal(false)
                            setClickedFile(undefined);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody >
                    {

                        <div>
                            {getURL() && getURL().endsWith(".pdf") ? <Iframe
                                url={getURL() + "#toolbar=0"}
                                height="600"
                                width="100%"
                                display="initial"
                                allowFullScreen={true}
                                position="relative"
                                frameBorder={0}

                            >
                            </Iframe> :

                                <DocViewer
                                    pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer,]}
                                    style={{ height: "500px" }}
                                    documents={getFR(getURL())}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: false,
                                            retainURLParams: false
                                        }
                                    }}

                                />
                            }
                        </div>

                    }

                    <Card style={{ backgroundColor: "#fff", marginTop: "-7mm", width: "100%", height: " 7mm" }} />




                </ModalBody>
            </Modal>
            <ExpenseDetailModal
                expenseDetailsPopup={expenseDetailsPopup}
                closeExpenseDetailsPopup={() => { setExpenseDetailsPopup(!expenseDetailsPopup) }}
                expenseDetails={expenseDetails}
                onClickAlbum={(albumId) => {
                    setShowImagesViewModal(true)
                    getAlbumsForAlbumId(albumId)
                }}
                onClickFile={(attachmentDoc) => {
                    setShowFileViewModal(true)
                    setClickedFile(attachmentDoc)
                }}
            />
        </React.Fragment>

    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { expenseCategories } = state.ExpenseCategory;
    const { expenseSuppliers } = state.ExpenseSupplier;
    const { kids } = state.Kid;
    const { teachers } = state.Teacher;
    
    return { selectedSchool,  expenseCategories, expenseSuppliers, kids, teachers};
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, editExpense, getExpensesForDate, getExpenseCategoryForSchoolId,getExpenseSupplierForSchoolId, getAllKids, getTeachersForSchool
})(ExpenseList)));
