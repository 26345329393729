import Parse from "parse";
import { printLog } from "./loghelper";


export const getExpensesForSchoolId = (id, isInstitute, updatedDate, isLoacal) => {
  printLog("HL: expenseManagement_helper : getExpensesForSchoolId")

  try {
    return new Promise((resolve, reject) => {
      var Expense = Parse.Object.extend("Expense");
      var query = new Parse.Query(Expense);
      // if (isInstitute) {
      //   query.equalTo("instituteId", id);
      // } else {
      //   query.equalTo("schoolId", id);
      // }
      query.equalTo("schoolId", id);
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      query.descending("createdAt");

      query.limit(1000)
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};




export const editExpense = (object, isInstitute) => {
  printLog("HL: expenseManagement_helper : editExpense")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

export const getExpensesForDate = (schoolId, fromDate, toDate, selectedCategory, selectedSupplierType,supplier ) => {
  console.log("Console", schoolId, fromDate, toDate, selectedCategory, selectedSupplierType, supplier);
  try {
    return new Promise((resolve, reject) => {
      var Expense = Parse.Object.extend("Expense");
      var query = new Parse.Query(Expense);
      query.equalTo("schoolId", schoolId);
      query.greaterThanOrEqualTo("createdAt", fromDate);
      query.lessThanOrEqualTo("createdAt", toDate);
      if (selectedCategory !== "all") {
        query.equalTo("categoryId", selectedCategory);
      }
      if (selectedSupplierType !== "all") {
        if (selectedSupplierType === "Supplier") {
          query.equalTo("supplierType", 1);
        } else if (selectedSupplierType === "Staff") {
          query.equalTo("supplierType", 2);
        } else if (selectedSupplierType === "Student") {
          query.equalTo("supplierType", 3);
        }
      }
      if (supplier) {
        query.equalTo("supplierId", supplier);
      }
      query.ascending("createdAt");
      query.notEqualTo("isDeleted", true);
      query.limit(1000)
      query.find().then((results) => {
        resolve(results);
      });

    })
  } catch (error) {
    console.log(error)
  }
}

export const getExpenseWalletForSchoolId = (schoolId) => {
  try {
    return new Promise((resolve, reject) => {
      var ExpenseWallet = Parse.Object.extend("ExpenseWallet");
      var query = new Parse.Query(ExpenseWallet);
      query.equalTo("schoolId", schoolId);

      query.first().then((results) => {
        resolve(results);
      });

    })
  } catch (error) {
    console.log(error)
  }
}

export const getExpenseWalletLogForSchoolId = (schoolId) => {
  try {
    return new Promise((resolve, reject) => {
      var ExpenseWalletLog = Parse.Object.extend("ExpenseWalletLog");
      var query = new Parse.Query(ExpenseWalletLog);
      query.equalTo("schoolId", schoolId);
      query.descending("createdAt");

      query.find().then((results) => {
        resolve(results);
      });

    })
  } catch (error) {
    console.log(error)
  }
}

export const addExpenseWallet = (schoolId, amount, teacherId) => {
  try {
    return new Promise((resolve, reject) => {

      Parse.Cloud.run("function_addExpenceBalance", {
        schoolId: schoolId,
        amount: amount,
        createdBy: teacherId,

      }).then((result) => {
        resolve(result)
        // console.log("function_addExpenceBalance", result)
      }, (error) => {
        console.log("function_addExpenceBalance", error)
      });
    })
  } catch (error) {
    console.log(error)
  }
}

export const getExpenseWalletLogForDate = (schoolId, fromDate, toDate) => {
  console.log("Results", fromDate, toDate)
  try {
    return new Promise((resolve, reject) => {
      var ExpenseWalletLog = Parse.Object.extend("ExpenseWalletLog");
      var query = new Parse.Query(ExpenseWalletLog);
      query.equalTo("schoolId", schoolId);
      query.greaterThanOrEqualTo("createdAt", fromDate);
      query.lessThanOrEqualTo("createdAt", toDate);
      query.descending("createdAt");
      query.notEqualTo("isDeleted", true);
      query.limit(1000)
      query.find().then((results) => {
        resolve(results);
      });

    })
  } catch (error) {
    console.log(error)
  }
}