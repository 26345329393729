import { Progress, Modal, ModalFooter, InputGroup, ModalBody, ModalHeader, Input, Form, FormGroup, Button, Container, Row, Col, TabContent, TabPane, Card, CardText, Nav, NavItem, NavLink, CardBody, Label, InputGroupAddon } from "reactstrap";
import React, { useEffect, useState } from 'react';
import Select from "react-select";
import Moment from "moment";
import CreatableSelect from "react-select/creatable";
import Parse from "parse"
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { withNamespaces } from 'react-i18next';



const AddExpenseModal = (props) => {

    //Constants
    const CASH = "Cash"
    const CHEQUE = "Cheque"
    const ONLINE = "Online"
    const SUPPLIER = "Supplier"
    const STAFF = "Staff"
    const STUDENT = "Student"
    const imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];


    //UseStates
    const [editAlbumObject, setEditAlbumObject] = useState(undefined);
    const [loadingAlbumCompleted, setLoadingAlbumCompleted] = useState(true);
    const [loadingExpense, setLoadingExpense] = useState(false);
    const [loadingTagMapCompleted, setLoadingTagMapCompleted] = useState(true);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showSupplierModal, setShowSupplierModal] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1");




    //Variables
    const [paymentNumber, setPaymentNumber] = useState('');
    const [note, setNote] = useState('');
    const [expenseDate, setExpenseDate] = useState(new Date());
    const [attachment, setAttachment] = useState();
    const [attachmentIsImage, setAttachmentIsImage] = useState(false);
    const [attachmentUploadProgress, setAttachmentUploadProgress] = useState(0);
    const [amount, setAmount] = useState('');


    //Error Variables
    const [amountError, setAmountError] = useState(false);
    const [selectedPaymentTypeError, setSelectedPaymentTypeError] = useState(false);
    const [paymentNumberError, setPaymentNumberError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [selectedCategoryError, setSelectedCategoryError] = useState(false);
    const [selectedSupplierTypeError, setSelectedSupplierTypeError] = useState(false);
    const [selectedSupplierError, setSelectedSupplierError] = useState(false);
    const [selectedTagsError, setSelectedTagsError] = useState(false);

    //Selected Values from Selections
    const [selectedPaymentType, setSelectedPaymentType] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedTDS, setSelectedTDS] = useState()
    const [selectedTax, setSelectedTax] = useState();
    const [selectedYear, setSelectedYear] = useState({ value: "2024-2025", label: "2024-2025" });
    const [selectedSupplierType, setSelectedSupplierType] = useState();
    const [selectedSupplier, setSelectedSupplier] = useState()
    const [selectedTags, setSelectedTags] = useState([]);
    const [newTags, setNewTags] = useState([]);

    //Constants Lists for Selections
    const [TDSList, setTDSList] = useState([
        { value: .1, label: `.1% (${props.currency} 0)`, tdsAmount: 0 },
        { value: 1, label: `1% (${props.currency} 0)`, tdsAmount: 0 },
        { value: 2, label: `2% (${props.currency} 0)`, tdsAmount: 0 },
        { value: 5, label: `5% (${props.currency} 0)`, tdsAmount: 0 },
        { value: 10, label: `10% (${props.currency} 0)`, tdsAmount: 0 },
        { value: 20, label: `20% (${props.currency} 0)`, tdsAmount: 0 },
        { value: 20, label: `30% (${props.currency} 0)`, tdsAmount: 0 }
    ]);
    const supplierTypeList = [{ value: SUPPLIER, label: SUPPLIER }, { value: STAFF, label: STAFF }, { value: STUDENT, label: STUDENT }]
    const paymentTypes = [{ value: CASH, label: CASH }, { value: CHEQUE, label: CHEQUE }, { value: ONLINE, label: ONLINE }]
    const [yearList, setYearList] = useState([{ value: "2025-2026", label: "2025-2026" },{ value: "2024-2025", label: "2024-2025" },{ value: "2023-2024", label: "2023-2024" }, ]);


    useEffect(() => {
        if (props.editParseObject) {
            fillValues(props.editParseObject)
        }
    }, [props.editParseObject])

    useEffect(() => {
        if (props.duplicateParseObject) {
            fillValues(props.duplicateParseObject)
        }
    }, [props.duplicateParseObject])

    useEffect(() => {
        if (loadingTagMapCompleted && loadingAlbumCompleted) {
            setLoadingExpense(false)
            setLoadingAlbumCompleted(false)
            setLoadingTagMapCompleted(false)
        }
    }, [loadingTagMapCompleted, loadingAlbumCompleted])


    useEffect(() => {
        if (props.preSelectedCategory) {
            let categoryObj = props.categoryList.find((category) => {
                return category.id === props.preSelectedCategory.id
            })
            setSelectedCategory(categoryObj)
            if (categoryObj && (categoryObj.id == "9uC1COjBu3" ||  categoryObj.name.toLowerCase().includes("salary"))) {
                setSelectedSupplierType({ value: STAFF, label: STAFF })
            }
        }
    }, [props.preSelectedCategory])

    useEffect(() => {
        if (props.preSelectedSupplier) {
            let supplierObj = props.supplierList.find((supplier) => {
                return supplier.id === props.preSelectedSupplier.id
            })
            setSelectedSupplier(supplierObj)
            setSelectedSupplierType({ value: SUPPLIER, label: SUPPLIER })
        }
    }, [props.preSelectedSupplier])



    const resetValues = (shouldResetTags = true) => {
        setAmount("")
        setPaymentNumber("")
        setNote("")
        setExpenseDate(new Date())
        setSelectedPaymentTypeError(false)
        setPaymentNumberError(false)
        setSelectedCategoryError(false)
        setSelectedPaymentType(undefined)
        setSelectedCategory(undefined)
        setSelectedTDS(undefined)
        setSelectedTax(undefined)
        setSelectedYear({ value: "2024-2025", label: "2024-2025"})
        setSelectedSupplierType(undefined)
        setSelectedSupplier(undefined)
        if (shouldResetTags) {
            setSelectedTags([])
        }
        setEditAlbumObject(undefined)
        setAttachment(undefined)
        setAttachmentUploadProgress(0)
        setLoadingAlbumCompleted(true)
        setLoadingExpense(false)
        setLoadingTagMapCompleted(true)

    }


    const getPayModeText = () => {

        if (selectedPaymentType && selectedPaymentType.label === CHEQUE) {
            return " / Cheque Number"
        } else if (selectedPaymentType && selectedPaymentType.label === ONLINE) {
            return " / Online Transaction Number"
        }
        return ""
    }

    function formatDate(d) {
        if (d == null || d === "" || typeof d === "string" || d instanceof String) {
            return "";
        } else {
            return Moment(d).format("YYYY-MM-DD");
        }
    }

    function toggleCustom(tab) {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    }


    const handleOnChangeTags = (e) => {
        if (e && e.length > 0) {
            if (e[e.length - 1].__isNew__) {
                let tempNewTags = [...newTags]
                tempNewTags.push(e[e.length - 1].value)
                setNewTags(tempNewTags)

            }
            setSelectedTags(e)
        } else {
            setSelectedTags([])
            setNewTags([])
        }
    }
    const getFileExtension = (result) => {
        if (typeof result === "object") {
            let name = result._name.split(/_(.+)/)[1];
            let fileExtension = name.split(".");
            return fileExtension[1].toLowerCase()
        } else {
            return result
        }
    }

    function getFileTypeImage(fileExtension) {
        if (fileExtension === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtension === "ppt" || fileExtension === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "doc" || fileExtension === "docx") {
            return "bx bxs-file-doc";
        }

        return "bx bxs-file-blank";
    }

    const inputStyle = {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    };

    function handleFileUploadChange(e) {
        setAttachment(undefined)
        setAttachmentUploadProgress(1)
        let fileExtension = e.target.files[0].name.split(".").pop();

        let tempFile = {
            actualFileName: e.target.files[0].name,
            fileExtension: fileExtension.toLowerCase(),
            fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
            file: e.target.files[0],
        };
        if (imgExtensions.includes(tempFile.fileExtension.toLowerCase())) {
            setAttachmentIsImage(true)
        } else {
            setAttachmentIsImage(false)
        }
        var name = tempFile.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "") + "." + tempFile.fileExtension;
        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            setAttachmentUploadProgress(value * 100)
                        }
                    },
                })
                .then(
                    (result) => {
                        setAttachment(result)
                        setAttachmentUploadProgress(false)
                    },
                    (error) => {
                    }
                );
        });
        setAttachment(tempFile.actualFileName)
    }

    const getUploadStatus = () => {

        if (attachment && attachment._url) {
            return true
        }
        return false
    }

    const showUploadProgress = () => {
        if (attachment && attachmentUploadProgress >= 1 && attachmentUploadProgress < 100) {
            return true
        } else if (attachment && !attachment._url) {
            return true
        } else {
            return false
        }
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }
    const getActualFileName = (result) => {
        if (typeof result === "object") {
            let name = result._name.split(/_(.+)/)[1];
            return name

        } else {
            return result
        }
    }



    const fillValues = (parseObject) => {
        if (parseObject) {
            let obj = parseObject.attributes

            setAmount(obj.amount)

            if (obj.paymentType === 1) {
                setSelectedPaymentType({ value: CASH, label: CASH })
            } else if (obj.paymentType === 2) {
                setSelectedPaymentType({ value: CHEQUE, label: CHEQUE })
                setPaymentNumber(obj.referenceNumber ?? "")
            } else if (obj.paymentType === 3) {
                setSelectedPaymentType({ value: ONLINE, label: ONLINE })
                setPaymentNumber(obj.referenceNumber ?? "")
            }

            setExpenseDate(obj.paymentDate)

            if (obj.categoryId) {
                let categoryObj = props.categoryList.filter((category) => {
                    return category.id === obj.categoryId
                })
                if (categoryObj.length > 0) {
                    setSelectedCategory(categoryObj[0])
                }
            }
            if (obj.supplierId) {
                if (obj.supplierType === 1) {
                    let supplierObj = props.supplierList.filter((supplier) => {
                        return supplier.id === obj.supplierId
                    })
                    if (supplierObj.length > 0) {
                        setSelectedSupplierType({ value: SUPPLIER, label: SUPPLIER })
                        setSelectedSupplier(supplierObj[0])
                    }
                } else if (obj.supplierType === 2) {

                    let supplierObj = props.staffList.filter((staff) => {
                        return staff.id === obj.supplierId
                    })
                    if (supplierObj.length > 0) {
                        setSelectedSupplierType({ value: STAFF, label: STAFF })
                        setSelectedSupplier(supplierObj[0])
                    }
                } else if (obj.supplierType === 3) {
                    let supplierObj = props.kidList.filter((kid) => {
                        return kid.id === obj.supplierId
                    })
                    if (supplierObj.length > 0) {
                        setSelectedSupplierType({ value: STUDENT, label: STUDENT })
                        setSelectedSupplier(supplierObj[0])
                    }
                }

                if (obj.taxSlabId) {
                    let taxObjs = props.taxList.filter((tax) => {
                        return tax.id === obj.taxSlabId
                    })
                    if (taxObjs.length > 0) {
                        setSelectedTax(taxObjs[0])
                    }
                }

                if (obj.tds && (obj.amount != undefined || obj.amount !== "")) {
                    // let tempTenPercent = obj.amount / 10
                    // let tempTwentyPercent = obj.amount / 5
                    // let tempFivePercent = obj.amount / 20
                    // let tempTDSList = [{ value: 5, label: `5% (${props.currency} ${tempFivePercent})` , tdsAmount: tempFivePercent},{ value: 10, label: `10% (${props.currency} ${tempTenPercent})`, tdsAmount: tempTenPercent }, { value: 20, label: `20% ( ${tempTwentyPercent})`, tdsAmount: tempTwentyPercent }]

                    let tempTDSList = []
                    for (const temptds of TDSList) {
                        let tempPercent = obj.amount * (temptds.value / 100)
                        tempTDSList.push(
                            {
                                value: temptds.value,
                                label: `${temptds.value}% (${props.currency} ${tempPercent})`,
                                tdsAmount: tempPercent
                            }
                        )
                    }
                    setTDSList(tempTDSList)
                    for (const t of tempTDSList) {
                        if (obj.tds === t.value) {
                            setSelectedTDS(t)
                        }
                    }
                    // if (obj.tds === 5) {
                    //     setSelectedTDS(tempTDSList[0])
                    // }else if (obj.tds === 10) {
                    //     setSelectedTDS(tempTDSList[1])
                    // } else if (obj.tds === 20) {
                    //     setSelectedTDS(tempTDSList[2])
                    // }
                } else if (obj.tds) {
                    let tdsObjs = TDSList.filter((tds) => {
                        return tds.value === obj.tds
                    })
                    if (tdsObjs.length > 0) {
                        setSelectedTDS(tdsObjs[0])
                    }
                }

                if (obj.year && obj.year.length > 0) {
                    let yearObjs = yearList.filter((year) => {
                        return year.value === obj.year
                    })
                    if (yearObjs.length > 0) {
                        setSelectedYear(yearObjs[0])
                    } else {
                        let tempYearList = [...yearList]
                        let tempYearObj = {}
                        tempYearObj.label = obj.year
                        tempYearObj.value = obj.year
                        tempYearList.push(tempYearObj)
                        setYearList(tempYearList)
                        setSelectedYear(tempYearObj)
                    }
                }

                //document.getElementById('note').value = obj.notes;
                setNote(obj.notes ?? "")

                getExpenseTagMap(parseObject.id)
                if (obj.albumId && obj.albumId.length > 0) {
                    getAlbumsForAlbumId(obj.albumId)

                } else if (obj.attachmentFile) {
                    setAttachment(obj.attachmentFile)
                    setAttachmentUploadProgress(false)
                    setLoadingAlbumCompleted(true)
                } else {
                    setLoadingAlbumCompleted(true)
                }
            }
        }
    }

    const getExpenseTagMap = (expenseId) => {
        setLoadingTagMapCompleted(false)
        setLoadingExpense(true)
        var query = new Parse.Query('ExpenseTagMap');
        query.equalTo('expenseId', expenseId);
        query.limit(1000);
        query.find().then((results) => {

            if (results && results.length > 0) {

                let tempSelectedTagsList = []
                for (const i in results) {
                    let tempSelectedObjs = props.tagList.filter((tag) => {
                        return tag.id === results[i].attributes.tagId
                    })
                    if (tempSelectedObjs.length > 0) {
                        let tempSelObject = tempSelectedObjs[0]
                        if (!props.duplicateParseObject) {
                            tempSelObject.expenseTagMapParseObject = results[i]
                        }
                        tempSelectedTagsList.push(tempSelObject)
                    }
                }
                setSelectedTags(tempSelectedTagsList)
            }
            setLoadingTagMapCompleted(true)
        },
            (error) => {
                setLoadingTagMapCompleted(true)

            }
        )
    }



    const getAlbumsForAlbumId = (albumId) => {
        setLoadingAlbumCompleted(false)
        setLoadingExpense(true)

        var query = new Parse.Query('Album');
        query.equalTo('albumId', albumId);
        query.limit(1000);
        query.find().then((results) => {
            if (results && results.length > 0) {
                if (results[0].attributes.image) {
                    setAttachment(results[0].attributes.image)
                    setAttachmentUploadProgress(false)
                }
                setEditAlbumObject(results[0])
            }
            setLoadingAlbumCompleted(true)
        },
            (error) => {
                setLoadingAlbumCompleted(true)
            }
        )
    }

    const handleOnClickAddNewExpense = () => {

        if (checkForMandatory()) {
            return
        }
        props.onCreatingExpense(true)
        let Expense = Parse.Object.extend("Expense");
        let expense = new Expense();

        let editParseObject = props.editParseObject
        if (editParseObject) {
            expense = editParseObject
        }
        expense.set("amount", parseInt(amount, 10))
        expense.set("paymentType", selectedPaymentType.label === CASH ? 1 : selectedPaymentType.label === CHEQUE ? 2 : 3)
        expense.set("referenceNumber", paymentNumber)
        expense.set("paymentDate", expenseDate)
        expense.set("categoryId", selectedCategory.parseObject.id)
        expense.set("supplierType", selectedSupplierType.label === SUPPLIER ? 1 : selectedSupplierType.label === STAFF ? 2 : 3)
        expense.set("supplierId", selectedSupplier.parseObject.id)
        expense.set("createdBy", Parse.User.current().attributes.teacherId)
        if (selectedYear) {
            expense.set("year", selectedYear.label)
        }
        if (selectedTax && selectedTax.value && selectedTax.value.length > 0) {
            expense.set("tax", selectedTax.tax)
            expense.set("taxSlabId", selectedTax.value)
        }
        if (selectedTDS) {
            expense.set("tds", selectedTDS.value)
            expense.set("tdsAmount", selectedTDS.tdsAmount)
        }
        expense.set("notes", note);
        expense.set("instituteId", props.instituteId ?? "");
        expense.set("schoolId", props.schoolId ?? "");

        if (!attachment || !attachment._url) {
            if (editParseObject && editParseObject.attributes.attachmentFile) {
                expense.unset("attachmentFile")
            }
            if (editParseObject && editParseObject.attributes.albumId) {
                expense.unset("albumId")
            }
        }
        if (attachment && attachment._url && !attachmentIsImage) {
            expense.set("attachmentFile", attachment)
            if (editParseObject && editParseObject.attributes.albumId) {
                expense.unset("albumId")
            }
        }
        if (attachment && attachment._url && attachmentIsImage) {
            if (editParseObject && editParseObject.attributes.attachmentFile) {
                expense.unset("attachmentFile")
            }


            const Album = Parse.Object.extend('Album');
            var album
            if (editAlbumObject && !props.duplicateParseObject) {
                album = editAlbumObject

            } else {
                album = new Album();
                album.set("albumId", uuidv4())
            }
            album.set("image", attachment)
            album.save().then(
                (result) => {
                    setEditAlbumObject(undefined)
                    expense.set("albumId", result.attributes.albumId)

                    resetValues(false)
                    let saveMsg = "Expense added successfully"
                    if (editParseObject) {
                        saveMsg = "Expense saved successfully"
                    }
                    props.handleOnClickAddNewExpense(expense, selectedTags, newTags)
                    toastr.success(saveMsg)

                },
                (error) => {
                    toastr.error("Problem with adding attachment, please try again...")
                }
            );

        } else {
            resetValues(false);
            let saveMsg = "Expense added successfully"
            if (editParseObject) {
                saveMsg = "Expense saved successfully"
            }
            props.handleOnClickAddNewExpense(expense, selectedTags, newTags)
            toastr.success(saveMsg)

        }

    }

    const checkForMandatory = () => {
        let breakFlag = false
        if (!amount || parseInt(amount, 10) <= 0) {
            setAmountError(true)
            breakFlag = true
        }
        if (!selectedPaymentType) {
            setSelectedPaymentTypeError(true)
            breakFlag = true
        } else if (selectedPaymentType.label !== CASH && !paymentNumber) {
            setPaymentNumberError(true)
            breakFlag = true
        }
        if (!expenseDate) {
            setDateError(true)
            breakFlag = true
        }
        if (!selectedCategory) {
            setSelectedCategoryError(true)
            breakFlag = true
        }
        if (!selectedSupplierType) {
            setSelectedSupplierTypeError(true)
            breakFlag = true
        } else if (!selectedSupplier) {
            setSelectedSupplierError(true)
            breakFlag = true
        }

        if ((attachmentUploadProgress >= 1 &&
            attachmentUploadProgress < 100) || (attachmentUploadProgress === 100 && attachment && !attachment._url)
        ) {
            breakFlag = true
            toastr.warning(
                "Please wait for file upload to complete, then try again",
                { timeOut: 1000 }
            );
        }
        return breakFlag
    }

















    return (
        <Modal scrollable={true} isOpen={props.showExpenseModal} size="xl">
            <ModalHeader toggle={() => {
                props.toggleExpenseModal()
            }}>
                <div className="modal-title mt-0">{!props.editParseObject ? props.t("Add Expenses") : props.t("Edit Expenses")}</div>
            </ModalHeader>

            {!loadingExpense && <ModalBody className="modal-body">

                <Row>
                    <Col lg={12}>
                        <Form onSubmit={() => { }}>
                            <Row className="">
                                <Col md="6">
                                    <FormGroup className="position-relative">
                                        <Label>{"Amount"}</Label>
                                        <Label
                                            className="text-danger ml-1"
                                        >
                                            *
                                        </Label>

                                        {amountError && (
                                            <Label className="label-xsmall text-danger ml-2">
                                                Please enter amount
                                            </Label>
                                        )}

                                        <Input
                                            name={"expenseAmount"}
                                            id={"expenseAmount"}
                                            type={"number"}
                                            className="form-control noscroll"
                                            placeholder={"Enter Amount"}
                                            value={amount}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setAmountError(false)
                                                    // let tempFivePercent = e.target.value / 20
                                                    // let tempTenPercent = e.target.value / 10
                                                    // let tempTwentyPercent = e.target.value / 5
                                                    // let tempTDSList = [{ value: 5, label: `5% (${props.currency} ${tempFivePercent})`, tdsAmount: tempFivePercent }, { value: 10, label: `10% (${props.currency} ${tempTenPercent})`, tdsAmount: tempTenPercent }, { value: 20, label: `20% (${props.currency} ${tempTwentyPercent})`, tdsAmount: tempTwentyPercent }]
                                                    // setTDSList(tempTDSList)
                                                    // if (selectedTDS && selectedTDS.value === 5) {
                                                    //     setSelectedTDS(tempTDSList[0])
                                                    // } else if (selectedTDS && selectedTDS.value === 10) {
                                                    //     setSelectedTDS(tempTDSList[1])
                                                    // } else if (selectedTDS && selectedTDS.value === 20) {
                                                    //     setSelectedTDS(tempTDSList[2])
                                                    // }

                                                    let tempTDSList = []
                                                    for (const temptds of TDSList) {
                                                        let tempPercent = e.target.value * (temptds.value / 100)
                                                        tempTDSList.push(
                                                            {
                                                                value: temptds.value,
                                                                label: `${temptds.value}% (${props.currency} ${tempPercent})`,
                                                                tdsAmount: tempPercent
                                                            }
                                                        )
                                                    }
                                                    setTDSList(tempTDSList)
                                                    for (const t of tempTDSList) {
                                                        if (selectedTDS && selectedTDS.value === t.value) {
                                                            setSelectedTDS(t)
                                                        }
                                                    }
                                                } else {
                                                    setAmountError(true)
                                                    setSelectedTDS()
                                                    setTDSList([{ value: 10, label: `10% (${props.currency} 0)`, tdsAmount: 0 }, { value: 20, label: `20% (${props.currency} 0)`, tdsAmount: 0 }])
                                                }
                                                setAmount(e.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="position-relative">
                                        <Label>{"Pay Mode" + getPayModeText()}</Label>
                                        <Label className="text-danger ml-1">*</Label>
                                        {selectedPaymentTypeError && <Label className="label-xsmall text-danger ml-2">Please select pay mode</Label>}
                                        {paymentNumberError && selectedPaymentType && selectedPaymentType.label !== CASH && <Label className="label-xsmall text-danger ml-2">{selectedPaymentType.label === CHEQUE ? "Please enter cheque number" : "Please enter online transaction number"}</Label>}
                                        <InputGroup>
                                            <div style={{ width: "30%" }}
                                            >
                                                <Select
                                                    value={selectedPaymentType}
                                                    onChange={(v) => {
                                                        if (v && selectedPaymentTypeError) {
                                                            setSelectedPaymentTypeError(false)
                                                        }
                                                        setSelectedPaymentType(v)
                                                    }}
                                                    options={paymentTypes}
                                                    className="selectPaymentType"
                                                    classNamePrefix="selectPayType"
                                                    isSearchable={false}
                                                />
                                            </div>

                                            {selectedPaymentType && selectedPaymentType.label !== CASH &&
                                                <div className='' style={{ width: "70%", paddingLeft: "20px" }}><Input
                                                    name={"expensePaymentNumber"}
                                                    id={"expensePaymentNumber"}
                                                    type={"text"}
                                                    className="form-control"
                                                    placeholder={selectedPaymentType.label === CHEQUE ? "Enter Cheque Number" : "Enter Online Transaction Number"}
                                                    value={paymentNumber}
                                                    onChange={(e) => {
                                                        if (e.target.value && paymentNumberError) {
                                                            setPaymentNumberError(false)
                                                        }
                                                        setPaymentNumber(e.target.value)
                                                    }}
                                                /></div>
                                            }
                                        </InputGroup>

                                    </FormGroup>


                                </Col>

                                <Col md="6">
                                    {<FormGroup className="position-relative">
                                        <Label>{"Date"}</Label>
                                        <Label className="text-danger ml-1">*</Label>
                                        {dateError && <Label className="label-xsmall text-danger ml-2">Please select date</Label>}
                                        <Input
                                            name={"expenseDate"}
                                            id={"expenseDate"}
                                            type={"date"}
                                            className="form-control"
                                            placeholder={"Date"}
                                            value={formatDate(expenseDate)}
                                            onChange={(e) => {
                                                if (e.target.value && dateError) {
                                                    setDateError(false)
                                                }
                                                let newDate = new Date(e.target.value)
                                                setExpenseDate(newDate)
                                            }}
                                        />
                                    </FormGroup>}
                                </Col>
                                <Col md="5">
                                    <FormGroup className="position-relative">
                                        <Label>{"What kind of expenses"}</Label>
                                        <Label className="text-danger ml-1">*</Label>
                                        {selectedCategoryError && <Label className="label-xsmall text-danger ml-2">Please select kind of expense</Label>}
                                        <Select
                                            value={selectedCategory}
                                            onChange={(v) => {
                                                if (v && selectedCategoryError) {
                                                    setSelectedCategoryError(false)
                                                }
                                                setSelectedCategory(v)
                                            }}
                                            options={props.categoryList}
                                            className="expenseCategorySelection"
                                            classNamePrefix="expCat"
                                        />





                                    </FormGroup>
                                </Col>
                                <Col md="1">
                                    <FormGroup className="position-relative">
                                        <div><Label>{""}</Label></div>
                                        <Button color="primary" className="mt-2" onClick={() => {
                                            props.showCategoryModalToggle()
                                        }}>
                                            Add
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="position-relative">
                                        <Label>{"Who Did You Pay"}</Label>
                                        <Label className="text-danger ml-1">*</Label>
                                        {selectedSupplierTypeError && <Label className="label-xsmall text-danger ml-2">Please select payee type</Label>}
                                        <Select
                                            value={selectedSupplierType}
                                            onChange={(v) => {
                                                if (v && selectedSupplierTypeError) {
                                                    setSelectedSupplierTypeError(false)
                                                }
                                                if (selectedSupplier) {
                                                    setSelectedSupplier()
                                                }

                                                setSelectedSupplierType(v)
                                            }}
                                            options={supplierTypeList}
                                            className="expenseSupplierTypeSelection"
                                            classNamePrefix="expSupplierType"
                                            isSearchable={false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={(selectedSupplierType && selectedSupplierType.label === SUPPLIER) ? "5" : "6"}>
                                    {selectedSupplierType && selectedSupplierType.label === SUPPLIER && <FormGroup className="position-relative">
                                        <Label>{"Select Supplier"}</Label>
                                        <Label className="text-danger ml-1">*</Label>
                                        {selectedSupplierError && <Label className="label-xsmall text-danger ml-2">Please select supplier</Label>}
                                        <Select
                                            value={selectedSupplier}
                                            onChange={(v) => {
                                                if (v && selectedSupplierError) {
                                                    setSelectedSupplierError(false)
                                                }
                                                setSelectedSupplier(v)
                                            }}
                                            options={props.supplierList}
                                            className="expenseSupplierSelection"
                                            classNamePrefix="expSupplier"
                                        />
                                    </FormGroup>}
                                    {selectedSupplierType && selectedSupplierType.label === STUDENT && <FormGroup className="position-relative">
                                        <Label>{"Select Student"}</Label>
                                        <Label className="text-danger ml-1">*</Label>
                                        {selectedSupplierError && <Label className="label-xsmall text-danger ml-2">Please select student</Label>}
                                        <Select
                                            value={selectedSupplier}
                                            onChange={(v) => {
                                                if (v && selectedSupplierError) {
                                                    setSelectedSupplierError(false)
                                                }
                                                setSelectedSupplier(v)
                                            }}
                                            options={props.kidList}
                                            className="expenseStudentSelection"
                                            classNamePrefix="expStudent"
                                        />

                                    </FormGroup>}
                                    {selectedSupplierType && selectedSupplierType.label === STAFF && <FormGroup className="position-relative">
                                        <Label>{"Select Staff"}</Label>
                                        <Label className="text-danger ml-1">*</Label>
                                        {selectedSupplierError && <Label className="label-xsmall text-danger ml-2">Please select staff</Label>}
                                        <Select
                                            value={selectedSupplier}
                                            onChange={(v) => {
                                                if (v && selectedSupplierError) {
                                                    setSelectedSupplierError(false)
                                                }
                                                setSelectedSupplier(v)
                                            }}
                                            options={props.staffList}
                                            className="expenseStaffSelection"
                                            classNamePrefix="expStaff"
                                        />
                                    </FormGroup>}
                                </Col>
                                {selectedSupplierType && selectedSupplierType.label === SUPPLIER && <Col md="1">
                                    <FormGroup className="position-relative">
                                        <div><Label>{""}</Label></div>
                                        <Button color="primary" className="mt-2" onClick={() => {

                                            props.showSupplierModalToggle()

                                        }}>
                                            Add
                                        </Button>
                                    </FormGroup>
                                </Col>}

                                <Col md="4">

                                    <FormGroup className="position-relative">
                                        <Label>{"What is Tax"}</Label>
                                        <Select
                                            value={selectedTax}
                                            onChange={(v) => { setSelectedTax(v) }}
                                            options={props.taxList}
                                            className="expenseTaxSelection"
                                            classNamePrefix="expTax"
                                            isSearchable={false}

                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="position-relative" style={{ fontFamily: "Arial, sans-serif", }}>
                                        <Label>{"Tax Deduction at source (TDS)"}</Label>
                                        <Select
                                            value={selectedTDS}
                                            onChange={(v) => { setSelectedTDS(v) }}
                                            options={TDSList}
                                            className="expenseTDS"
                                            classNamePrefix="expTDS"
                                            isSearchable={false}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="4">

                                    <FormGroup className="position-relative">
                                        <Label>{"Pick Year"}</Label>
                                        <Select
                                            value={selectedYear}
                                            onChange={(v) => { setSelectedYear(v) }}
                                            options={yearList}
                                            className="expenseYearSelection"
                                            classNamePrefix="selectYear"
                                            isSearchable={false}
                                        />
                                    </FormGroup>


                                </Col>
                                <Col md="6">
                                    <FormGroup className="position-relative">
                                        <Label>{"Pick/Add tags"}</Label>

                                        {false && <Label className="text-danger ml-1">*</Label>}
                                        {selectedTagsError && <Label className="label-xsmall text-danger ml-2">Please select atleast 1 tag</Label>}
                                        <CreatableSelect
                                            isClearable
                                            isMulti
                                            onChange={(e) => {
                                                handleOnChangeTags(e)
                                            }}
                                            value={selectedTags}
                                            options={props.tagList}
                                            className={"selectExpenseTags"}
                                            classNamePrefix={"selectExpTag"}
                                            name={"expenseTags"}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="position-relative">
                                        <Label>{"Note"}</Label>
                                        <textarea
                                            name={"note"}
                                            id={"note"}
                                            rows="3"
                                            className="form-control"
                                            placeholder={"Enter Note"}
                                            value={note}
                                            onChange={(e) => { setNote(e.target.value) }}
                                        ></textarea>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="position-relative">
                                        <div>{attachment == undefined && (
                                            <div className="d-flex">
                                                <Label
                                                    htmlFor={"input-file-expense"}
                                                    color="primary"
                                                    className="btn btn-primary"
                                                >
                                                    Add Attachment
                                                    <input
                                                        id={"input-file-expense"}
                                                        style={inputStyle}
                                                        type="file"
                                                        onChange={(e) => {
                                                            handleFileUploadChange(e);
                                                        }}
                                                        accept=".doc,.docx,.pdf,.xlsx,image/*"
                                                    />
                                                </Label>
                                            </div>
                                        )}

                                            {getUploadStatus() && (
                                                <Col xl="12" md="12" sm="12" xs="12">
                                                    <div className="border p-3">
                                                        <Row>
                                                            <div
                                                                className="avatar ml-3"
                                                                style={{ width: "5rem", height: "5rem" }}
                                                            >
                                                                <span
                                                                    className="avatar-title bg-white ml-0 text-primary h1"
                                                                    style={{ fontSize: "60px" }}
                                                                >
                                                                    <i
                                                                        className={getFileTypeImage(
                                                                            getFileExtension(attachment)
                                                                        )}
                                                                    ></i>
                                                                </span>
                                                            </div>

                                                            <div className="float-right ml-4">
                                                                <div
                                                                    className="text-muted font-weight-bold text-truncate"
                                                                    style={{ width: "200px" }}
                                                                >
                                                                    {getActualFileName(attachment)}
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <div>
                                                                        <Label
                                                                            htmlFor={"input-file-expense-Update"}
                                                                            className="btn btn-warning mb-0"
                                                                        >
                                                                            Update
                                                                            <input
                                                                                id={"input-file-expense-Update"}
                                                                                style={inputStyle}
                                                                                type="file"
                                                                                onChange={(e) => {
                                                                                    handleFileUploadChange(e);
                                                                                }}
                                                                                accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                                                                            />
                                                                        </Label>
                                                                    </div>

                                                                    <Button
                                                                        className="btn btn-danger float-right ml-2"
                                                                        color="danger"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        onClick={() => {
                                                                            setAttachment(undefined)
                                                                            setAttachmentUploadProgress(0)
                                                                        }}
                                                                    >
                                                                        Clear
                                                                    </Button>

                                                                    <Button
                                                                        className="btn btn-primary float-right ml-2"
                                                                        color="primary"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        onClick={() => {
                                                                            if (attachment && attachment._url) {
                                                                                window.open(attachment._url, "_blank")
                                                                            } else {
                                                                                toastr.info("Please wait and try again...", { timeOut: 1000 })
                                                                            }
                                                                        }}
                                                                    >
                                                                        Open
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            )}

                                            {
                                                showUploadProgress() && (
                                                    <div class="d-flex">
                                                        <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                            <div className="border p-3">
                                                                <Row>
                                                                    <div
                                                                        className="avatar ml-3"
                                                                        style={{ width: "5rem", height: "5rem" }}
                                                                    >
                                                                        <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                            <i
                                                                                className={getFileTypeImage(attachment)}
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className="float-right ml-4"
                                                                        style={{ width: "200px" }}
                                                                    >
                                                                        <div className="text-muted font-weight-bold">
                                                                            {attachment}
                                                                        </div>
                                                                        <div className=" mt-3">
                                                                            <Progress
                                                                                color="primary"
                                                                                value={attachmentUploadProgress}
                                                                            ></Progress>
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                )}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>}

            {
                loadingExpense && <ModalBody>
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Label className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading details please wait...{" "}
                                </Label>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            }
            <ModalFooter>
                {<Button
                    color='primary'
                    onClick={() => {

                        handleOnClickAddNewExpense()
                    }}
                    disabled={loadingExpense || props.creatingExpense}
                >
                    {props.creatingExpense && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}
                    {props.editParseObject ? "Save" : "Add Expenses"}
                </Button>}
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(AddExpenseModal);