import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Badge,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledTooltip
} from "reactstrap";
import DatePicker from "react-datepicker";
import {
  getAllTransactionForFilter,
  getAllTransactionCountForFilter,
  getInvoiceForIds,
  getTeachersForSchool,
  verifyTriansactionById,
  deleteTriansactionById,
  recheckTransactionState,
} from "../../store/actions";
import * as Constants from "../../Constents";
import Moment from "moment";

const AllTransaction = (props) => {
  let date = new Date();
  const [activeFilterBy, activeFilterByOps] = useState("All");
  const [fromDate, fromDateOps] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30)
  );
  const [toDate, todateOps] = useState(new Date());
  const [allTransactionList, allTransactionListOps] = useState([]);
  const [invoiceDec, invoiceDecOps] = useState([]);
  const [kidDec, kidDecOps] = useState([]);
  const [teacherDec, teacherDecOps] = useState([]);
  const [transactions, transactionsOps] = useState([]);
  const [showLoader, showLoaderOps] = useState(false);

  const [verified, verifiedOps] = useState(0);
  const [verifiedAmount, verifiedAmountOps] = useState(0);
  const [success, successOps] = useState(0);
  const [successAmount, successAmountOps] = useState(0);
  const [outstanding, outstandingOps] = useState(0);
  const [outstandingAmount, outstandingAmountOps] = useState(0);
  const [toggleId, toggleIdOps] = useState();
  const [activeYear, activeYearOps] = useState("All");
  const [nameOrder, nameOrderOps] = useState("ascending");
  const [dateOrder, dateOrderOps] = useState("ascending");
  const [invoiceOrder, invoiceOrderOps] = useState("ascending");
  const [amountOrder, amountOrderOps] = useState("ascending");
  const [typeOrder, typeOrderOps] = useState("ascending");
  const [statusOrder, statusOrderOps] = useState("ascending");

  const [sortVerifiedStyle, sortVerifiedStyleOps] = useState({});
  const [sortSuccessStyle, sortSuccessStyleOps] = useState({});
  const [sortOutstandingStyle, sortOutstandingStyleOps] = useState({});
  const [sortByStatusValue, sortByStatusValueOps] = useState("");
  const [beforeSortOrgCopy, beforeSortOrgCopyOps] = useState([]);

  const [entryCount, entryCountOps] = useState("100");
  const [currentPage, currentPageOps] = useState("1");
  const [paginationList, paginationListOps] = useState([]);
  const [transactionCount, transactionCountOps] = useState(0);

  const entries = ["100", "500", "1000"];

  const [admissionKidDec, admissionKidDecOps] = useState([]);
  const [deletedKidDec, deletedKidDecOps] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [showReferenceInput, setShowReferenceInput] = useState(false);
  const [chequeNumber, setChequeNumber] = useState("");
  const [showChequeInput, setShowChequeInput] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  let filterByListValue = ["cash", "cheque", "online", "gateway", "cancelled", "Reference Number","Cheque Number"];
  const schoolId = props.schoolId;
  useEffect(() => {
    if (props.activeTab === "2") {
      loadAllTransactionForFilter();
      props.getTeachersForSchool(schoolId);
    }
  }, [schoolId, props.activeTab]);
  useEffect(() => {
    showLoaderOps(props.showAllTransactionLoader);
  }, [props.showAllTransactionLoader]);
  useEffect(() => {
    if (props.activeTab === "2") {
      let tempList = [];
      let invoiceIds = [];
      let success = 0;
      let verified = 0;
      let outstanding = 0;
      let successAmount = 0;
      let verifiedAmount = 0;
      let outstandingAmount = 0;
      for (const val of props.moneyTransactions) {
        let temp = {};
        temp.id = val.id;
        temp.Obj = val;
        temp.amount = val.attributes.amount;
        temp.createdAt = val.attributes.createdAt;
        temp.paidOrgDate = val.attributes.paidDate || val.attributes.createdAt;
        temp.paidDate = Moment(val.attributes.paidDate || val.attributes.createdAt).format("DD-MMM-YYYY");
        temp.type = val.attributes.transactionType;
        if (temp.type === "AirPay"|| temp.type === "RazorPay" || temp.type === "OnePay") {
          temp.canVerify = false;
        } else {
          temp.canVerify = true;
        }
        temp.invoiceId = val.attributes.invoiceId;
        invoiceIds.push(val.attributes.invoiceId);
        temp.recivedBy = val.attributes.receivedBy;
        temp.verifiedBy = val.attributes.verifiedBy;
        temp.kidName = "--";

        if (val.attributes.transactionType === "AirPay"|| val.attributes.transactionType === "RazorPay"||val.attributes.transactionType === "OnePay") {
          temp.status = "Verified";
          temp.statusCss = "text-success";
        } else {
          temp.status = val.attributes.status
            ? val.attributes.status === 1
              ? "Outstanding"
              : val.attributes.status === 2
              ? "Verified"
              : "Success"
            : "Success";
          temp.statusValue = val.attributes.status;
          temp.statusCss = val.attributes.status
            ? val.attributes.status === 1
              ? "text-danger"
              : val.attributes.status === 2
              ? "text-success"
              : "text-warning"
            : "text-warning";
        }
        if (val.attributes.transactionType === "Cheque") {
          temp.transactionId = val.attributes.chequeNo;
        } else  {
          temp.transactionId = val.attributes.transactionId;
        }
        temp.remark = val.attributes.transactionStatus;
        temp.deletedCss2 = {  width: "15%", whiteSpace: "pre" };
        if (val.attributes.deleted) {
          temp.deletedBy = val.attributes.deletedBy;
          temp.deleted = true;
          temp.deletedCss = { textDecoration: "line-through" };
          temp.deletedCss2 = { textDecoration: "line-through", width: "15%", whiteSpace: "pre" };
          temp.status = "Cancelled";
          temp.statusCss = "text-danger";
          if (temp.type === "OnePay") {
            temp.showCheckStatus = true;
          }
        }
        
        tempList.push(temp);
        if (
          (val.attributes.transactionType === "AirPay"|| val.attributes.transactionType === "RazorPay"|| val.attributes.transactionType === "OnePay") &&
          !val.attributes.deleted
        ) {
          verified += 1;
          verifiedAmount += val.attributes.amount;
        } else if (!val.attributes.deleted) {
          val.attributes.status
            ? val.attributes.status === 1
              ? (outstanding += 1)
              : val.attributes.status === 2
              ? (verified += 1)
              : (success += 1)
            : (success += 1);
          val.attributes.status
            ? val.attributes.status === 1
              ? (outstandingAmount += val.attributes.amount)
              : val.attributes.status === 2
              ? (verifiedAmount += val.attributes.amount)
              : (successAmount += val.attributes.amount)
            : (successAmount += val.attributes.amount);
        }
      }
      verifiedOps(verified);
      verifiedAmountOps(verifiedAmount);
      successOps(success);
      successAmountOps(successAmount);
      outstandingOps(outstanding);
      outstandingAmountOps(outstandingAmount);
      allTransactionListOps(tempList);
      if (tempList.length === 0) {
        setNoResultsFound(true);
    } else {
        setNoResultsFound(false);
    }
      // addKidNameToTriansaction();
      nameOrderOps("ascending");
      dateOrderOps("ascending");
      invoiceOrderOps("ascending");
      amountOrderOps("ascending");
      typeOrderOps("ascending");
      statusOrderOps("ascending");

      sortByStatusValueOps("");
      sortVerifiedStyleOps({ borderBottom: "0px solid #fff" });
      sortSuccessStyleOps({ borderBottom: "0px solid #fff" });
      sortOutstandingStyleOps({ borderBottom: "0px solid #fff" });
      beforeSortOrgCopyOps([]);
      if (invoiceIds.length > 0) {
        props.getInvoiceForIds(invoiceIds);
      }
      
    }
  }, [props.moneyTransactions]);
  useEffect(() => {
    if (props.invoices) {
      let tempInvoice = [];
      for (const val of props.invoices) {
        tempInvoice[val.id] = val;
      }
      invoiceDecOps(tempInvoice);
    }
  }, [props.invoices]);

  useEffect(() => {
    let tempKids = [];
    for (const val of props.kids) {
      tempKids[val.id] = val;
    }
    kidDecOps(tempKids);
    // addKidNameToTriansaction();
  }, [props.kids]);

  useEffect(() => {
    if (props.admissionKids.length > 0) {
      let tempKids = [];
      for (const val of props.admissionKids) {
        tempKids[val.id] = val;
      }
      admissionKidDecOps(tempKids);
    }
  }, [props.admissionKids]);

  useEffect(() => {
    if (props.allDeletedKids.length > 0) {
      let tempKids = [];
      for (const val of props.allDeletedKids) {
        tempKids[val.id] = val;
      }
      deletedKidDecOps(tempKids);
    }
  }, [props.allDeletedKids]);

  useEffect(() => {
    let tempTeacher = [];
    for (const val of props.teachers) {
      tempTeacher[val.id] = val;
    }
    teacherDecOps(tempTeacher);
  }, [props.teachers]);
  const enableLoader = (id, btn) => {
    let tempObjs = Object.assign([], transactions);
    for (let val of tempObjs) {
      if (val.id === id) {
        if (btn === 1) {
          val.isLoaderForCancel = true;
        } else {
          val.isLoaderForVerify = true;
        }
        break;
      }
    }
    transactionsOps(tempObjs);
  };
  const loadAllTransactionForFilter = () => {
    let filters = {};
    filters.schoolId = schoolId;
    if (fromDate && toDate) {
      filters.fromDate = fromDate;
      filters.toDate = toDate;
    } else {
      filters.fromDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 30
      );
      filters.toDate = new Date();
      fromDateOps(
        new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30)
      );
      todateOps(new Date());
    }
    if (activeYear) {
      let tempTags = [];
      if (activeYear === "2017-18") {
        tempTags.push("2017 - 18");
        tempTags.push("2017-18");
        tempTags.push("2017- 18");
        tempTags.push("2017 -18");
      } else if (activeYear === "2018-19") {
        tempTags.push("2018 - 19");
        tempTags.push("2018-19");
        tempTags.push("2018- 19");
        tempTags.push("2018 -19");
      } else if (activeYear === "2019-20") {
        tempTags.push("2019 - 20");
        tempTags.push("2019-20");
        tempTags.push("2019- 20");
        tempTags.push("2019 -20");
      } else if (activeYear === "2020-21") {
        tempTags.push("2020 - 21");
        tempTags.push("2020-21");
        tempTags.push("2020- 21");
        tempTags.push("2020 -21");
      } else if (activeYear === "2021-22") {
        tempTags.push("2021 - 22");
        tempTags.push("2021-22");
        tempTags.push("2021- 22");
        tempTags.push("2021 -22");
      } else if (activeYear === "2022-23") {
        tempTags.push("2022 - 23");
        tempTags.push("2022-23");
        tempTags.push("2022- 23");
        tempTags.push("2022 -23");
      }
      filters.tags = tempTags;
    }
    filters.referenceNumber=undefined;
    if (referenceNumber) {
      filters.referenceNumber = referenceNumber;
      filters.tags=[];
      filters.fromDate = undefined;
      filters.endDate = undefined;
    }
    filters.chequeNumber=undefined;
    if (chequeNumber) {
      filters.chequeNumber = chequeNumber;
      filters.tags=[];
      filters.fromDate = undefined;
      filters.endDate = undefined;
    }
    filters.filterBy = activeFilterBy;
    filters.limit = +entryCount;
    filters.skipValue = +currentPage;
    props.getAllTransactionCountForFilter(filters);
    props.getAllTransactionForFilter(filters);
    allTransactionListOps([]);
    setNoResultsFound(false);
  };
  useEffect(() => {
    loadAllTransactionForFilter();
  }, [activeYear, currentPage]);


  const getKidName = (kidId) => {
    let childName = "--";
    if (kidDec[kidId]) {
      childName = kidDec[kidId].attributes.Name;
    }else  if (admissionKidDec[kidId]) {
      childName = admissionKidDec[kidId].attributes.Name;
    }
     else if (deletedKidDec[kidId]) {
      childName = deletedKidDec[kidId].attributes.Name;
    } 
    
    return childName;
  }

  useEffect(() => {
    if (allTransactionList.length > 0) {
      let gotValue = false;
      let tempAllTransaction = Object.assign([], allTransactionList);
      for (const val of tempAllTransaction) {
        
        if (invoiceDec[val.invoiceId] && (!val.kidName || val.kidName === "--")) {
          val.invoiceNumber =
            invoiceDec[val.invoiceId].attributes.invoiceNumber;

          let recipientId = invoiceDec[val.invoiceId].attributes.recipientId;
          if (kidDec[recipientId]) {
            gotValue = true;
            val.kidName = kidDec[recipientId].attributes.Name;
          } else if (admissionKidDec[recipientId]) {
            gotValue = true;
            val.kidName = admissionKidDec[recipientId].attributes.Name ;
          } else if (deletedKidDec[recipientId]) {
            gotValue = true;
            val.kidName = deletedKidDec[recipientId].attributes.Name ;
          } else {
            val.kidName = "--";
          }
        }
      }
      if (gotValue) {
        allTransactionListOps(tempAllTransaction);
      }
    }
  }, [allTransactionList,invoiceDec, kidDec, deletedKidDec, admissionKidDec]);
  

  const getYearDropDown = () => {
    //dropdown for list
    let currentState = activeYear;
    let newList = props.list.filter((item) => item !== currentState);
    if (!props.list.includes("All") && currentState !== "All") {
      newList.unshift("All");
    }

    return (
      <Dropdown
        isOpen={toggleId === currentState}
        toggle={() => toggleIdOps(toggleId ? undefined : currentState)}
      >
        <DropdownToggle className="bg-white border-0">
          <Badge
            className="font-size-12 badge-soft-success"
            color="success"
            pill
          >
            {currentState}
            <i className="mdi mdi-chevron-down"></i>
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {newList.map((item, key) => (
            <DropdownItem key={key} onClick={() => activeYearOps(item)}>
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };
  const sortBy = (type, order) => {
    let sortingFor = "";
    let sortFor = order === "ascending" ? false : true;
    if (type === "kidName") {
      // nameOrderOps("ascending");
      dateOrderOps("ascending");
      amountOrderOps("ascending");
      invoiceOrderOps("ascending");
      typeOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "kidName";
    } else if (type === "paidOrgDate") {
      nameOrderOps("ascending");
      // dateOrderOps("ascending");
      amountOrderOps("ascending");
      invoiceOrderOps("ascending");
      typeOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "paidOrgDate";
    } else if (type === "createdDate") {
      nameOrderOps("ascending");
      // dateOrderOps("ascending");
      amountOrderOps("ascending");
      invoiceOrderOps("ascending");
      typeOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "createdAt";
    } else if (type === "amount") {
      nameOrderOps("ascending");
      dateOrderOps("ascending");
      // amountOrderOps("ascending");
      invoiceOrderOps("ascending");
      typeOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "amount";
    } else if (type === "invoiceNumber") {
      nameOrderOps("ascending");
      dateOrderOps("ascending");
      amountOrderOps("ascending");
      // invoiceOrderOps("ascending");
      typeOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "invoiceNumber";
    } else if (type === "type") {
      nameOrderOps("ascending");
      dateOrderOps("ascending");
      amountOrderOps("ascending");
      invoiceOrderOps("ascending");
      // typeOrderOps("ascending")
      statusOrderOps("ascending");
      sortingFor = "type";
    } else if (type === "status") {
      nameOrderOps("ascending");
      dateOrderOps("ascending");
      amountOrderOps("ascending");
      invoiceOrderOps("ascending");
      typeOrderOps("ascending");
      // statusOrderOps("ascending")
      sortingFor = "status";
    }
    let tempAllTransaction = Object.assign([], allTransactionList);
    let sortedList = tempAllTransaction.sort(function (a, b) {
      if (sortFor) {
        if (a[sortingFor] < b[sortingFor]) {
          return -1;
        }
        if (a[sortingFor] > b[sortingFor]) {
          return 1;
        }
        return 0;
      } else {
        if (a[sortingFor] < b[sortingFor]) {
          return 1;
        }
        if (a[sortingFor] > b[sortingFor]) {
          return -1;
        }
        return 0;
      }
    });
    allTransactionListOps(sortedList);
  };
  const sortByStatus = (type) => {
    if (sortByStatusValue === type) {
      sortByStatusValueOps("");
      sortVerifiedStyleOps({ borderBottom: "0px solid #fff" });
      sortSuccessStyleOps({ borderBottom: "0px solid #fff" });
      sortOutstandingStyleOps({ borderBottom: "0px solid #fff" });
      allTransactionListOps(beforeSortOrgCopy);
      beforeSortOrgCopyOps([]);
    } else {
      sortByStatusValueOps(type);
      if (type === "verified") {
        sortVerifiedStyleOps({ borderBottom: "5px solid #566ee6" });
        sortSuccessStyleOps({});
        sortOutstandingStyleOps({});
      } else if (type === "success") {
        sortVerifiedStyleOps({});
        sortSuccessStyleOps({ borderBottom: "5px solid #566ee6" });
        sortOutstandingStyleOps({});
      } else if (type === "outstanding") {
        sortVerifiedStyleOps({});
        sortSuccessStyleOps({});
        sortOutstandingStyleOps({ borderBottom: "5px solid #566ee6" });
      }
      displaySortByStatus(type);
    }
  };
  const displaySortByStatus = (type) => {
    let copyInvoice = beforeSortOrgCopy;
    if (beforeSortOrgCopy.length === 0 && allTransactionList.length > 0) {
      let tempInvoices = Object.assign([], allTransactionList);
      copyInvoice = tempInvoices;
      beforeSortOrgCopyOps(tempInvoices);
    }
    let sortedList = [];
    for (const val of copyInvoice) {
      if (val.status.toLowerCase() === type) {
        sortedList.push(val);
      }
    }
    allTransactionListOps(sortedList);
  };
  useEffect(() => {
    loadAllTransactionForFilter();
  }, [entryCount]);

  useEffect(() => {
    transactionCountOps(props.transactionCount);
    if (props.transactionCount === 0) {
      paginationListOps(["1"]);
    } else {
      const countPages = props.transactionCount / +entryCount;
      const pages = Math.ceil(countPages);
      let pagination = [];
      for (let i = 1; i <= pages; i++) {
        pagination.push("" + i);
      }
      paginationListOps(pagination);
    }
  }, [props.transactionCount]);
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col
              style={{ marginRight: "-2.2rem", }}
            >
              <div className="d-flex justify-content-end">
                {getYearDropDown()}
                <i className="mdi mdi-18px mdi-filter"></i>
              </div>
            </Col>
          </Row>
          <Row className="bg-light ">
            <Col md="5" xs="12" xl="5">
              <FormGroup className="mt-2 mb-2 p-1">
                <Label className="mb-0">{props.t("Filter by")} :</Label>
                <select
                  className="form-control "
                  value={activeFilterBy}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    activeFilterByOps(selectedValue);
                    setShowReferenceInput(selectedValue === "Reference Number");
                    setShowChequeInput(selectedValue === "Cheque Number");
                    if (selectedValue !== "Reference Number" || "Cheque Number") {
                      setReferenceNumber("");
                      setChequeNumber("");
                    }
                  }}
                >
                  <option value="All">{props.t("All")}</option>
                  {filterByListValue.map((val, key) => (
                    <option key={key} value={val}>
                      {props.t(`${val.charAt(0).toUpperCase()}${val.slice(1)}`)}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            {showReferenceInput && (
                <Col md="3" xs="12" xl="3">
                  <FormGroup>
                    <Label className="mt-2">{props.t("Reference Number")}:</Label>
                    <input
                      type="text"
                      className="form-control"
                      value={referenceNumber}
                      onChange={(e) => {setReferenceNumber(e.target.value.trim())}}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          loadAllTransactionForFilter(4);
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
            )}
            {showChequeInput && (
                <Col md="3" xs="12" xl="3">
                  <FormGroup>
                    <Label className="mt-2">{props.t("Cheque Number")}:</Label>
                    <input
                      type="text"
                      className="form-control"
                      value={chequeNumber}
                      onChange={(e) => {setChequeNumber(e.target.value.trim())}}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          loadAllTransactionForFilter(5);
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
            )}
            {!(showReferenceInput || showChequeInput) && (
            <Col md="3" xs="12" xl="3">
              <FormGroup className="mt-2 mb-2 p-1">
                <Label className="mb-0">{props.t("From (Date)")} :</Label>
                <DatePicker
                  selected={fromDate}
                  onChange={(date) => {
                    fromDateOps(date);
                  }}
                  className="form-control"
                  placeholderText={props.t("Select Date")}
                  startDate={fromDate}
                  endDate={toDate}
                  maxDate={toDate}
                  dateFormat="dd/MM/yyyy"
                />
              </FormGroup>
            </Col>)}
            {!(showReferenceInput || showChequeInput) && (
            <Col md="3" xs="12" xl="3">
              <FormGroup className="mt-2 mb-2 p-1">
                <Label className="mb-0">{props.t("To (Date)")} :</Label>
                <DatePicker
                  selected={toDate}
                  onChange={(date) => todateOps(date)}
                  className="form-control"
                  placeholderText={props.t("Select Date")}
                  startDate={fromDate}
                  endDate={toDate}
                  minDate={fromDate}
                  dateFormat="dd/MM/yyyy"
                />
              </FormGroup>
            </Col>)}
            <Col md="1" xs="12" xl="1">
              <div className="mt-2">
                <button
                  onClick={loadAllTransactionForFilter}
                  className="btn btn-primary float-right mt-4 mr-2"
                >
                  Apply
                </button>
              </div>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col sm="4">
              <Card
                onClick={() => sortByStatus("verified")}
                style={{ cursor: "pointer" }}
              >
                <CardBody style={sortVerifiedStyle}>
                  <Row>
                    <Col md="3" xl="3" xs="3">
                      <i className="mdi mdi-bank h1 text-success align-middle mb-0 mr-3"></i>
                    </Col>
                    <Col md="9" xl="9" xs="9">
                      <p className="text-muted h5 mb-2">
                        {props.t("Verified")}(
                        <span className="text-success">{verified}</span>)
                      </p>
                      <div>
                        <p className="text-muted text-truncate mb-0">
                          (
                          <span style={{ fontFamily: "Arial, sans-serif" }}>
                            {props.currency}
                          </span>
                          ) {parseFloat(verifiedAmount).toFixed(2)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card
                onClick={() => sortByStatus("success")}
                style={{ cursor: "pointer" }}
              >
                <CardBody style={sortSuccessStyle}>
                  <Row>
                    <Col md="3" xl="3" xs="3">
                      <i className="mdi mdi-account-cash-outline h1 text-warning align-middle mb-0 mr-3"></i>
                    </Col>
                    <Col md="9" xl="9" xs="9">
                      <p className="text-muted h5 mb-2">
                        {props.t("Success")}(
                        <span className="text-warning">{success}</span>)
                      </p>
                      <div>
                        <p className="text-muted text-truncate mb-0">
                          (
                          <span style={{ fontFamily: "Arial, sans-serif" }}>
                            {props.currency}
                          </span>
                          ) {parseFloat(successAmount).toFixed(2)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card
                onClick={() => sortByStatus("outstanding")}
                style={{ cursor: "pointer" }}
              >
                <CardBody style={sortOutstandingStyle}>
                  <Row>
                    <Col md="3" xl="3" xs="3">
                      <i className="mdi mdi-credit-card-outline h1 text-danger align-middle mb-0 mr-3"></i>
                    </Col>
                    <Col md="9" xl="9" xs="9">
                      <p className="text-muted h5 mb-2">
                        {props.t("Outstanding")}(
                        <span className="text-danger">{outstanding}</span>)
                      </p>
                      <div>
                        <p className="text-muted h6 text-truncate mb-0">
                          (
                          <span style={{ fontFamily: "Arial, sans-serif" }}>
                            {props.currency}
                          </span>
                          ) {parseFloat(outstandingAmount).toFixed(2)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="table-responsive mt-2">
            <Row>
              <Col>
                {props.t("Show Entries")}
                <select
                  className="custom-select ml-1"
                  value={entryCount}
                  style={{ width: "15%" }}
                  onChange={(e) => {
                    entryCountOps(e.target.value);
                  }}
                >
                  {entries.map((val, key) => (
                    <option key={key} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-end"
                >
                  <PaginationItem disabled={currentPage === "1"}>
                    <PaginationLink
                      onClick={() => currentPageOps(+currentPage - 1 + "")}
                      href="#"
                      tabIndex="-1"
                    >
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  {paginationList.map((val, key) => (
                    <PaginationItem active={currentPage === val} key={key}>
                      <PaginationLink
                        onClick={() => currentPageOps(val)}
                        href="#"
                      >
                        {val}{" "}
                        {currentPage === val && (
                          <span className="sr-only">(current)</span>
                        )}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    disabled={currentPage === paginationList.length + ""}
                  >
                    <PaginationLink
                      href="#"
                      onClick={() => currentPageOps(+currentPage + 1 + "")}
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th
                    onClick={() => {
                      nameOrder === "ascending"
                        ? nameOrderOps("descending")
                        : nameOrderOps("ascending");
                      sortBy("kidName", nameOrder);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`mdi mdi-18px mdi-order-alphabetical-${nameOrder} mr-1`}
                    ></i>
                    {props.t("Student Name")}
                  </th>
                  <th
                    onClick={() => {
                      dateOrder === "ascending"
                        ? dateOrderOps("descending")
                        : dateOrderOps("ascending");
                      sortBy("createdDate", dateOrder);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`mdi mdi-18px mdi-order-numeric-${dateOrder} mr-1`}
                    ></i>
                    {props.t("Date")}
                  </th>
                  <th
                    onClick={() => {
                      dateOrder === "ascending"
                        ? dateOrderOps("descending")
                        : dateOrderOps("ascending");
                      sortBy("paidOrgDate", dateOrder);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`mdi mdi-18px mdi-order-numeric-${dateOrder} mr-1`}
                    ></i>
                    {props.t("Paid Date")}
                  </th>
                  <th
                    onClick={() => {
                      typeOrder === "ascending"
                        ? typeOrderOps("descending")
                        : typeOrderOps("ascending");
                      sortBy("type", typeOrder);
                    }}
                    style={{ cursor: "pointer", width:'15%' }}
                  >
                    <i
                      className={`mdi mdi-18px mdi-order-alphabetical-${typeOrder} mr-1`}
                    ></i>
                    {props.t("Type")}
                  </th>
                  <th
                    onClick={() => {
                      amountOrder === "ascending"
                        ? amountOrderOps("descending")
                        : amountOrderOps("ascending");
                      sortBy("amount", amountOrder);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`mdi mdi-18px mdi-order-numeric-${amountOrder} mr-1`}
                    ></i>
                    {props.t("Amount")}({" "}
                    <span style={{ fontFamily: "Arial, sans-serif" }}>
                      {props.currency}
                    </span>
                    )
                  </th>
                  <th
                    onClick={() => {
                      invoiceOrder === "ascending"
                        ? invoiceOrderOps("descending")
                        : invoiceOrderOps("ascending");
                      sortBy("invoiceNumber", invoiceOrder);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`mdi mdi-18px mdi-order-numeric-${invoiceOrder} mr-1`}
                    ></i>
                    {props.t("Invoice Number")}
                  </th>
                  {/* <th>{props.t("Details")}</th> */}
                  <th
                    onClick={() => {
                      statusOrder === "ascending"
                        ? statusOrderOps("descending")
                        : statusOrderOps("ascending");
                      sortBy("status", statusOrder);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`mdi mdi-18px mdi-order-alphabetical-${statusOrder} mr-1`}
                    ></i>
                    {props.t("Status")}
                  </th>
                   
                  {(props.teacherPaymenrWrite && props.paymentAdmin) &&
                    <th style={{ width:"20%" }} >{props.t("Action")}</th> 
                  }
                 
                  <th>{props.t("Remark")}</th>
                </tr>
              </thead>
              <tbody>
                {showLoader && noResultsFound &&(
                  <tr>
                    <td colSpan="10" className="pt-4 text-center">
                      <Label to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                        Loading...{" "}
                      </Label>
                    </td>
                  </tr>
                )}
                {!showLoader && allTransactionList.length === 0 && (
                  <tr>
                    <td colSpan="10" className="pt-4 text-center">
                      <h3 className="text-center text-info">
                        {props.t("No transaction found for the filter.")}
                      </h3>
                    </td>
                  </tr>
                )}
                {allTransactionList.map((val, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td style={val.deletedCss} id={"tt" + key}>
                      
                      <p>
                        {val.kidName && val.kidName.length > 18
                          ? val.kidName.slice(0, 17)
                          : val.kidName
                        }
                      <UncontrolledTooltip placement="top-start" target={"tt" + key}>
                       {val.kidName}
                      </UncontrolledTooltip>
                      </p>
                    </td>
                    <td style={val.deletedCss}>{Moment(val.createdAt).format("DD-MMM-YYYY")}</td>
                    <td style={val.deletedCss}>{Moment( val.paidDate).format("DD-MMM-YYYY")}</td>
                    <td style={val.deletedCss2}>
                      {val.type} <br />
                      {val.transactionId&&<>{val.transactionId.length>20 ?<p id={"tid" + key}> {val.transactionId.slice(0,19)} </p>: val.transactionId}</>}
                      {val.type === "AirPay" ? <>{val.transactionId && <br />}{"Order id: " + val.id}</> : ""}
                      {val.type === "RazorPay" ? <>{val.transactionId && <br />}{"Order id: " + val.Obj.attributes.razorpayId}</> : ""}
                      {val.type === "OnePay" ? <>{val.transactionId && <br />}{"Order id: " + val.id}</> : ""}

                      {val.transactionId && val.transactionId.length > 20 &&
                        <UncontrolledTooltip placement="top-start" target={"tid" + key}>
                       {val.transactionId}
                      </UncontrolledTooltip>}
                    </td>
                    <td  style={val.deletedCss}>
                      {parseFloat(val.amount).toFixed(2)}
                    </td>
                    <td style={val.deletedCss}>{val.invoiceNumber}</td>
                    <td className={val.statusCss}>
                      {val.status}<br /> <br />
                      {teacherDec[val.receivedBy] && (
                        <div className="">
                          {props.t("Received By")}: <br />
                          <span className="text-secondary">
                            {teacherDec[val.receivedBy].attributes.Name}
                          </span>
                          <br />
                        </div>
                      ) }
                      {teacherDec[val.verifiedBy] && (
                        <div className="">
                          {props.t("Verified By")}: <br />
                          <span className="text-secondary">
                            {teacherDec[val.verifiedBy].attributes.Name}
                          </span>
                          <br />
                        </div>
                      ) }
                      {teacherDec[val.deletedBy] && (
                        <div className="">
                          {props.t("Cancelled By")}: <br />
                          <span className="text-secondary">
                            {teacherDec[val.deletedBy].attributes.Name}
                          </span>
                          <br />
                        </div>
                      ) }
                    </td>
                    {/* <td className={val.statusCss}>{val.status}</td> */}
                    {(!val.deleted && props.teacherPaymenrWrite && props.paymentAdmin) ?
                     
                      <td style={{ width:"20%" }}>
                        {val.canVerify &&
                          props.teacherPaymenrWrite &&
                          props.paymentAdmin &&
                          // <td>
                          !val.deleted && (
                            <button
                              type="button"
                              onClick={() => {
                                enableLoader(val.id, 1);
                                props.deleteTriansactionById(val.id);
                              }}
                              className="btn btn-danger waves-effect btn-sm m-1"
                            >
                              {val.isLoaderForCancel && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                              )}
                              {props.t("Cancel")}
                            </button>
                          )}
                        {val.canVerify &&
                          props.teacherPaymenrWrite &&
                          props.paymentAdmin &&
                          !val.deleted &&
                          val.statusValue !== 2 && (
                            <button
                              type="button"
                              onClick={() => {
                                enableLoader(val.id, 2);
                                props.verifyTriansactionById(val.id);
                              }}
                              className="btn btn-success waves-effect m-1 btn-sm "
                            >
                              {val.isLoaderForVerify && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                              )}
                              {props.t("Verify")}
                            </button>
                            // </td>
                          )}
                         <br />
                        
                      
                      </td> : <td>
                        {
                          val.deleted && val.showCheckStatus && (
                            <button
                              type="button"
                              onClick={() => {
                                  props.recheckTransactionState(val.id)
                              }}
                              className="btn btn-success  btn-sm "
                            >
                                
                              {props.t("Check status")}
                            </button>
                          )
                        }
                      </td>}
                      <td style={val.deletedCss2}>{val.remark}</td>
                    
                    
                  </tr>
                ))}
              </tbody>
            </table>
            <Row>
              <Col>
                {props.t("Show Entries")}
                <select
                  className="custom-select ml-1"
                  value={entryCount}
                  style={{ width: "15%" }}
                  onChange={(e) => {
                    entryCountOps(e.target.value);
                  }}
                >
                  {entries.map((val, key) => (
                    <option key={key} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-end"
                >
                  <PaginationItem disabled={currentPage === "1"}>
                    <PaginationLink
                      onClick={() => currentPageOps(+currentPage - 1 + "")}
                      href="#"
                      tabIndex="-1"
                    >
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  {paginationList.map((val, key) => (
                    <PaginationItem active={currentPage === val} key={key}>
                      <PaginationLink
                        onClick={() => currentPageOps(val)}
                        href="#"
                      >
                        {val}{" "}
                        {currentPage === val && (
                          <span className="sr-only">(current)</span>
                        )}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    disabled={currentPage === paginationList.length + ""}
                  >
                    <PaginationLink
                      href="#"
                      onClick={() => currentPageOps(+currentPage + 1 + "")}
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {
    moneyTransactions,
    showAllTransactionLoader,
    transactionCount,
  } = state.MoneyTransactions;
  const { invoices } = state.Invoice;
  const { kids,allDeletedKids,admissionKids } = state.Kid;
  const { teachers } = state.Teacher;
  return {
    transactionCount,
    moneyTransactions,
    invoices,
    kids,
    teachers,
    showAllTransactionLoader,
    allDeletedKids,
    admissionKids,
  };
};
export default connect(mapStatetoProps, {
  getAllTransactionForFilter,
  getAllTransactionCountForFilter,
  getInvoiceForIds,
  getTeachersForSchool,
  verifyTriansactionById,
  deleteTriansactionById,
  recheckTransactionState,
})(withRouter(withNamespaces()(AllTransaction)));
