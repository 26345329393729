import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withNamespaces } from "react-i18next";
import Switch from "react-switch";
import * as Constants from "../../Constents";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { Row, Col, Card, CardBody, Table, Label, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Input, Button } from "reactstrap";
import { setSelecetdSchool, getPaymentOptionsForSchool } from "../../store/actions";

const schoolSettings = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/tools-school-settings/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;

    const [schoolObject, setSchoolObject] = useState();
    const [pgDisableAlert, setShowPGDisableAlert] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [modalSubscriptionDate, setModalSubscriptionDate] = useState(new Date());

    const [modalGracePeriod, setModalGracePeriod] = useState();
    const [subScriptionEndAfterGrace, setSubScriptionEndAfterGrace] = useState();
    const [subscriptionEndDate, setSubscriptionEndDate] = useState();
    const [showLoginAlert, setShowLoginAlert] = useState(false);
    const [showPaymentGatewayOptions, setShowPaymentGatewayOptions] = useState(false)
    const [lockFeatures, setlockFeatures] = useState();
    const [selfCheckinForParent, setSelfCheckInForParent] = useState(false);
    const [selfCheckinForTeacher, setSelfCheckInForTeacher] = useState(false);
    const [selfCheckin, setSelfCheckIn] = useState(false);

    useEffect(() => {
        Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
            schoolId: schoolId,
        }).then((result) => {
            console.log(result)
            setSchoolObject(result);
        },
            (error) => {
                console.log(error);
            }
        );
    }, [schoolId]);

    useEffect(() => {
        updateFeatureList();
    }, [schoolObject]);


    const getDate = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };

    const handleSave = () => {
        Parse.Cloud.run("function_updateSubscriptionExpiry", {
            schoolId: schoolObject.id,
            subscriptionExpiryDate: modalSubscriptionDate,
            gracePeriod: parseInt(modalGracePeriod),
        }).then((result) => {
            console.log(result)
            // let updatselectedSchoolsList = ReduxUtil.updsateParseObjects(props., [result.schoolObject]);
            setSchoolObject(result.School);
        },
            (error) => {
                console.log(error);
            }
        );
    }

    // console.log(schoolObject.id)

    useEffect(() => {
        let newDate = new Date(modalSubscriptionDate);
        newDate.setDate(newDate.getDate() + parseInt(modalGracePeriod));
        const today = new Date();
        const diffTime = newDate.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
        setSubScriptionEndAfterGrace(diffDays);

        setSubscriptionEndDate(newDate);
    }, [modalSubscriptionDate, modalGracePeriod])

    const getFormattedDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };



    const [featuresList, setfeaturesList] = useState([])

    const getfeatureValue = (list, index) => {

        if (list && list.length > index) {
            return list[index]
        }
        return 0
    }

    useEffect(() => {
        if (schoolId) {
            props.getPaymentOptionsForSchool(schoolId);
        }
    }, [schoolId]);

    // console.log(props.paymentOptionsForSchool, "paymentOptionsForSchool")
    useEffect(() => {
        if (props.paymentOptionsForSchool && props.paymentOptionsForSchool.length > 0) {
            // for(const payment of props.paymentOptionsForSchool){
            //     if(payment.attributes.paymentGateway){
            //         setEnablePaymentGateway(true)
            //     }
            // }
            updateFeatureList();
        }
    }, [props.paymentOptionsForSchool])

    const updateFeatureList = () => {
        if (schoolObject) {
            let featuresList = schoolObject.attributes.featuresList;
            let additionalList = schoolObject.attributes.additionalSettings;
            let parentList = schoolObject.attributes.parentPermissions;
            let tempFeaturesList = [
                {
                    feature: "Messaging",
                    type: "videoEnabled",
                    subFeature: "Send Video",
                    colomn: "featureList",
                    index: schoolObject.attributes.videoEnabled,
                    isEnabled: schoolObject.attributes.videoEnabled > 0 ? 1 : 0,
                    currentValue: "",
                    condition: true,
                },
                // {
                //     feature: "",
                //     subFeature: "SMS on alert",
                //     colomn: "featureList",
                //     index: Constants.FEATURE_SMS_ON_ALERT_INDEX,
                //     isEnabled: getfeatureValue(featuresList, Constants.FEATURE_SMS_ON_ALERT_INDEX) > 0 ? true : false,
                //     currentValue: "",
                //     condition: true,
                // },
                {
                    feature: "Attendance",
                    type: 'basic',
                    subFeature: "Attendance",
                    colomn: "featureList",
                    index: Constants.FEATURE_ATTENDANCE_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_ATTENDANCE_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    type: "qr",
                    subFeature: "QR Checkin and Checkout",
                    colomn: "featureList",
                    index: Constants.FEATURE_ATTENDANCE_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_ATTENDANCE_INDEX) > 1 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    type: "qr-self",
                    subFeature: "Self Checkin",
                    colomn: "featureList",
                    index: Constants.FEATURE_ATTENDANCE_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_ATTENDANCE_INDEX) > 3 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Attendance with temperature",
                    colomn: "featureList",
                    index: Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable attendance message ",
                    colomn: "featureList",
                    index: Constants.FEATURE_NO_ATTENDANCE_MESSAGE_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_NO_ATTENDANCE_MESSAGE_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable Self Button while scaning (Android) ",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_DISABLE_SELF_SCAN_ANDROID,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_DISABLE_SELF_SCAN_ANDROID) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "Child Development",
                    subFeature: "Assessment",
                    colomn: "featureList",
                    index: Constants.FEATURE_ACTIVITY_TRACKING_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_ACTIVITY_TRACKING_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "For Parents",
                    colomn: "featureList",
                    index: Constants.FEATURE_ACTIVITY_TRACKING_PARENT_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_ACTIVITY_TRACKING_PARENT_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Home Learning",
                    colomn: "featureList",
                    index: Constants.FEATURE_E_LEARNING,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_E_LEARNING) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Live Classes",
                    colomn: "featureList",
                    index: Constants.FEATURE_GO_LIVE,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_GO_LIVE) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable Child dev message",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_CHILD_DEV_MSG_DISABLED,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_CHILD_DEV_MSG_DISABLED) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "Fee Management",
                    subFeature: "Fee Management",
                    colomn: "featureList",
                    index: Constants.FEATURE_PAYMENTS_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_PAYMENTS_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Payment Gateway",
                    type: "Payment_gateway",
                    colomn: "",
                    index: "",
                    isEnabled: (props.paymentOptionsForSchool && props.paymentOptionsForSchool.length > 0) ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable edit item amount(Parent)",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_PAYMENT_EDIT_DISABLED,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_PAYMENT_EDIT_DISABLED) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Program",
                    colomn: "featureList",
                    index: Constants.FEATURE_PROGRAM_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_PROGRAM_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable Payment message ",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_PAYMENT_MSG_DISABLED,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_PAYMENT_MSG_DISABLED) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Show Invoice created Date on Receipt",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_PAYMENT_DATE,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_PAYMENT_DATE) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Receipt without Transactions",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_RECEIPT_WITHOUT_TRANSACTION,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_RECEIPT_WITHOUT_TRANSACTION) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "Other Settings",
                    subFeature: "Transport",
                    colomn: "featureList",
                    index: Constants.FEATURE_TRANSPORT_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_TRANSPORT_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Day Care",
                    colomn: "featureList",
                    index: Constants.FEATURE_CUSTOM_DIARY_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_CUSTOM_DIARY_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Charge For Full Hour",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_DAYCARE_FULL_HOUR_CHARGES,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_DAYCARE_FULL_HOUR_CHARGES) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Pick And Drop",
                    colomn: "featureList",
                    index: Constants.FEATURE_PICKNDROP_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_PICKNDROP_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Medical Details",
                    colomn: "featureList",
                    index: Constants.FEATURE_MEDICAL_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_MEDICAL_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Expenses",
                    colomn: "featureList",
                    index: Constants.FEATURE_EXPENSE_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_EXPENSE_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "CCTV",
                    type: "cctv",
                    subFeature: "CCTV Camera",
                    colomn: "featureList",
                    index: Constants.FEATURE_CCTV_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_CCTV_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    type: "cctv-with-attendance",
                    subFeature: "CCTV access with Attendance",
                    colomn: "featureList",
                    index: Constants.FEATURE_CCTV_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_CCTV_INDEX) == 1 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                // {
                //     feature: "School permission",
                //     subFeature: "Parent transport",
                //     colomn: "featureList",
                //     index: Constants.FEATURE_PARENT_TRANSPORT_INDEX,
                //     isEnabled: getfeatureValue(featuresList, Constants.FEATURE_PARENT_TRANSPORT_INDEX) > 0 ? true : false,
                //     currentValue: "",
                //     condition: true,
                // },
                // {
                //     feature: "",
                //     subFeature: "Observation report",
                //     colomn: "featureList",
                //     index: Constants.FEATURE_OBSERVATION_REPORT_INDEX,
                //     isEnabled: getfeatureValue(featuresList, Constants.FEATURE_OBSERVATION_REPORT_INDEX) > 0 ? true : false,
                //     currentValue: "",
                //     condition: true,
                // },
                // {
                //     feature: "",
                //     subFeature: "Web message",
                //     colomn: "featureList",
                //     index: Constants.FEATURE_WEB_MESSAGE_INDEX,
                //     isEnabled: getfeatureValue(featuresList, Constants.FEATURE_WEB_MESSAGE_INDEX) > 0 ? true : false,
                //     currentValue: "",
                //     condition: true,
                // },
                {
                    feature: "School permission",
                    type: "visitors-form",
                    subFeature: "Visitors management",
                    colomn: "featureList",
                    index: Constants.FEATURE_VISITOR_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_VISITOR_INDEX) == 1 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    type: "visitors-form-with-auth",
                    subFeature: "Visitors management with OTP authentication",
                    colomn: "featureList",
                    index: Constants.FEATURE_VISITOR_INDEX,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_VISITOR_INDEX) == 2 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Marks card",
                    colomn: "featureList",
                    index: Constants.FEATURE_MARKS_CARD,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_MARKS_CARD) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                // {
                //     feature: "",
                //     subFeature: "Market place",
                //     colomn: "featureList",
                //     index: Constants.FEATURE_MARKETPLACE_INDEX,
                //     isEnabled: getfeatureValue(featuresList, Constants.FEATURE_MARKETPLACE_INDEX) > 0 ? true : false,
                //     currentValue: "",
                //     condition: true,
                // },
                {
                    feature: "",
                    subFeature: "Course",
                    colomn: "featureList",
                    index: Constants.FEATURE_COURSE,
                    isEnabled: getfeatureValue(featuresList, Constants.FEATURE_COURSE) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                    isDisabled: true,
                },
                {
                    feature: "",
                    subFeature: "Enable Water mark",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_WATER_MARK,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_WATER_MARK) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable voice message for start and stop",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_VOICE_MESSAGE_DISABLED_FOR_START_STOP,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_VOICE_MESSAGE_DISABLED_FOR_START_STOP) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Enable Diary approval",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_DIARY_APPROVAL,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_DIARY_APPROVAL) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Marketplace voice",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_MARKETPLACE_VOICE,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_MARKETPLACE_VOICE) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "LilTringle content share permission",
                    colomn: "additionalSettings",
                    index: Constants.ADDITIONAL_SETTING_lilTriangle_CONTENT_SHARE_PERMISSIM,
                    isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_lilTriangle_CONTENT_SHARE_PERMISSIM) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                // {
                //     feature: "Additional Settings",
                //     subFeature: "LilTringle extra protection",
                //     colomn: "additionalSettings",
                //     index: Constants.ADDITIONAL_SETTING_lilTriangle_EXTRA_PROTECTION,
                //     isEnabled: getfeatureValue(additionalList, Constants.ADDITIONAL_SETTING_lilTriangle_EXTRA_PROTECTION) > 0 ? true : false,
                //     currentValue: "",
                //     condition: true,
                // },
                {
                    feature: "Parent permission",
                    subFeature: "Disable edit parents details ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_EDIT_PARENT_DETAILS_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_EDIT_PARENT_DETAILS_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable edit medical details ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_EDIT_MEDICAL_DETAILS_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_EDIT_MEDICAL_DETAILS_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable edit pickup details ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_EDIT_PICKUP_DETAILS_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_EDIT_PICKUP_DETAILS_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable edit favorite color ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_FAVORITE_COLOR_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_FAVORITE_COLOR_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable Refer child",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_REFER_CHILD_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_REFER_CHILD_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable Share image ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_SHARE_IMAGE_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_SHARE_IMAGE_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable Help vieo ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_HELP_VIDEO_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_HELP_VIDEO_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable edit profile picture ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_EDIT_PROFILE_PICK_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_EDIT_PROFILE_PICK_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable Parent feedback ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_PARENT_FEEDBACK_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_PARENT_FEEDBACK_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },
                {
                    feature: "",
                    subFeature: "Disable Parent marketplace ",
                    colomn: "parentPermissions",
                    index: Constants.PARENT_PERMISSIONS_PARENT_MARKETPLACE_INDEX,
                    isEnabled: getfeatureValue(parentList, Constants.PARENT_PERMISSIONS_PARENT_MARKETPLACE_INDEX) > 0 ? true : false,
                    currentValue: "",
                    condition: true,
                },

            ]
            setfeaturesList(tempFeaturesList)
        }
    }
    const handleListLength = (value, list, indexNumber, directValue) => {
        var tempSettingsList = [...list];

        if (list == null) {
            //additionalSettingsList = [0, 0, 0, 0, 0, parseInt(value, 10)]
        } else {
            if (tempSettingsList.length > indexNumber) {
                tempSettingsList[indexNumber] = directValue
                    ? value
                    : parseInt(value, 10);
            } else {
                //Manage if length is less than indexNumber
                var i = tempSettingsList.length;
                for (i = tempSettingsList.length; i < indexNumber + 1; i++) {
                    if (i === indexNumber) {
                        tempSettingsList[i] = directValue
                            ? value
                            : parseInt(value, 10);
                    } else {
                        tempSettingsList.push(0);
                    }
                }
            }
        }
        return tempSettingsList;
    };

    const changeFeaturePermission = (feature, isChecked) => {
        let featuresList = schoolObject.attributes.featuresList ?? [];
        let additionalList = schoolObject.attributes.additionalSettings ?? [];
        let parentList = schoolObject.attributes.parentPermissions;
        let schoolObj = schoolObject;


        if (feature.colomn === "featureList") {
            if (feature.type == "videoEnabled") {
                schoolObj.set("videoEnabled", isChecked ? 1 : 0);
            }
            else if (feature.index == Constants.FEATURE_ATTENDANCE_INDEX) {
                let newVal = isChecked ? 1 : 0
                if (feature.type == "qr") {
                    newVal = isChecked ? 2 : 1
                }
                if (feature.type == "qr-self") {
                    newVal = isChecked ? 4 : 2
                }
                featuresList = [
                    ...handleListLength(
                        newVal,
                        featuresList,
                        Constants.FEATURE_ATTENDANCE_INDEX,
                        true
                    ),
                ];
            }

            else if (feature.index == Constants.FEATURE_CCTV_INDEX) {
                let newVal = isChecked ? 2 : 0
                if (feature.type == "cctv-with-attendance") {
                    newVal = isChecked ? 1 : 2
                }
                featuresList = [
                    ...handleListLength(
                        newVal,
                        featuresList,
                        Constants.FEATURE_CCTV_INDEX,
                        true
                    ),
                ];
            }

            else if (feature.index == Constants.FEATURE_CUSTOM_DIARY_INDEX) {
                let newVal = isChecked ? 2 : 0
                featuresList = [
                    ...handleListLength(
                        newVal,
                        featuresList,
                        Constants.FEATURE_CCTV_INDEX,
                        true
                    ),
                ];
            }

            else if (feature.index === Constants.FEATURE_VISITOR_INDEX) {
                let newVal = isChecked ? 1 : 0; 
                if (feature.type === "visitors-form-with-auth") {
                    newVal = isChecked ? 2 : 0; 
                }
                featuresList = [
                    ...handleListLength(
                        newVal,
                        featuresList,
                        Constants.FEATURE_VISITOR_INDEX,
                        true
                    ),
                ];
            }

            else {
                featuresList = [
                    ...handleListLength(
                        isChecked ? 1 : 0,
                        featuresList,
                        feature.index,
                        true
                    ),
                ];
            }
            schoolObj.set("featuresList", featuresList);

        } else if (feature.colomn === "additionalSettings") {
            additionalList = [
                ...handleListLength(
                    isChecked ? 1 : 0,
                    additionalList,
                    feature.index,
                    true
                ),
            ];
            schoolObj.set("additionalSettings", additionalList);

        } else if (feature.colomn === "parentPermissions") {
            parentList = [
                ...handleListLength(
                    isChecked ? 1 : 0,
                    parentList,
                    feature.index,
                    true
                ),
            ];
            schoolObj.set("parentPermissions", parentList);
        }
        //props.editSchool(schoolObj);
        schoolObj.save().then(
            (result) => {
                setSchoolObject(result);
                updateFeatureList(result);
            },
            (error) => {
            }
        );
    }

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                <small>No</small>
            </div>
        );
    };

    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                <small>Yes</small>
            </div>
        );
    };

    useEffect(() => {
        if (schoolObject) {
            setModalSubscriptionDate(schoolObject.attributes.subscriptionExpiryDate);
            setModalGracePeriod(schoolObject.attributes.gracePeriod);
        }
    }, [schoolObject]);

    const handleGrantAccess = (instituteId) => {

        Parse.Cloud.run("function_getSchoolAdminAccess", {
            instituteId: instituteId,

            gracePeriod: parseInt(modalGracePeriod),
        }).then((result) => {
            // console.log(result)
            setShowLoginAlert(true);
        },
            (error) => {
                console.log(error);
            }
        );
    }

    return (
        <div className='page-content'>
            {showLoginAlert && (
                <SweetAlert
                    success
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Login!!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="primary"
                    title="Success!"
                    onCancel={() => {
                        setShowLoginAlert(false);
                    }}
                    onConfirm={() => {
                        window.open('https://dashboard.liltriangle.com/login', '_blank');
                    }}
                >
                    <div>
                        There you go access granted
                    </div>
                </SweetAlert>
            )}
            <div className=" ">
                <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className=" list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4>{schoolObject && schoolObject.attributes.Name} (Tools)</h4>
                        </div>
                        <div className='ms-auto m-1'>
                            <Button color='success' className='btn' onClick={() => {
                                handleGrantAccess(schoolObject.attributes.instituteId)
                            }}>
                                Access
                            </Button>
                        </div>
                    </Col>
                </Row>

                {pgDisableAlert && (
                    <SweetAlert
                        warning
                        confirmBtnText="Close"
                        cancelBtnBsStyle="success"
                        title="Alert!"
                        onCancel={() => {
                            // setShowFeatureDisableAlert(false);
                        }}
                        onConfirm={() => setShowPGDisableAlert(false)}
                    >
                        <div>
                            {" "}
                            Please contact lilTriangle Tech Team Payment Gateway
                            <br /> <p className="text-primary mt-2">{"7676548853 or hello@liltriangle.com"}</p>
                        </div>
                    </SweetAlert>
                )}
                <div className=''>
                    <Card style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                        <CardBody>
                            <Table className="table-centered table-bordered table-sm">
                                <tbody>
                                    <tr >
                                        <td ><Label>subscription expiry date</Label> </td>
                                        <td className='text-center'>
                                            <div>
                                                <span>{getDate(schoolObject && schoolObject.attributes.subscriptionExpiryDate)}</span>
                                                {schoolObject && schoolObject.attributes.gracePeriod && (
                                                    <span style={{ marginLeft: '5px' }}>
                                                        <strong>+{schoolObject.attributes.gracePeriod} days</strong>
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                        <td className='text-center'><Label className='text-success ' style={{ cursor: "pointer" }} onClick={() => {
                                            setShowPopup(true)
                                        }}>
                                            <u>Edit</u></Label></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table className="table-centered table-bordered table-sm">
                                <tbody>
                                    <tr >
                                        <td ><Label>Lock Features</Label> </td>
                                        <td className='text-center'>
                                            <Switch
                                                height={25}
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                onColor="#626ed4"
                                                onChange={(isChecked) => {
                                                    setlockFeatures(isChecked);
                                                    if (schoolObject) {
                                                        schoolObject.set("lockFeatures", isChecked);
                                                        schoolObject.save().then();
                                                    }
                                                }}
                                                checked={schoolObject?.attributes?.lockFeatures ?? lockFeatures}
                                            />
                                        </td>

                                    </tr>
                                </tbody>
                            </Table>

                            <Table className="table-centered table-nowrap table-hover table-sm">
                                <thead className="thead-light" >
                                    <tr>
                                        <th scope="col">Features</th>
                                        <th scope="col">
                                            Sub features
                                        </th>
                                        <th scope="col">Permission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        featuresList.map((feature, key) =>
                                            <tr key={key} >
                                                <td><Label>{feature.feature}</Label> </td>
                                                <td>{feature.subFeature}</td>
                                                <td>
                                                    <Row>
                                                        <Col >
                                                            <Switch
                                                                height={25}
                                                                //width={0}
                                                                uncheckedIcon={<Offsymbol />}
                                                                checkedIcon={<OnSymbol />}
                                                                onColor="#626ed4"
                                                                onChange={(isChecked) => {
                                                                    changeFeaturePermission(feature, isChecked)
                                                                    if (feature.type == "Payment_gateway") {
                                                                        setShowPGDisableAlert(true);
                                                                    }
                                                                }}
                                                                checked={feature.isEnabled}
                                                                disabled={feature.isDisabled ?? false}
                                                            />
                                                        </Col>

                                                        <Col lg={8}>
                                                            {feature.type == "cctv" && <Link to={"/tools-school-settings-cameraDetails/" + schoolId} className=" waves-effect">
                                                                <span className='font-size-14 text-success '> Details</span>
                                                            </Link>}
                                                            {(feature.type == "Payment_gateway" && props.paymentOptionsForSchool && props.paymentOptionsForSchool.length > 0) && <Link to={"#"} className=" waves-effect"
                                                                onClick={() => {
                                                                    getPaymentOptionsForSchool()
                                                                    setShowPaymentGatewayOptions(true)
                                                                }}
                                                            >
                                                                <span className='font-size-14 text-success '> Details</span>
                                                            </Link>}
                                                            {feature.type == "qr-self" &&
                                                                <span className='font-size-14 text-success' style={{ 'cursor': "pointer"}} onClick={() => {
                                                                    setSelfCheckIn(!selfCheckin);
                                                                }}> Details</span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>)}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Modal
                size="md"
                isOpen={showPopup}
                toggle={() => {
                    setShowPopup(false)
                    setModalSubscriptionDate(undefined)
                    setModalGracePeriod(undefined)
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setShowPopup(false)
                        setModalSubscriptionDate(undefined)
                        setModalGracePeriod(undefined)
                    }}
                >
                    {" "}
                    Subscription date
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg="5">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Subscription end date :</Label>
                                    <DatePicker
                                        selected={modalSubscriptionDate}
                                        onChange={(date) => {
                                            setModalSubscriptionDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={"Select Date"}
                                        dateFormat="dd/MM/yyyy"
                                        showFullMonthYearPicker
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="5">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Grace Period:</Label>
                                    <Input className="form-control"
                                        type="number"
                                        min={0}
                                        id="gracePeriod"
                                        placeholder="Grace Period"
                                        required
                                        value={modalGracePeriod}
                                        onChange={(e) => {
                                            setModalGracePeriod(e.target.value)
                                        }}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>
                        <p className='text-danger'>Subscription Ends in <b>{subScriptionEndAfterGrace ? subScriptionEndAfterGrace : "--"}</b> days, and date is <b>{subscriptionEndDate ? getFormattedDate(subscriptionEndDate) : "--"}</b></p>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            handleSave();
                            setShowPopup(false)
                        }}
                    >
                        <i className="align-middle"></i>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                size="md"
                isOpen={showPaymentGatewayOptions}
                toggle={() => {
                    setShowPaymentGatewayOptions(false)
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setShowPaymentGatewayOptions(false)
                    }}
                >
                    {" "}
                    Payment Gateway
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <Table className="table mb-0 table-bordered">
                                <tbody>
                                    {props.paymentOptionsForSchool.map((val, key) => (
                                        <React.Fragment key={key}>
                                            <tr>
                                                <td colSpan="2">
                                                    <b>{`Payment Gateway ${key + 1} (${val.id}) ${val.attributes.paymentGateway ? "Active" : "Inactive"}`}</b>
                                                </td>
                                            </tr>
                                            {val.attributes.enabledOptions[Constants.GATEWAY_AIR_PAY_INDEX] > 0 && (
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Air Pay</th>
                                                    <td>{val.attributes.apMerchantId}</td>
                                                </tr>
                                            )}
                                            {val.attributes.enabledOptions[Constants.GATEWAY_RAZOR_PAY_INDEX] > 0 && (
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Razorpay Pay</th>
                                                    <td>{val.attributes.razorpayUsername}</td>
                                                </tr>
                                            )}
                                            {val.attributes.enabledOptions[Constants.GATEWAY_ONE_PAY_INDEX] > 0 && (
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>One Pay</th>
                                                    <td>{val.attributes.onepayMID}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setShowPaymentGatewayOptions(false)
                        }}
                    >
                        <i className="align-middle"></i>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
            <Modal size='md' isOpen={selfCheckin} toggle={() => {
                setSelfCheckIn(!selfCheckin);
            }}>
                <ModalHeader toggle={() => {
                    setSelfCheckIn(!selfCheckin);
                }}>
                    Disable Self checkin
                </ModalHeader>
                <ModalBody>
                    <div className='m-2'>
                        <Row>
                            <Col lg={7}>
                                <Label>
                                    Disable self checkin for parent:
                                </Label>
                            </Col>
                            <Col>
                                <Switch
                                    height={25}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={(isChecked) => {
                                        setSelfCheckInForParent(isChecked);
                                        if (schoolObject) {
                                            schoolObject.attributes.additionalSettings[15] = isChecked ? 1 : 0;
                                            schoolObject.save().then();
                                        }
                                    }}
                                    checked={schoolObject && schoolObject.attributes && schoolObject.attributes.additionalSettings[15] == 1 || selfCheckinForParent}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className='m-2'>
                        <Row>
                            <Col lg={7}>
                                <Label>
                                    Disable self checkin for teacher:
                                </Label>
                            </Col>
                            <Col>
                                <Switch
                                    height={25}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={(isChecked) => {
                                        setSelfCheckInForTeacher(isChecked);
                                        if (schoolObject) {
                                            schoolObject.attributes.additionalSettings[15] = isChecked ? 2 : 0;
                                            schoolObject.save().then();
                                        }
                                    }}
                                    checked={schoolObject && schoolObject.attributes && schoolObject.attributes.additionalSettings[15] == 2 || selfCheckinForTeacher}
                                />
                            </Col>
                        </Row>
                    </div>

                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools, paymentOptionsForSchool } = state.School;
    return { selectedSchool, schools, paymentOptionsForSchool };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getPaymentOptionsForSchool
})(schoolSettings)));
