import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventoryCategoriesHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryCategory_helper";
import * as actionType from "./actionTypes";
import * as InventoryCategoryAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getInventoryCategoriesForOwnerId({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_CATEGORIES_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventoryCategoriesHelper.getInventoryCategoriesForOwnerId, ownerId, ownerType, null, true);
        yield put(InventoryCategoryAction.updateInventoryCategories(localResponse));


        const serverResponse = yield call(InventoryCategoriesHelper.getInventoryCategoriesForOwnerId, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventoryCategoryAction.updateInventoryCategories(serverResponse));


            var nonDeleted = serverResponse.filter(
                (inventoryCategory) =>
                    inventoryCategory.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventoryCategory) =>
                    inventoryCategory.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* deleteAInventoryCategory({ payload: { inventoryCategory } }) {
    try {
      yield put(InventoryCategoryAction.updateDeleteInventoryCategory(inventoryCategory));
      const response = yield call(
        InventoryCategoriesHelper.deleteInventoryCategoryFromParse,inventoryCategory
      );
      if (response === "error") {
        inventoryCategory.set("isDisabled", undefined);
        yield put(InventoryCategoryAction.updateDeleteInventoryCategory(inventoryCategory));
      } else
      if (response) {
        toastr.success("Inventory Category successfully Disabled", { timeOut: 1000 });
        response.unPin();
      }else{

      }

    } catch (error) {
     
    }
  }

export function* watchGetInventoryCategoriesForOwnerId() {
    yield takeEvery(
        actionType.GET_INVENTORY_CATEGORIES,
        getInventoryCategoriesForOwnerId
    );
}

export function* watchDeleteAInventoryCategory() {
    yield takeEvery(
        actionType.DELETE_INVENTORY_CATEGORY,
        deleteAInventoryCategory
    );
}


function* InventoryCategoriesSaga() {
    yield all([
        fork(watchGetInventoryCategoriesForOwnerId),
        fork(watchDeleteAInventoryCategory)
    ]);
}
export default InventoryCategoriesSaga;

