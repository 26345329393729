import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Card,
  Form,
  Button,
  CardText,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  FormGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  ButtonDropdown,
  Dropdown,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Moment from "moment";
import * as WhiteLable from "../../components/Common/WhiteLable";
import FeedbackForm from "./feedbackFormPopup";
import Licence from '../../components/Common/Licence'
import {
  getAllDeletedKidsForSchoolIdOnlyForArchives,
  getParentsForFamilyIds,
  getArchiveGroupsForSchoolId,
  getClassroomsForSchoolId,
  saveGroupMaps,
  getAllDeletedKidsForIds,
  getGroupMapsForGroupId,
  getDeletedTeachersForSchoolId,
  saveKids,
  saveTeachers,
  clearDeletedKids,
  setSelecetdSchool

} from "../../store/actions";
import * as Constants from "../../Constents";
import { matchPath } from "react-router";
import Parse from "parse";

const ArchiveDetailPage = (props) => {
  const matchAllStudent = matchPath(props.history.location.pathname, {
    path: "/archivedetailstudent/:id",
    exact: true,
    strict: false,
  });

  const matchArchiveGroup = matchPath(props.history.location.pathname, {
    path: "/archivedetail/:id/:groupId/:groupName",
    exact: true,
    strict: false,
  });
  const matchAllStaff = matchPath(props.history.location.pathname, {
    path: "/archivedetailstaff/:id",
    exact: true,
    strict: false,
  });

  var groupId = null;
  var groupName = "";
  var schoolId;

  if (matchArchiveGroup) {
    schoolId = matchArchiveGroup.params.id;
    groupName = matchArchiveGroup.params.groupName;
    groupId = matchArchiveGroup.params.groupId;
  }
  if (matchAllStudent) {
    schoolId = matchAllStudent.params.id;
  }
  if (matchAllStaff) {
    schoolId = matchAllStaff.params.id;
  }


  const [activeTab, setactiveTab] = useState("1");

  const [drp_link, setdrp_link] = useState(false);
  const [arp_link, setarp_link] = useState(false);
  const [brp_link, setbrp_link] = useState(false);
  const [crp_link, setcrp_link] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [loding, setLoading] = useState(false);

  const [filterList, setFilterList] = useState([
    "All",
    "Passed Out",
    "Drop Out",
    "Improper Data",
    "Incomplete Documents",
  ]);
  const [changeStatusOptions, setChangeStatusOptions] = useState([
    "Passed Out",
    "Drop Out",
    "Improper Data",
    "Incomplete Documents",
  ]);
  const [selectedChangeStatus, setSelectedChangeStatus] = useState(undefined);

  const [selectedFilter, setSelectedFilter] = useState("All");

  const [classroomList, setClassroomList] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState(undefined);

  const [selectAll, setSelectAll] = useState(false);

  const [selectedKidsList, setSelectedKidsList] = useState([]);
  const [selectedStaffList, setSelectedStaffList] = useState([]);

  const [archiveGroupsList, setArchiveGroupsList] = useState([]);
  const [selectedArchiveGroup, setSelectedArchiveGroup] = useState(undefined);

  const [showMoveToGroupAlert, setShowMoveToGroupAlert] = useState(false);
  const [showChangeStatusAlert, setShowChangeStatusAlert] = useState(false);

  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showStaffRestoreAlert, setShowStaffRestoreAlert] = useState(false);

  var displayList = [];
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [kidDetailsList, setKidDetailsList] = useState([]);
  const [staffDetailsList, setStaffDetailsList] = useState([]);
  const [allKids, setAllKids] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [feedbackFormPopup, setFeedbackFormPopup] = useState(false)
  const [kidObject, setKidObject] = useState()
  const [motherMobileNo, setMotherMobileNo] = useState()
  const [fatherMobileNo, setfatherMobileNo] = useState()

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    props.clearDeletedKids();
    if (groupId != null) {
      props.getGroupMapsForGroupId(groupId);
      props.getClassroomsForSchoolId(schoolId);
      props.getArchiveGroupsForSchoolId(schoolId);
    }
  }, [groupId]);

  useEffect(() => {
    props.clearDeletedKids();
    if (matchAllStudent) {
      props.getAllDeletedKidsForSchoolIdOnlyForArchives(schoolId);
      props.getClassroomsForSchoolId(schoolId);
      props.getArchiveGroupsForSchoolId(schoolId);
    }
  }, [schoolId]);

  useEffect(() => {
    if (matchAllStaff) {
      props.getDeletedTeachersForSchoolId(schoolId);
    }
  }, [schoolId]);

  useEffect(() => {
    // console.log("deletedKids" + props.deletedKids);
    // console.log("allkids" + allKids.length);
    if (props.deletedKids) {
      setAllKids(props.deletedKids);
      // console.log("successfully got deleted kids");
      let familyIds = [];
      for (var j = 0; j < props.deletedKids.length; j++) {
        // console.log(props.deletedKids[j].attributes.SchoolID);
        familyIds.push(props.deletedKids[j].attributes.FamilyID);
      }
      if (familyIds.length > 0) {
        props.getParentsForFamilyIds(familyIds);
      }
    }
  }, [props.deletedKids]);

  useEffect(() => {
    if (props.deletedTeachers) {
      let tempList = [];
      for (var i = 0; i < props.deletedTeachers.length; i++) {
        let tempObject = {
          staffName: props.deletedTeachers[i].attributes.Name,
          designation: getDesignation(props.deletedTeachers[i].attributes.Role),
          email: props.deletedTeachers[i].attributes.EMail,
          phone: props.deletedTeachers[i].attributes.PhoneNumber,
          staffParseObject: props.deletedTeachers[i],
        };

        tempList.push(tempObject);
      }
      setStaffDetailsList(tempList);
    }
  }, [props.deletedTeachers]);

  const getDesignation = (role) => {
    if (role === 3) {
      return props.t("Director");
    } else if (role === 2) {
      return props.t("Center Head");
    } else {
      return props.t("Teacher");
    }
  };


  useEffect(() => {
    if (props.archiveGroups && props.archiveGroups.length > 0) {
      setArchiveGroupsList(props.archiveGroups);
    }
  }, [props.archiveGroups]);

  useEffect(() => {
    let kidIds = [];
    if (props.groupmaps && groupId) {
      for (var i = 0; i < props.groupmaps.length; i++) {
        if (props.groupmaps[i].attributes.groupId === groupId) {
          kidIds.push(props.groupmaps[i].attributes.userId);
        }
      }
      if (kidIds.length > 0) {
        props.getAllDeletedKidsForIds(kidIds);
      } else {
        setIsLoading(false)
        setKidDetailsList([]);
      }
    }
  }, [props.groupmaps]);


  useEffect(() => {
    if (matchAllStaff) {
      setIsLoading(props.deletedTeachersLoading);
    } else {
      setIsLoading(props.isLoading);
    }

  }, [props.isLoading, props.deletedTeachersLoading]);



  useEffect(() => {
    if (
      allKids.length > 0 &&
      props.parents
    ) {
      setClassroomList(props.classrooms);

      let kidDetailList = [];

      for (var j = 0; j < allKids.length; j++) {
        let kidDetailObject = {};
        let classroom = [];
        if (
          props.classrooms &&
          props.classrooms.length > 0 &&
          allKids[j].attributes.ClassRoomID &&
          allKids[j].attributes.ClassRoomID.length > 0
        ) {
          classroom = props.classrooms.filter(
            (classroomTemp) =>
              classroomTemp.id === allKids[j].attributes.ClassRoomID
          );
        }

        kidDetailObject = {
          kidName: allKids[j].attributes.Name,
          kidDeletedOn: allKids[j].attributes.deletedOn ? Moment(allKids[j].attributes.deletedOn).format("DD MMM YYYY") : " ",
          deletedStatus: allKids[j].attributes.deletedStatus,
          kidParseObject: allKids[j],
        };
        if (classroom != undefined && classroom.length > 0) {
          kidDetailObject.classroomObject = classroom[0];
        }

        for (var i = 0; i < props.parents.length; i++) {
          if (
            props.parents[i].attributes.FamilyID ===
            allKids[j].attributes.FamilyID
          ) {
            if (
              props.parents[i].attributes.Relation &&
              props.parents[i].attributes.Relation.toLowerCase() === "father"
            ) {
              kidDetailObject.fatherName = props.parents[i].attributes.Name;
              kidDetailObject.fatherMobile =
                props.parents[i].attributes.PhoneNumber;
              kidDetailObject.fatherEmail = props.parents[i].attributes.EMail;
            } else {
              kidDetailObject.motherName = props.parents[i].attributes.Name;
              kidDetailObject.motherMobile =
                props.parents[i].attributes.PhoneNumber;
              kidDetailObject.motherEmail = props.parents[i].attributes.EMail;
            }
          }
        }
        kidDetailList.push(kidDetailObject);
      }
      setKidDetailsList(kidDetailList);
    } else {
      setKidDetailsList([]);
    }
  }, [props.parents, allKids, props.classrooms]);


  const handleDeleteGroup = () => {
    if (groupToDelete != null) {
      var temp = groupToDelete;
      temp.set("deleted", true);
      props.deleteGroup(temp);
      setShowDeleteAlert(false);
    }
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const getStudentName = () => {
    let studentName = "students";
    if (selectedKidsList.length === 1) {
      studentName = selectedKidsList[0].kidName;
    }
    return studentName;
  };

  const handleChangeStatus = () => {
    let kidObjectsToSave = [];
    let tempKidDetailList = [...kidDetailsList];

    for (var i = 0; i < selectedKidsList.length; i++) {
      let index = tempKidDetailList.indexOf(selectedKidsList[i]);
      let tempStatus = undefined;

      let tempObject = selectedKidsList[i].kidParseObject;
      if (selectedChangeStatus === "Passed Out") {
        tempObject.set("deletedStatus", 1);
        tempStatus = 1;
      } else if (selectedChangeStatus === "Drop Out") {
        tempObject.set("deletedStatus", 2);
        tempStatus = 2;
      } else if (selectedChangeStatus === "Improper Data") {
        tempObject.set("deletedStatus", 3);
        tempStatus = 3;
      } else if (selectedChangeStatus === "Incomplete Documents") {
        tempObject.set("deletedStatus", 4);
        tempStatus = 4;
      }
      
      kidObjectsToSave.push(tempObject);
      tempKidDetailList[index] = {
        ...tempKidDetailList[index],
        deletedStatus: tempStatus,
        kidParseObject: tempObject,
      };
    }
    setShowChangeStatusAlert(false);
    props.saveKids(kidObjectsToSave);
    setSelectedKidsList([]);
    setKidDetailsList(tempKidDetailList);
    setSelectAll(false);

    toastr.success('Status changed successfully', { timeOut: 1500 })
    //Save These objects.
  };

  const handleStaffRestore = () => {
    let staffObjectsToSave = [];
    let tempStaffDetailsList = [...staffDetailsList];

    for (var i = 0; i < selectedStaffList.length; i++) {
      let index = tempStaffDetailsList.indexOf(selectedStaffList[i]);

      let tempObject = selectedStaffList[i].staffParseObject;

      tempObject.set("deleted", false);
      staffObjectsToSave.push(tempObject);
      removeElement(tempStaffDetailsList, tempStaffDetailsList[index]);
    }
    setShowStaffRestoreAlert(false);
    props.saveTeachers(staffObjectsToSave);
    setStaffDetailsList(tempStaffDetailsList);

    setSelectedStaffList([]);

    toastr.success('Staff restored successfully', { timeOut: 1500 })
  };

  const handleRestore = () => {
    let kidObjectsToSave = [];
    let tempKidDetailList = [...kidDetailsList];
    let kidIds = [];

    for (var i = 0; i < selectedKidsList.length; i++) {
      kidIds.push(selectedKidsList[i].kidParseObject.id);
      let index = tempKidDetailList.indexOf(selectedKidsList[i]);

      let tempObject = selectedKidsList[i].kidParseObject;
      tempObject.set("Deleted", false);
      tempObject.set("ClassRoomID", selectedClassroom.id);
      tempObject.unset('daycareClassId');
      tempObject.unset('isDaycareChild');
      kidObjectsToSave.push(tempObject);
      removeElement(tempKidDetailList, tempKidDetailList[index]);
    }

    let groupmapsToDelete = props.groupmaps.filter((gm) => {
      return kidIds.includes(gm.attributes.userId);
    });
    for (var i = 0; i < groupmapsToDelete.length; i++) {
      groupmapsToDelete[i].set("deleted", true);
    }
    props.saveGroupMaps(groupmapsToDelete);
    setKidDetailsList(tempKidDetailList);
    setShowRestoreAlert(false);
    // console.log(kidObjectsToSave);
    props.saveKids(kidObjectsToSave);

    setSelectedKidsList([]);
    setSelectAll(false);

    toastr.success('Student restored successfully', { timeOut: 1500 })
    //Save These objects.
  };

  const handleMoveToGroup = () => {
    if (groupId === selectedArchiveGroup.id && matchArchiveGroup) {
      setSelectedKidsList([]);
      setSelectAll(false);
      setShowMoveToGroupAlert(false);

      toastr.warning('cannot move students to same group', { timeOut: 1500 })

    } else if (matchArchiveGroup) {
      let tempIds = []
      let groupMapsToSave = [];

      let tempKidDetailList = [...kidDetailsList]

      for (var i = 0; i < selectedKidsList.length; i++) {
        tempIds.push(selectedKidsList[i].kidParseObject.id)
        let index = tempKidDetailList.indexOf(selectedKidsList[i]);
        removeElement(tempKidDetailList, tempKidDetailList[index])
      }

      let selectedGroupMaps = props.groupmaps.filter(
        (groupmap) => tempIds.includes(groupmap.attributes.userId));
      for (var i = 0; i < selectedGroupMaps.length; i++) {

        let groupMapObject = selectedGroupMaps[i]
        groupMapObject.set("groupId", selectedArchiveGroup.id);
        groupMapsToSave.push(groupMapObject);


      }

      setShowMoveToGroupAlert(false);
      setKidDetailsList(tempKidDetailList)
      props.saveGroupMaps(groupMapsToSave);
      setSelectedKidsList([]);
      setSelectAll(false);

      toastr.success('Student moved successfully', { timeOut: 1500 })
    }
    else {
      let groupMapsToSave = [];

      let tempKidDetailList = [...kidDetailsList]
      for (var i = 0; i < selectedKidsList.length; i++) {
        const GroupMap = Parse.Object.extend("GroupMap");
        let groupMapObject = new GroupMap();
        groupMapObject.set("groupId", selectedArchiveGroup.id);
        groupMapObject.set("userId", selectedKidsList[i].kidParseObject.id);
        groupMapObject.set("userType", 1);
        groupMapsToSave.push(groupMapObject);
        let index = tempKidDetailList.indexOf(selectedKidsList[i]);

        removeElement(tempKidDetailList, tempKidDetailList[index])

      }
      setShowMoveToGroupAlert(false);
      setKidDetailsList(tempKidDetailList)
      props.saveGroupMaps(groupMapsToSave);
      setSelectedKidsList([]);
      setSelectAll(false);

      toastr.success('Student moved successfully', { timeOut: 1500 })
    }


    //Save These objects.
  };

  const getTitle = () => {
    let valueToReturn = "";
    if (matchAllStaff) {
      valueToReturn = "All Staff";
      if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {
        valueToReturn = "Coordinators";
      }
    } else if (matchAllStudent) {
      valueToReturn = "All Students";
      if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {
        valueToReturn = "All Participants";
      }
    } else if (matchArchiveGroup) {
      valueToReturn = groupName;
    }

    return valueToReturn;
  };

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const getDisplayList = () => {
    let tempList = [...kidDetailsList];

    let listToReturn = tempList.filter((detail) => {
      if (selectedFilter === "Passed Out") {
        return detail.deletedStatus === 1;
      } else if (selectedFilter === "Drop Out") {
        return detail.deletedStatus === 2;
      } else if (selectedFilter === "Improper Data") {
        return detail.deletedStatus === 3;
      }else if (selectedFilter === "Incomplete Documents") {
        return detail.deletedStatus === 4;
      } else if (selectedFilter === "All") {
        return true;
      }
    });
    listToReturn = getSearchFilteredList(listToReturn, 1)
    displayList = listToReturn;
    return listToReturn;
  };

  function handelSelectAll() {
    if (displayList.length === 0 && !matchAllStaff) {
      return
    }
    if (staffDetailsList.length === 0 && matchAllStaff) {
      return
    }

    if (selectAll) {
      if (matchAllStaff) {
        setSelectAll(false);
        var list = [];
        setSelectedStaffList(list);
      } else {
        setSelectAll(false);
        var list = [];
        setSelectedKidsList(list);
      }

    } else {

      if (matchAllStaff) {
        var list = [];
        let filteredList = getSearchFilteredList(staffDetailsList, 2)
        for (const i in filteredList) {
          list.push(filteredList[i]);
        }
        setSelectAll(true);
        setSelectedStaffList(list);
      } else {
        var list = [];
        for (const i in displayList) {
          list.push(displayList[i]);
        }
        setSelectAll(true);
        setSelectedKidsList(list);
      }
    }

  }

  const getSearchFilteredList = (list, type) => {

    let listToReturn
    if (list && list.length > 0 && searchString && searchString.length > 0) {
      listToReturn = list.filter((item) => {
        if (type === 2) {
          return item.staffName.toLowerCase().includes(searchString.toLowerCase())
        } else if (type === 1) {
          return item.kidName.toLowerCase().includes(searchString.toLowerCase())
        }
      }
      )
    } else {
      listToReturn = [...list]
    }
    return listToReturn
  }
  // console.log(kidObject)

  const getFeedbackShareLink = () => {
    if (kidObject) {
      return (
        WhiteLable.getHost() + "/exit-feedback-form/" + kidObject.id
      )
    }
  }

  function copyFeedbackShareLink(str, event) {
    document.oncopy = function (event) {
      event.clipboardData.setData("Text", str);
      event.preventDefault();
    };
    document.execCommand("Copy");
    document.oncopy = undefined;
    toastr.success("Link copied to clipboard", { timeOut: 1000 });
  }
  // console.log(fatherMobileNo);
  // console.log(motherMobileNo)

  return (
    <React.Fragment>
      <div className="page-content">


        {/* <Row className="navbar-header p-0">
          <Col className="" >

            <div className="navbar-header p-0">

              <div style={{ cursor: 'pointer' }} onClick={() => { window.history.back(); }}>
                <ul className="verti-timeline list-unstyled">
                  <div >
                    <i className="bx bx-chevron-left h1 text-primary"></i>
                  </div>
                </ul>
              </div>

              <h4>ARCHIVES : {getTitle()}</h4>

            </div>
          </Col>
          <Col className=" float-right mr-3 mb-2">

            <div className="search-box mr-2 float-right">
              <div className="position-relative">
                <Input type="text" className="form-control border-0" value={searchString} placeholder={matchAllStaff ? "Search Staff..." : "Search Student..."} onChange={(e) => setSearchString(e.target.value)} />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>

          </Col>

        </Row> */}


        {!isLoading && (
          <Container fluid>
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=''>
                  <h4 className="mt-2">ARCHIVES : {getTitle()}</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="search-box mr-2">
                  <div className="position-relative">
                    <Input type="text" className="form-control border-0" value={searchString} placeholder={matchAllStaff ? "Search Staff..." : "Search Student..."} onChange={(e) => setSearchString(e.target.value)} />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </Col>
            </Row>

            <Card>
              {showChangeStatusAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowChangeStatusAlert(false);
                  }}
                  onConfirm={() => handleChangeStatus()}
                >
                  Are you sure to change status for this selected students?
                  <br></br>
                </SweetAlert>
              )}
              {showDeleteAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowDeleteAlert(false);
                  }}
                  onConfirm={() => handleDeleteGroup()}
                >
                  Are you sure to delete this group?<br></br>
                </SweetAlert>
              )}
              {showStaffRestoreAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowStaffRestoreAlert(false);
                  }}
                  onConfirm={() => handleStaffRestore()}
                >
                  Are you sure to restore selected Staff?<br></br>
                </SweetAlert>
              )}
              {showRestoreAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowRestoreAlert(false);
                  }}
                  onConfirm={() => {
                    // handleRestore();
                    setShowLimitModal(true)
                    setLoading(true);
                    setShowRestoreAlert(false);
                  }}
                >
                  {`Are you sure to restore ${getStudentName()} to ${selectedClassroom.attributes.Name
                    }`}
                  <br></br>
                </SweetAlert>
              )}
              {showMoveToGroupAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowMoveToGroupAlert(false);
                  }}
                  onConfirm={() => handleMoveToGroup()}
                >
                  {`Are you sure to move ${getStudentName()} to ${selectedArchiveGroup.attributes.name
                    }`}
                  <br></br>
                </SweetAlert>
              )}
              <CardBody>


                <div className="table">
                  {matchAllStaff && (
                    <Row className="bg-light mb-3">
                      <Col lg={2} md={2} xs={6} className="ml-3 mt-3 mb-3">
                        <button
                          className="text-center form-control bg-white border border-light btn btn-sm"
                          onClick={() => {
                            if (selectedStaffList.length > 0) {
                              setShowStaffRestoreAlert(true);
                            } else {
                              toastr.error(
                                props.t("Please select atleast one staff"),
                                { timeOut: 1500 }
                              );
                            }
                          }}
                        >
                          Restore
                        </button>
                      </Col>
                    </Row>
                  )}
                  {!matchAllStaff && props.selectedSchool && props.selectedSchool.attributes.schoolType != 10 && (
                    <Row className="bg-light mb-3">
                      <Col lg={2} md={2} xs={6} className="ml-3 mt-3 mb-3">
                        <h6 className="text-center">Restore To</h6>
                        <ButtonDropdown isOpen={drp_link} toggle={() => { setdrp_link(!drp_link) }} style={{ width: "100%" }}>
                          <DropdownToggle caret className="form-control bg-white border border-light btn btn-sm">
                            <span className="text-dark" style={{ width: "100%" }}>
                              Classes <i className="mdi mdi-chevron-down"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-right">
                            {classroomList.map((filter, key) => (
                              <DropdownItem key={key} onClick={() => {
                                if (selectedKidsList.length > 0) {
                                  setSelectedClassroom(filter);
                                  setShowRestoreAlert(true);
                                } else {
                                  toastr.error(
                                    props.t(
                                      "Please select atleast one student"
                                    ),
                                    { timeOut: 1500 }
                                  );
                                }
                              }}>
                                {filter.attributes.Name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </Col>
                      <Col lg={2} md={2} xs={6} className="ml-0 mt-3 mb-3">
                        <h6 className="text-center">Filter By</h6>
                        <Dropdown isOpen={arp_link} toggle={() => { setarp_link(!arp_link) }} style={{ width: "100%" }}>
                          <DropdownToggle caret className="form-control bg-white border border-light btn btn-sm">
                            <span className="text-dark" style={{ width: "100%" }}>
                              {selectedFilter}
                              <i className="mdi mdi-chevron-down"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu end>
                            {filterList.map((filter, key) => (
                              <DropdownItem key={key} onClick={() => setSelectedFilter(filter)}>
                                {filter}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                      <Col lg={2} md={2} xs={6} className="ml-0 mt-3 mb-3">
                        <h6 className="text-center">Change Status</h6>
                        <ButtonDropdown isOpen={brp_link} toggle={() => { setbrp_link(!brp_link) }} style={{ width: "100%" }}>
                          <DropdownToggle caret className="form-control bg-white border border-light btn btn-sm">
                            <span className="text-dark" style={{ width: "100%" }}>
                              Change Status
                              <i className="mdi mdi-chevron-down"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu end>
                            {changeStatusOptions.map((filter, key) => (
                              <DropdownItem key={key} onClick={() => {
                                if (selectedKidsList.length > 0) {
                                  setSelectedChangeStatus(filter);
                                  setShowChangeStatusAlert(true);
                                } else {
                                  toastr.error(props.t("Please select at least one student"), { timeOut: 1500 });
                                }
                              }}>
                                {filter}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </Col>
                      <Col lg={2} md={2} xs={6} className="ml-0 mt-3 mb-3">
                        <h6 className="text-center">Move To</h6>
                        <ButtonDropdown isOpen={crp_link} toggle={() => { setcrp_link(!crp_link) }} style={{ width: "100%" }}>
                          <DropdownToggle caret className="form-control bg-white border border-light btn btn-sm">
                            <span className="text-dark" style={{ width: "100%" }}>
                              Archive Groups <i className="mdi mdi-chevron-down"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu end>
                            {archiveGroupsList.map((value, key) => (
                              <DropdownItem key={key} onClick={() => {
                                if (selectedKidsList.length > 0) {
                                  setShowMoveToGroupAlert(true);
                                  setSelectedArchiveGroup(value);
                                } else {
                                  toastr.error(props.t("Please select at least one student"), { timeOut: 1500 });
                                }
                              }}>
                                {value.attributes.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </Col>
                    </Row>)}
                  {!matchAllStaff && (
                    <div className="table-responsive">
                      {props.selectedSchool && props.selectedSchool.attributes.schoolType == 10 ?
                        <table className="table table-centered  mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th>{props.t("Participant Name	")}</th>
                              <th>{props.t("Organization Name	")}</th>
                              <th>{props.t("Email")}</th>
                              <th>{props.t("Phone Number")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getDisplayList().map((detail, key) => (
                              <tr key={key}>


                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <Label className="text-dark">
                                      {" "}
                                      {detail.kidName}
                                    </Label>
                                  </h5>
                                </td>

                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <Label className="text-dark">
                                      {" "}
                                      {detail.kidParseObject.attributes.workplace}
                                    </Label>
                                  </h5>
                                </td>
                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <Label className="text-dark">
                                      {" "}
                                      {detail.kidParseObject.attributes.email}
                                    </Label>
                                  </h5>
                                </td>
                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <Label className="text-dark">
                                      {" "}
                                      {detail.kidParseObject.attributes.phoneNumber}
                                    </Label>
                                  </h5>
                                </td>

                              </tr>
                            ))}
                          </tbody>

                        </table>
                        :
                        <table className="table table-centered  mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th style={{ width: "20px" }}>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckboxInArchive"
                                    checked={selectAll}
                                    onChange={() => {
                                      handelSelectAll();
                                    }}
                                  />
                                </div>
                              </th>

                              <th>{props.t("Name") + ` (${getDisplayList().length})`}</th>
                              <th>{props.t("Class Name")}</th>
                              <th>{props.t("Father's Details")}</th>
                              <th>{props.t("Mother's Details")}</th>
                              <th width={'130px'}>{props.t("Deleted On")}</th>
                              <th style={{ textAlign: "center" }}>{props.t("Transfer Certificate")}</th>
                              <th style={{ textAlign: "center" }}>{props.t("Feedback")}</th>

                            </tr>
                          </thead>
                          <tbody>
                            {getDisplayList().map((detail, key) => (
                              <tr key={key}>
                                <td>
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={key}
                                      checked={selectedKidsList.includes(detail)}
                                      onChange={() => {
                                        if (selectedKidsList.includes(detail)) {
                                          let tempList = [...selectedKidsList];
                                          removeElement(tempList, detail);
                                          setSelectedKidsList(tempList);
                                        } else {
                                          let tempList = [...selectedKidsList];
                                          tempList.push(detail);
                                          setSelectedKidsList(tempList);
                                        }
                                      }}
                                    />
                                    <label className="custom-control-label" htmlFor={key}></label>
                                  </div>

                                </td>
                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <Link to={`/studentProfile/${schoolId}/${detail.kidParseObject.id}`} className="">
                                      {" "}
                                      {detail.kidName}
                                    </Link><br/>
                                    <small>{detail.kidParseObject.attributes.admissionNumber}</small>
                                  </h5>
                                </td>
                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <Label className="text-dark">
                                      {" "}
                                      {detail.classroomObject
                                        ? detail.classroomObject.attributes.Name
                                        : ""}
                                    </Label>
                                  </h5>
                                </td>
                                <td>
                                  <Label className="mb-0">{detail.fatherName}</Label>
                                  <p className="mb-0">{detail.fatherMobile}</p>
                                  <p className="mb-0">{detail.fatherEmail}</p>
                                </td>
                                <td>
                                  <Label className="mb-0">{detail.motherName}</Label>
                                  <p className="mb-0">{detail.motherMobile}</p>
                                  <p className="mb-0">{detail.motherEmail}</p>
                                </td>
                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <Label className="text-dark">
                                      {detail.kidDeletedOn}
                                    </Label>
                                  </h5>
                                </td>
                                <td>
                                  <div className="flex-fill text-center ">
                                    {(
                                      <Link
                                        to={"/transfer-certificate/" + schoolId + "/" + detail.kidParseObject.id}
                                        id={"TransferCertificate" + key}

                                      >
                                        <i className="font-size-22 bx bx-file"></i>
                                      </Link>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <h5 className="font-size-14 mb-1">
                                    <p className="text-success" style={{ cursor: 'pointer' }} onClick={() => {
                                      setFeedbackFormPopup(true);
                                      setKidObject(detail.kidParseObject)
                                      setfatherMobileNo(detail.motherMobile)
                                      setfatherMobileNo(detail.fatherMobile)
                                    }}>
                                      {detail.kidParseObject.attributes.exitFeedbackSubmittedOn ? "Submitted" : "Share"}
                                    </p>
                                  </h5>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>}
                    </div>
                  )}
                  {
                    getDisplayList().length === 0 && !matchAllStaff && (
                      <Col className="mt-5">
                        <h5 className="text-center">{props.selectedSchool && props.selectedSchool.attributes.schoolType == 10 ? props.t('No Participents') : props.t('No Students')} </h5>
                      </Col>
                    )
                  }
                  {matchAllStaff && (
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "20px" }}>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck1"
                                checked={selectAll}
                                onChange={() => {
                                  handelSelectAll();
                                }}
                              />
                              <label
                                className="custom-control-label"
                                onClick={() => {
                                  // handelSelectAll();
                                }}
                              ></label>
                            </div>
                          </th>
                          <th>{props.t("Name")}</th>
                          <th>{props.t("Designation")}</th>
                          <th>{props.t("Phone")}</th>
                          <th>{props.t("Email")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getSearchFilteredList(staffDetailsList, 2).map((detail, key) => (
                          <tr key={key}>
                            <td>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={key}
                                  checked={selectedStaffList.includes(detail)}
                                  onChange={() => {
                                    if (selectedStaffList.includes(detail)) {
                                      let tempList = [...selectedStaffList];
                                      removeElement(tempList, detail);
                                      setSelectedStaffList(tempList);
                                    } else {
                                      let tempList = [...selectedStaffList];
                                      tempList.push(detail);
                                      setSelectedStaffList(tempList);
                                    }
                                  }}
                                />
                                <label
                                  className="custom-control-label"

                                ></label>
                              </div>
                            </td>

                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Label className="text-dark">
                                  {detail.staffName}
                                </Label>
                              </h5>
                            </td>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Label className="text-dark">
                                  {detail.designation}
                                </Label>
                              </h5>
                            </td>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Label className="text-dark">
                                  {detail.phone}
                                </Label>
                              </h5>
                            </td>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Label className="text-dark">
                                  {detail.email}
                                </Label>
                              </h5>
                            </td>
                          </tr>
                        ))}


                      </tbody>
                    </table>

                  )}
                  {
                    staffDetailsList.length === 0 && matchAllStaff && (
                      <Col className="mt-5">
                        <h5 className="text-center">{props.selectedSchool && props.selectedSchool.attributes.schoolType == 10 ? props.t('No Coordinators') : props.t('No Staff')} </h5>
                      </Col>
                    )
                  }
                </div>
              </CardBody>
            </Card>
          </Container>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading details...{" "}
                </Label>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {kidObject && <FeedbackForm
        feedbackFormPopup={feedbackFormPopup}
        closeFeedbackFormPopup={() => {
          setFeedbackFormPopup(!feedbackFormPopup)
        }}
        kidObject={kidObject}
        fatherMobileNo={fatherMobileNo}
        motherMobileNo={motherMobileNo}
      />}
      <Licence  
          showopup ={showLimitModal}
          handelAdd={()=>{
            handleRestore();
            setShowLimitModal(false);
            setLoading(false);
          }}
          addCount={selectedKidsList.length}
          handelClose={()=>{
            setShowLimitModal(false);
            setLoading(false);
          }}
      >
      </Licence>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { deletedKids, kids, isLoading } = state.Kid;
  const { parents } = state.Parent;
  const { classrooms } = state.Classroom;
  const { archiveGroups } = state.Group;
  const { groupmaps } = state.GroupMap;
  const { deletedTeachers, deletedTeachersLoading } = state.Teacher;
  const { selectedSchool } = state.School;

  return {
    deletedKids,
    parents,
    classrooms,
    archiveGroups,
    groupmaps,
    kids,
    deletedTeachers,
    isLoading,
    deletedTeachersLoading,
    selectedSchool,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getClassroomsForSchoolId,
      getAllDeletedKidsForSchoolIdOnlyForArchives,
      getParentsForFamilyIds,
      getArchiveGroupsForSchoolId,
      saveGroupMaps,
      getGroupMapsForGroupId,
      getAllDeletedKidsForIds,
      getDeletedTeachersForSchoolId,
      saveKids,
      saveTeachers,
      clearDeletedKids,
      setSelecetdSchool
    })(ArchiveDetailPage)
  )
);
