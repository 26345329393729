import React, { useState, useEffect }from 'react';
import {Row, Col, Input,InputGroup,Button,Card, CardBody} from "reactstrap";
import Switch from "react-switch";

const CourseOverview = (props) => {


    const [courseContent,setCourseContent] = useState([
        {
            index: 0,
            type: "video",
            content: "Hours Video",
            val: undefined,
            cat: 1,
            cssStyle: { height: "3.5cm" },
        },
        {
            index: 1,
            type: "lectures",
            content: "Sessions",
            val: undefined,
            cat: 1,
            cssStyle: { height: "3.5cm" },
        },
        {
            index: 2,
            type: "liveClasses",
            content: "Live Classes",
            val: undefined,
            cat: 1,
            cssStyle: { height: "3.5cm" },
        },
        {
            index: 3,
            type: "p-resources",
            content: "Physical Resources",
            val: undefined,
            cat: 1,
            cssStyle: { height: "3.5cm" },
        },
        {
            index: 4,
            type: "d-resources",
            content: "Downloadable Resources",
            val: undefined,
            cat: 1,
            cssStyle: { height: "3.5cm" },
        },
        {
            index: 5,
            type: "validity",
            content: "Days",
            val: undefined,
            val2:undefined ,
            daccess: 0,
            cat: 2,
            cssStyle: { height: "3.5cm" },
        },
        
        {
            index: 6,
            type: "certificate",
            icon:"mdi-file-video",
            val: undefined,
            cat: 3,
            cssStyle: { height: "3.5cm" },
        },
        
        // { type: "liveSessions", content: "Test" },
    ]);

    const [certificate, setCertificate] = useState(false);

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };
    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };

    const getImageClass = (type) => {
        var classname = "mdi mdi-star-outline mdi-36px";
        if ("video" === type) {
            classname = "mdi mdi-file-video mdi-36px";
        } else if ("lectures" === type) {
            classname = "mdi mdi-google-classroom mdi-36px";
        } else if ("p-resources" === type) {
            classname = "mdi mdi-library-shelves mdi-36px";
        } else if ("validity" === type) {
            classname = "mdi mdi-timer-sand-empty mdi-36px";
        } else if ("liveClasses" === type) {
            classname = "mdi mdi-video-plus mdi-36px";
        } else if ("certificate" === type) {
            classname = "mdi  mdi-certificate-outline mdi-36px";
        } else if ("d-resources" === type) {
            classname = "mdi mdi-cloud-download-outline mdi-36px";
        } else if ("liveSessions" === type) {
            classname = "mdi mdi-video-outline mdi-36px";
        }

        return classname;
    };

    useEffect(() => {
        if (props.courseObject) {
            let tempContent = [...courseContent];
            tempContent[0].val = props.courseObject.attributes.totalVideoHours;
            tempContent[1].val = props.courseObject.attributes.lectures;
            tempContent[2].val = props.courseObject.attributes.liveSessions;
            tempContent[3].val = props.courseObject.attributes.physicalResources;
            tempContent[4].val = props.courseObject.attributes.downloadableResources;
            if (props.courseObject.attributes.courseValidDays === -1) {
                tempContent[5].val2 = false;
            } else if(props.courseObject.attributes.courseValidDays >0){
                tempContent[5].val2 = true;
            }
            tempContent[5].val = props.courseObject.attributes.courseValidDays;
            tempContent[6].val = props.courseObject.attributes.certificateIncluded===1?true:false;
            setCourseContent(tempContent);
           
            setCertificate(props.courseObject.attributes.certificateIncluded===1?true:false)
        }
    }, [props.courseObject]);


    const onClickSave = (isNext) => {
        let tempObject = props.courseObject;

        tempObject.set("totalVideoHours", courseContent[0].val);
        tempObject.set("lectures", courseContent[1].val);
        tempObject.set("liveSessions", courseContent[2].val);
        tempObject.set("physicalResources", courseContent[3].val);
        tempObject.set("downloadableResources", courseContent[4].val);
        if (courseContent[5].val2) {
            tempObject.set("courseValidDays", courseContent[5].val);  
        } else {
            tempObject.set("courseValidDays", -1);
        }
        tempObject.set("certificateIncluded", certificate?1:0);


        
        
        props.onSaveCourseObject(tempObject,isNext?"5":undefined);

    }

    const updateInputValues = (co,value ,value2) => {
        let tempContent = [...courseContent];

        if (co.index == 5) {
            tempContent[co.index].val =parseInt( value);
            tempContent[co.index].val2 = value2;
        } else {
            tempContent[co.index].val = parseInt( value);
        }
        setCourseContent(tempContent);

    }


    
    return (
        <Card>
            <CardBody>

                {courseContent.map((con, key) => (

               

                    <Card key={key} className="m-2">
               
                        <CardBody >
                            <Row>
                    

                                <Col xl={1} className="text-center ">
                                    <div className="avatar-md mx-auto ">
                                        {/* style={{ backgroundColor:"#eff2f7" }}  */}
                                        <div className="avatar-title bg-light  rounded-circle text-primary h1">
                                            <i className={getImageClass(con.type)}></i>
                                        </div>
                                    </div>

                        
                                </Col>
                    
                                <Col xl={11}>
                                    

                                    {
                                        con.cat == 2 && <div className="mt-2 ml-3">


                                            <div>
                                                    <h5 className="font-size-14 mb-2">Course Validity</h5>
                                                    <div className="form-check mb-3">
                                                    <input className="form-check-input"
                                                        type="radio" name="exampleRadios"
                                                        id="exampleRadios1" value="option1"
                                                        checked={!con.val2 && con.val===-1}
                                                        onChange={(e) => {
                                                            // console.log(e);
                                                            updateInputValues(con ,e.target.checked?-1:undefined, e.target.checked ?false:true);
                                                        }}
                                                    />
                                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                                            Life Time Access
                                                        </label>
                                                    </div>
                                                    <div className="form-check ">
                                                    <input className="form-check-input"
                                                        type="radio" name="exampleRadios"
                                                        id="exampleRadios2" value="option2"
                                                        checked={con.val2}
                                                        onChange={(e) => {
                                                            // console.log(e);
                                                            updateInputValues(con,undefined ,!con.val2);
                                                        }}
                                                    />
                                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                                            Limitted time Access
                                                        </label>
                                                    </div>
                                                </div>
                                        </div>

                                    }

                                    {
                                        con.cat == 3 && <Row >

                                            <h5 className="ml-4 mt-4">Certificate Of Completion</h5>
                                            <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                onColor="#626ed4"
                                                className="ml-3 mt-3"
                                                onChange={(e) => {
                                                    setCertificate(e)
                                                    //updateInputValues(con,!con.val)
                                                }}
                                                checked={certificate}

                                            />
                                        </Row>

                                    }
                                    { con.cat == 1 &&  <h5 className="font-size-14 mb-0 ml-3">{con.content}</h5>}
                                    {(con.cat == 1 || (con.cat == 2 && con.val2))&& <InputGroup className="rounded mt-2 ml-2" style={{ backgroundColor: "#eff2f7" }}>
                                        <Input
                                            type="number"
                                            className="form-control bg-transparent border-0"
                                            min={0}
                                            value={con.val}
                                            onChange={(e) => {
                                                updateInputValues(con,e.target.value,con.val2);
                                            }}
                                        />
                                        <Button color="primary" type="button" id="button-addon2">
                                            {con.content}
                                        </Button>
                                    </InputGroup>}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                
                ))}

                {props.saveInprogress ? <div>
                    <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>
                    <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>
                </div>
                    :
                    <div>
                        <button className="btn btn-primary m-1" onClick={() => { onClickSave() }}> Save </button>
                        <button className="btn btn-primary m-1" onClick={() => { onClickSave(true) }}> Save & Next</button>
                    </div>
                }


            </CardBody>
        </Card>
    );
}
export default CourseOverview