import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Row, Col, Table, Button, UncontrolledTooltip, Label, FormGroup, ModalBody, Modal, ModalHeader, ModalFooter, Badge } from "reactstrap";
import Image from "../../assets/images/book_lover.svg";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import { Link } from "react-router-dom";
import Moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import Parse from 'parse';
import Editable from "react-bootstrap-editable";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';


import {
    getKidsForCorporatePartners,
    getParentsForFamilyIds,
    getClassroomsForSchoolIds,
    updateAParent,
    addOrupdateParent,
    setSelecetdSchool,
    setSelectedCorporatePartner
} from "../../store/actions";

const corporatePartnerDetails = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/corporate-partners-details/:id",
        exact: true,
        strict: false,

    });

    const corporatePartnerId = match ? match.params.id : "";

    const [familyIds, setFamilyIds] = useState([])

    useEffect(() => {
        props.getKidsForCorporatePartners(corporatePartnerId);
        props.setSelectedCorporatePartner(corporatePartnerId);
    }, [corporatePartnerId]);

    useEffect(() => {
        // console.log(props.kidObj)
        if (props.kidObj && props.kidObj.length > 0) {
            let ids = [];
            let schoolIds = [];
            for (const i in props.kidObj) {
                if (!familyIds.includes(props.kidObj[i].attributes.FamilyID)) {
                    ids.push(props.kidObj[i].attributes.FamilyID);
                }
                if (!schoolIds.includes(props.kidObj[i].attributes.SchoolID)) {
                    schoolIds.push(props.kidObj[i].attributes.SchoolID);
                }
            }
            if (ids.length > 0) {
                var newIds = familyIds.concat(ids);
                setFamilyIds(newIds);
                props.getParentsForFamilyIds(ids);
            }

            if (schoolIds.length > 0) {
                props.getClassroomsForSchoolIds(schoolIds);
            }
        }
    }, [props.kidObj]);

    const getSchoolName = (schoolId) => {
        if (props.schools && props.schools.length > 0) {
            let temp = props.schools.filter(
                (school) => school.id === schoolId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getCorporateName = (corporateId) => {
        if (props.corporatePartners && props.corporatePartners.length > 0) {
            let temp = props.corporatePartners.filter(
                (corporate) => corporate.id === corporateId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.name ?? "-")
            }
        }
    }

    const getClassRoomName = (classroomId) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === classroomId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name + " " ?? "-")
            }
        }
    }




    const parentsCorporateEmployeeIdUpdate = (parentObj) => {
        // parentObj.save().then((parent) => {
        //     console.log(parent)
        //     props.addOrupdateParent(parent)
        // }, (error) => {

        // });
        props.updateAParent(parentObj)
    }

    const parentsCorporateEmailIdUpdate = (parentObj) => {
        props.updateAParent(parentObj)
    }

    const getParentObjcts = (familyId) => {
        var parents = []
        if (props.parents && props.parents.length > 0) {
            parents = props.parents && props.parents.filter(
                (parent) => parent.attributes.FamilyID === familyId
            );
        }

        var motherObj = null;
        var fatherObj = null;
        for (const i in parents) {
            if (parents[i].attributes.Relation === "Mother") {
                motherObj = parents[i];
            } else {
                fatherObj = parents[i];
            }
        }
        return {
            mother: motherObj,
            father: fatherObj,
        }
    }

    function getParent(familyId) {
        var parents = []
        if (props.parents && props.parents.length > 0) {
            parents = props.parents && props.parents.filter(
                (parent) => parent.attributes.FamilyID === familyId
            );
        }

        var motherObj = null;
        var fatherObj = null;
        for (const i in parents) {
            if (parents[i].attributes.Relation === "Mother") {
                motherObj = parents[i];
            } else {
                fatherObj = parents[i];
            }
        }

        return (
            <>
                <td>
                    {fatherObj && fatherObj.attributes.Name && (
                        <p className=" mb-0">
                            <i className="bx bx-male m-1" />
                            {fatherObj.attributes.Name > 20
                                ? fatherObj.attributes.Name.slice(0, 19)
                                : fatherObj.attributes.Name}{" "} <br />
                            {/* <i className="bx bx-mobile" />
                            {fatherObj.attributes.PhoneNumber} <br />
                            <i className="bx bx-envelope" />
                            {fatherObj.attributes.EMail} <br /> */}
                            <div className='d-flex justify-content-start'>
                                <i className="bx bx-id-card m-1" />
                                <Editable
                                    className={'d-flex'}
                                    onValidated={(value) => {
                                        fatherObj.set("corporateEmployeeId", value)
                                        parentsCorporateEmployeeIdUpdate(fatherObj)
                                    }}
                                    onCancel={() => {
                                        fatherObj.attributes.corporateEmployeeId
                                    }}
                                    disabled={false}
                                    editText={fatherObj.attributes.corporateEmployeeId ? " ✎" : "No Corporate Employee Id  ✎"}
                                    id={null}
                                    isValueClickable={false}
                                    mode="inline"
                                    placement="top"
                                    initialValue={
                                        fatherObj.attributes.corporateEmployeeId ? fatherObj.attributes.corporateEmployeeId : ""
                                    }
                                    showText
                                    type="textfield"
                                    validate={(value) => {
                                        if (!value || value.length == 0) {
                                            return "Please enter title"
                                        }
                                    }}
                                />
                            </div>
                            <div className='d-flex justify-content-start'>
                                <i className="bx bx-envelope m-1" />
                                <Editable
                                    className={'d-flex'}
                                    alwaysEditing={false}
                                    onValidated={(value) => {
                                        fatherObj.set("corporateEmailId", value)
                                        parentsCorporateEmailIdUpdate(fatherObj)
                                    }}
                                    onCancel={() => {
                                        fatherObj.attributes.corporateEmailId
                                    }}
                                    disabled={false}
                                    editText={fatherObj.attributes.corporateEmailId ? " ✎" : "No Corporate Email Id  ✎"}
                                    id={null}
                                    isValueClickable={false}
                                    mode="inline"
                                    placement="top"
                                    initialValue={
                                        fatherObj.attributes.corporateEmailId
                                    }
                                    showText
                                    type="textfield"
                                    validate={(value) => {
                                        if (!value || value.length == 0) {
                                            return "Please enter title"
                                        }
                                    }}
                                />
                            </div>
                        </p>
                    )}
                </td>
                <td>
                    {motherObj && motherObj.attributes.Name && (
                        <p className=" mb-0">
                            <i className="bx bx-female m-1" />
                            {motherObj.attributes.Name.length > 20
                                ? motherObj.attributes.Name.slice(0, 19)
                                : motherObj.attributes.Name}{" "}<br />
                            {/* <i className="bx bx-mobile" />
                            {motherObj.attributes.PhoneNumber} <br />
                            <i className="bx bx-envelope" />
                            {motherObj.attributes.EMail} <br /> */}
                            <div className='d-flex justify-content-start'>
                                <i className='bx bx-id-card m-1'></i>
                                <Editable
                                    className={'d-flex'}
                                    alwaysEditing={false}
                                    onValidated={(value) => {
                                        motherObj.set("corporateEmployeeId", value)
                                        parentsCorporateEmployeeIdUpdate(motherObj)
                                    }}
                                    onCancel={() => {
                                        motherObj.attributes.corporateEmployeeId
                                    }}
                                    disabled={false}
                                    editText={motherObj.attributes.corporateEmployeeId ? " ✎" : "No Corporate Employee Id  ✎"}
                                    id={null}
                                    isValueClickable={false}
                                    mode="inline"
                                    placement="top"
                                    initialValue={
                                        motherObj.attributes.corporateEmployeeId
                                    }
                                    showText
                                    type="textfield"
                                    validate={(value) => {
                                        if (!value || value.length == 0) {
                                            return "Please enter title"
                                        }
                                    }}
                                />
                            </div>
                            <div className='d-flex justify-content-start'>
                                <i className="bx bx-envelope m-1" />
                                <Editable
                                    className={'d-flex'}
                                    alwaysEditing={false}
                                    onValidated={(value) => {
                                        motherObj.set("corporateEmailId", value)
                                        parentsCorporateEmailIdUpdate(motherObj)
                                    }}
                                    onCancel={() => {
                                        motherObj.attributes.corporateEmailId
                                    }}
                                    disabled={false}
                                    editText={motherObj.attributes.corporateEmailId ? " ✎" : "No Corporate Email Id  ✎"}
                                    id={null}
                                    isValueClickable={false}
                                    mode="inline"
                                    placement="top"
                                    initialValue={
                                        motherObj.attributes.corporateEmailId
                                    }
                                    showText
                                    type="textfield"
                                    validate={(value) => {
                                        if (!value || value.length == 0) {
                                            return "Please enter title"
                                        }
                                    }}
                                />
                            </div>
                        </p>
                    )}
                </td>
            </>
        )
    }

    const downloadCorporateReport = () => {

        let finalData = [];
        let titles = [];
        let fileName = "";
        let sheetName = "";
        fileName = `Corporate_Report.xlsx`;
        sheetName = "Corporate_Report";
        finalData.push([
            `Report : Corporate Partner Students Report`,
        ]);

        titles.push("Name");
        titles.push("School Name");
        titles.push("Corporate Partner Name");
        titles.push("Class");
        titles.push("Admission Number")
        titles.push("Gender");
        titles.push("Father Name");
        titles.push("Father Phone");
        titles.push("Father Email");
        titles.push("Father Employee Id ");
        titles.push("Father Corporate EmailId");
        titles.push("Mother Name");
        titles.push("Mother Phone");
        titles.push("Mother Email");
        titles.push("Mother Employee Id ");
        titles.push("Mother Corporate EmailId");

        finalData.push(titles);
        for (const item of props.kidObj) {
            let temp = [];
            let parents = getParentObjcts(item.attributes.FamilyID)
            temp.push(item.attributes.Name)
            temp.push(getSchoolName(item.attributes.SchoolID))
            temp.push(getCorporateName(item.attributes.corporateId))
            temp.push(getClassRoomName(item.attributes.ClassRoomID))
            temp.push(item.attributes.admissionNumber)
            temp.push(item.attributes.Gender)
            temp.push(parents && parents.father && parents.father.attributes.Name)
            temp.push(parents && parents.father && parents.father.attributes.PhoneNumber)
            temp.push(parents && parents.father && parents.father.attributes.EMail)
            temp.push(parents && parents.father && parents.father.attributes.corporateEmployeeId)
            temp.push(parents && parents.father && parents.father.attributes.corporateEmailId)

            temp.push(parents && parents.mother && parents.mother.attributes.Name)
            temp.push(parents && parents.mother && parents.mother.attributes.PhoneNumber)
            temp.push(parents && parents.mother && parents.mother.attributes.EMail)
            temp.push(parents && parents.mother && parents.mother.attributes.corporateEmployeeId)
            temp.push(parents && parents.mother && parents.mother.attributes.corporateEmailId)

            finalData.push(temp);
        }
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className='d-flex align-items-start'>
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <h4>Corporate Partner Details{""} ({props.selectedCorporatePartner && props.selectedCorporatePartner.attributes.name})</h4>
                            </div>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                <button
                                    className="btn btn-info m-1"
                                    onClick={() => { downloadCorporateReport() }}
                                >
                                    <i className="bx bxs-download m-1" />
                                    Download
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive mt-2">
                                        <Table className="table-centered table-nowrap table-hover">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" >Student Name</th>
                                                    < th scope="col">Father Corporate Details</th>
                                                    < th scope="col">Mother Corporate Details</th>
                                                    <th scope="col">Class</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.kidObj.map((kid, i) =>
                                                        <tr key={kid.id} >

                                                            <td>
                                                                <h5 className="font-size-14  mt-2">{kid.attributes.Name}</h5>
                                                                <p>{kid.attributes.admissionNumber}</p>
                                                                <small>{getSchoolName(kid.attributes.SchoolID)}</small>
                                                            </td>
                                                            {
                                                                getParent(kid.attributes.FamilyID)
                                                            }
                                                            <td>
                                                                <Badge>{getClassRoomName(kid.attributes.ClassRoomID)}</Badge><br />
                                                                <Badge color='info'>{kid.attributes.feeType}</Badge><br />
                                                                <Badge color='success'>{kid.attributes.programType}</Badge>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}


const mapStatetoProps = (state) => {
    const { franchise } = state.Franchise;
    const { parents } = state.Parent;
    const { classrooms } = state.Classroom;
    const { corporatePartners, kidObj, selectedCorporatePartner } = state.CorporatePartners;
    const { selectedSchool, schools } = state.School;


    return { franchise, corporatePartners, kidObj, parents, classrooms, selectedSchool, schools, selectedCorporatePartner };
};


export default connect(mapStatetoProps, {
    getKidsForCorporatePartners,
    getParentsForFamilyIds,
    getClassroomsForSchoolIds,
    updateAParent,
    addOrupdateParent,
    setSelecetdSchool,
    setSelectedCorporatePartner
})(withNamespaces()(corporatePartnerDetails));
