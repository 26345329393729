import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { GET_USER_TEACHER, LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import {
  loginSuccess,
  updateUser,
  logoutUserSuccess,
  apiError,
  updateUserTeacher,
  setLodingState,
  setVerifyUserError,
} from "./actions";
import {
  parseLoginUser,
  parseLogoutUser,
  getUser,
} from "../../../helpers/ParseHelpers/login_helper";
import { getSchoolsForInstitute } from "../../../helpers/ParseHelpers/school_helper";
import Parse from "parse";
import * as Constants from '../../../Constents';
import * as CommonFunctions from "../../../components/Common/CommonFunctions";
import * as Analytics from '../../../components/Common/Analytics';
import * as WhiteLable from "../../../components/Common/WhiteLable";


function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "parse") {
      localStorage.clear();
      yield put(apiError(""));
      yield put(setLodingState(true));
      const response = yield call(parseLoginUser, user.email, user.password);
      yield put(setVerifyUserError(false));
      if (response.type === "Error") {
        yield put(apiError(response.message));
      } else if (CommonFunctions.isToolsHost()) {
        if (Parse.User.current().attributes.emailVerified) {
          console.log("lilTriangleAdmin",Parse.User.current().attributes.lilTriangleAdmin)
          if (Parse.User.current().attributes.lilTriangleAdmin) {
            Analytics.track_Login(Parse.User.current().id,Parse.User.current().attributes.email,"Tools","Tools")
            history.push("/tools-schools-subscription/");
          } else {
            window.location.replace('https://dashboard.liltriangle.com/');
          }
        } else {
          yield call(parseLogoutUser);
          yield put(apiError("Please verify your email to login"));
          yield put(setVerifyUserError(true));
        }
      }
      else if (Parse.User.current().attributes.instituteId) {
        if (Parse.User.current().attributes.emailVerified) {
          localStorage.setItem("authUser", JSON.stringify(response));

          let schoolResponse = yield call(
            getSchoolsForInstitute,
            Parse.User.current().attributes.instituteId,
            "instituteId",
            false
          );
          const teacher = yield call(getUser);

          if (schoolResponse && schoolResponse.length > 0) {
            let franchiseId = undefined
            if (WhiteLable.isMakoonsApp()) {
              franchiseId = "WPs3dlhGTO"
            } else if (WhiteLable.isSanfortApp()) {
              //franchiseId = "I5wVcx9jVg";
            } else if (WhiteLable.isJumpStartApp()) {
              //franchiseId = "fQ7JF1Nbc0";
            } else if (WhiteLable.isArtklubApp()) {
              //franchiseId = "xPFCC9IkBO";
            }

            let fSchools=[]
            if (franchiseId) {
              fSchools = schoolResponse.filter(
                (school) => school.attributes.franchiseId === franchiseId);
              
                if (fSchools.length > 0) {
                  schoolResponse=fSchools
                } else {
                  history.push("/profile-access-warning/3");
                  return
                }
            }
            

            if (teacher && teacher.attributes.schools && teacher.attributes.schools.length > 0) {
              let list = []
              for (const s of schoolResponse) {
                if (teacher.attributes.schools.includes(s.id)) {
                  list.push(s)
                }
              }
              Parse.Object.pinAll(list);
              localStorage.setItem(Constants.SELECTED_SCHOOL_ID, list[0].id);
              history.push("/dashboard/" + list[0].id);
            } else {
              Parse.Object.pinAll(schoolResponse);
              localStorage.setItem(Constants.SELECTED_SCHOOL_ID, schoolResponse[0].id);
              history.push("/dashboard/" + schoolResponse[0].id);
            }
            
          } else {
            history.push("/registration");
          }
          yield put(loginSuccess(response));
          
          if (teacher) {
            localStorage.setItem("userTeacher", JSON.stringify(teacher));
            yield put(updateUser());
            yield put(updateUserTeacher(teacher));

            Analytics.track_Login(Parse.User.current().id,Parse.User.current().attributes.email,teacher.attributes.Name,"institute",Parse.User.current().attributes.instituteId)
          }else{
            Analytics.track_Login(Parse.User.current().id,Parse.User.current().attributes.email,"No teacher","institute",Parse.User.current().attributes.instituteId)
          }
        } else {
          yield call(parseLogoutUser);
          yield put(apiError("Please verify your email to login"));
          yield put(setVerifyUserError(true));
        }
      } else if (Parse.User.current().attributes.franchiseId) {
        if (Parse.User.current().attributes.emailVerified) {
          localStorage.setItem("authUser", JSON.stringify(response));

          const teacher = yield call(getUser);
          if (teacher) {
            localStorage.setItem("userTeacher", JSON.stringify(teacher));
            yield put(updateUser());
            yield put(updateUserTeacher(teacher));
            Analytics.track_Login(Parse.User.current().id,Parse.User.current().attributes.email,teacher.attributes.Name,"franchise",Parse.User.current().attributes.franchiseId)
          }else{
            Analytics.track_Login(Parse.User.current().id,Parse.User.current().attributes.email,"No teacher","franchise",Parse.User.current().attributes.franchiseId)
          }
          history.push(
            "/franchise/" + Parse.User.current().attributes.franchiseId
          );
          yield put(loginSuccess(response));
        } else {
          yield call(parseLogoutUser);
          yield put(apiError("Please verify your email to login"));
          yield put(setVerifyUserError(true));
        }
      } else {
        if (Parse.User.current().attributes.emailVerified) {
          Analytics.track_Login(Parse.User.current().id, Parse.User.current().attributes.email, "New User", "registration");
          history.push("/registration");
        } else {
          yield call(parseLogoutUser);
          yield put(apiError("Please verify your email to login"));
          yield put(setVerifyUserError(true));
        }
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getUserTeacher({ payload: { teacherId } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "parse") {
      if (Parse.User.current()) {
        const teacher = yield call(getUser);
        if (teacher) {
          localStorage.setItem("userTeacher", JSON.stringify(teacher));
          yield put(updateUser());
          yield put(updateUserTeacher(teacher));
        }
      } else {
        //history.push('/login');
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "parse") {
      const response = yield call(parseLogoutUser);
      put(logoutUserSuccess(response));
      localStorage.clear();
    }

    if(history){
      history.push("/login");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}
export function* watchGetUserTeacher() {
  yield takeEvery(GET_USER_TEACHER, getUserTeacher);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchGetUserTeacher),
  ]);
}

export default authSaga;
