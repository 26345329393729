import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Card, CardBody, Label, CardHeader, CardText, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Parse from 'parse';
import SweetAlert from "react-bootstrap-sweetalert";
import { setSelecetdSchool, getInventoryCategoriesForOwnerId, updateInventoryCategories, deleteInventoryCategory } from '../../../store/actions';
import SchoolPicker from '../../Messaging/SchoolPicker';
import toastr from 'toastr';

const AllCategories = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/inventory-categories/:id",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-inventory-categories/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [name, setName] = useState('');
    const [addCategoryModal, setAddCategoryModal] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showSchoolPickerModal, setShowSchoolPickerModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    const [showDisable, setShowDisable] = useState(false);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryCategoriesForOwnerId(franchiseId, 'franchise');
        }
    }, [franchiseId]);

    useEffect(() => {
        if (selectedCategory && selectedCategory) {
            setName(selectedCategory.attributes.name)
        }
    }, [selectedCategory]);

    const handleDisableCategory = (selectedCategory) => {

        selectedCategory.set("isDisabled", true);
        selectedCategory.set("disabledBy", Parse.User.current().attributes.teacherId);

        selectedCategory.save().then(
            (result) => {
                props.updateInventoryCategories([result]);
                setShowDisable(false);
                toastr.success("Category disabled successfully.");
            },
            (error) => {
                console.error("Error disabling category:", error);
                toastr.warning("Failed to disable category. Please try again."); // Provide error feedback
            }
        );
    };

    const saveCategories = () => {

        const InventorySchoolCategories = Parse.Object.extend('InventorySchoolCategories');
        let inventoryCategories = new InventorySchoolCategories();

        if (selectedCategory) {
            inventoryCategories = selectedCategory;
        } else {
            inventoryCategories.set("ownerId", franchiseId);
            inventoryCategories.set("ownerType", "franchise");
        }

        inventoryCategories.set("name", name);

        setIsSaving(true);
        inventoryCategories.save().then(
            (result) => {
                props.updateInventoryCategories([result]);
                setIsSaving(false);
                setAddCategoryModal(false);
            },
            (error) => {
                setIsSaving(false);
                toastr.warning("Error while saving, please refresh and try again");
                setAddCategoryModal(false);
            }
        );
    };

    const verifyMandatoryFields = () => {
        let returnValue = true;
        if (name && name.trim().length > 0) {
            setNameError(false);
        } else {
            setNameError(true);
            returnValue = false;
        }
        return returnValue;
    };

    const handleSchoolCategory = (selectedCategory, schools) => {
        const selectedSchoolIds = new Set(schools.map(school => school.id));
        const currentSchoolIds = selectedCategory.get("schools") || [];

        const previousCategory = props.inventoryCategories.find(category =>
            category.id !== selectedCategory.id &&
            category.get("schools") &&
            category.get("schools").some(schoolId => selectedSchoolIds.has(schoolId))
        );

        if (previousCategory) {
            const previousSchoolIds = previousCategory.get("schools") || [];
            const updatedPreviousSchoolIds = previousSchoolIds.filter(id => !selectedSchoolIds.has(id));
            previousCategory.set("schools", updatedPreviousSchoolIds);

            previousCategory.save().then(
                (result) => {
                    console.log(`Successfully updated previous category: ${result.id}`);
                },
                (error) => {
                    console.error("Error updating previous category:", error);
                    toastr.warning("Failed to update previous category. Please try again.");
                }
            );
        }

        props.schools.forEach(school => {
            const inventoryCategories = school.attributes.inventoryCategories || [];

            if (selectedSchoolIds.has(school.id)) {
                if (!inventoryCategories.includes(selectedCategory.id)) {
                    school.set("inventoryCategories", [selectedCategory.id]);
                    toastr.success(`Added new category to ${school.attributes.name}`);
                }

                if (!currentSchoolIds.includes(school.id)) {
                    currentSchoolIds.push(school.id);
                    selectedCategory.set("schools", currentSchoolIds);
                }
            } else {
                if (inventoryCategories.includes(selectedCategory.id)) {
                    const updatedCategories = inventoryCategories.filter(id => id !== selectedCategory.id);
                    school.set("inventoryCategories", updatedCategories);
                    toastr.success(`Removed category from ${school.attributes.name}`);
                }

                if (currentSchoolIds.includes(school.id)) {
                    const updatedSchoolIds = currentSchoolIds.filter(id => id !== school.id);
                    selectedCategory.set("schools", updatedSchoolIds);
                }
            }

            school.save().then(
                (result) => {
                    console.log(`Successfully updated school: ${result.id}`);
                },
                (error) => {
                    console.error("Error updating school:", error);
                    toastr.warning("Failed to update school categories. Please try again.");
                }
            );
        });

        selectedCategory.save().then(
            (result) => {
                console.log(`Successfully updated selected category: ${result.id}`);
            },
            (error) => {
                console.error("Error updating selected category:", error);
                toastr.warning("Failed to update selected category. Please try again.");
            }
        );

        setShowSchoolPickerModal(false);
    };

    const getSelectedSchools = () => {

        if (!selectedCategory) {
            console.log("No category selected.");
            return [];
        }

        if (!props.schools || props.schools.length === 0) {
            console.log("No schools found.");
            return [];
        }

        const filteredSchools = props.schools.filter(school => {
            const inventoryCategories = school.attributes.inventoryCategories || [];
            return inventoryCategories.includes(selectedCategory.id);
        });

        return filteredSchools;
    };

    return (
        <div className="page-content">
            <Modal isOpen={showSchoolPickerModal} className={"modal-dialog modal-xl"} scrollable={true}>
                <ModalHeader toggle={() => setShowSchoolPickerModal(!showSchoolPickerModal)}>{`Add Schools to ${selectedCategory && selectedCategory.attributes.name} Region/Category`}</ModalHeader>
                <ModalBody>
                    <SchoolPicker
                        franchiseId={Parse.User.current().attributes.franchiseId}
                        showCategory={true}
                        selectedSchools={getSelectedSchools()}
                        hideSelectAllButton={true}
                        onButtonClick={(selectedSchools) => {
                            if (selectedSchools && selectedSchools.length > 0) {
                                handleSchoolCategory(selectedCategory, selectedSchools);
                            }
                        }}
                    />
                </ModalBody>
            </Modal>
            <Modal isOpen={addCategoryModal}>
                <ModalHeader toggle={() => setAddCategoryModal(!addCategoryModal)}>{selectedCategory ? "Edit Region/Category" : "Add Region/Category"}</ModalHeader>
                <ModalBody>
                    <AvForm>
                        <FormGroup className="mt-2">
                            <Label htmlFor="categoryName">{'Name'}:</Label>
                            <Label htmlFor="categoryName" className="text-danger">*</Label>
                            {nameError && (
                                <Label className="label-xsmall text-danger ml-2">
                                    (Region/Category Name is mandatory)
                                </Label>
                            )}
                            <AvField
                                type="text"
                                name="categoryName"
                                placeholder={'Enter Region/Category Name...'}
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormGroup>
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        disabled={isSaving}
                        onClick={() => {
                            setIsSaving(true);
                            if (verifyMandatoryFields()) {
                                saveCategories();
                            } else {
                                setIsSaving(false);
                            }
                        }}
                    >
                        {isSaving && <i className="bx bx-loader bx-spin mr-2 align-middle"></i>}
                        {isSaving ? "Saving..." : "Add"}
                    </button>
                </ModalFooter>
            </Modal>
            <Row className='d-flex align-items-start'>
                <Col className="d-flex align-items-start">
                    <div style={{ cursor: "pointer" }} onClick={() => window.history.back()}>
                        <ul className="list-unstyled">
                            <div className=''>
                                <i className="bx bx-chevron-left h1 text-primary"></i>
                            </div>
                        </ul>
                    </div>
                    <div className='m-2'>
                        <h4>{"All Categories"}</h4>
                    </div>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                        <div className="mb-3 float-sm-right form-inline">
                            <Link to={"#"}>
                                <Button
                                    color="success"
                                    style={{ float: "right" }}
                                    className="btn waves-effect waves-light float-right m-2"
                                    onClick={() => setAddCategoryModal(!addCategoryModal)}
                                >
                                    Add Region/Categories
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
            {showDisable && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Disable"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => setShowDisable(false)}
                    onConfirm={() => { handleDisableCategory(selectedCategory) }}
                >
                    {"Are you sure you want to disable?"}
                </SweetAlert>
            )}
            <div>
                {props.inventoryCategories && props.inventoryCategories.length > 0 ? (
                    <Card>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>#</th>
                                            <th>Region/Categories Name</th>
                                            <th>Number of schools</th>
                                            <th>Add Schools</th>
                                            <th style={{ width: "20px" }}>Edit</th>
                                            <th style={{ width: "20px" }}>Disable</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.inventoryCategories
                                            .filter(category => !category.attributes.isDisabled)
                                            .map((category, key) => (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>
                                                        <Label className="font-size-12 mb-1">{category.attributes.name}</Label>
                                                    </td>
                                                    <td>
                                                        <Label className="font-size-12 mb-1">{category.attributes.schools ? category.attributes.schools.length : "0"}</Label>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className="btn btn-primary btn-sm waves-effect waves-light"
                                                            color='primary'
                                                            onClick={() => {
                                                                setSelectedCategory(category);
                                                                setShowSchoolPickerModal(true);
                                                            }}
                                                        >
                                                            Add School
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <div className="flex-fill text-center">
                                                            <Link to={"#"} onClick={() => {
                                                                setSelectedCategory(category)
                                                                setAddCategoryModal(true)
                                                            }}>
                                                                <i className="bx bx-edit-alt"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="flex-fill text-center"
                                                            style={{
                                                                cursor: "pointer",
                                                                opacity: 1,
                                                                fontWeight: "normal"
                                                            }}
                                                            onClick={() => {
                                                                setSelectedCategory(category);
                                                                setShowDisable(true);
                                                            }}
                                                        >
                                                            <i className="bx bx-block text-danger"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        {props.inventoryCategories
                                            .filter(category => category.attributes.isDisabled) // Disabled categories
                                            .map((category, key) => (
                                                <tr key={key + props.inventoryCategories.length}>
                                                    <td>{key + props.inventoryCategories.length + 1}</td>
                                                    <td>
                                                        <Label className="font-size-12 mb-1">{category.attributes.name}</Label>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className="btn btn-primary btn-sm waves-effect waves-light"
                                                            color='primary'
                                                            disabled
                                                        >
                                                            Add School
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <div className="flex-fill text-center">
                                                            <Link to={"#"} onClick={() => {
                                                            }} disabled>
                                                                <i className="bx bx-edit-alt"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="flex-fill text-center"
                                                            style={{
                                                                cursor: "not-allowed",
                                                                opacity: 0.5,
                                                                fontWeight: "bold"
                                                            }}
                                                        >
                                                            <i className="bx bx-block text-danger"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                ) : (
                    <Card className='text-center'>
                        <CardBody>
                            <CardHeader className='m-3'><b><h4>Add Categories</h4></b></CardHeader>
                            <CardText>
                                Create and manage your Region / Categories
                            </CardText>
                            <Button
                                className="btn btn-primary waves-effect waves-light"
                                color='primary'
                                onClick={() => setAddCategoryModal(true)}
                            >
                                Add New Category
                            </Button>
                        </CardBody>
                    </Card>
                )}
            </div>
        </div>
    );
};

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { inventoryCategories } = state.InventoryCategories;
    return { selectedSchool, inventoryCategories, schools };
};

export default withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryCategoriesForOwnerId, updateInventoryCategories, deleteInventoryCategory })(AllCategories));