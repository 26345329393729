import React, { useEffect, useState } from 'react';
import { Container, Row, Col, TabContent, TabPane, Card, Nav, NavItem, NavLink, CardBody, CardTitle, CardSubtitle, Label, Form, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, Input, Button, Table } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { matchPath } from "react-router";
import toastr from 'toastr';
import TopBar from './Topbar';
import moment from 'moment';
import Parse from "parse";
import Moment from "moment";
import AddExpenseModal from "./AddExpenseModal"
import {
    setSelecetdSchool,
    getExpensesForSchoolId,
    getExpenseCategoryForSchoolId,
    getExpenseSupplierForSchoolId,
    getTeacher,
    editExpenseCategory,
    editExpenseSupplier,
    getTeachersForSchool,
    getAllKids,
    editExpense,
    clearExpenseResult,
    getExpensesForDate,
    clearExpenses,
    addExpenseWallet,
    getExpenseWalletForSchoolId,
} from '../../store/actions';
import ExpenseCategory from './ExpenseCategory';
import ExpenseSupplier from './ExpenseSupplier';
import ExpenseManagement from './ExpenseList';
import ExpenseWallet from './ExpenseWallet';
import { TEACHER_EXPENSE_INDEX } from '../../Constents';
import COUNTRY_JSON from '../../assets/other/countries.json'

const ExpenseIndex = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/expenses/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;

    //Constants
    const CASH = "Cash"
    const CHEQUE = "Cheque"
    const ONLINE = "Online"
    const SUPPLIER = "Supplier"
    const STAFF = "Staff"
    const STUDENT = "Student"
    const imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];

    //Constants Lists for Selections
    const supplierTypeList = [{ value: SUPPLIER, label: SUPPLIER }, { value: STAFF, label: STAFF }, { value: STUDENT, label: STUDENT }]
    const paymentTypes = [{ value: CASH, label: CASH }, { value: CHEQUE, label: CHEQUE }, { value: ONLINE, label: ONLINE }]
    const [categoryList, setCategoryList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [kidList, setKidList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [taxList, setTaxList] = useState([])






    //UseStates
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [duplicateParseObject, setDuplicateParseObject] = useState(undefined);
    const [editParseObject, setEditParseObject] = useState(undefined);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showSupplierModal, setShowSupplierModal] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(undefined);
    const [isExpenseLoading, setIsExpenseLoading] = useState(true);
    const [writePermission, setWritePermission] = useState(false);
    const [newTags, setNewTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [showExpenseModal, setShowExpenseModal] = useState(false);
    const [creatingExpense, setCreatingExpense] = useState(false);


    //Main Lists
    const [expenseList, setExpenseList] = useState([]);
    const [expenseCategoryList, setExpenseCategoryList] = useState([]);
    const [expenseSupplierList, setExpenseSupplierList] = useState([]);

    const [showWalletBalance, setShowWalletBalance] = useState(false);
    const [showWalletPopup, setShowWalletPopup] = useState(false);
    const [walletAmount, setWalletAmount] = useState();
    const [currency, setCurrency] = useState("₹");

    const [showUI, setShowUI] = useState(false);
    const [preSelectedCategory, setPreSelectedCategory] = useState(undefined);
    const [preSelectedSupplier,setPreSelectedSupplier]=useState(undefined);


    useEffect(() => {
        if (showUI) {
            if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
                props.setSelecetdSchool(schoolId);
            }
            if (Parse.User.current()) {
                props.getTeacher(Parse.User.current().attributes.teacherId)
                props.getTeachersForSchool(schoolId);
                props.getAllKids(schoolId);
                getTaxSlab()
            }
            if (props.selectedSchool && props.selectedSchool.attributes.countryCode != "IN") {
                for (const cc of COUNTRY_JSON) {
                    if (cc.code == props.selectedSchool.attributes.countryCode) {
                        setCurrency(cc.currency.currencySymbol);
                        break;
                    }
                }
            }
            if (props.selectedSchool && props.userTeacher && props.selectedSchool.id === schoolId) {
                if (props.userTeacher.attributes.Role === 3 || true) {
                    props.getExpensesForSchoolId(schoolId)
                    props.getExpenseCategoryForSchoolId(schoolId, props.selectedSchool.attributes.instituteId)
                    props.getExpenseSupplierForSchoolId(schoolId, props.selectedSchool.attributes.instituteId)
                    getExpenseTags(schoolId, props.selectedSchool.attributes.instituteId)
                } else if (
                    (props.userTeacher.attributes.Role === 2 && props.userTeacher.attributes.SchoolID === props.selectedSchool.id) ||
                    Parse.User.current().attributes.franchiseId === props.selectedSchool.attributes.franchiseId
                ) {
                    props.getExpensesForSchoolId(schoolId)
                    props.getExpenseCategoryForSchoolId(schoolId)
                    props.getExpenseSupplierForSchoolId(schoolId)
                    getExpenseTags(schoolId)
                }
            }
        } else {
            setTimeout(myFunction, 2000);
        }

    }, [props.selectedSchool, schoolId,showUI]);

    useEffect(() => {
        if(showUI){
            props.getExpenseWalletForSchoolId(schoolId)
        }
    }, [schoolId,showUI])

    const myFunction = () => {
        setShowUI(true);
    }

    const getTaxSlab = () => {
        var query = new Parse.Query('TaxSlab');
        query.find().then((results) => {
            if (results && results.length > 0) {
                let tempSelectList = []
                for (const i in results) {
                    let tempObj = {}
                    tempObj.id = results[i].id
                    tempObj.value = results[i].id
                    tempObj.label = results[i].attributes.title ?? ""
                    tempObj.tax = results[i].attributes.tax
                    tempObj.parseObject = results[i]
                    tempSelectList.push(tempObj)
                }
                setTaxList(tempSelectList)
            }
        })
    }

    useEffect(() => {

        if (props.selectedSchool && props.userTeacher && props.selectedSchool.id === schoolId) {

            if (props.userTeacher.attributes.Role === 11) {
                setIsSuperAdmin(true)
            } else {
                setIsSuperAdmin(false)
            }
            let isWrite = false
            if (props.userTeacher) {

                if (props.userTeacher.attributes.featurePermissions) {
                    let features = props.userTeacher.attributes.featurePermissions
                    if (features.length > TEACHER_EXPENSE_INDEX) {
                        if (features[TEACHER_EXPENSE_INDEX] && features[TEACHER_EXPENSE_INDEX] === 2) {
                            isWrite = true
                        }
                    }
                }
                setWritePermission(isWrite)
            }
        }
    }, [props.selectedSchool, schoolId, props.userTeacher])

    useEffect(() => {
        if (props.expenses) {
            let tempList = []
            for (const i in props.expenses) {

                let obj = props.expenses[i].attributes
                let tempObject = {}
                tempObject.expenseNumber = obj.expenseNumber 
                tempObject.note = obj.notes ?? ""
                tempObject.amount = obj.amount ?? ""
                tempObject.tax = obj.tax
                tempObject.tds = obj.tds
                tempObject.paymentDate = obj.paymentDate
                tempObject.referenceNumber = obj.referenceNumber
                tempObject.paymentType = (obj.paymentType && obj.paymentType === 1) ? CASH : (obj.paymentType && obj.paymentType === 2) ? CHEQUE : ONLINE
                tempObject.expenseParseObject = props.expenses[i]
                tempObject.isVerified = obj.status === 1 ? true : false
                tempObject.createdAt = props.expenses[i].createdAt
                tempObject.supplierType = obj.supplierType;
                tempObject.supplierId = obj.supplierId;
                if (obj.attachmentFile) {
                    tempObject.attachmentDoc = obj.attachmentFile
                }
                if (obj.albumId) {
                    tempObject.albumId = obj.albumId
                }
                if (props.expenseCategories) {
                    let tempCategories = props.expenseCategories.filter((category) => {
                        if (obj.categoryId === category.id) {
                            return true
                        }
                    })
                    if (tempCategories.length > 0) {
                        tempObject.categoryParseObject = tempCategories[0]
                        tempObject.categoryName = tempCategories[0].attributes.name
                    }
                }

                if (props.expenseSuppliers && obj.supplierType == 1) {
                    let tempSuppliers = props.expenseSuppliers.filter((supplier) => {
                        if (obj.supplierId === supplier.id) {
                            return true
                        }
                    })
                    if (tempSuppliers.length > 0) {
                        tempObject.supplierParseObject = tempSuppliers[0]
                        tempObject.supplierName = tempSuppliers[0].attributes.name
                    }
                } else if (obj.supplierType == 2 && obj.supplierId) {
                    //teachers
                    let tempTeachers = props.teachers.filter((teacher) => {
                        if (obj.supplierId === teacher.id) {
                            return true
                        }
                    })
                    if (tempTeachers.length > 0) {
                        tempObject.supplierParseObject = tempTeachers[0]
                        tempObject.supplierName = tempTeachers[0].attributes.Name
                    }

                } else if (obj.supplierType == 3 && obj.supplierId) {
                    //kid
                    let tempStudents = props.kids.filter((student) => {
                        if (obj.supplierId === student.id) {
                            return true
                        }
                    })
                    if (tempStudents.length > 0) {
                        tempObject.supplierParseObject = tempStudents[0]
                        tempObject.supplierName = tempStudents[0].attributes.Name
                    }
                }

                tempList.push(tempObject)
            }
            setIsExpenseLoading(false)
            setExpenseList(tempList)
        }

    }, [props.expenses, props.expenseCategories, props.expenseSuppliers])

    useEffect(() => {
        if (props.expenseCategories && props.expenseCategories.length > 0) {
            let tempList = []
            let tempSelectList = []
            for (const i in props.expenseCategories) {

                let obj = props.expenseCategories[i].attributes
                let tempObject = {}
                tempObject.name = obj.name ?? ""
                tempObject.description = obj.description ?? ""
                tempObject.expenseCategoryParseObject = props.expenseCategories[i]
                tempObject.isDefault = obj.isDefault ?? false
                tempList.push(tempObject)


                let tempSelectObj = {}
                tempSelectObj.id = props.expenseCategories[i].id
                tempSelectObj.name = obj.name ?? ""
                tempSelectObj.value = props.expenseCategories[i].id
                tempSelectObj.label = obj.name ?? ""
                tempSelectObj.parseObject = props.expenseCategories[i]
                tempSelectList.push(tempSelectObj)
            }
            setExpenseCategoryList(tempList)
            setCategoryList(tempSelectList)

        }
    }, [props.expenseCategories])

    useEffect(() => {
        if (props.expenseSuppliers && props.expenseSuppliers.length > 0) {
            let tempList = []
            let tempSelectList = []

            for (const i in props.expenseSuppliers) {

                let obj = props.expenseSuppliers[i].attributes
                let tempObject = {}
                tempObject.name = obj.name ?? ""
                tempObject.companyName = obj.companyName
                tempObject.email = obj.email ?? ""
                tempObject.mobile = obj.mobile ?? ""
                tempObject.phone = obj.phone ?? ""
                tempObject.address = obj.address
                tempObject.website = obj.website ?? ""
                tempObject.isDefault = obj.isDefault ?? false
                tempObject.expenseSupplierParseObject = props.expenseSuppliers[i]
                tempList.push(tempObject)



                let tempSelectObj = {}
                tempSelectObj.id = props.expenseSuppliers[i].id
                tempSelectObj.name = obj.name ?? ""
                tempSelectObj.value = props.expenseSuppliers[i].id
                tempSelectObj.label = obj.name ?? ""
                tempSelectObj.parseObject = props.expenseSuppliers[i]
                tempSelectList.push(tempSelectObj)
            }

            setExpenseSupplierList(tempList)
            setSupplierList(tempSelectList)

        }
    }, [props.expenseSuppliers])

    useEffect(() => {
        if (props.kids && props.kids.length > 0) {
            let tempSelectList = []
            for (const i in props.kids) {
                let tempSelectObj = {}
                let obj = props.kids[i].attributes
                tempSelectObj.id = props.kids[i].id
                tempSelectObj.name = obj.Name ?? ""
                tempSelectObj.value = props.kids[i].id
                tempSelectObj.label = obj.Name ?? ""
                tempSelectObj.parseObject = props.kids[i]
                tempSelectList.push(tempSelectObj)
            }
            setKidList(tempSelectList)
        }
    }, [props.kids])

    useEffect(() => {
        if (props.teachers && props.teachers.length > 0) {
            let tempSelectList = []
            for (const i in props.teachers) {
                let tempSelectObj = {}
                let obj = props.teachers[i].attributes
                tempSelectObj.id = props.teachers[i].id
                tempSelectObj.name = obj.Name ?? ""
                tempSelectObj.value = props.teachers[i].id
                tempSelectObj.label = obj.Name ?? ""
                tempSelectObj.parseObject = props.teachers[i]
                tempSelectList.push(tempSelectObj)
            }
            setStaffList(tempSelectList)
        }
    }, [props.teachers])

    


    useEffect(() => {
        if (props.expenseResult) {
            setCreatingExpense(false)
            if (newTags && newTags.length > 0) {
                let listToSave = []
                for (const i in newTags) {

                    const ExpenseTags = Parse.Object.extend('ExpenseTags');
                    var expenseTag = new ExpenseTags();
                    expenseTag.set("tagName", newTags[i])
                    expenseTag.set("instituteId", props.selectedSchool.attributes.instituteId);
                    expenseTag.set("schoolId", props.selectedSchool.id);

                }
                listToSave.push(expenseTag)
                if (listToSave.length > 0) {
                    Parse.Object.saveAll(listToSave).then(
                        (results) => {

                            let objectTagMapList = []
                            for (const i in selectedTags) {
                                if (!selectedTags[i].expenseTagMapParseObject) {
                                    const ExpenseTagMap = Parse.Object.extend('ExpenseTagMap');
                                    var expenseTagMap = new ExpenseTagMap();
                                    if (selectedTags[i].__isNew__) {
                                        let filteredList = results.filter((tag) => {
                                            if (tag.attributes.tagName === selectedTags[i].value) {
                                                return true
                                            }
                                        })
                                        if (filteredList.length > 0) {
                                            expenseTagMap.set("tagId", filteredList[0].id);
                                        }
                                    } else {
                                        expenseTagMap.set("tagId", selectedTags[i].parseObject.id);
                                    }
                                    expenseTagMap.set("expenseId", props.expenseResult.id);
                                    objectTagMapList.push(expenseTagMap);
                                }
                            }
                            setSelectedTags([])
                            if (objectTagMapList.length > 0) {
                                Parse.Object.saveAll(objectTagMapList).then(
                                    (results) => {

                                        props.clearExpenseResult()

                                    },
                                    (error) => {
                                        console.log("Error", error);
                                        props.clearExpenseResult()
                                    }
                                );
                            } else {
                                props.clearExpenseResult()
                            }

                        },
                        (error) => {
                            console.log("Error", error);
                            props.clearExpenseResult()
                        }
                    );
                } else {
                    props.clearExpenseResult()

                }
            } else {
                let objectTagMapList = []
                for (const i in selectedTags) {
                    if (!selectedTags[i].expenseTagMapParseObject) {
                        const ExpenseTagMap = Parse.Object.extend('ExpenseTagMap');
                        var expenseTagMap = new ExpenseTagMap();

                        expenseTagMap.set("tagId", selectedTags[i].parseObject.id);

                        expenseTagMap.set("expenseId", props.expenseResult.id);
                        objectTagMapList.push(expenseTagMap);
                    }
                }
                if (objectTagMapList.length > 0) {
                    Parse.Object.saveAll(objectTagMapList).then(
                        (results) => {

                            props.clearExpenseResult()

                        },
                        (error) => {
                            console.log("Error", error);
                            props.clearExpenseResult()
                        }
                    );
                } else {
                    props.clearExpenseResult()
                }
            }
        }
    }, [props.expenseResult])

    const getExpenseTags = (id, instituteId) => {


        var ExpenseTags = Parse.Object.extend("ExpenseTags");

        var query1 = new Parse.Query(ExpenseTags);
        query1.equalTo("schoolId", schoolId);


        var query2 = new Parse.Query(ExpenseTags);
        query2.equalTo("instituteId", instituteId);

        var query3 = new Parse.Query(ExpenseTags);
        query3.equalTo("isDefault", true);

        var mainQuery = Parse.Query.or(query1, query3);
        if (instituteId) {
            mainQuery = Parse.Query.or(query1, query2, query3);
        }


        mainQuery.notEqualTo("isDeleted", true);
        mainQuery.descending("createdAt");
        mainQuery.limit(1000)
        mainQuery.find().then(
            (results) => {
                if (results && results.length > 0) {
                    let tempSelectList = []


                    for (const i in results) {
                        let tempObj = {}
                        tempObj.id = results[i].id
                        tempObj.value = results[i].id
                        tempObj.label = results[i].attributes.tagName ?? ""
                        tempObj.parseObject = results[i]
                        tempSelectList.push(tempObj)

                    }
                    setTagList(tempSelectList)

                }
            },
            (error) => {

            }
        );

    }

    function convertDateToString(date) {
        return moment(date).format('DD MMM YYYY')
    }

    function toggleCustom(tab) {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    }
    const [searchString, setSearchString] = useState('');
    const handelSearch = (string) => {
        setSearchString(string)
    }

    function formatDate(d) {
        if (d == null || d === "" || typeof d === "string" || d instanceof String) {
            return "";
        } else {
            return Moment(d).format("YYYY-MM-DD");
        }
    }


    const handleOnClickAddNewExpense = (expense, selectedTags, newTags) => {
        setSelectedTags(selectedTags)
        setNewTags(newTags)
        setShowExpenseModal(false);
        setPreSelectedCategory(undefined)
        setPreSelectedSupplier(undefined)
        setEditParseObject(undefined);
        setDuplicateParseObject(undefined);
        props.editExpense(expense);
    }

    const showCategoryModalToggle = () => {
        setShowExpenseModal(false)
        setPreSelectedCategory(undefined)
        setPreSelectedSupplier(undefined)
        setShowCategoryModal(true)
        toggleCustom("2")

    }

    const showSupplierModalToggle = () => {
        setShowExpenseModal(false)
        setPreSelectedCategory(undefined)
        setPreSelectedSupplier(undefined)
        setShowSupplierModal(true)
        toggleCustom("3")
    }



    const handleOnClickDuplicate = (parseObject) => {
        setDuplicateParseObject(parseObject)
        setPreSelectedCategory(undefined)
        setPreSelectedSupplier(undefined)
        setShowExpenseModal(true)
    }

    const handleOnClickEdit = (parseObject) => {
        setEditParseObject(parseObject)
        setPreSelectedCategory(undefined)
        setPreSelectedSupplier(undefined)
        setShowExpenseModal(true)
    }

    const onCreatingExpense = (boolValue) => {
        setCreatingExpense(boolValue)
    }

    const getExpencesForDate = (fromDate, toDate, selectedCategory, selectedSupplierTypeList,supplier) => {
        props.getExpensesForDate(schoolId, fromDate, toDate, selectedCategory, selectedSupplierTypeList, supplier);
    }

    const clearExpence = () => {
        props.clearExpenses(schoolId)
        props.getExpensesForSchoolId(schoolId);
    }

    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <TopBar
                        title="Expense Management"
                        schoolId={schoolId}
                        showSearchBar={customActiveTab === "0" ? true : false}
                        onSearch={handelSearch}
                        handleButtonOnClick={() => {
                            setShowExpenseModal(true)
                            setPreSelectedCategory(undefined)
                            setPreSelectedSupplier(undefined)
                         }}
                        showAddNewExp={writePermission}
                        showDropDown={showWalletBalance}
                        closeDropDown={() => { setShowWalletBalance(!showWalletBalance) }}
                        handleWalletOnClick={() => { setShowWalletPopup(true) }}
                        balance={props.expenseWallet && props.expenseWallet.attributes.balance}
                        currency={currency}
                    />
                    <Card className="mb-0">
                        <CardBody>
                            <Nav pills className="navtab-bg nav-justified ">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1");
                                        }}
                                    >
                                        {props.t('All Expenses').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                                {true && <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "4",
                                        })}
                                        onClick={() => {
                                            toggleCustom("4");
                                        }}
                                    >
                                        {props.t('Wallet').toUpperCase()}
                                    </NavLink>
                                </NavItem>}
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2");
                                        }}
                                    >
                                        {props.t('Categories').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleCustom("3");
                                        }}
                                    >
                                        {props.t('Suppliers').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                    {showUI ? <TabContent activeTab={customActiveTab}>
                        <TabPane tabId="1" >
                            {!isExpenseLoading && <div>{(expenseList && expenseList.length > 0) ? <ExpenseManagement
                                expenseList={expenseList}
                                onClickEdit={(parseObject) => {
                                    handleOnClickEdit(parseObject)
                                }}
                                // onClickVerify={(parseObject) => {
                                //     handleOnClickVerify(parseObject)
                                // }}
                                onClickDuplicate={(parseObject) => {
                                    handleOnClickDuplicate(parseObject)
                                }}

                                isSuperAdmin={isSuperAdmin}
                                teacher={props.userTeacher}
                                writePermission={writePermission}
                                dateFilter={(fromDate, toDate, selectedCategory, selectedSupplierTypeList,supplier) => {
                                    getExpencesForDate(fromDate, toDate, selectedCategory, selectedSupplierTypeList, supplier)
                                }}
                                clearExpence={() => {
                                    clearExpence()
                                }}
                                currency={currency}
                            />
                                :

                                <Card className="mt-3">
                                    <CardBody>
                                        <center ><Label className='text-center' style={{ textAlign: "center" }}>No Expenses to Show !!</Label></center>
                                    </CardBody>
                                </Card>
                            }</div>}
                            {
                                isExpenseLoading &&
                                <Row>
                                    <Col xs="12">
                                        <div className="text-center my-3">
                                            <Link to="#" className="text-success" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                                Loading Expenses ...{" "}
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                            }
                        </TabPane>
                        <TabPane tabId="4" id="all-order">
                            <ExpenseWallet
                                schoolId={schoolId}
                                balance={props.expenseWallet && props.expenseWallet.attributes.balance}
                                expenseList={expenseList}
                                currency={currency}
                            />
                        </TabPane>
                        <TabPane tabId="2" >
                            <ExpenseCategory
                                expenseCategoryList={expenseCategoryList}
                                editExpenseCategory={(expenseCategory) => {
                                    props.editExpenseCategory(expenseCategory)
                                }}
                                school={props.selectedSchool}
                                teacher={props.userTeacher}
                                showModal={showCategoryModal}
                                toggleCategoryModal={(boolValue) => {
                                    setShowCategoryModal(boolValue)
                                }}
                                addExpense={(category) => {
                                    setPreSelectedCategory(category)
                                    setPreSelectedSupplier(undefined)
                                    setShowExpenseModal(true)
                                }}
                                writePermission={writePermission}
                            />
                        </TabPane>
                        <TabPane tabId="3" >
                            <ExpenseSupplier
                                expenseSupplierList={expenseSupplierList}
                                editExpenseSupplier={(expenseSupplier) => {
                                    props.editExpenseSupplier(expenseSupplier)
                                }}
                                school={props.selectedSchool}
                                teacher={props.userTeacher}
                                showModal={showSupplierModal}
                                toggleSupplierModal={(boolValue) => {
                                    setShowSupplierModal(boolValue)
                                }}
                                addExpense={(supplier) => {
                                    setPreSelectedSupplier(supplier)
                                    setPreSelectedCategory(undefined)
                                    setShowExpenseModal(true)
                                }}
                                writePermission={writePermission}
                            />
                        </TabPane>
                    </TabContent> :
                        <Row>
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>

            <AddExpenseModal
                showExpenseModal={showExpenseModal}
                kidList={kidList ?? []}
                staffList={staffList ?? []}
                categoryList={categoryList ?? []}
                supplierList={supplierList ?? []}
                taxList={taxList ?? []}
                showCategoryModalToggle={() => {
                    showCategoryModalToggle()
                }}
                showSupplierModalToggle={() => {
                    showSupplierModalToggle()
                }}
                tagList={tagList ?? []}
                instituteId={props.selectedSchool && props.selectedSchool.attributes.instituteId}
                schoolId={props.selectedSchool && props.selectedSchool.id}
                creatingExpense={creatingExpense}
                toggleExpenseModal={(boolValue) => {
                    setPreSelectedCategory(undefined)
                    setPreSelectedSupplier(undefined)
                    setShowExpenseModal(boolValue)
                }}
                onCreatingExpense={(boolValue) => {
                    setPreSelectedCategory(undefined)
                    setPreSelectedSupplier(undefined)
                    onCreatingExpense(boolValue)
                }}
                duplicateParseObject={duplicateParseObject}
                editParseObject={editParseObject}
                writePermission={writePermission}
                handleOnClickAddNewExpense={(expense, selectedTags, newTags) => {
                    setPreSelectedCategory(undefined)
                    setPreSelectedSupplier(undefined)
                    handleOnClickAddNewExpense(expense, selectedTags, newTags)
                }}
                currency={currency}
                preSelectedCategory={preSelectedCategory}
                preSelectedSupplier={preSelectedSupplier}
                
            />
            <Modal size='sm' isOpen={showWalletPopup} toggle={() => { setShowWalletPopup(!showWalletPopup) }} scrollable={true}>
                <ModalHeader toggle={() => {
                    setShowWalletPopup(!showWalletPopup)
                }}>
                    Enter Amount
                </ModalHeader>
                <ModalBody>
                    <Row form>
                        <Col className="col-12">
                            <Form>
                                <FormGroup>
                                    <Input
                                        type="number"
                                        name="number"
                                        placeholder=""
                                        value={walletAmount}
                                        onChange={(e) => {
                                            setWalletAmount(e.target.value);
                                        }}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-right">
                                <Button color='primary' className="btn mr-2" onClick={() => {
                                    props.addExpenseWallet(schoolId, parseFloat(walletAmount), Parse.User.current().attributes.teacherId)
                                    setShowWalletPopup(!showWalletPopup)
                                }
                                }>Done</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { expenses, expenseResult, expenseWallet } = state.Expenses;
    const { expenseCategories } = state.ExpenseCategory;
    const { expenseSuppliers } = state.ExpenseSupplier;
    const { userTeacher } = state.Login;
    const { teachers } = state.Teacher;
    const { kids } = state.Kid;

    return {
        selectedSchool, expenses, expenseCategories, expenseSuppliers,
        userTeacher, teachers, kids, expenseResult, expenseWallet
    };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getExpensesForSchoolId,
    getExpenseCategoryForSchoolId,
    getExpenseSupplierForSchoolId,
    editExpenseCategory,
    getTeacher,
    editExpenseSupplier,
    getTeachersForSchool,
    getAllKids,
    editExpense,
    clearExpenseResult,
    getExpensesForDate,
    clearExpenses,
    addExpenseWallet,
    getExpenseWalletForSchoolId
})(ExpenseIndex)));
