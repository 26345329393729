import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { Row,Col, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Table, Card,CardBody, UncontrolledTooltip,Container, Label } from "reactstrap";


import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import Parse from 'parse';
import Moment from 'moment';
import Image from "../../../assets/images/book_lover.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import * as CommonFunctions from "../../../components/Common/CommonFunctions";
import {
    setSelecetdSchool,
    getCoursesForOwnerIds,
    deleteReminder,
} from "../../../store/actions";

import TopBar from '../TopBar';

const AllAnnouncements = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/announcements/:schoolId',
        exact: true,
        strict: false,

    });
    const schoolId = match ? match.params.schoolId : "";

    const [loading, setLoading]=useState(true);
    const [allAnnouncement, setAnnouncement]=useState([]);

    const [ownerIds, setOwnerIds] = useState([]);
    const [courseIds, setCourseIds] = useState([]);

    const [deleteAnnouncement,setDeleteAnnouncement]=useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        getAllAnouncementsForSchool();
    }, [schoolId]);

    useEffect(() => {
       
        var ids = [];
        if (props.selectedSchool) {
            ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }


            if (ownerIds.length === ids.length) {

                var call = false;
                for (const i in ownerIds) {
                    if (ownerIds[i] !== ids[i]) {
                        call = true;
                    }
                }
                if (call) {
                    props.getCoursesForOwnerIds(ids);
                }

            } else {
                setOwnerIds(ids);
                props.getCoursesForOwnerIds(ids);
            }
        }

    }, [props.selectedSchool]);


    const getAllAnouncementsForSchool=()=>{
        var Announcement = Parse.Object.extend("Announcement");
        var query = new Parse.Query(Announcement);
        query.equalTo("schoolId", schoolId);
        // if (isLoacal) {
        //   query.fromLocalDatastore();
        // }
        query.notEqualTo("isDeleted", true);
  
        query.descending("createdAt");
        query.limit(1000);
        query.find().then(
          (result) => {
            setLoading(false)
            setAnnouncement(result);
          },
          (error) => {
            
          }
        );
    }
    
    const handelDeleteAnnouncement =(announcement)=>{
        
        announcement.set("deleted", Parse.User.current().attributes.teacherId);
        announcement.set('isDeleted',true);
        announcement.save().then(
            (result) => {
                if(announcement.attributes.reminderId){

                    const Reminder = Parse.Object.extend("Reminder");
                    let reminder = new Reminder();
                    reminder.id = announcement.id;
                    reminder.set("isDeleted", true);
                    reminder.set("status", false);
                    props.deleteReminder(reminder[0]);
                }

                var others = allAnnouncement.filter(
                    (an) => an.id !== announcement.id
                  );
                setAnnouncement(others);

                Parse.Cloud.run("function_sendEmail", {
                    schoolId: schoolId,
                    type:"announcement",
                    announcementId:result.id,
                    emailType:"delete"
                }).then((res) => {
                    // console.log("function_sendEmail r",res)
                }, (error) => {
                    // console.log("function_sendEmail e",error)
                });

                //send cancel email to users

            }
        );
    }

    
    const handelOnClickBack = () => {
        window.history.back();
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }
    
    const handelAdd=()=>{
        props.history.push("/create-announcements/" + schoolId );
    }
    const getCourseForCourseId=(courseId)=>{
        if (props.courses) {
            let course;
            for (const cr of props.courses) {
                if (courseId===cr.id) {
                    course= cr;  
                }
            }
            if (course) {
                return course.attributes.name;
            } 
        }
        return "--"
    }
    
    return (
    
    <React.Fragment>
        <div className="page-content">
            <Container fluid>

                <TopBar
                        title={"Announcements"}
                        backButton={false}
                        hideButton={false}
                        buttonName={"Create New"}
                        onClickAdd={handelAdd}
                        onClickBackButton={handelOnClickBack}
                    />
                


                {/* <Card className="email-leftbar">
                    <Button type="button" color="danger" className="waves-effect waves-light" onClick={() => { setmodal(!modal) }} block>
                        Compose
                    </Button>
                
                </Card>  */}

                {(!allAnnouncement || allAnnouncement.length === 0) ? <Card>
                        {loading?
                        <CardBody>
                        <Label  className="text-success">
                            <i className="bx bx-hourglass bx-spin mr-2"></i> Loading...{" "}
                        </Label>
                        </CardBody>:
                        <CardBody className="text-center" >
                            <Row className="justify-content-center">
                                <Col sm={4}>
                                    <div className="maintenance-img">
                                        <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>
                            </Row>
                            <h3 className="mt-5">{props.t('No Announcements ')}</h3>
                            <p>{props.t('Click in Create to add an Announcement ')}.</p>

                            <button type="button"
                                className="btn btn-primary "
                                onClick={() => {
                                    handelAdd();
                                }}  >
                                {props.t('Create')}
                            </button>
                        </CardBody>}
                    </Card>
                        :
                        <Row >
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th className="col-1" scope="col" style={{ width: "100px" }}>#</th>
                                                    <th className="col-9" scope="col">Details</th>
                                                    <th className="col-2" scope="col" align="right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allAnnouncement.map((announcement, key) =>
                                                    <tr key={announcement.id} >
                                                        <td><img src={Image} alt="" className="avatar-sm" /></td>
                                                        {

                                                            <td 
                                                                style={{ cursor: "pointer" }}
                                                               
                                                            >
                                                                <h6 className=" " ><Link  to={"#"} className='text-dark' ><strong>{"Title: "}</strong> {announcement.attributes.title}</Link></h6>
                                                                <p className=" mb-0" ><u><strong> Message:</strong> </u></p>
                                                                <p className=" mb-0" style={{ "whiteSpace": "pre-wrap" }} >{announcement.attributes.message}</p>
                                                                
                                                                {<p className=" mb-0" ><strong>Participants:</strong> {announcement.attributes.participants && announcement.attributes.participants.length >0 ?getCourseForCourseId(announcement.attributes.participants[0]):"@All Courses" }</p>}
                                                                {announcement.attributes.dateAndTime && <p className="text-muted"><strong>{"Date & Time :"}</strong> {getTimeStringForDate(announcement.attributes.dateAndTime)}</p>}

                                                                

                                                               
                                                            </td>
                                                        }
                                                        <td>
                                                            {

                                                                <ul className="list-inline font-size-20 contact-links mb-0 float-right">


                                                                    { <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                           setDeleteAnnouncement(announcement);
                                                                        }}
                                                                        id={"sub-delete" + key}>
                                                                        <i className="bx bx-trash text-danger"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                            {props.t('Delete')}
                                                                        </UncontrolledTooltip>

                                                                    </li>

                                                                    }
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                           props.history.push("/announcement-view/" + schoolId + "/" + announcement.id);
                                                                        }}
                                                                        id={"sub-cyc" + key}>
                                                                        <i className="bx bx-right-arrow-circle"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                            {props.t('Details')}
                                                                        </UncontrolledTooltip>
                                                                    </li>
                                                                </ul>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    }
            </Container>
        </div>
        { deleteAnnouncement ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        handelDeleteAnnouncement(deleteAnnouncement);
                        setDeleteAnnouncement(undefined);
                        setsuccess_dlg(true); 
                    }}
                    onCancel={() =>{
                        setDeleteAnnouncement(undefined);
                        setsuccess_dlg(false);
                        
                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {props.t('Announcement has been deleted.')}
                </SweetAlert>
            ) : null}
        </React.Fragment>
    );
    
}
   

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { courses } = state.Course;

    return { selectedSchool,courses };
};
export default connect(mapStatetoProps, {
    setSelecetdSchool,
    getCoursesForOwnerIds,
    deleteReminder,
})(withNamespaces()(AllAnnouncements));
