import Parse from "parse";
  
  const getInventoryCategoriesForOwnerId = (id, ownerType, updatedDate,isLocal,) => {
    try {
      return new Promise((resolve, reject) => {
        var InventorySchoolCategories = Parse.Object.extend("InventorySchoolCategories");
        var query = new Parse.Query(InventorySchoolCategories);
        query.equalTo("ownerId", id);
        query.equalTo("ownerType", ownerType)
  
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
        } else {
          query.notEqualTo("isDeleted", true);
        }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
  
        query.find().then(
          (result) => {
           resolve(result); 
          //  console.log("result", result)
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };

  const deleteInventoryCategoryFromParse = (inventoryCategory) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryCategory.set("isDisabled", true);
        if (Parse.User.current().attributes.teacherId) {
          inventoryCategory.set(
            "disabledBy", Parse.User.current().attributes.teacherId);
        } else {
          inventoryCategory.set("disabledBy", Parse.User.current().id);
        }
  
        inventoryCategory.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("error");
          }
        );
      });
    } catch (error) {}
  };

  const saveObjects = (object) => {
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) {}
  };
  
  
  export {
    getInventoryCategoriesForOwnerId,
    deleteInventoryCategoryFromParse,
    saveObjects,
  };
  