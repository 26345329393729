import React, {useState, useEffect}from "react";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import { Container, Row, Col, CardBody, Card, Button,Alert } from "reactstrap";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/lilLogo.png";
import avatar from "../../assets/images/users/avatar-1.jpg";

import profile from "../../assets/images/companies/img-3.png";
import * as Constants from "../../Constents";
import Parse from 'parse';



const LockScreen = (props) => {

  const [loding, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    
    if (Parse.User.current()) {
      localStorage.setItem(Constants.SCREEN_LOCK, "LOCK");
      // Parse.User.logOut().then(() => {
      // },(error) => { });
    } 
    
    
  });

  function onClickLogin(v){
    setLoading(true);
    setError(undefined);
    var email = localStorage.getItem(Constants.USER_PROFILE_EMAIL);
    
    Parse.User.logIn(email, v.password)
      .then((user) => {
        setLoading(false);
        localStorage.setItem(Constants.SCREEN_LOCK, "UNLOCK");
        // console.log(localStorage.getItem(Constants.SCREEN_LOCK));
      window.history.back();
    
      }, (error) => {
        setError("Incorrect Password");
        setLoading(false);
    })
  }
  

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Lock screen</h5>
                        <p>Enter your password to unlock the screen!</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      onValidSubmit={(e, v) => {
                        onClickLogin(v)
                        
                      }}
                      className="form-horizontal">
                      <div className="user-thumb text-center mb-4">
                        <img
                          src={localStorage.getItem(Constants.USER_PROFILE_PHOTO)? localStorage.getItem(Constants.USER_PROFILE_PHOTO) :profile}
                          className="rounded-circle img-thumbnail avatar-md"
                          alt="thumbnail"
                        />
                        <h5 className="font-size-15 mt-3">{ localStorage.getItem(Constants.USER_PROFILE_NAME)}</h5>
                      </div>

                      {error && error ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      {loding ?
                        
                        <div className="form-group row mb-0">
                          <Col xs="12" className="text-right">
                            <Button
                              color="primary"
                              className=" w-md waves-effect waves-light"
                              
                            >
                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                            </Button>
                          </Col>
                        </div>
                        
                        :<div className="form-group row mb-0">
                        <Col xs="12" className="text-right">
                          <Button
                            color="primary"
                            className=" w-md waves-effect waves-light"
                            type="submit"
                          >
                            Unlock
                          </Button>
                        </Col>
                      </div>}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Not you ? return{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Sign In{" "}
                  </Link>{" "}
                </p>
                <p>
                  © 2020 lilTriangle. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by lilTriangle
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default LockScreen;
