import React, { useEffect, useState } from 'react';
import {
    Container, Row, Col, Card, CardBody, Label, Input, Button, Modal, ModalBody, ModalHeader, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, TabContent,
    TabPane, Alert
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Topbar from '../Inventory/Topbar'
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import { getEnquiryRemindersForSchoolId, setSelecetdSchool, getTeachersForSchool, saveEnquiryReminder } from "../../store/actions";
import Select from 'react-select';
import { Badge, CardHeader, CardTitle } from 'reactstrap/lib';
import * as Constants from '../../Constents';

const EnquiryTasks = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [startDateDisplay, setStartDateDisplay] = useState(new Date());
    const [endDateDisplay, setEndDateDisplay] = useState(new Date());

    const [loading, setLoading] = useState(true);
    const [teacherList, setTeacherList] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(undefined);
    const [classModal, toggleClassModal] = useState(false);
    const [reminderToAssign, setReminderToAssign] = useState("");
    const [teacherSelectWarning, setTeacherSelectWarning] = useState();
    const [showStatusAlert, setShowStatusAlert] = useState(false)
    const [enquiryReminderObj, setEnquiryReminderObj] = useState();
    const [showTab, setShowTab] = useState(false);



    const match = matchPath(props.history.location.pathname, {
        path: "/enquiry-tasks/:id",
        exact: true,
        strict: false,
    });
    const schoolId = match && match.params.id;

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (startDate && endDate) {

            var fromDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
            var toDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59);

            setStartDateDisplay(fromDate)
            setEndDateDisplay(toDate)

            props.getEnquiryRemindersForSchoolId(schoolId, fromDate, toDate);
        }
        setLoading(false);
    }, [schoolId, startDate, endDate]);

    useEffect(() => {
        if (schoolId) {
            props.getTeachersForSchool(schoolId);
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.teachers && props.teachers.length > 0) {
            let list = []
            for (const teacher of props.teachers) {

                if (teacher.attributes.featurePermissions &&
                    teacher.attributes.featurePermissions.length > Constants.TEACHER_ENQUIRY &&
                    teacher.attributes.featurePermissions[Constants.TEACHER_ENQUIRY] > 0) {
                    list.push(teacher)
                }
            }
            setTeacherList(list);
        }
    }, [props.teachers]);

    function getDesignation(teacher) {
        if (teacher.designation) {
            return teacher.designation;
        } else if (teacher.Role === 3) {
            return props.t('Director');
        } else if (teacher.Role === 2) {
            return props.t('Center Head');
        } else {
            return props.t('Teacher');
        }
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY, h:mm A');
    }

    const toggleTab = () => {
        setShowTab(!showTab);
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }
    // console.log(props.enquiryRemindersForSchool)


    const changeStatus = (status, enquiryReminder) => {
        enquiryReminder.set("taskBy", Parse.User.current().attributes.teacherId)
        enquiryReminder.set("taskStatus", status)
        props.saveEnquiryReminder(enquiryReminder);
        setShowStatusAlert(false)
    }

    const saveTeacher = () => {


        setTeacherSelectWarning(false);
        if (reminderToAssign) {
            reminderToAssign.set("assignedTo", selectedTeacher);
            reminderToAssign.set("assignedBy", Parse.User.current().attributes.teacherId)
            props.saveEnquiryReminder(reminderToAssign);
            setLoading(false)

            toggleClassModal(false);
        }
    };
    return (
        <React.Fragment>
            {showStatusAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Are You Sure"
                    onCancel={() => {
                        setShowStatusAlert(false);
                    }}
                    onConfirm={() => {
                        if (enquiryReminderObj.attributes.taskStatus == 0) {
                            changeStatus(1, enquiryReminderObj)
                        } else {
                            changeStatus(0, enquiryReminderObj)
                        }

                    }}
                >
                    {enquiryReminderObj.attributes.taskStatus == 0 ? "Would you like to change  completed state " : "Would you like to change  not completed state "}
                    <br></br>
                </SweetAlert>
            )}
            <Modal isOpen={classModal} scrollable={true}>
                <ModalHeader isOpen={classModal}>
                    <div className="modal-title mt-0">Assign Teacher</div>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <p>
                        {props.t("Please select Teacher")}
                    </p>

                    <p>
                        {props.t("Showing all the staff who have Enquiry read permission")}
                    </p>


                    {teacherList.map((teacher, i) => (
                        <div key={i}
                        >

                            <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={teacher.id}
                                checked={selectedTeacher == teacher.id}
                                onChange={() => {
                                    setSelectedTeacher(teacher.id);
                                }}


                            >

                            </Input>
                            <span onClick={() => setSelectedTeacher(teacher.id)}>
                                {" " + teacher.attributes.Name + "(" + getDesignation(teacher) + ")"}
                            </span>
                        </div>
                    ))}

                    {teacherList && teacherList.length > 0}
                    {teacherSelectWarning && (
                        <p className="text-danger m-2">
                            {props.t("Please select")}
                        </p>
                    )}

                </ModalBody>
                <ModalFooter>
                    {!loading ? (
                        <div>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setTeacherSelectWarning(false);
                                    toggleClassModal(!classModal);
                                }}
                            >
                                {props.t("Close")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary m-2"
                                onClick={() => {
                                    if (selectedTeacher) {
                                        setTeacherSelectWarning(false);
                                        setLoading(true);
                                        saveTeacher()
                                    } else {
                                        setTeacherSelectWarning(true);
                                    }
                                }}
                            >
                                {props.t("Save")}
                            </button>
                        </div>
                    ) : (
                        <button type="button" className="btn btn-light waves-effect">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                            {props.t("")}
                        </button>
                    )}
                </ModalFooter>

            </Modal>
            <Container>
                <div className='page-content'>
                    <Row>
                        <Col lg={6}>
                            <Topbar
                                title={"Enquiry Tasks"}
                                buttonName={"New "}
                                hideButton={true}
                            />
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex justify-content-end">

                                <Button type="button" color="primary" onClick={toggleTab} >

                                    {props.t('How to add tasks')}
                                </Button>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col lg={4} className="mt-3 mb-5">
                            <Card style={{}} className="mb-5">
                                <CardBody style={{ textAlign: 'center' }}>

                                    <h4>Pick Date:</h4>
                                    <ReactDatePicker
                                        style={{ width: '100%', height: '100%' }}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        inline
                                        onChange={handleDateChange}

                                    // maxDate={new Date((startDate?? new Date).getTime() + (7 * 24 * 60 * 60 * 1000))}
                                    />

                                </CardBody>
                            </Card>
                        </Col>

                        {startDate && (
                            <Col lg={8} className="mt-3 mb-3">

                                <Card>


                                    <CardBody>

                                        {showTab && (

                                            <Alert color="info" className="" >
                                                <div xs={6}>
                                                <p className="lead mb-1"><strong><u>Navigate to the Enquiry Profile:</u></strong></p>
                                                <p className="mb-0">* Go to the Enquiry section.</p>
                                                <p className="mb-0">* Find and select the specific Enquiry profile you want to add a task to..</p>

                                                <p className="lead mb-1"><strong><u>Scroll to the Bottom and Click on Tasks:</u></strong></p>
                                                <p className="mb-0">* Once you're in the profile, scroll down to the bottom of the page.</p>
                                                <p className="mb-0">* Look for a section labeled "Tasks".</p>

                                                <p className="lead mb-1"><strong><u>Add Task:</u></strong></p>
                                                <p className="mb-0">* Within the Tasks section, you should see an option to "Add Task" button.</p>
                                                <p className="mb-0">* Click on "Add Task" to open the task creation window.</p>

                                                <p className="lead mb-1"><strong><u>Type Task Message and Set Task Reminder Time:</u></strong></p>
                                                <p className="mb-0"> * In the task creation window, you'll typically have a text field to enter your task message.</p>
                                                <p className="mb-0"> * Type in the details of your task, including what needs to be done.</p>
                                                <p className="mb-0"> * Next, set the task reminder time. This could involve choosing a specific date and time for the reminder to alert you about the task.</p>

                                                <p className="lead mb-1"><strong><u>Save:</u></strong></p>
                                                <p className="mb-0">* After entering the task message and setting the reminder time, look for a "Save" button.</p>
                                                <p className="mb-0">* Click on "Save" to confirm and save your task.</p>


                                        


                                                    
                                                </div>

                                            </Alert>
                                        )}
                                        {startDateDisplay && endDateDisplay && (
                                            <CardHeader>
                                                <CardTitle>
                                                    <b>Selected Dates:</b> {`${Moment(startDateDisplay).format('DD-MMM-YYYY')} to ${Moment(endDateDisplay).format('DD-MMM-YYYY')}`}
                                                </CardTitle>
                                            </CardHeader>
                                        )}

                                        <hr />
                                        <div style={{ height: '300px', overflowY: 'auto' }}>
                                            {loading ?
                                                <div className="text-center">
                                                    <p>
                                                        <Link to="#" className="text-success">
                                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Fetching Tasks')}
                                                        </Link>
                                                    </p>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    {!props.enquiryRemindersForSchool && <div>
                                                    <Label className="text-success">
                                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                                            <span>{props.t('Fetching Tasks')}</span>
                                                    </Label></div>}
                                                    {props.enquiryRemindersForSchool && props.enquiryRemindersForSchool.length === 0 && <p>No Tasks</p>}
                                                    {props.enquiryRemindersForSchool && props.enquiryRemindersForSchool.length > 0 && (
                                                        <div>
                                                            {props.enquiryRemindersForSchool.map((reminder, index) => (
                                                                <div key={index} className='m-1'>

                                                                    <Row>
                                                                        <Col lg={6} style={{ margin: "0mm" }}>

                                                                            <h5 >{reminder.attributes.title}</h5>
                                                                            <div style={{ fontSize: "4mm" }}>{reminder.attributes.body}</div>
                                                                            <p className="text-muted m-1">{props.t('Dated') + ":" + getTimeStringForDate(reminder.attributes.createdAt)}</p>
                                                                            <div className='d-flex'>
                                                                                <Label className='m-1'>Assign To: {reminder.attributes.assignedTo ? getTeacherName(reminder.attributes.assignedTo) : ""}</Label>
                                                                                <Badge
                                                                                    onClick={() => {
                                                                                        setSelectedTeacher(reminder.attributes.assignedTo);
                                                                                        setReminderToAssign(reminder)
                                                                                        toggleClassModal(true);
                                                                                    }}
                                                                                    style={{ cursor: "pointer" }}
                                                                                    className={`badge font-size-11 m-1 badge-soft-primary`}
                                                                                >
                                                                                    {reminder.attributes.assignedTo ? "Change" : "Assign to"}
                                                                                    <i className="mdi mdi-chevron-down"></i>
                                                                                </Badge>
                                                                            </div>
                                                                            <div className='m-1'>
                                                                                <Label htmlFor="setSelectedStatus" >Status:</Label>
                                                                                <div
                                                                                    onClick={() => {
                                                                                        setShowStatusAlert(true);
                                                                                        setEnquiryReminderObj(reminder)
                                                                                    }}
                                                                                    style={{ cursor: "pointer" }}
                                                                                    className={reminder.attributes.taskStatus == 0 ? "badge font-size-11 m-1 badge-soft-warning" : "badge font-size-11 m-1 badge-soft-success"}

                                                                                >
                                                                                    {reminder.attributes.taskStatus == 0 ? "Not completed" : "Completed"}
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <b>Reminder time:</b>
                                                                            <p className="text-muted"> {getTimeStringForDate(reminder.attributes.reminderDateTime)}</p>
                                                                            <div>
                                                                                <Link to={"/enquiryprofile/" + schoolId + "/" + reminder.attributes.enquiryId}>
                                                                                    <u>{props.t('View Details')}</u>
                                                                                </Link>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>


                                                                    <hr></hr>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            }
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { enquiryRemindersForSchool } = state.EnquiryReminder;
    const { teachers } = state.Teacher;

    return { selectedSchool, enquiryRemindersForSchool, teachers };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getEnquiryRemindersForSchoolId,
    getTeachersForSchool,
    saveEnquiryReminder,
})(EnquiryTasks)));

