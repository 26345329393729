import * as actions from "./actionTypes"

export const updateInventoryCategories = (inventoryCategories) =>{
    return{
        type:actions.UPDATE_INVENTORY_CATEGORIES,
        payload: {inventoryCategories},
    }
}

export const getInventoryCategoriesForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_CATEGORIES,
    payload: {ownerId, ownerType},
    }
}

export const deleteInventoryCategory = (inventoryCategory) =>{
    return{
        type:actions.DELETE_INVENTORY_CATEGORY,
        payload: {inventoryCategory},
    }
}

export const updateDeleteInventoryCategory = (inventoryCategory) =>{
    return{
        type:actions.UPDATE_DELETE_INVENTORY_CATEGORY,
        payload: {inventoryCategory},
    }
}
