import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Label, Input, Progress } from "reactstrap";
import classnames from 'classnames';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import { matchPath } from "react-router";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Select from "react-select";
import Editable from "react-bootstrap-editable";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import create from '../../assets/images/registration/create.svg';

import schoolImage from '../../assets/images/registration/single_branch.svg';
import instituteImage from '../../assets/images/registration/multiple_branch.svg';
import franchiseImage from '../../assets/images/registration/FranchiserMultipleBranches.svg';
import accademyImage from '../../assets/images/registration/AcademyWith_1_branch.svg';
import accademyBranchesImage from '../../assets/images/registration/AcademyMultipleBranches.svg';

import * as WhiteLable from '../../components/Common/WhiteLable'
import * as Constents from '../../Constents'
import COUNTRY_JSON from '../../assets/other/countries.json'

import UserDisableWarning from "./UserDisableWarning";



const Index = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/new-branch",
        exact: true,
        strict: false,
    });

    const path = match && match.path;

    const [isUserDisabled, setIsUserDisabled] = useState(false);

    useEffect(() => {
        if (Parse.User.current() === null || Parse.User.current() === undefined) {
            window.location = '/login';
        } else if (Parse.User.current().attributes.instituteId !== undefined
            && Parse.User.current().attributes.instituteId === null
            && Parse.User.current().attributes.instituteId.length > 0) {
            window.location = '/';
        } else if (!match) {

            if (Parse.User.current().attributes.isDisabled) {
                let l = Parse.User.current().attributes.isDisabled;
                // console.log("f1",l)
                setIsUserDisabled(true);
            } else {
                let franchiseId = Parse.User.current().attributes.franchiseId;
                let instituteId = Parse.User.current().attributes.instituteId;
                let teacherId = Parse.User.current().attributes.teacherId;

                let id = franchiseId ?? instituteId;
                // console.log("teacherId:", teacherId)
                if (teacherId && (!id)) {
                    // console.log("f2")
                    setIsUserDisabled(true);
                }
            }
        }
    });

    const schoolTypeList =
        [{ id: "1", type: "School or Preschool with 1 Branch    ", image: schoolImage },
        { id: "2", type: "School or Preschool with More than 1 Branch", image: instituteImage },
        { id: "3", type: "Franchiser Who Runs Multiple Branches", image: franchiseImage },
        { id: "4", type: "Academy with 1 branch", image: accademyImage },
        { id: "5", type: "Academy with Multiple Branches ", image: accademyBranchesImage },
        ]
    const allDays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];



    const [cyclicYears, setCyclicYears] = useState([
        {
            id: "Daycare",
            i: 0,
            title: "Daycare",
            actualCycles: ["Infant 0-18 Months", "Toddler 13-36 Months"],
            cycles: ["Infant	0-18 Months", "Toddler 	13-36 Months"],
            c: [{ id: "1.1", i: 0, year: "Infant 0-18 Months", renamed: undefined }, { id: "1.2", i: 1, year: "Toddler 13-36 Months", renamed: undefined }]
        },
        {
            id: "Foundational",
            i: 1,
            title: "Foundational Stage - (5 Years)",
            actualCycles: ["PlayGroup", "Nursery", "LKG", "UKG", "Class1", "Class2"],
            cycles: ["PlayGroup", "Nursery", "LKG", "UKG", "Class1", "Class2"],
            c: [{ id: "2.0", i: 0, year: "PlayGroup", renamed: undefined }, { id: "2.1", i: 1, year: "Nursery", renamed: undefined }, { id: "2.2", i: 2, year: "LKG", renamed: undefined }, { id: "2.3", i: 3, year: "UKG", renamed: undefined }, { id: "2.4", i: 4, year: "Class1", renamed: undefined }, { id: "2.5", i: 5, year: "Class2", renamed: undefined }]
        },
        {
            id: "Preparatory",
            i: 2,
            title: "Preparatory Stage - (3 Years)",
            actualCycles: ["Class3", "Class4", "Class5 "],
            cycles: ["Class3", "Class4", "Class5"],
            c: [{ id: "3.1", i: 0, year: "Class3", renamed: undefined }, { id: "3.2", i: 1, year: "Class4", renamed: undefined }, { id: "3.3", i: 2, year: "Class5", renamed: undefined },]
        },
        {
            id: "Middle",
            i: 3,
            title: "Middle Stage - (3 Years)",
            actualCycles: ["Class6", "Class7", "Class8"],
            cycles: ["Class6", "Class7", "Class8"],
            c: [{ id: "4.1", i: 0, year: "Class6", renamed: undefined }, { id: "4.2", i: 1, year: "Class7", renamed: undefined }, { id: "4.3", i: 2, year: "Class8", renamed: undefined },]
        },
        {
            id: "Secondary",
            i: 4,
            title: "Secondary Stage - (4 Years)",
            actualCycles: ["Class9", "Class10", "Class11", "Class12 "],
            cycles: ["Class9", "Class10", "Class11", "Class12"],
            c: [{ id: "5.1", i: 0, year: "Class9", renamed: undefined }, { id: "5.2", i: 2, year: "Class10", renamed: undefined }, { id: "5.3", i: 2, year: "Class11", renamed: undefined }, { id: "5.4", i: 3, year: "Class12", renamed: undefined }]
        }

    ])

    const [selectedSchool, setSelectedSchool] = useState(undefined);
    const [multiBranch, setMutiBranch] = useState(false);
    const [showWizard, setShowWizard] = useState(false);
    const [activeTab, setactiveTab] = useState(1);
    const [progressValue, setprogressValue] = useState(33);
    const [newBranch, setNewBranch] = useState(false);
    const [countryCodeList, setCountryCodeList] = useState([])


    useEffect(() => {
        setAllCountryCodeList()
        if (path === "/new-branch") {
            setSelectedSchool(schoolTypeList[0]);
            setNewBranch(true);
            setMutiBranch(false);
            setactiveTab(2)
            setprogressValue(33);
            setShowWizard(true);
        }

    }, [path]);

    //Institute

    const [instituteName, setInstituteName] = useState(undefined);
    const [instituteAddress, setInstituteAdress] = useState(undefined);
    const [institutePhone, setInstitutePhone] = useState(undefined);
    const [instituteEmail, setInstituteEmail] = useState(undefined);
    const [instituteCity, setInstituteCity] = useState(undefined);
    const [instituteState, setInstituteState] = useState(undefined);
    const [instituteCountry, setInstituteCountry] = useState({ value: "IN", label: 'India' });
    const [institutePincode, setInstitutePincode] = useState(undefined);


    //School

    const [schoolName, setSchoolName] = useState(undefined);
    const [schoolAddress, setSchoolAdress] = useState(undefined);
    const [schoolPhone, setSchoolPhone] = useState(undefined);
    const [schoolEmail, setSchoolEmail] = useState(undefined);
    const [schoolCity, setSchoolCity] = useState(undefined);
    const [schoolState, setSchoolState] = useState(undefined);
    const [schoolCountry, setSchoolCountry] = useState({ value: "IN", label: 'India' });
    const [schoolPincode, setSchoolPincode] = useState(undefined);
    const [schoolWebsite, setSchoolWebsite] = useState(undefined);
    const [schoolLanguage, setSchoolLanguage] = useState(undefined);
    const [schoolLocality, setSchoolLocality] = useState(undefined);

    const [schoolStartTime, setSchoolStartTime] = useState(undefined);
    const [schoolEndTime, setSchoolEndTime] = useState(undefined);
    const [schoolWorkingDays, setSchoolWorkingDays] = useState(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]);



    const [selectedCycles, setSelectedCycles] = useState([]);

    const [createSchool, setCreateSchool] = useState(false);


    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
            }
            if (multiBranch) {
                if (tab === 1) {
                    setprogressValue(25)
                } else if (tab === 2) {
                    setprogressValue(50)
                } else if (tab === 3) {
                    setprogressValue(75)
                } else if (tab === 4) {
                    setprogressValue(100)
                }
            } else {
                if (tab === 2) {
                    setprogressValue(33)
                } else if (tab === 3) {
                    setprogressValue(66)
                }
                else if (tab === 4) {
                    setprogressValue(100)
                }
            }
        }
    }

    const [instituteNameError, setInstituteNameError] = useState(false);
    const [institutePhoneError, setInstitutePhoneError] = useState(false);
    const [instituteEmailError, setInstituteEmailError] = useState(false);
    function instituteValidation() {
        var res = true;
        if (!instituteName || instituteName.trim().length === 0) {
            setInstituteNameError(true);
            res = false;
        }
        if (!institutePhone || institutePhone.trim().length === 0) {
            setInstitutePhoneError(true);
            res = false;
        }
        if (!instituteEmail || instituteEmail.trim().length === 0) {
            setInstituteEmailError(true);
            res = false;
        }

        return res;
    }

    const [schoolNameError, setSchoolNameError] = useState(false);
    const [schoolAddressError, setSchoolAdressError] = useState(false);
    const [schoolPhoneError, setSchoolPhoneError] = useState(false);
    const [schoolEmailError, setSchoolEmailError] = useState(false);
    const [schoolCityError, setSchoolCityError] = useState(false);
    const [schoolStateError, setSchoolStateError] = useState(false);
    const [schoolAreaError, setSchoolAreaError] = useState(false);
    const [schoolPincodeError, setSchoolPincodeError] = useState(false);
    const [schoolCountryError, setSchoolCountryError] = useState(false);
    function schoolValidation() {
        var res = true;
        if (!schoolName || schoolName.trim().length === 0) {
            setSchoolNameError(true);
            res = false;
        }
        if (!schoolAddress || schoolAddress.trim().length === 0) {
            setSchoolAdressError(true);
            res = false;
        }
        if (!schoolPhone || schoolPhone.trim().length === 0) {
            setSchoolPhoneError(true);
            res = false;
        }
        if (!schoolEmail || schoolEmail.trim().length === 0) {
            setSchoolEmailError(true);
            res = false;
        }
        if (!schoolCity || schoolCity.trim().length === 0) {
            setSchoolCityError(true);
            res = false;
        }
        if (!schoolState) {
            setSchoolStateError(true);
            res = false;
        }

        if (!schoolLocality || schoolLocality.trim().length === 0) {
            setSchoolAreaError(true);
            res = false;
        }
        if (!schoolPincode || schoolPincode.trim().length === 0) {
            setSchoolPincodeError(true);
            res = false;
        }
        if (!schoolCountry) {
            setSchoolCountryError(true);
            res = false;
        }

        return res;
    }


    function switchTab(newTab) {
        if (activeTab === 1) {
            if (instituteValidation()) {
                toggleTab(newTab);
            }
        } else if (activeTab === 2) {
            if (schoolValidation()) {
                toggleTab(newTab);
            }
        } else {
            toggleTab(newTab);
        }
    }



    const setAllCountryCodeList = () => {
        let allCountries = []
        for (const country of COUNTRY_JSON) {
            let countryObject = {
                value: country.code,
                label: country.name,
            }
            allCountries.push(countryObject)
        }
        setCountryCodeList(allCountries)
    }


    function onCreateInstituteAndBranch() {


        setCreateSchool(true);

        var Institute = Parse.Object.extend("Institute");
        var institute = new Institute();

        if (!newBranch) {
            if (selectedSchool.id === "1" || selectedSchool.id === "4") {
                institute.set("name", schoolName.trim());
                institute.set("primaryPhone", schoolPhone.trim());
                institute.set("email", schoolEmail.trim());
                institute.set("address", schoolAddress);
                institute.set("city", schoolCity);
                if (schoolState && schoolCountry.value == "IN") {
                    institute.set("state", schoolState.label);
                    institute.set("areaCode", schoolState.value);
                } else {
                    institute.set("state", schoolState);
                }
                institute.set("country", schoolCountry.label);
                institute.set("countryCode", schoolCountry.value);
                institute.set("pinCode", schoolPincode);
            } else {
                institute.set("name", instituteName.trim());
                institute.set("primaryPhone", institutePhone.trim());
                institute.set("email", instituteEmail.trim());
                institute.set("address", instituteAddress);
                institute.set("city", instituteCity);
                if (instituteState && instituteCountry.value == "IN") {
                    institute.set("state", instituteState.label);
                    institute.set("areaCode", instituteState.value);
                } else {
                    institute.set("state", instituteState);
                }
                institute.set("country", instituteCountry.label);
                institute.set("countryCode", instituteCountry.value);
                institute.set("pinCode", institutePincode);
            }
        }


        var School = Parse.Object.extend("School");
        var school = new School();

        school.set("Name", schoolName.trim());
        school.set("primaryPhoneNumber", schoolPhone.trim());
        school.set("EMail", schoolEmail.trim());
        school.set("Address", schoolAddress.trim());
        school.set("city", schoolCity);
        if (schoolState && schoolCountry.value == "IN") {
            school.set("state", schoolState.label);
            school.set("areaCode", schoolState.value);
        } else {
            school.set("state", schoolState);
        }

        school.set("country", schoolCountry.label);
        school.set("url", schoolWebsite);
        school.set("pinCode", schoolPincode);
        school.set("language", schoolLanguage);
        school.set("locality", schoolLocality);
        school.set("countryCode", schoolCountry.value);
        school.set("schoolType", 1);
        school.set("additionalSettings", []);

        if (WhiteLable.isMakoonsApp()) {
            school.set("franchiseId", "WPs3dlhGTO");
        }else if (WhiteLable.isSanfortApp()) {
            school.set("franchiseId", "I5wVcx9jVg");
        }else if (WhiteLable.isJumpStartApp()) {
            school.set("franchiseId", "fQ7JF1Nbc0");
        }else if (WhiteLable.isArtklubApp()) {
            school.set("franchiseId", "xPFCC9IkBO");
        }


        var workingDays = [];
        for (const i in allDays) {
            if (schoolWorkingDays.includes(allDays[i])) {
                workingDays.push(allDays[i]);
            }
        }

        if (workingDays.length > 0) {
            school.set("workingDays", workingDays);
        }

        if (schoolStartTime) {
            school.set("startTime", schoolStartTime);
        }
        if (schoolEndTime) {
            school.set("EndTime", schoolEndTime);
        }


        var cycles = [];
        var daycare = false;

        for (const i in cyclicYears) {
            for (const j in cyclicYears[i].c) {
                if (selectedCycles.includes(cyclicYears[i].c[j].id)) {
                    var lil = cyclicYears[i].c[j].year;
                    var soc = cyclicYears[i].c[j].year;
                    if (cyclicYears[i].c[j].renamed && cyclicYears[i].c[j].renamed.length > 0) {
                        soc = cyclicYears[i].c[j].renamed;
                    }
                    cycles.push({ lil: lil, school: soc, level: cyclicYears[i].id });



                    if (cyclicYears[i].id === cyclicYears[0].id) {
                        daycare = true;
                    }

                }
            }
        }
        if (daycare) {
            school.set("featuresList", [0, 1, 0, 0, 1, 1, 0, 2, 0]);
        } else {
            school.set("featuresList", [0, 1, 0, 0, 1, 1, 0, 0, 0]);
        }

        if (Parse.User.current().attributes.refferedBy) {
            school.set("refferedBy", Parse.User.current().attributes.refferedBy);
            institute.set("refferedBy", Parse.User.current().attributes.refferedBy);
        }


        if (newBranch) {
            var id = Parse.User.current().attributes.instituteId;
            school.set("instituteId", id);
            createBranch(school, cycles);

        } else {
            institute.save().then(
                (result) => {
                    institute.pin();
                    school.set("instituteId", result.id);
                    createBranch(school, cycles);

                },
                (error) => {
                }
            );
        }



    }

    function createBranch(school, cycles) {
        school.save().then(
            (schoolObject) => {
                schoolObject.pin();
                Parse.Cloud.run("function_enquiryQuestionValidateAndCreate", {
                    schoolIds: [schoolObject.id],
                }).then((newresults) => {
                    Parse.Object.pinAll(newresults);

                    Parse.Cloud.run("function_createDefaultAdmissionForm", {
                        schoolId: schoolObject.id,
                    }).then((newtitles) => {
                        Parse.Object.pinAll(newtitles);
                        if (newBranch) {
                            createCycles(schoolObject, cycles);
                        } else {
                            createAdmin(schoolObject, cycles)
                        }

                    }, (error) => {
                        if (newBranch) {
                            createCycles(schoolObject, cycles);
                        } else {
                            createAdmin(schoolObject, cycles)
                        }
                    });

                }, (error) => {
                    if (newBranch) {
                        createCycles(schoolObject, cycles);
                    } else {
                        createAdmin(schoolObject, cycles)
                    }
                });
            },
            (error) => {

            }
        );
    }

    function createAdmin(schoolObject, cycles) {

        let user = Parse.User.current();
        user.set("instituteId", schoolObject.attributes.instituteId);
        user.save();

        const Teacher = Parse.Object.extend("Teacher");
        var teacher = new Teacher();
        teacher.set("Name", "Admin");
        teacher.set("EMail", Parse.User.current().attributes.email);
        teacher.set("Role", 3);
        teacher.set("isWebAccess", true);
        teacher.set("instituteId", Parse.User.current().attributes.instituteId);
        teacher.save().then(
            (result) => {
                teacher.pin();
                let user = Parse.User.current();
                user.set("instituteId", schoolObject.attributes.instituteId);
                user.set("teacherId", result.id);
                user.set("isSuperUser", true);
                user.save();
                user.save().then(
                    (result) => {
                        createCycles(schoolObject, cycles);
                    },
                    (error) => {

                    }
                );

            },
            (error) => {

            }
        );

    }

    function createCycles(schoolObject, cycles) {
        if (cycles.length > 0) {

            var list = [];
            for (const i in cycles) {
                var AcademicCycles = Parse.Object.extend("AcademicCycles");
                var academicCycles = new AcademicCycles();
                academicCycles.set("defaultCycle", cycles[i].lil);
                academicCycles.set("schoolCycle", cycles[i].school);
                academicCycles.set("schoolId", schoolObject.id);
                if (cycles[i].lil == "Infant 0-18 Months" || cycles[i].lil == "Toddler 13-36 Months") {
                    academicCycles.set("level", "Daycare");
                } else {
                    academicCycles.set("level", schoolObject.level);
                }

                academicCycles.set("order", parseInt(i));
                list.push(academicCycles);
            }

            Parse.Object.saveAll(list).then((result) => {
                createClassrooms(schoolObject, cycles);
            }, (error) => {

            });

        } else {
            window.location = '/dashboard/' + schoolObject.id;
        }
    }

    function createClassrooms(schoolObject, cycles) {
        var list = [];
        for (const i in cycles) {
            var ClassRoom = Parse.Object.extend("ClassRoom");
            var classRoom = new ClassRoom();

            classRoom.set("Name", cycles[i].school ? cycles[i].school : cycles[i].lil);
            classRoom.set("classLevel", cycles[i].school ? cycles[i].school : cycles[i].lil);
            classRoom.set("SchoolID", schoolObject.id);

            if (cycles[i].level === cyclicYears[0].id) {
                classRoom.set("classroomType", 2);
            }

            list.push(classRoom);
        }

        Parse.Object.saveAll(list).then((result) => {
            window.location = '/dashboard/' + schoolObject.id;
        }, (error) => {
            console.log(error);
        });
    }

    function handelWholeCycle(cycle) {

        scroller.scrollTo('toCreate', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 0,
        });

        if (selectedAll(cycle)) {
            var temp = [];
            var ids = [];
            for (const i in cycle.c) {
                ids.push(cycle.c[i].id)
            }
            for (const i in selectedCycles) {
                if (!ids.includes(selectedCycles[i])) {
                    temp.push(selectedCycles[i])
                }
            }
            setSelectedCycles(temp);
        } else {
            var temp = [...selectedCycles];

            for (const i in cycle.c) {
                if (!selectedCycles.includes(cycle.c[i].id)) {
                    temp.push(cycle.c[i].id)
                }
            }
            setSelectedCycles(temp);
        }
    }


    function handelCycle(year) {

        scroller.scrollTo('toCreate', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 0,
        });

        if (selectedCycles.includes(year.id)) {

            var temp = selectedCycles.filter(cycle => cycle !== year.id);

            setSelectedCycles(temp)
        } else {
            var temp = [...selectedCycles];
            temp.push(year.id);
            setSelectedCycles(temp);
        }
    }

    function selectedAll(cycle) {
        var responcse = true;
        for (const i in cycle.c) {
            if (!selectedCycles.includes(cycle.c[i].id)) {
                responcse = false;
            }
        }
        return responcse
    }

    const autoSelectWeekDays = (day) => {

        if (schoolWorkingDays.includes(day)) {
            return (true)
        } else {
            return (false)
        }
    }

    return (

        <React.Fragment>

            {isUserDisabled && <UserDisableWarning />}
            {!createSchool && !isUserDisabled && <div className="  pt-2">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-3">
                                <h3 className="mt-5">{newBranch ? props.t('Add New Branch') : props.t('Welcome to ') + WhiteLable.getAppName()}</h3>
                            </div>
                        </Col>


                    </Row>



                    {!showWizard &&
                        <Row className="justify-content-center">

                            <Col lg="12">
                                <div className="text-center mb-3">
                                    <p>{props.t('Please select the type of Institute which you running')} </p>
                                </div>
                            </Col>
                            {schoolTypeList.map((card) =>
                                <Col xl="4" sm="4" key={card.id}>


                                    <Card outline
                                        size='sm'
                                        color={selectedSchool && selectedSchool.id === card.id ? "primary" : "white"}
                                        className="border text-center mt-2" style={{ 'height': '5.2cm' }}
                                        onClick={() => {
                                            setSelectedSchool(card);
                                            scroller.scrollTo('toNext', {
                                                duration: 200,
                                                delay: 0,
                                                smooth: true,
                                                offset: 0,
                                            });
                                        }}
                                    >

                                        <CardBody>

                                            {selectedSchool && selectedSchool.id === card.id ? <i className="mdi mdi-bullseye-arrow mr-1 float-end text-primary"></i> : null}

                                            <div className="mb-4">
                                                <img alt="Skote" width="100" height="100" src={card.image} />
                                            </div>
                                            <h6 className="text-dark">{card.type}</h6>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}



                        </Row>}

                        <Row>
                            <Col lg="12">
                                {selectedSchool && !showWizard &&
                                    <button
                                        onClick={() => {
                                            setShowWizard(true);
                                            if (selectedSchool && (selectedSchool.id === "1" || selectedSchool.id === "4")) {
                                                setMutiBranch(false);
                                                setactiveTab(2)
                                                setprogressValue(33);
                                            } else {
                                                setMutiBranch(true);
                                                setactiveTab(1)
                                                setprogressValue(25);
                                            }
                                        }}
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light float-end ">
                                        {('Next')}   <i className="bx bx-right-arrow-alt font-size-16 align-middle mr-1"></i>

                                    </button>
                                }
                            </Col>
                        </Row>
                        <div style={{ height: '100px'}}></div>
                    <Element name="toNext">
                       
                    </Element>

                    {showWizard && <Row>

                        <Col lg="12">
                            <Card>
                                <CardBody>

                                    {!newBranch && <Row>

                                        <Col lg={8} md={8} xs={12}>
                                            <h4>{selectedSchool.type}</h4>

                                        </Col>
                                        <Col lg={4} md={4} xs={12}>

                                            <button
                                                onClick={() => { setShowWizard(false); setSelectedSchool(undefined) }}
                                                type="button"
                                                className="btn btn-success btn-sm waves-effect waves-light float-end ">
                                                {('Reset')}   <i className="bx bx-refresh font-size-16 align-middle mr-1"></i>

                                            </button>
                                        </Col>
                                    </Row>
                                    }

                                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                            {multiBranch && <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 1 })}
                                                    onClick={() => {
                                                        // switchTab(1);
                                                    }} >
                                                    <span className="step-number mr-2">01</span>
                                                    {props.t('Franchise/Institute Details')}
                                                </NavLink>
                                            </NavItem>}
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 2 })}
                                                    onClick={() => {
                                                        // switchTab(2);
                                                    }} >
                                                    <span className="step-number mr-2">{multiBranch ? "02" : "01"}</span>
                                                    <span>{newBranch ? props.t(' Branch Deatils') : props.t('Create your First Branch')} </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 3 })}
                                                    onClick={() => {
                                                        // switchTab(3);
                                                    }} >
                                                    <span className="step-number mr-2">{multiBranch ? "03" : "02"}</span>
                                                    <span>{props.t('Timings')} </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 4 })}
                                                    onClick={() => {
                                                        // switchTab(4);
                                                    }} >
                                                    <span className="step-number mr-2">{multiBranch ? "04" : "03"}</span>
                                                    {props.t('Cycle Years')}
                                                </NavLink>
                                            </NavItem>

                                        </ul>
                                        <div id="bar" className="mt-4">
                                            <Progress color="success" striped animated value={progressValue} />
                                            <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                                        </div>
                                        <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                                            <TabPane tabId={1}>
                                                <Form>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >{props.t('Franchise/Institute Name')} <span className="text-danger">*</span></Label>
                                                                <Input type="text"
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        if (e.target.value && e.target.value.trim().length > 0) {
                                                                            setInstituteNameError(false);
                                                                        }
                                                                        setInstituteName(e.target.value);
                                                                    }
                                                                    } />
                                                                {instituteNameError && <p className="text-danger">{props.t('Franchise/Institute Name is mandatory')}</p>}
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Label >{props.t('Address')}</Label>
                                                                <textarea id="basicpill-address-input1"
                                                                    className="form-control"
                                                                    rows="3"
                                                                    onChange={(e) => setInstituteAdress(e.target.value)}
                                                                ></textarea>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label >{props.t('Phone')} <span className="text-danger">*</span></Label>
                                                                <Input type="phone"
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        if (e.target.value && e.target.value.trim().length > 0) {
                                                                            setInstitutePhoneError(false);
                                                                        }
                                                                        setInstitutePhone(e.target.value)
                                                                    }} />
                                                                {institutePhoneError && <p className="text-danger">{props.t('Phone number is mandatory')}</p>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label >{props.t('Email')} <span className="text-danger">*</span></Label>
                                                                <Input type="email"
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        if (e.target.value && e.target.value.trim().length > 0) {
                                                                            setInstituteEmailError(false);
                                                                        }
                                                                        setInstituteEmail(e.target.value)
                                                                    }} />
                                                                {instituteEmailError && <p className="text-danger">{props.t('Email is mandatory')}</p>}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label >{props.t('Country')}</Label>
                                                                {/* <Input type="text"
                                                                    className="form-control"
                                                                    onChange={(e)=> setInstituteCountry(e.target.value)} 
                                                                /> */}
                                                                <Select
                                                                    value={instituteCountry}
                                                                    onChange={(v) => {
                                                                        setInstituteState(undefined)
                                                                        setInstituteCountry(v)
                                                                    }}
                                                                    options={countryCodeList}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label >{props.t('State')}</Label>
                                                                {instituteCountry.value != "IN" ? <Input type="text"
                                                                    className="form-control"
                                                                    onChange={(e) => setInstituteState(e.target.value)} /> :
                                                                    <Select
                                                                        value={instituteState}
                                                                        onChange={(v) => { setInstituteState(v) }}
                                                                        options={Constents.stateName}
                                                                        classNamePrefix="select2-selection"
                                                                    />}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label >{props.t('City')}</Label>
                                                                <Input type="text"
                                                                    className="form-control"
                                                                    onChange={(e) => setInstituteCity(e.target.value)} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <Label >{props.t('Pin Code')}</Label>
                                                                <Input type="text"
                                                                    className="form-control"
                                                                    onChange={(e) => setInstitutePincode(e.target.value)} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </Form>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <div>
                                                    <Form>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <Label >{props.t('School Name')} <span className="text-danger">*</span></Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        onChange={(e) => {
                                                                            if (e.target.value && e.target.value.trim().length > 0) {
                                                                                setSchoolNameError(false);
                                                                            }
                                                                            setSchoolName(e.target.value)
                                                                        }} />
                                                                    {schoolNameError && <p className="text-danger">{props.t('School Name is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>

                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <Label >{props.t('Address')} <span className="text-danger">*</span></Label>
                                                                    <textarea
                                                                        id="basicpill-address-input"
                                                                        className="form-control"
                                                                        rows="3"
                                                                        onChange={(e) => {
                                                                            if (e.target.value && e.target.value.trim().length > 0) {
                                                                                setSchoolAdressError(false);
                                                                            }
                                                                            setSchoolAdress(e.target.value)
                                                                        }}
                                                                    ></textarea>
                                                                    {schoolAddressError && <p className="text-danger">{props.t('School address is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <Label >{props.t('School Phone')} <span className="text-danger">*</span></Label>
                                                                    <Input type="phone"
                                                                        className="form-control"
                                                                        onChange={(e) => {
                                                                            if (e.target.value && e.target.value.trim().length > 0) {
                                                                                setSchoolPhoneError(false);
                                                                            }
                                                                            setSchoolPhone(e.target.value)
                                                                        }} />
                                                                    {schoolPhoneError && <p className="text-danger">{props.t('Phone number is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <Label>{props.t('School Email')} <span className="text-danger">*</span></Label>
                                                                    <Input type="email"
                                                                        className="form-control"
                                                                        onChange={(e) => {
                                                                            if (e.target.value && e.target.value.trim().length > 0) {
                                                                                setSchoolEmailError(false);
                                                                            }
                                                                            setSchoolEmail(e.target.value)
                                                                        }} />
                                                                    {schoolEmailError && <p className="text-danger">{props.t('Email is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <Label >{props.t('Area')}  <span className="text-danger">*</span> </Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        onChange={(e) => setSchoolLocality(e.target.value)} />
                                                                    {schoolAreaError && <p className="text-danger">{props.t('Area is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>

                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <Label >{props.t('City')}  <span className="text-danger">*</span> </Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        onChange={(e) => {
                                                                            if (e.target.value && e.target.value.trim().length > 0) {
                                                                                setSchoolCityError(false);
                                                                            }
                                                                            setSchoolCity(e.target.value)
                                                                        }}
                                                                    />
                                                                    {schoolCityError && <p className="text-danger">{props.t('City is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <Label >{props.t('Country')} <span className="text-danger">*</span> </Label>
                                                                    {/* <Input type="text"
                                                                        className="form-control"
                                                                        value={schoolCountry}
                                                                        onChange={(e)=> setSchoolCountry(e.target.value)} 
                                                                    /> */}
                                                                    <Select
                                                                        value={schoolCountry}
                                                                        onChange={(v) => {
                                                                            setSchoolState(undefined)
                                                                            setSchoolCountry(v)
                                                                        }}
                                                                        options={countryCodeList}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {schoolCountryError && <p className="text-danger">{props.t('Country is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <Label >{props.t('State')} <span className="text-danger">*</span></Label>
                                                                    {schoolCountry.value != "IN" ? <Input type="text"
                                                                        className="form-control"
                                                                        onChange={(e) => setSchoolState(e.target.value)}
                                                                    /> :
                                                                        <Select
                                                                            value={schoolState}
                                                                            onChange={(v) => {
                                                                                if (v) {
                                                                                    setSchoolStateError(false);
                                                                                }
                                                                                setSchoolState(v)
                                                                            }}
                                                                            options={Constents.stateName}

                                                                            classNamePrefix="select2-selection text-dark"
                                                                        />}
                                                                    {schoolStateError && <p className="text-danger">{props.t('State is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <Label >{props.t('Pin Code')}<span className="text-danger">*</span></Label>
                                                                    <Input type="number"
                                                                        className="form-control"
                                                                        onChange={(e) => setSchoolPincode(e.target.value)} />
                                                                    {schoolPincodeError && <p className="text-danger">{props.t('Pincode is mandatory')}</p>}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <Label >{props.t('School Website')}</Label>
                                                                    <Input type="text"
                                                                        className="form-control"
                                                                        onChange={(e) => setSchoolWebsite(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                    </Form>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={3}>

                                                <div>
                                                    <Form>
                                                        <h4 className="mb-2">{props.t('School Timings')} </h4>
                                                        <Row>
                                                            <Col lg="6">
                                                                <Col >
                                                                    <FormGroup>
                                                                        <Label >{props.t('School Day Begins')}</Label>
                                                                        <DatePicker
                                                                            selected={schoolStartTime}
                                                                            onChange={(date) => setSchoolStartTime(date)}
                                                                            className="form-control bg-white"
                                                                            placeholderText={props.t('Select Start Time')}

                                                                            startDate={schoolStartTime}
                                                                            endDate={schoolEndTime}
                                                                            maxDate={schoolEndTime}
                                                                            dateFormat="h:mm aa"
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            timeIntervals={15}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <Label>{props.t('School Day Ends')}</Label>
                                                                        <DatePicker
                                                                            selected={schoolEndTime}
                                                                            onChange={(date) => setSchoolEndTime(date)}
                                                                            className="form-control bg-white"
                                                                            placeholderText={props.t('Select End Time')}
                                                                            startDate={schoolStartTime}
                                                                            endDate={schoolEndTime}
                                                                            minDate={schoolStartTime}
                                                                            dateFormat="h:mm aa"
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            timeIntervals={15}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>


                                                            </Col>


                                                            <Col lg="6">
                                                                <div className="ml-3"><h5>{props.t('Days Of Week')} </h5>

                                                                    <Row>  {
                                                                        allDays.map((day, key) => (

                                                                            <Col lg="4" key={key}>
                                                                                <div className="custom-control custom-checkbox mt-2 ml-3 " >
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        defaultChecked={autoSelectWeekDays(day)}
                                                                                        value="2"
                                                                                        className="custom-control-input"
                                                                                        id={key}
                                                                                        onClick={() => {
                                                                                            if (schoolWorkingDays.includes(day)) {
                                                                                                var temp = schoolWorkingDays.filter(d => d !== day);
                                                                                                setSchoolWorkingDays(temp);
                                                                                            } else {
                                                                                                var temp = [...schoolWorkingDays];
                                                                                                temp.push(day);
                                                                                                setSchoolWorkingDays(temp);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <Label className="custom-control-label" htmlFor={key}>	{day}</Label>
                                                                                </div>
                                                                            </Col>
                                                                        ))
                                                                    }
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={4}>
                                                <div className="row ">
                                                    <Col >

                                                        <div>
                                                            <p className="text-muted mr-7">{props.t('Please click on Each Box next to each Cycle Year that is represented at your school. If you use different terminology for each cycle year you may edit them.')}</p>
                                                            <u><p className="text-dark mr-7">{props.t('Academic cycle years are based on National Education Policy (NEP) 2020')} </p></u>
                                                        </div>

                                                        <Row className="justify-content-center">

                                                            {cyclicYears.map((cycle) => (

                                                                <Col xl="4" sm="4" key={cycle.id}>

                                                                    <Card
                                                                        outline
                                                                        color="primary"
                                                                        className="border  mt-2" style={{ 'height': '6cm' }}

                                                                    >

                                                                        <CardBody>

                                                                            <div className="custom-control custom-checkbox">
                                                                                <Input type="checkbox" value="2" className="custom-control-input" onChange={() => (handelWholeCycle(cycle))} id={cycle.id} checked={selectedAll(cycle)} />
                                                                                <Label className="custom-control-label" style={{ marginLeft: '5px'}} htmlFor={cycle.id}>{cycle.title}</Label>
                                                                            </div>
                                                                            <div className="pt-2">
                                                                                {cycle.c.map((year) => (
                                                                                    <div className="custom-control custom-checkbox" style={{ marginTop: '-10px' }} key={year.id}>
                                                                                        <Row>
                                                                                            <Col xs="1">
                                                                                                <div>
                                                                                                    <Input
                                                                                                        type="checkbox"
                                                                                                        value="2"
                                                                                                        className="custom-control-input ml-1"
                                                                                                        id={year.id}
                                                                                                        onChange={() => handelCycle(year)}
                                                                                                        checked={selectedCycles.includes(year.id) ? true : false}
                                                                                                    />
                                                                                                    <Label className="custom-control-label" htmlFor={year.id}></Label>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <Editable
                                                                                                    className={'d-flex'}
                                                                                                    alwaysEditing={false}
                                                                                                    onValidated={(value) => {
                                                                                                        var list = [...cyclicYears];
                                                                                                        list[cycle.i].c[year.i].renamed = value;
                                                                                                        setCyclicYears(list);
                                                                                                        console.log(list);
                                                                                                    }}
                                                                                                    onCancel={(v) => {
                                                                                                        var list = [...cyclicYears];
                                                                                                        list[cycle.i].c[year.i].renamed = undefined;
                                                                                                        setCyclicYears(list);
                                                                                                        console.log(list);
                                                                                                    }}
                                                                                                    disabled={false}
                                                                                                    editText="✎"
                                                                                                    id={null}
                                                                                                    isValueClickable={false}
                                                                                                    mode="inline"
                                                                                                    placement="top"
                                                                                                    initialValue={year.renamed && year.renamed.length > 0 ? year.renamed : year.year}
                                                                                                    showText
                                                                                                    type="textfield"
                                                                                                    validate={(value) => {
                                                                                                        if (!value) {
                                                                                                            return props.t("can not be empty");
                                                                                                        }
                                                                                                    }}
                                                                                                ></Editable>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                ))}
                                                                            </div>


                                                                        </CardBody>

                                                                    </Card>
                                                                </Col>

                                                            ))}
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </TabPane>

                                        </TabContent>

                                        <Element name="toCreate">
                                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                <li className={activeTab === 1 ? "previous disabled" : "previous"}><button
                                                    className="btn btn-primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        if (selectedSchool.id === "1" || selectedSchool.id === "4") {

                                                            if (activeTab !== 2) {
                                                                toggleTab(activeTab - 1);
                                                            }
                                                        } else {
                                                            toggleTab(activeTab - 1);
                                                        }
                                                    }
                                                    }>Previous</button></li>
                                                <li className={activeTab === 4 ? "next " : "next"}><button
                                                    className="btn btn-primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        if (activeTab === 4) {
                                                            onCreateInstituteAndBranch();
                                                        } else {
                                                            if (activeTab === 1 || activeTab === 2) {
                                                                switchTab(activeTab + 1)
                                                            } else {
                                                                toggleTab(activeTab + 1);
                                                            }

                                                        }
                                                    }}>{activeTab === 4 ? "Save " : "Next"}</button></li>
                                            </ul>


                                        </Element>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}
                </Container>
            </div>}


            {createSchool && <div className="account-pages my-5 pt-5">
                <Container>
                    <Card className="p-4">
                        <CardBody>
                            <Row>
                                <Col lg="12">
                                    <div className="text-center mb-5">

                                        <h4 className="text-uppercase">Setting up school.....</h4>
                                        <h1 className="display-2 font-weight-medium"><i className="bx bx-buoy bx-spin text-primary display-3"></i></h1>
                                        <h5 className="text-uppercase">Please Wait.....</h5>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md="8" xl="6">
                                    <div>
                                        <img src={create} alt="" className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            }
        </React.Fragment>

    );

}

export default withNamespaces()(Index);