import * as actions from "./actionTypes";

export const updateMoneyTransaction = (moneyTransaction) => {
  return {
    type: actions.UPDATE_MONEY_TRANSACTION,
    payload: { moneyTransaction },
  };
};

export const deleteMoneyTransaction = (moneyTransaction) => {
  return {
    type: actions.DELETE_MONEY_TRANSACTION,
    payload: { moneyTransaction },
  };
};

export const updateMoneyTransactions = (moneyTransactions) => {
  return {
    type: actions.UPDATE_MONEY_TRANSACTIONS,
    payload: { moneyTransactions },
  };
};
export const updateMoneyTransactionsUpdate = (moneyTransactions) => {
  return {
    type: actions.UPDATE_MONEY_TRANSACTIONS_UPDATE,
    payload: { moneyTransactions },
  };
};

export const getMoneyTransactionForSchoolId = (schoolId) => {
  return {
    type: actions.GET_MONEY_TRANSACTIONS,
    payload: { schoolId },
  };
};

export const getMoneyTransactionForInvoiceId = (id) => {
  return {
    type: actions.GET_TRANSACTION_FOR_FOR_INVOICE_ID,
    payload: { id },
  };
};
export const getVerifiedStatusForInvoices = (ids) => {
  return {
    type: actions.GET_VERIFIED_STATUS_FOR_INVOICES,
    payload: { ids },
  };
};
export const setVerifiedForPendingInvoice = (transactions) => {
  return {
    type: actions.SET_VERIFIED_FOR_PENDING_INVOICE,
    payload: { transactions },
  };
};

export const receivePaymentByFunction = (inputParam, invoiceItems) => {
  return {
    type: actions.RECEIVE_PAYMENT_BY_FUNCTION,
    payload: { inputParam, invoiceItems },
  };
};
export const recivePaymentByFunctionSuccess = (Obj) => {
  return {
    type: actions.RECEIVE_PAYMENT_BY_FUNCTION_SUCCESS,
    payload: { Obj },
  };
};
export const reciveTriansactionDisplaySuccess = () => {
  return {
    type: actions.RECIVE_TRIANSACTION_DISPLAY_SUCCESS,
    payload: {},
  };
};
export const verifyTriansactionById = (id) => {
  return {
    type: actions.VERIFY_TRIANSACTION_BY_ID,
    payload: { id },
  };
};
export const setTriansaction = (tObj) => {
  return {
    type: actions.SET_TRIANSACTION,
    payload: { tObj },
  };
};
export const deleteTriansactionById = (id) => {
  return {
    type: actions.DELETE_TRANSACTION_BY_ID,
    payload: { id },
  };
};

export const getAllTransactionForFilter = (filters) => {
  return {
    type: actions.GET_ALL_TRIANSACTION_FOR_FILTER,
    payload: { filters },
  };
};

export const getAllTransactionCountForFilter = (filters) => {
  return {
    type: actions.GET_ALL_TRIANSACTION_COUNT_FOR_FILTER,
    payload: { filters },
  };
};

export const setTriansactionCountForFilter = (count) => {
  return {
    type: actions.SET_ALL_TRIANSACTION_COUNT_FOR_FILTER,
    payload: { count },
  };
};

export const getTriansactionForInvoiceIds = (invoiceIds) => {
  return {
    type: actions.GET_TRIANSACTION_FOR_INVOICE_IDS,
    payload: { invoiceIds },
  };
};

export const setTriansactionForInvoiceIds = (mtObjs) => {
  return {
    type: actions.SET_TRIANSACTION_FOR_INVOICE_IDS,
    payload: { mtObjs },
  };
};

export const removeFromPendingTriansactions = (mt) => {
  return {
    type: actions.REMOVE_FROM_PENDING_TRANSACTIONS,
    payload: { mt },
  };
};
export const recheckTransactionState = (transactionId) => {
  return {
    type: actions.RECHECK_TRANSACTION_STATE,
    payload: { transactionId },
  };
};

