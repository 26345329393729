import React, { useState, useEffect } from "react";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withNamespaces } from 'react-i18next';
import { Button, Card, CardBody, Label, Table, Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';
import { Link } from "react-router-dom";
import Moment from "moment"

const ListView = props => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState(null);

    const getDate = (date) => {
        let time = Moment(date).format("DD/MM/YYYY");
        return time;
    };
    const getTime = (date) => {
        let time = Moment(date).format("hh:mm a");
        return time;
    }

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handlePhotoClick = (photoUrl) => {
        setCurrentPhoto(photoUrl);
        toggleModal();
    };

    // const getStringFromList = (list) => {
    //     let listToString;
    //     if (list && list.length > 0) {
    //         for (const i in list) {
    //             if (i == 0) {
    //                 listToString = list[i];
    //             } else {
    //                 listToString = listToString + list[i];
    //             }
    //             if (list.length != i - 1) {
    //                 listToString = listToString + ","
    //             }
    //         }
    //     }
    //     return listToString
    // }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <Table className="table-centered table-nowrap table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">{props.t('#')}</th>
                                    <th scope="col">{props.t('Name')}</th>
                                    <th scope="col"><div >{props.t('Date/Time')}</div></th>
                                    <th scope="col"><div >
                                        {props.t('Purpose of visit')}
                                    </div></th>
                                    <th scope="col"><div >{props.t('Phone Number')}</div></th>
                                    <th scope="col"><div >{props.t('Person to meet')}</div></th>
                                    <th scope="col"><div >{props.t('Visiting from')}</div></th>
                                    <th scope="col">
                                        <div className=" ">
                                            Status
                                            <i className="fas fa-question-circle" id="types">
                                                <UncontrolledTooltip placement="right" target="types">
                                                    Upgrade visitor management to an OTP-based system. For more details, please contact customer support.
                                                </UncontrolledTooltip>
                                            </i>
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            {/* {console.log("props.mainList", props.mainList)} */}
                            <tbody>
                                {props.mainList.map((val, key) => (
                                    <tr key={key}>
                                        <td>
                                            {!val.attributes.visitorPhoto ? (
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle">
                                                        {val.attributes.name
                                                            ? val.attributes.name.toUpperCase().charAt(0)
                                                            : " "}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <img
                                                        className="rounded-circle avatar-xs"
                                                        src={val.attributes.visitorPhoto._url}
                                                        alt=""
                                                        onClick={() => handlePhotoClick(val.attributes.visitorPhoto._url)}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <div>
                                                <Label className='mb-0'>
                                                    {val.attributes.name}
                                                </Label>
                                            </div>
                                            <div className='font-size-12'>
                                                {val.attributes.visitorType}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {getDate(val.createdAt)}
                                            </div>
                                            <div className='font-size-12'>
                                                {getTime(val.createdAt)}
                                            </div>
                                        </td>
                                        <td>
                                            <div >
                                                {val.attributes.visitingPurpose ? val.attributes.visitingPurpose.join(", ") : ""}
                                            </div>
                                        </td>

                                        <td>
                                            <div >
                                                {val.attributes.phoneNumber}
                                            </div>
                                        </td>
                                        <td>
                                            <div >
                                                {val.attributes.meetingPerson}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {val.attributes.visitingFrom}
                                            </div>
                                        </td>
                                        <td>
                                            
                                                {val.attributes.isOTPVerified ? <div className="text-success">{"OTP Verified"} </div> : "Not Verified"}
                                           
                                        </td>



                                    </tr>
                                ))}
                            </tbody>Verified
                        </Table>
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
                <ModalBody>
                    {currentPhoto && (
                        <img
                            src={currentPhoto}
                            alt="Visitor"
                            className="img-fluid"
                            style={{ display: "block", margin: "0 auto" }}
                        />
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment >
    )
}
const mapStatetoProps = state => {
    return {}
}

export default connect(mapStatetoProps, {})(withRouter(withNamespaces()(ListView)));
