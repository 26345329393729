import React, { useState } from 'react';
import Parse from 'parse';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Container, Col, Row, Card, CardBody, CardTitle, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx/xlsx.mjs';
import Moment from 'moment';

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";
import { withNamespaces } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import * as Constants from '../../Constents';
import { getEnquiryForSchoolId, saveEnquiryObjects } from '../../store/actions';

const EnquiryImport = (props) => {

    var schoolId = props.schoolId;
    const Male = ["male", "boy", "b", "m", "he"];
    const Female = ["female", "girl", "g", "f", "she"];
    const [showPopup, setShowPopup] = useState(false);
    const firstRow = ["SlNo", "Child Name", "DOB", "Gender", "Mother Name", "Mother Phone", "Mother Email", "Father Name", "Father Phone", "Father Email", "Admission Year", "Enquiry Date", "Enquiry Source", "Admission Class", "Notes"];
    const firstRow1 = ["enquiryDate", "name", "phoneNumber", "email", "zone", "city", "workPlace", "source", "occupation", "notes", "status"];
    const [tableData, setTableData] = useState({
        columns: [
            {
                label: "Child Name",
                field: "childName",
                sort: "asc",
                width: 136
            },
            {
                label: "DOB",
                field: "dob",
                sort: "asc",
                width: 130
            },
            {
                label: "Gender",
                field: "gender",
                sort: "asc",
                width: 70
            },
            {
                label: "Mother Name",
                field: "motherName",
                sort: "asc",
                width: 120
            },
            {
                label: "Mother Phone",
                field: "motherPhone",

                width: 126
            },
            {
                label: "Mother Email",
                field: "motherEmail",

                width: 150
            },
            {
                label: "Father Name",
                field: "fatherName",
                sort: "asc",
                width: 120
            },

            {
                label: "Father Phone",
                field: "fatherPhone",

                width: 126
            },
            {
                label: "Father Email",
                field: "fatherEmail",

                width: 150
            },

            {
                label: "Admission Year",
                field: "admissionYear",

                width: 126
            },
            {
                label: "Enquiry Date",
                field: "enquiryDate",
                sort: "asc",
                width: 130
            },


            {
                label: "Enquiry Source",
                field: "source",
                sort: "asc",
                width: 126

            },

            {
                label: "Admission Class",
                field: "admissionClass",
                sort: "asc",
                width: 120
            },
            {
                label: "Notes",
                field: "notes",

                width: 150

            },

        ],
        rows: [

        ]
    });


    const templateURL = "https://parsefiles.back4app.com/0ZRsOIsU32VP5AhOrDR7nMqrGR7PFkzaSYEPBsNN/db8a6941b26fa4adda5c43d40747550b_enquiry_template.xlsx";
    const handleUpload = (e) => {
        // e.preventDefault();

        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            createJsObjects(dataParse)
            // createJsObjects2(dataParse)

        };
        reader.readAsBinaryString(f)
    }


    function createJsObjects(dataParse) {

        var IstRow = [];
        var allrows = [];
        for (const i in dataParse) {
            if (dataParse[i] && dataParse[i].length > 0) {
                if (i == 0) {
                    IstRow = dataParse[0];
                } else {
                    allrows.push(dataParse[i]);
                }
            }
        }
        if (allrows && allrows.length > 0) {
            var objectList = [];
            for (const j in allrows) {
                var obj = {
                    childName: undefined,
                    dob: undefined,
                    dobDateObject: undefined,
                    gender: undefined,
                    motherName: undefined,
                    motherPhone: undefined,
                    motherEmail: undefined,
                    fatherName: undefined,
                    fatherPhone: undefined,
                    fatherEmail: undefined,
                    admissionYear: undefined,
                    admissionClass: undefined,
                    source: undefined,
                    notes: undefined,
                    enquiryDate: undefined,
                    enquiryDateObject: undefined
                }
                objectList.push(obj)
            }

            for (const i in IstRow) {


                for (const j in allrows) {
                    // console.log(allrows[j][i]);
                    if (IstRow[i] === firstRow[1]) {
                        objectList[j].childName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[2]) {
                        if (Number.isInteger(allrows[j][i])) {
                            var date = ExcelDateToJSDate(allrows[j][i])
                            objectList[j].dob = convertDateToString(date);
                            objectList[j].dobDateObject = date;
                        } else {
                            objectList[j].dob = allrows[j][i];
                        }

                    } else if (IstRow[i] === firstRow[3]) {
                        var gen = allrows[j][i];
                        if (gen && Female.includes(gen.toLowerCase())) {
                            objectList[j].gender = "Female";
                        } else if (gen && Male.includes(gen.toLowerCase())) {
                            objectList[j].gender = "Male";
                        }
                    } else if (IstRow[i] === firstRow[4]) {
                        objectList[j].motherName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[5]) {
                        objectList[j].motherPhone = allrows[j][i];
                    } else if (IstRow[i] === firstRow[6]) {
                        objectList[j].motherEmail = allrows[j][i];
                    } else if (IstRow[i] === firstRow[7]) {
                        objectList[j].fatherName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[8]) {
                        objectList[j].fatherPhone = allrows[j][i];
                    } else if (IstRow[i] === firstRow[9]) {
                        objectList[j].fatherEmail = allrows[j][i];
                    } else if (IstRow[i] === firstRow[10]) {
                        objectList[j].admissionYear = allrows[j][i];
                    } else if (IstRow[i] === firstRow[11]) {

                        if (Number.isInteger(allrows[j][i])) {
                            var date = ExcelDateToJSDate(allrows[j][i])
                            objectList[j].enquiryDate = convertDateToString(date);
                            objectList[j].enquiryDateObject = date;
                        } else {
                            objectList[j].enquiryDate = allrows[j][i];
                        }

                    } else if (IstRow[i] === firstRow[12]) {
                        objectList[j].source = allrows[j][i];
                    } else if (IstRow[i] === firstRow[13]) {
                        objectList[j].admissionClass = allrows[j][i];
                    } else if (IstRow[i] === firstRow[14]) {
                        objectList[j].notes = allrows[j][i];
                    }
                }
            }

            var obj = { ...tableData, rows: objectList };
            // console.log(obj);
            setTableData(obj);



        }

    }

    function createJsObjects2(dataParse) {

        var IstRow = [];
        var allrows = [];
        for (const i in dataParse) {
            if (dataParse[i] && dataParse[i].length > 0) {
                if (i == 0) {
                    IstRow = dataParse[0];
                } else {
                    allrows.push(dataParse[i]);
                }
            }
        }
        if (allrows && allrows.length > 0) {
            var objectList = [];
            for (const j in allrows) {
                var obj = {
                    name: undefined,
                    email: undefined,
                    phoneNumber: undefined,
                    zone: undefined,
                    city: undefined,
                    workPlace: undefined,
                    occupation: undefined,
                    source: undefined,
                    notes: undefined,
                    enquiryDate: undefined,
                    enquiryDateObject: undefined,
                    status: undefined
                }
                objectList.push(obj)
            }

            for (const i in IstRow) {


                for (const j in allrows) {
                    //console.log(allrows[j][i]);
                    if (IstRow[i] === firstRow1[1]) {
                        objectList[j].name = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[0]) {

                        if (Number.isInteger(allrows[j][i])) {
                            var date = ExcelDateToJSDate(allrows[j][i])
                            objectList[j].enquiryDate = convertDateToString(date);
                            objectList[j].enquiryDateObject = date;
                        } else {
                            objectList[j].enquiryDate = allrows[j][i];
                            objectList[j].enquiryDateObject = convertStringTodate(allrows[j][i]);
                        }

                    } else if (IstRow[i] === firstRow1[2]) {
                        objectList[j].phoneNumber = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[3]) {
                        objectList[j].email = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[4]) {
                        objectList[j].zone = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[5]) {
                        objectList[j].city = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[6]) {
                        objectList[j].workPlace = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[7]) {
                        objectList[j].source = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[8]) {
                        objectList[j].occupation = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[9]) {
                        objectList[j].notes = allrows[j][i];
                    } else if (IstRow[i] === firstRow1[10]) {
                        objectList[j].status = allrows[j][i];
                    }
                }
            }

            var obj = { ...tableData, rows: objectList };
            // console.log(obj);
            setTableData(obj);



        }

    }

    function ExcelDateToJSDate(date) {
        return new Date(Math.round((date - 25569) * 86400 * 1000));
    }

    function convertDateToString(date) {
        return Moment(date).format('DD/MM/YYYY')
    }

    function convertStringTodate(dateString, type) {
        if (dateString && dateString.length > 0) {
            var res = [];
            res = dateString.split("/");
            if (!res || res.length !== 3) {
                res = dateString.split("-");
            }

            if (res && res.length === 3) {
                var d = res[0];
                var m = res[1];
                var y = res[2];

                if (parseInt(m) > 12 && parseInt(d) > 0) {
                    return undefined;
                }

                if (parseInt(m) <= 12) {
                    return new Date(parseInt(y), parseInt(m) - 1, parseInt(d), 0, 0, 0, 0);
                } else {
                    return new Date(parseInt(y), parseInt(d) - 1, parseInt(m), 0, 0, 0, 0);
                }
            }
        } else {
            if (type !== "DOB") {
                return new Date();
            }
        }
        return undefined;
    }

    function handelSave() {


        if (tableData && tableData.rows && tableData.rows.length > 0) {

            var enquiries = [];
            var parents = [];

            for (const i in tableData.rows) {

                var enquiryObject = tableData.rows[i];

                let familyId = uuidv4();
                let groupACL = new Parse.ACL();
                groupACL.setRoleWriteAccess(schoolId + "_Admin", true);
                groupACL.setRoleReadAccess(schoolId + "_Admin", true);

                groupACL.setRoleWriteAccess(schoolId + "_Teacher", false);
                groupACL.setRoleReadAccess(schoolId + "_Teacher", true);

                groupACL.setRoleWriteAccess(familyId + "_Role", true);
                groupACL.setRoleReadAccess(familyId + "_Role", true);

                var publicACL = new Parse.ACL();
                publicACL.setPublicReadAccess(true);
                publicACL.setPublicWriteAccess(true);

                const Enquiry = Parse.Object.extend("Enquiry");
                let enquiry = new Enquiry();
                enquiry.set("kidName", enquiryObject.childName);
                enquiry.set("kidGender", enquiryObject.gender);
                enquiry.set("dateOfBirth", convertStringTodate(enquiryObject.dob, "DOB"));

                enquiry.set("fatherName", enquiryObject.fatherName);
                enquiry.set("fatherMobile", enquiryObject.fatherPhone + "");
                enquiry.set("fatherEmail", enquiryObject.fatherEmail);
                enquiry.set("motherName", enquiryObject.motherName);
                enquiry.set("motherMobile", enquiryObject.motherPhone + "");
                enquiry.set("motherEmail", enquiryObject.motherEmail);

                enquiry.set("enquiryDate", convertStringTodate(enquiryObject.enquiryDate, "DOE"));
                enquiry.set("source", enquiryObject.source);
                enquiry.set("year", enquiryObject.admissionYear);
                enquiry.set("familyId", familyId);

                if (enquiryObject.admissionClass) {
                    var res = [];
                    if (enquiryObject.admissionClass.includes(",")) {
                        res = enquiryObject.admissionClass.split(",");
                    } else {
                        res.push(enquiryObject.admissionClass);
                    }
                    enquiry.set("enquiryFor", res);
                }
                enquiry.set("additionalNote", enquiryObject.notes);
                enquiry.set("schoolId", schoolId);
                enquiry.set("isBulkUpdate", true);
                enquiry.setACL(publicACL);

                enquiries.push(enquiry);

                const FatherObject = Parse.Object.extend("Parent");
                var fatherObject = new FatherObject();
                fatherObject.set("Relation", "Father");
                fatherObject.set("FamilyID", familyId);
                fatherObject.set("NotificationChoice", 16127);
                fatherObject.setACL(groupACL);
                fatherObject.set("Name", enquiryObject.fatherName && enquiryObject.fatherName.length > 0 ? enquiryObject.fatherName : "Father");

                if (enquiryObject.fatherName && enquiryObject.fatherName.length > 0) {
                    if (enquiryObject.fatherPhone) {
                        fatherObject.set("PhoneNumber", enquiryObject.fatherPhone + "");
                    }

                    if (enquiryObject.fatherEmail) {
                        fatherObject.set("EMail", enquiryObject.fatherEmail);
                    }
                    parents.push(fatherObject);
                }

                const MotherObject = Parse.Object.extend("Parent");
                var motherObject = new MotherObject();
                motherObject.set("Relation", "Mother");
                motherObject.set("FamilyID", familyId);
                motherObject.set("NotificationChoice", 16127);
                motherObject.setACL(groupACL);

                motherObject.set("Name", enquiryObject.motherName && enquiryObject.motherName.length > 0 ? enquiryObject.motherName : "Mother");

                if (enquiryObject.motherName && enquiryObject.motherName.length > 0) {
                    if (enquiryObject.motherPhone) {
                        motherObject.set("PhoneNumber", enquiryObject.motherPhone + "");
                    }

                    if (enquiryObject.motherEmail) {
                        motherObject.set("EMail", enquiryObject.motherEmail);
                    }
                    parents.push(motherObject);
                }

            }

            props.saveEnquiryObjects(enquiries, schoolId, parents, props.t('Upload successfull!'));
            setShowPopup(false);
            setTableData({ ...tableData, rows: [] });
        }



    }

    function handelSave2() {

        var obj = {
            name: undefined,
            email: undefined,
            phoneNumber: undefined,
            zone: undefined,
            city: undefined,
            workPlace: undefined,
            occupation: undefined,
            source: undefined,
            notes: undefined,
            enquiryDate: undefined,
            enquiryDateObject: undefined
        }

        if (tableData && tableData.rows && tableData.rows.length > 0) {

            var enquiries = [];
            var parents = [];

            for (const i in tableData.rows) {

                var enquiryObject = tableData.rows[i];

                let familyId = uuidv4();
                let groupACL = new Parse.ACL();
                groupACL.setRoleWriteAccess(schoolId + "_Admin", true);
                groupACL.setRoleReadAccess(schoolId + "_Admin", true);

                groupACL.setRoleWriteAccess(schoolId + "_Teacher", false);
                groupACL.setRoleReadAccess(schoolId + "_Teacher", true);

                groupACL.setRoleWriteAccess(familyId + "_Role", true);
                groupACL.setRoleReadAccess(familyId + "_Role", true);

                var publicACL = new Parse.ACL();
                publicACL.setPublicReadAccess(true);
                publicACL.setPublicWriteAccess(true);

                const Enquiry = Parse.Object.extend("Enquiry");
                let enquiry = new Enquiry();
                enquiry.set("kidName", enquiryObject.name);


                enquiry.set("email", enquiryObject.email);
                enquiry.set("phoneNumber", enquiryObject.phoneNumber + "");
                enquiry.set("zone", enquiryObject.zone + "");
                enquiry.set("city", enquiryObject.city + "");
                enquiry.set("workPlace", enquiryObject.workPlace + "");
                enquiry.set("source", enquiryObject.source + "");
                enquiry.set("enquiryDate", convertStringTodate(enquiryObject.enquiryDate, "DOE"));
                enquiry.set("occupation", enquiryObject.occupation);
                enquiry.set("familyId", familyId);
                enquiry.set("additionalNote", enquiryObject.notes);
                enquiry.set("schoolId", schoolId);
                enquiry.set("status", enquiryObject.status);
                enquiry.setACL(publicACL);

                enquiries.push(enquiry);



            }

            // console.log(enquiries)
            props.saveEnquiryObjects(enquiries, schoolId, [], props.t('Upload successfull!'));
            setShowPopup(false);
            setTableData({ ...tableData, rows: [] });
        }



    }

    return (
        <React.Fragment>
            {/* <Card>
                <CardBody>
                
                    <Label className="btn btn-primary" htmlFor="inputImage">
                        <input type="file"
                            className="sr-only"
                            id="inputImage"
                            name="file"
                            accept="xlxs/*"
                            onChange={handleUpload} /> Choose Exel
                    </Label>

                    <MDBDataTable responsive bordered data={tableData} className="mt-5" />

                    <Col>
                        <button
                            onClick={()=>{handelSave()}}
                            type="button"
                            className="btn btn-primary waves-effect waves-light float-right m-2">
                            <i className="bx bx-upload font-size-16 align-middle mr-2"></i> {props.t('Upload and Save')}             
                        </button>
                    </Col>
                    
                </CardBody>
            </Card> */}


            <Card>
                <CardBody>
                    <CardTitle className="mb-2"> {props.t('Enquiry bulk upload')}</CardTitle>
                    <div className="">
                        <ul className="verti-timeline list-unstyled">


                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className={"bx bx-right-arrow-circle"}></i>
                                </div>
                                <div className="media d-flex justify-content-start">
                                    <div className="mr-3">
                                        <i className={"bx bx-download h2 text-primary"}></i>
                                    </div>
                                    <div className="media-body m-2">
                                        <div>
                                            <h5>{props.t('Download Excel Template')}</h5>
                                            <p className="text-muted">{"Please download the Excel template sheet to ﬁll in all enquiry details quickly."}</p>

                                        </div>
                                        <button
                                            onClick={() => { window.open(templateURL) }}
                                            type="button"
                                            className="btn btn-primary waves-effect waves-light ">
                                            <i className="bx bx-download font-size-16 align-middle"></i> {props.t('Download Template')}
                                        </button>
                                    </div>

                                </div>
                            </li>



                            <li className="event-list">

                                <div className="event-timeline-dot">
                                    <i className={"bx bx-right-arrow-circle"}></i>
                                </div>


                                <div className="media d-flex justify-content-start">
                                    <div className="mr-3">
                                        <i className={"bx bx-copy-alt h2 text-primary"}></i>
                                    </div>
                                    <div className="media-body m-2">
                                        <div>
                                            <h5>{"Choose Excel"}</h5>
                                            <p className="text-muted">{"Now simply upload the Excel sheet that you just ﬁlled with all details."}</p>

                                            <div>
                                                <Label className="btn btn-primary mt-1" htmlFor="inputImage">
                                                    <input type="file"
                                                        className="sr-only"
                                                        id="inputImage"
                                                        name="file"
                                                        accept=".xlsx,/*"
                                                        onChange={handleUpload} /> {props.t('Choose Excel')}
                                                </Label>

                                                {tableData.rows && tableData.rows.length > 0 &&
                                                    <button
                                                        onClick={() => {
                                                            if (tableData.rows && tableData.rows.length > 0) {
                                                                var obj = { ...tableData, rows: [] };
                                                                setTableData(obj);
                                                            }

                                                        }}
                                                        type="button"
                                                        className="btn btn-light waves-effect waves-light ml-2 ">
                                                        {props.t('Clear')}
                                                    </button>
                                                }

                                            </div>
                                        </div>

                                    </div>



                                </div>


                            </li>
                        </ul>
                    </div>

                    {tableData.rows && tableData.rows.length > 0 && <MDBDataTable responsive bordered data={tableData} className="mt-5" />}

                    <div className="">

                        <ul className="verti-timeline list-unstyled mt-2">

                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className={"bx bx-right-arrow-circle "}></i>
                                </div>
                                <div className="media d-flex justify-content-start">
                                    <div className="mr-3">
                                        <i className={"bx bx-upload h2 text-primary"}></i>
                                    </div>
                                    <div className="media-body m-2">
                                        <div>
                                            <h5>{"Upload and Save"}</h5>
                                            <p className="text-muted">{"We would magically create the enquiry database for you"}</p>
                                            <button
                                                onClick={() => {
                                                    if (tableData.rows && tableData.rows.length > 0) {
                                                        setShowPopup(true);
                                                    }

                                                }}
                                                type="button"
                                                className={tableData.rows && tableData.rows.length > 0 ? "btn btn-primary waves-effect waves-light " : "btn btn-light waves-effect waves-light "}>
                                                <i className="bx bx-upload font-size-16 align-middle"></i> {props.t('Upload and Save')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </CardBody>
            </Card>

            <Modal isOpen={showPopup || props.bulkUploadLoading}>
                <ModalHeader>
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t('Enquiry bulk upload')}
                    </h5>
                </ModalHeader>
                <ModalBody >
                    <p className=" m-4">{props.t('Are you sure you want To upload this Enquiry data?')}</p>
                </ModalBody>
                <ModalFooter>
                    {!props.bulkUploadLoading ? <Row className="text-center">
                        <button
                            type="button"
                            onClick={() => {
                                setShowPopup(false);
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                        >
                            {props.t('Cancel')}
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light ml-2"
                            onClick={() => {


                                handelSave();
                                // handelSave2();

                            }}
                        >
                            {props.t('Yes, Upload')}
                        </button>

                    </Row> : <button
                        type="button" className="btn btn-light waves-effect">

                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Uploading Enquiry data ')}

                    </button>

                    }
                </ModalFooter>
            </Modal>
        </React.Fragment>

    );

}

const mapStatetoProps = state => {
    const { enquiries, bulkUploadLoading } = state.Enquiry;
    return { enquiries, bulkUploadLoading };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { saveEnquiryObjects })(EnquiryImport)));