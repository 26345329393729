import React, { useState, useEffect } from "react";
import {

} from "../../store/actions";

import toastr from "toastr";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
} from "reactstrap";
//Import Images
import avatar2 from "../../assets/images/users/user.png";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Parse from "parse";
import { stateName } from "../../Constents";
import Select from "react-select";
import { getInventoryCategoriesForOwnerId } from '../../store/actions';

const SchoolPicker = (props) => {

    const ALL = "All"

    //SchoolId is schoolIdParam
    const [stateList, setStateList] = useState(stateName);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [searchBarString, setSeachBarString] = useState("");
    const [selectedStateFilter, setSelectedStateFilter] = useState(undefined);
    const [selectedCityFilter, setSelectedCityFilter] = useState(undefined);
    const [cityList, setCityList] = useState([]);
    const [cityCodeList, setCityCodeList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [intialLoading, setIntialLoading] = useState(true);
    const [filterList, setFilteredList] = useState(props.schools)

    useEffect(() => {
        if (props.selectedSchools) {
            setSelectedSchools(props.selectedSchools)
        }
        setAllCityList()
    }, [props.selectedSchools])

    useEffect(() => {
        if (props.franchiseId) {
            props.getInventoryCategoriesForOwnerId(props.franchiseId, 'franchise');
        }
    }, [props.franchiseId])

    const getCategoryName = (categoryId) => {
        console.log(props.inventoryCategories)
        if (props.inventoryCategories && props.inventoryCategories.length > 0) {
            let temp = props.inventoryCategories.filter(
                (category) => category.id === categoryId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.name ?? "-")
            }
        }
    }

    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    function handleSelectAll() {
        // let tempList1 = [...getAllSchoolsFilteredList()];
        // let tempList = []


        if (selectedSchools && selectedSchools.length == props.schools.length) {
            setSelectedSchools([])
        } else {
            setSelectedSchools(props.schools)
        }

        // for (const school1 of tempList1) {
        //     if (school1.id === "PickAll") {
        //         continue
        //     }
        //     let schools = selectedSchools.filter((school) =>
        //         school.id == school1.id
        //     )
        //     if (schools.length == 0) {
        //         tempList.push(school1)
        //     }
        // }
        // tempList = tempList.concat(selectedSchools)
        // setSelectedSchools(tempList)



        // if (tempList.length > 0) {
        //     tempList.shift();
        //     if (tempList.length > 0) {
        //         let oldList = [...selectedSchools];
        //         let temp = oldList.concat(tempList);
        //         setSelectedSchools(temp);
        //     }
        // }
    }

    const handleCheckboxChangeForSelected = (schoolObject) => {
        var tempSelectedList = [...selectedSchools];
        removeElement(tempSelectedList, schoolObject);
        setSelectedSchools(tempSelectedList);
    };


    // const getAllSchoolsFilteredList = () => {
    //     var listToReturn = [];
    //     if (selectedStateFilter != undefined) {
    //         if (selectedStateFilter === "all_state") {
    //             listToReturn = [...props.schools];
    //         } else {
    //             var i = 0;
    //             for (i = 0; i < props.schools.length; i++) {
    //                 if (
    //                     props.schools[i].attributes.areaCode === selectedStateFilter.value
    //                 ) {
    //                     listToReturn.push(props.schools[i]);
    //                 }
    //                 // console.log(listToReturn)
    //                 // if(props.school[i].attributes.city == selectedCityFilter){
    //                 //     listToReturn.push(props.schools[i]);
    //                 // }

    //             }

    //             if (selectedStateFilter.value) {

    //                 let filter = props.schools.filter(function (item) {
    //                     return (item.attributes.areaCode === selectedStateFilter.value);
    //                 });

    //                 if (filter && filter.length > 0) {
    //                     let citylist = [];
    //                     for (const s of filter) {
    //                         if (!citylist.includes(s.attributes.city)) {
    //                             citylist.push(s.attributes.city);
    //                         }

    //                     }
    //                     setCityList(citylist)
    //                 }
    //                 listToReturn = [...filter]
    //             }
    //         }
    //     } else {
    //         listToReturn = [...props.schools];
    //     }
    //     if (searchBarString === "") {
    //     } else {
    //         listToReturn = listToReturn.filter((object) =>
    //             object.attributes.Name.toLowerCase().includes(
    //                 searchBarString.toLowerCase()
    //             )
    //         );
    //     }
    //     // if (selectedSchools.length > 0) {
    //     //     listToReturn = listToReturn.filter(
    //     //         (object) => !selectedSchools.includes(object)
    //     //     );
    //     // }
    //     if (
    //         listToReturn != null &&
    //         listToReturn.length > 0 &&
    //         listToReturn[0].id !== "PickAll"
    //     ) {
    //         const KidObjectParse = Parse.Object.extend("School");
    //         var kidObjectParse = new KidObjectParse();
    //         kidObjectParse.id = "PickAll"
    //         kidObjectParse.set("Name", "Pick All");
    //         listToReturn.unshift(kidObjectParse);
    //     }
    //     return listToReturn;
    // };

    const setAllCityList = () => {
        let allCities = [{ value: "all", label: "ALL" }]
        console.log(cityList)
        for (const city of cityList) {
            let cityObject = {
                value: city,
                label: city,
            }
            allCities.push(cityObject)
        }
        console.log(allCities)
        setCityCodeList(allCities)
    }


    const getAllSchoolsFilteredList = () => {


        useEffect(() => {
            if (selectedStateFilter) {
                if (selectedStateFilter === "all_state") {
                    setFilteredList(props.schools);
                } else {
                    if (selectedStateFilter && selectedCityFilter) {
                        let filter1 = props.schools.filter(item => item.attributes.areaCode === selectedStateFilter.value);
                        let filter = filter1.filter(item => item.attributes.city === selectedCityFilter.value);
                        setFilteredList(filter)
                    } else if (selectedStateFilter) {
                        const filter = props.schools.filter(item => item.attributes.areaCode === selectedStateFilter.value);

                        if (filter && filter.length > 0) {
                            const uniqueCities = new Set();
                            let citylist = [];

                            for (const s of filter) {
                                const city = s.attributes.city;

                                if (!uniqueCities.has(city)) {
                                    uniqueCities.add(city);
                                    citylist.push({
                                        value: city,
                                        label: city,
                                    });
                                }
                            }

                            setCityList(citylist);
                        }

                        setFilteredList(filter)
                    }
                }
            } else {
                setFilteredList(props.schools);
            }
        }, [props.schools, selectedStateFilter, selectedCityFilter]);

        const listToReturn = filterList.filter(object =>
            object.attributes.Name.toLowerCase().includes(searchBarString.toLowerCase())
        );

        if (listToReturn.length > 0 && listToReturn[0].id !== "PickAll") {
            const KidObjectParse = Parse.Object.extend("School");
            const kidObjectParse = new KidObjectParse();
            kidObjectParse.id = "PickAll";
            kidObjectParse.set("Name", "Pick All");
            listToReturn.unshift(kidObjectParse);
        }

        return listToReturn;
    };




    const handleSearchBarTextChange = (value) => {
        setSeachBarString(value);
    };

    const getKidPhoto = (schoolObject) => {
        var kidPhoto = "";
        kidPhoto =
            schoolObject.attributes.Photo != undefined
                ? schoolObject.attributes.Photo._url
                : avatar2;
        return kidPhoto;
    };


    const handleCheckboxChange = (schoolObject) => {
        if (schoolObject.id === "PickAll") {
            handleSelectAll();
        } else {
            let schools = selectedSchools.filter((school) =>
                schoolObject.id == school.id
            )
            if (schools.length > 0) {
                let otherSchools = selectedSchools.filter((school) =>
                    schoolObject.id != school.id
                )
                setSelectedSchools(otherSchools)

            } else {
                var tempSelectedList = [...selectedSchools];

                tempSelectedList.unshift(schoolObject);

                setSelectedSchools(tempSelectedList);
            }

        }
    };

    const checkForCheckbox = (schoolObject) => {

        if (schoolObject.id === "PickAll") {
            return props.schools.length === selectedSchools.length
        }

        let schools = selectedSchools.filter((school) =>
            schoolObject.id == school.id
        )
        if (schools.length > 0) {
            return true
        }
        return false
    }
    const getSchoolName = (schoolId) => {
        if (props.schools && props.schools.length > 0) {
            let temp = props.schools.filter(
                (school) => school.id === schoolId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }
    return (
        <React.Fragment>
            <Container fluid={true}>

                {(!isLoading) && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Label className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading List...{" "}
                                </Label>
                            </div>
                        </Col>
                    </Row>
                )}

                {(isLoading) && (
                    <Row>
                        <Col lg={12} className="mx-auto">
                            <Card>
                                <CardBody>
                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-start">
                                            {!props.hideSlectAllButton && <button className="btn btn-primary" onClick={() => {
                                                props.onButtonClick(props.schools, props.schools ?? [])
                                            }}>
                                                Select all schools
                                            </button>}
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button className="btn btn-primary" onClick={() => {
                                                if (selectedSchools.length > 0) {
                                                    props.onButtonClick(selectedSchools, props.schools ?? [])
                                                } else {
                                                    toastr.warning("Please select atleast one school to proceed")
                                                }
                                            }}>
                                                Done
                                            </button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Label className="mt-2 mb-2 ml-1">Select Schools</Label>

                                    <Row lg={12}>
                                        <Col lg={7}>
                                            <Row>
                                                <Col lg={4}>
                                                    <FormGroup>
                                                        <Select
                                                            value={selectedStateFilter}
                                                            onChange={(v) => {
                                                                setSelectedStateFilter(v)
                                                                setSelectedCityFilter(undefined)
                                                            }}
                                                            options={stateList}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={4}>
                                                    <FormGroup>
                                                        <Select
                                                            value={selectedCityFilter}
                                                            onChange={(v) => {
                                                                setSelectedCityFilter(v)
                                                            }}
                                                            options={cityList}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={4}>
                                                    <Input
                                                        type="text"
                                                        name="searchAG"
                                                        className="inner form-control mb-3"
                                                        placeholder="Search"
                                                        onChange={(e) => {
                                                            handleSearchBarTextChange(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <div className="table-responsive">
                                                    <table className="table table-nowrap table-centered mb-0">
                                                        <tbody>
                                                            {getAllSchoolsFilteredList().length === 0 && (
                                                                <div className="ml-3 mt-3">
                                                                    <p>No School found</p>
                                                                    <hr />
                                                                </div>
                                                            )}

                                                            {getAllSchoolsFilteredList().map(
                                                                (schoolObject, keyInside) => (
                                                                    <tr key={keyInside}>
                                                                        <td style={{ width: "60px" }}>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={"customCheck12_" + keyInside}
                                                                                onChange={() => {
                                                                                    handleCheckboxChange(schoolObject);
                                                                                }}
                                                                                checked={checkForCheckbox(schoolObject)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <Label className="text-truncate font-size-14 m-0">

                                                                                <Label className="text-dark ml-2">
                                                                                    {schoolObject.attributes.Name}
                                                                                    {props.showCategory && schoolObject.attributes.inventoryCategories && schoolObject.attributes.inventoryCategories.length > 0 && (
                                                                                        <b>
                                                                                            {` (${getCategoryName(schoolObject.attributes.inventoryCategories[0])})`}
                                                                                        </b>
                                                                                    )}
                                                                                </Label>
                                                                            </Label>
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Row>
                                        </Col>
                                        {<Col lg={5}>
                                            <Row lg={12}>
                                                <Col lg={12}>
                                                    <div
                                                        className="text-center pt-2 py-auto bg-primary text-white"
                                                        style={{ borderRadius: "5px", height: "35px" }}
                                                    >
                                                        {"Selected Schools (" + selectedSchools.length + ")"}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <div className="table-responsive mt-3">
                                                    <table className="table table-nowrap table-centered mb-0">
                                                        <tbody>
                                                            {selectedSchools.length === 0 && (
                                                                <div className="ml-1">
                                                                    <p className="ml-3">No School Selected</p>
                                                                    <hr />
                                                                </div>
                                                            )}

                                                            {selectedSchools.map((schoolObject, keyInside) => (
                                                                <tr key={keyInside}>
                                                                    <td style={{ width: "60px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={"customCheck1_" + keyInside}
                                                                            onChange={() => {
                                                                                handleCheckboxChangeForSelected(
                                                                                    schoolObject
                                                                                );
                                                                            }}
                                                                            checked={selectedSchools.includes(schoolObject)}
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <Label className="text-truncate font-size-14 m-0">

                                                                            <Label className="text-dark ml-2">
                                                                                {getSchoolName(schoolObject.id)}
                                                                            </Label>{" "}
                                                                        </Label>
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Row>
                                        </Col>}
                                    </Row>
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-primary" onClick={() => {
                                            if (selectedSchools.length > 0) {
                                                props.onButtonClick(selectedSchools, props.schools ?? [])
                                            } else {
                                                toastr.warning("Please select atleast one school to proceed")
                                            }
                                        }}>
                                            Done
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {

    const { schools } = state.School;
    const { inventoryCategories } = state.InventoryCategories;

    return {
        schools, inventoryCategories
    };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, { getInventoryCategoriesForOwnerId })(SchoolPicker)
    )
);
