import React,{useState,useEffect} from 'react';
import { Container,Card,CardBody,Row,Col,FormGroup,Label,Nav,NavItem,TabPane,TabContent,NavLink, CardTitle } from "reactstrap";
import { AvForm, AvField,  } from "availity-reactstrap-validation";
import contentImage from "../../../assets/images/undraw_typewriter.svg";
import { withNamespaces } from "react-i18next";
import classnames from "classnames";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import Parse from 'parse';


import {
    updateCourse,
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
    setSelecetdSchool,
    setSelectedCourse,
    updateSelectedCourse,
    getTeachersForSchool,
} from "../../../store/actions";
//Import Breadcrumb
import TopBar from '../TopBar';


import BasicDetails from './BasicDetails';
import Target from './Target';
import CourseOverview from './CourseOverview';
import Pricing from './Pricing';
import ContentCreation from './ContentCreation'
import PostSubscription from './PostSubscription';


const CreateCourse = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/course-content/:schoolId/:courseId',
        exact: true,
        strict: false,
      
    });

    const schoolId = match ? match.params.schoolId : "";
    const courseId = match ? match.params.courseId : "addCourse";

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
        props.getTeachersForSchool(schoolId);
    }, [props.selectedSchool, schoolId]);
    

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [selectedTab, setSelectedTab] = useState(courseId === "addCourse" ? "nameCard" : "contentCard");
    const [courseName, setCourseName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [createCourseLoading, setCreateCourseLoading] = useState(false);

    const [contentTab, setContentTab] = useState("1");
    const [courseObject, setCourseObject] = useState(undefined);
    const [forInstitute, setForInstitute] = useState(false);

    const [saveInprogress, setSaveInprogress] = useState(false);

    //Content
    const [addCategory, setAddCategory] = useState(false)
    const [reorder, setReorder] = useState(false)
    

    useEffect(() => {
        if (courseId !== "addCourse") {
            setSelectedTab("contentCard")
            // tToggle();
            if (!courseObject) {
                getCourseObject();
            }
        }
         
         
        // console.log(courseObject);
    
    }, [courseId]);
    
    const canShowCheckboxForAllSchool = () => {
        let returnValue = false
    
        if (Parse.User.current() && Parse.User.current().attributes.instituteId && Parse.User.current().attributes.isSuperUser) {
            if (Parse.User.current().attributes.teacherId) {
                if (props.userTeacher && props.userTeacher.attributes.instituteId && props.userTeacher.attributes.instituteId.length > 0) {
                    returnValue = true
                }
            } else {
                returnValue = true
            }
        }
        return returnValue
    }


    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        if (props.leftSideBarType !== "condensed") {
            props.changeSidebarType("condensed", isMobile);
        }
    }

    const getCourseObject = () => {
        let query = new Parse.Query("Course");
        query.equalTo("objectId", courseId);
        query.first().then((result) => {
            setCourseObject(result);
            updateCourseObject(result);
            props.updateSelectedCourse(result);
        });
    }

    const createCourse = () => {
        let Course = Parse.Object.extend("Course");
        let course = new Course();
        course.set("name", courseName);
        if (forInstitute) {
            course.set("ownerId", Parse.User.current().attributes.instituteId);
            course.set("type", "Institute");
        } else {
            course.set("ownerId", schoolId);
            course.set("type", "School");
        }
        course.set("state", "inprogress");
        course.set("authorId", Parse.User.current().attributes.teacherId);
        course.set("authorId2", Parse.User.current().attributes.teacherId);
        course.save().then((result) => {

            if (result) {
                //setSelectedTab("contentCard");

                setCourseObject(result);
                props.history.push(result.id)
            }
        });
    }

    const saveCourseObject = (parseObject, nextPage) => {
        setSaveInprogress(true);
        parseObject.save().then(
            (result) => {
                setCourseObject(result);
                updateCourseObject(result);
                setSaveInprogress(false);
                props.updateSelectedCourse(result);
                if (nextPage) {
                    setContentTab(nextPage);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }



    const updateCourseObject = (object) => {
        setCourseName(object.attributes.name);
    }

    const handelOnClickBack = () => {
        window.history.back();
    }

    const getHideButton = () => {  
        if (contentTab === "3" ||!courseObject) {
            return false;
        } else {
            return true;
        }
    }

    const handelOnClickAdd = (bool) => {
        setAddCategory(bool)
    }
    const handelOnClickReorder = (bool) => {
        setReorder(bool)
    }
       
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                   <TopBar title={courseObject? courseObject.attributes.name: "COURSE CONTENT"}
                        backButton={true}
                        hideButton={getHideButton()}
                        reorderButton={courseObject && true}
                        buttonName="Add Chapter"
                        onClickBackButton={handelOnClickBack}

                        onClickAdd={()=>{handelOnClickAdd(true)}}
                        onClickReorder={()=>{handelOnClickReorder(true)}}
                    />


                    {selectedTab === "nameCard" && <Card>
                        <CardBody>
                            <Row >
                                <Col sm={7}>
                                    <AvForm className="outer-repeater" >
                                        <div data-repeater-item className="outer">
                                            <FormGroup >
                                                <Label ><h3 className="mt-5">{props.t('What do you want to name  your Course ?')}</h3> </Label>
                                                <AvField
                                                    type="text"
                                                    name="name"
                                                    placeholder={props.t('Course Name')}
                                                    className="form-control"
                                                    value={courseName}
                                                
                                                    onChange={(e) => {
                                                        if (e.target.value.length > 0 && nameError) {
                                                            setNameError(false);
                                                        }
                                                        setCourseName(e.target.value);
                                                    }}
                                            
                                                />
                                                {nameError && <p className="text-danger"> {props.t('Course name can not be empty')}</p>}
                                        

                                                {/* {canShowCheckboxForAllSchool() &&  <FormGroup>
                                                    <div className="custom-control custom-checkbox mb-2 ml-3" >
                                                        <input type="checkbox" className="custom-control-input" id="instituteCheck" onChange={() => false} checked={forInstitute} />
                                                        <label className="custom-control-label" onClick={() => { setForInstitute(!forInstitute); }} >{props.t('Add this course to all the institutes')}</label>
                                                    </div>
                                                </FormGroup>} */}
                                            </FormGroup>
                                                
                                        </div>
                                    </AvForm>
                                    {!createCourseLoading ? <button type="button"
                                        className="btn btn-primary  "
                                        onClick={() => {
                                            if (courseName && courseName.length > 0) {
                                                setCreateCourseLoading(true)
                                                createCourse();
                                                
                                            } else {
                                                setNameError(true);
                                            }
                                        }}  >
                                        {props.t('Next')}
                                    </button>
                                        : <div>
                                            <Label className=" btn btn-primary">
                                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Creating Course </Label>
                                        </div>
                                    
                                    }
                                </Col>
                                <Col sm={5}>
                                    <div className="maintenance-img">
                                        <img src={contentImage} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>

                            </Row>
                        </CardBody>
                    </Card>}


                    {selectedTab === "contentCard" && courseObject &&

                        <Row className="checkout-tabs">
                            <Col lg="2">
                                <Nav className="flex-column" pills>
                                                                    
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: contentTab === "1" })}
                                            onClick={() => {
                                                setContentTab("1");
                                            }}
                                        >
                                            {/* <i className="bx bx-detail d-block check-nav-icon mt-4 mb-2"></i> */}
                                            <p className="font-weight-bold mb-4 mt-4">
                                                {props.t("Basic Details")}
                                            </p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: contentTab === "2" })}
                                            onClick={() => {
                                                setContentTab("2");
                                            }}
                                        >
                                            {/* <i className=" bx bx-target-lock d-block check-nav-icon mt-4 mb-2"></i> */}
                                            <p className="font-weight-bold mb-4 mt-4">{props.t("Target")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: contentTab === "3" })}
                                            onClick={() => {
                                                setContentTab("3");
                                            }}
                                        >
                                            {/* <i className="bx bx-book-content d-block check-nav-icon mt-4 mb-2"></i> */}
                                            <p className="font-weight-bold mb-4 mt-4">{props.t("Content")}</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: contentTab === "4" })}
                                            onClick={() => {
                                                setContentTab("4");
                                            }}
                                        >
                                            {/* <i className="bx bx-bar-chart-square d-block check-nav-icon mt-4 mb-2"></i> */}
                                            <p className="font-weight-bold mb-4 mt-4">{props.t("Course Overview")}</p>
                                        </NavLink>
                                    </NavItem>
                                
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: contentTab === "5" })}
                                            onClick={() => {
                                                setContentTab("5");
                                            }}
                                        >
                                            {/* <i className="bx bx-money d-block check-nav-icon mt-4 mb-2"></i> */}
                                            <p className="font-weight-bold mb-4 mt-4">{props.t("Pricing")}</p>
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: contentTab === "6" })}
                                            onClick={() => {
                                                setContentTab("6");
                                            }}
                                        >
                                            {/* <i className="bx bx-edit-alt check-nav-icon mt-4 mb-2"></i> */}
                                            <p className="font-weight-bold mb-4 mt-4">{props.t("Post Subscription")}</p>
                                        </NavLink>
                                    </NavItem>


                                </Nav>
                            </Col>
                            <Col lg="10">
                               
                                <TabContent activeTab={contentTab}>

                                    {/* <Card>
                                        <CardBody>                                        
                                            <CardTitle>{courseName}</CardTitle>
                                        </CardBody>
                                    </Card> */}

                                    <TabPane
                                        tabId="1"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                               
                                        <BasicDetails
                                            courseObject={courseObject}
                                            onSaveCourseObject={saveCourseObject}
                                            saveInprogress={saveInprogress}
                                            teachers={props.teachers}
                                    
                                        ></BasicDetails>
                                    </TabPane>
                                    <TabPane
                                        tabId="2"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                        <Target
                                            courseObject={courseObject}
                                            onSaveCourseObject={saveCourseObject}
                                            saveInprogress={saveInprogress}
                                        ></Target>
                                               
                                    </TabPane>

                                    <TabPane
                                        tabId="3"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                        <ContentCreation
                                            courseObject={courseObject}
                                            onSaveCourseObject={saveCourseObject}
                                            saveInprogress={saveInprogress}
                                            courseId={courseId}
                                            addCategory={addCategory}
                                            reorder={reorder}
                                            onCloseAddCategory={() => {
                                                setAddCategory(false)
                                            }}
                                            onCloseReorder={() => {
                                                setReorder(false);
                                            }}
                                        ></ContentCreation>
                                            
                                    </TabPane>
                                        
                                    <TabPane
                                        tabId="4"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                        <CourseOverview
                                            courseObject={courseObject}
                                            onSaveCourseObject={saveCourseObject}
                                            saveInprogress={saveInprogress}
                                            userTeacher={props.userTeacher}
                                            courseId={courseId}
                                        
                                        ></CourseOverview>
                                               
                                    </TabPane>

                                    <TabPane
                                        tabId="5"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                        <Pricing
                                            courseObject={courseObject}
                                            onSaveCourseObject={saveCourseObject}
                                            saveInprogress={saveInprogress}
                                        ></Pricing>
                                    
                                    </TabPane>
                                    <TabPane
                                        tabId="6"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                        <PostSubscription
                                            courseObject={courseObject}
                                            onSaveCourseObject={saveCourseObject}
                                            saveInprogress={saveInprogress}
                                        />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    }
                   
                    {
                        (!courseObject) && <Col xs="12">
                            <div className="text-center my-3">
                                <Label className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                                </Label>
                            </div>
                
                        </Col>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
}
            
const mapStatetoProps = (state) => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
    const { userTeacher } = state.Login;
    const { selectedSchool } = state.School;
    const { selectedCourse } = state.Course;
    const { teachers } = state.Teacher;

  return { layoutType, showRightSidebar, leftMenu,leftSideBarType,userTeacher,selectedSchool ,selectedCourse,teachers};
};


export default connect(mapStatetoProps, {
    updateCourse,
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
    setSelecetdSchool,
    setSelectedCourse,
    updateSelectedCourse,
    getTeachersForSchool,
})(withNamespaces()(CreateCourse));