import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
    setSelecetdSchool, getExpenseCategoryForSchoolId,
    getExpenseSupplierForSchoolId,
    getExpenseWalletLogForDate, clearExpenseWalletLog,
    getTeacher,getAllKids, getTeachersForSchool
} from '../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import moment from 'moment';
import DocViewer, { DocViewerRenderers, BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";
import Select from "react-select";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Button,
    Modal, ModalHeader, ModalBody, UncontrolledTooltip, ModalFooter,
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import COUNTRY_JSON from '../../assets/other/countries.json'

const ExpenseReports = (props) => {

    const schoolId = props.schoolId

    const [isLoadingForImagesPopup, setIsLoadingForImagesPopup] = useState(false);
    const [selectedStartDate, selectedStartDateOps] = useState();
    const [selectedEndDate, selectedEndDateOps] = useState();
    const [gotReport, setGotReport] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [showImagesViewModal, setShowImagesViewModal] = useState(false);
    const [showFileViewModal, setShowFileViewModal] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [reportType, setReportType] = useState("1");
    const [clickedFile, setClickedFile] = useState(undefined);
    const [supplierList, setSupplierList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [expenseWalletLog, setExpenseWalletLog] = useState([]);

    const [requestGenerate, setRequestGenerate] = useState(false);
    const [numberOfExpense, setNumberOfExpense] = useState()
    const [totalSpent, setTotalSpent] = useState()
    const [walletBalance, setWalletBalance] = useState(0);
    const [amountSpent, setAmountSpent] = useState(0);

    const [selectedExpenseCategory, setSelectedExpenseCategory] = useState({ value: "all", label: "All" });

    const [selectedSupplierType, setSelectedSupplierType] = useState({ value: "all", label: "All" });
    const [selectedSupplier, setSelectedSupplier] = useState({ value: "all", label: "All" });
    
    const [teacherList, setTeacherList] = useState([]);
    const [kidList, setKidList] = useState([]);
    
    const CASH = "Cash"
    const CHEQUE = "Cheque"
    const ONLINE = "Online"
    const SUPPLIER = "Supplier"
    const STAFF = "Staff"
    const STUDENT = "Student"

    const supplierTypeList = [{ value: "all", label: "All" }, {value: SUPPLIER, label: SUPPLIER }, { value: STAFF, label: STAFF }, { value: STUDENT, label: STUDENT }]
    
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if (Parse.User.current() && props.selectedSchool && requestGenerate) {
            props.getExpenseCategoryForSchoolId(schoolId, props.selectedSchool.attributes.instituteId)
            props.getExpenseSupplierForSchoolId(schoolId, props.selectedSchool.attributes.instituteId)
        }

    }, [props.selectedSchool, schoolId, requestGenerate]);

    useEffect(() => {
        if (Parse.User.current()) {
            props.getTeacher(Parse.User.current().attributes.teacherId)
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.expenseCategories && props.expenseCategories.length > 0) {
            const options = props.expenseCategories.map(category => ({
                value: category.id,
                label: category.attributes.name
            }));
            const allOption = { value: "all", label: "All" };
            setCategoryList([allOption, ...options]);
            console.log("Updated Category List:", [allOption, ...options]);
        }
    }, [props.expenseCategories]);

    useEffect(() => {
        if (props.expenseSuppliers && props.expenseSuppliers.length > 0) {
            const options = props.expenseSuppliers.map(supplier => ({
                value: supplier.id,
                label: supplier.attributes.name
            }));
            const allOption = { value: "all", label: "All" };
            setSupplierList([allOption, ...options]);
            console.log("SupplierList", supplierList)
        }
    }, [props.expenseSuppliers]);

    useEffect(() => {
        if (props.teachers && props.teachers.length > 0) {
            const options = props.teachers.map(teacher => ({
                value: teacher.id,
                label: teacher.attributes.Name
            }));
            const allOption = { value: "all", label: "All" };
            setTeacherList([allOption, ...options]);
            console.log("TeacherList", teacherList)
        }
    }, [props.teachers]);

    useEffect(() => {
        if (props.kids && props.kids.length > 0) {
            const options = props.kids.map(kid => ({
                value: kid.id,
                label: kid.attributes.Name
            }));
            const allOption = { value: "all", label: "All" };
            setKidList([allOption, ...options]);
        }
    }, [props.kids]);

    const generateExpenseReport = () => {
        let filter = {};
        filter.schoolId = props.schoolId;
        filter.startDate = new Date(
            selectedStartDate.getFullYear(),
            selectedStartDate.getMonth(),
            selectedStartDate.getDate(),
            0, 0, 0, 0
        );
        filter.endDate = new Date(
            selectedEndDate.getFullYear(),
            selectedEndDate.getMonth(),
            selectedEndDate.getDate(),
            23,
            59,
            59,
            999
        );
        filter.selectedSupplierType = selectedSupplierType.value
        filter.selectedCategory = selectedExpenseCategory.value;
        filter.selectedSupplier = selectedSupplier.value;
        setRequestGenerate(true)

        reportType == "1" ? getExpenseReports(filter) : generateExpenseWalletReport(filter);
    }

    const generateExpenseWalletReport = (filter) => {
        if (reportType === "2") {
            setExpenseWalletLog(undefined)
            var ExpenseWalletLog = Parse.Object.extend("ExpenseWalletLog");
            var query = new Parse.Query(ExpenseWalletLog);
            query.equalTo("schoolId", filter.schoolId);
            query.limit(1000);
            query.greaterThanOrEqualTo("createdAt", filter.startDate);
            query.lessThanOrEqualTo("createdAt", filter.endDate);
            query.descending("createdAt");
            query.notEqualTo("isDeleted", true);
            query.find().then(
                (results) => {
                    // console.log("Results", results);
                    let expenses = []
                    let totalSpent = 0;
                    let balance = 0; 
                    let order = 1;
                    for (const expenseLog of results) {
                        let newExpenses = {
                            slno: order++,
                            type: expenseLog.attributes.type,
                            payee: getTeacherName(expenseLog.attributes.createdBy),
                            date: convertDateToString(expenseLog.attributes.createdAt),
                            createdAt: expenseLog.attributes.createdAt,
                            createdBy: expenseLog.attributes.createdBy,
                            amount: expenseLog.attributes.amount,
                            money: <Label className={expenseLog.attributes.amount > 0 ? 'text-success' : 'text-danger'}>{expenseLog.attributes.amount}</Label>,
                            expenseNumber: expenseLog.attributes.expenseNumber ?? "",
                            walletBalance: expenseLog.attributes.walletBalance ?? "",
                        }
                        expenses.push(newExpenses)
                        if (expenseLog.attributes.amount > 0) {
                            balance += expenseLog.attributes.amount; 
                        } else {
                            totalSpent += Math.abs(expenseLog.attributes.amount);
                        }
                    }
                    setExpenseWalletLog(expenses);
                    setGotReport(true);
                    setWalletBalance(balance); 
                    setAmountSpent(totalSpent)
                },
                (error) => {

                }
            );
        }
    }
    

    function getURL() {
        if (clickedFile) {
            return clickedFile._url;
        }
    }

    const getExpenseReports = (filter) => {
        if (reportType === "1") {
            setReportList(undefined)
            var Expense = Parse.Object.extend("Expense");
            var query = new Parse.Query(Expense);
            query.equalTo("schoolId", filter.schoolId);
            query.notEqualTo("isDeleted", true);
            query.greaterThanOrEqualTo("paymentDate", filter.startDate);
            query.lessThanOrEqualTo("paymentDate", filter.endDate);
            if (filter.selectedCategory != "all") {
                query.equalTo("categoryId", filter.selectedCategory);
                console.log("SelectedCategory", filter.selectedCategory)
            }
            if (filter.selectedSupplierType !== "all") {
                if (filter.selectedSupplierType === "Supplier") {
                    query.equalTo("supplierType", 1);
                } else if (filter.selectedSupplierType === "Staff") {
                    query.equalTo("supplierType", 2);
                } else if (filter.selectedSupplierType === "Student") {
                    query.equalTo("supplierType", 3);
                }

                if (filter.selectedSupplier != "all") {
                    query.equalTo("supplierId", filter.selectedSupplier);
                }
            }
            
            query.limit(1000);
            query.descending("paymentDate");
            query.find().then(
                (results) => {

                    let tempList = []
                    let totalSpentAmount = 0;
                    let numberOfExpense = 0;

                    for (const res of results) {
                        let obj = res.attributes
                        let tempObject = {}
                        tempObject.note = obj.notes ?? ""
                        tempObject.amount = obj.amount ?? ""
                        tempObject.tax = obj.tax
                        tempObject.tds = obj.tds
                        tempObject.paymentDate = obj.paymentDate
                        tempObject.referenceNumber = obj.referenceNumber
                        tempObject.expenseNumber = obj.expenseNumber ?? ""
                        tempObject.paymentType = (obj.paymentType && obj.paymentType === 1) ? CASH : (obj.paymentType && obj.paymentType === 2) ? CHEQUE : ONLINE
                        tempObject.expenseParseObject = res
                        tempObject.isVerified = obj.status === 1 ? true : false
                        tempObject.createdAt = res.createdAt
                        tempObject.supplierType = getSupplierType(obj.supplierType);
                        totalSpentAmount += obj.amount ?? 0;
                        numberOfExpense += 1;

                        if (obj.attachmentFile) {
                            tempObject.attachmentDoc = obj.attachmentFile
                        }
                        if (obj.albumId) {
                            tempObject.albumId = obj.albumId
                        }
                        if (props.expenseCategories) {
                            let tempCategories = props.expenseCategories.filter((category) => {
                                if (obj.categoryId === category.id) {
                                    return true
                                }
                            })
                            if (tempCategories.length > 0) {
                                tempObject.categoryParseObject = tempCategories[0]
                                tempObject.categoryName = tempCategories[0].attributes.name
                            }
                        }

                        if (props.expenseSuppliers) {
                            let tempSuppliers = props.expenseSuppliers.filter((supplier) => {
                                if (obj.supplierId === supplier.id) {
                                    return true
                                }
                            })
                            if (tempSuppliers.length > 0) {
                                tempObject.supplierParseObject = tempSuppliers[0]
                                tempObject.supplierName = tempSuppliers[0].attributes.name
                            }
                        }

                        tempList.push(tempObject)
                        console.log("Tempobject", tempObject)

                    }



                    setTotalSpent(totalSpentAmount)
                    setNumberOfExpense(numberOfExpense)
                    setReportList(tempList)
                    setGotReport(true)

                },
                (error) => {

                }
            );
        }
    }

    const downloadTheExpenseReport = () => {

        if (!reportList || reportList.length === 0) {
            return;
        }


        let finalData = [];
        let titles = [];
        let fileName = "";
        let sheetName = "";
        fileName = `Expense_Report_${getDateInDDMMYYYY(selectedStartDate)}-${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
        sheetName = "Expense_Report";
        finalData.push([
            `Report Type : Expense Report`,
        ]);
        finalData.push([
            `School : ${props.selectedSchool.attributes.Name}`,
        ]);
        finalData.push([
            `Duration : ${getDateInDDMMYYYY(selectedStartDate)}-${getDateInDDMMYYYY(selectedEndDate)}`
        ]);
        let totalAmount = 0
        for (const item of reportList) {
            totalAmount += item.amount
        }
        finalData.push([
            `Total Amount : ${totalAmount}`
        ]);
        finalData.push([
        ]);
        finalData.push([
        ]);

        titles.push("Si no.");
        titles.push("Method");
        titles.push("Expense Date");
        titles.push("Reference Number");
        titles.push("Status");
        titles.push("Amount");
        titles.push("Tax");
        titles.push("TDS");
        titles.push("Category");
        titles.push("Payee/Supplier");
        titles.push("Notes");
        titles.push("Expense Number");

        finalData.push(titles);
        var f = 0
        for (const item of reportList) {
            let temp = [];
            temp.push(++f);
            temp.push(item.paymentType)
            temp.push(convertDateToString(item.paymentDate ?? item.createdAt))
            temp.push(item.referenceNumber && item.referenceNumber.length > 0 ? item.referenceNumber : "-")
            temp.push(item.isVerified ? "Verified" : "Not Verified")
            temp.push(item.amount)
            temp.push(item.tax ? `${item.tax}%` : "-")
            temp.push(item.tds ? `${item.tds}%` : "-")
            temp.push(item.categoryName ?? "-")
            temp.push(item.supplierName ?? "-")
            temp.push(item.note)
            temp.push(item.expenseNumber)
            finalData.push(temp);
        }
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        ws['!cols'] = Array(titles.length).fill({ wpx: 100 });
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);

    }

    const downloadTheExpenseWalletReport = () => {

        if (!expenseWalletLog || expenseWalletLog.length === 0) {
            return;
        }


        let finalData = [];
        let titles = [];
        let fileName = "";
        let sheetName = "";
        fileName = `Expense_Wallet_Report_${getDateInDDMMYYYY(selectedStartDate)}-${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
        sheetName = "Expense_Report";
        finalData.push([
            `Report Type : Expense Wallet Report`,
        ]);
        finalData.push([
            `School : ${props.selectedSchool.attributes.Name}`,
        ]);
        finalData.push([
            `Duration : ${getDateInDDMMYYYY(selectedStartDate)}-${getDateInDDMMYYYY(selectedEndDate)}`
        ]);
        finalData.push(["Amount Credited (Wallet)", walletBalance]);
        finalData.push(["Amount Debited (Spent)", amountSpent]);
        finalData.push([]);

        titles.push("SL No.");
        titles.push("Type");
        titles.push("Date");
        titles.push("Payee");
        titles.push("Amount");
        titles.push("Expense Ref");
        titles.push("Wallet Balance");
        finalData.push(titles);
        var f = 0
        for (const item of expenseWalletLog) {
            let temp = [];
            temp.push(++f);
            temp.push(item.type)
            temp.push(moment(item.createdAt).format('DD/MM/YYYY'))
            temp.push(getTeacherName(item.createdBy))
            temp.push(item.amount)
            temp.push(item.expenseNumber ?? "-")
            temp.push(item.walletBalance ?? "-")
            finalData.push(temp);
        }
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        ws['!cols'] = Array(titles.length).fill({ wpx: 100 });
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
    }

    const getDateInDDMMYYYY = (date) => {
        return moment(date).format("DD/MM/YYYY");
    };

    const getFR = (url) => {
        const docs = [
            { uri: url },
        ];
        return docs;
    }

    function convertDateToString(date) {
        return moment(date).format('DD MMM YYYY')
    }

    const getAlbumsForAlbumId = (albumId) => {
        setIsLoadingForImagesPopup(true)

        var query = new Parse.Query('Album');
        query.equalTo('albumId', albumId);
        query.limit(1000);
        query.find().then((results) => {
            setIsLoadingForImagesPopup(false)

            if (results && results.length > 0) {
                let tempImages = []
                for (const i in results) {
                    tempImages.push(results[i].attributes.image ? results[i].attributes.image._url : "")
                }
                setSelectedImages(tempImages)
            }
        })
    }

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getSupplierType = (type) => {
        if (type === 1) {
            return 'Supplier';
        } else if (type === 2) {
            return 'Staff';
        } else if (type === 3) {
            return 'Kid';
        } else {
            return 'Unknown';
        }
    };
    
    console.log("ReportList", reportList)
    return (
        <React.Fragment>
            <CardTitle>Expense Report</CardTitle>
            <CardSubtitle className="mb-3">
                Select filter to generate the report
            </CardSubtitle>
            <Row className="bg-light">
                <Col md="4" xs="12" xl="4" lg="4">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Report Type :</Label>
                        <select
                            className="form-control "
                            value={reportType}
                            onChange={(e) => {
                                setReportType(e.target.value);
                            }}
                        >
                            <option value={1}>{props.t("Expense Report")}</option>
                            <option value={2}>{props.t("Expense Wallet Report")}</option>
                        </select>
                    </FormGroup>
                </Col>
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select Start Date :</Label>
                        <DatePicker
                            selected={selectedStartDate}
                            onChange={(date) => {
                                date ? selectedStartDateOps(date) : selectedStartDateOps(new Date());
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            // startDate={fromDate}
                            // endDate={toDate}
                            maxDate={selectedEndDate}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select End Date :</Label>
                        <DatePicker
                            selected={selectedEndDate}
                            onChange={(date) => {
                                date
                                    ? selectedEndDateOps(date)
                                    : selectedEndDateOps(new Date());
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            // startDate={fromDate}
                            // endDate={toDate}
                            minDate={selectedStartDate}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                {reportType == "1" && (
                    <Col md="3" xs="12" xl="4" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label className="mb-0">{props.t("What kind of Expenses")} :</Label>
                        <Select
                            value={selectedExpenseCategory}
                            onChange={option => {
                                setSelectedExpenseCategory(option)
                            }}
                            options={categoryList}
                            placeholder={props.t("Select Category")}
                        />
                    </FormGroup>
                </Col>)}
                {reportType == "1" && (
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label className="mb-0">{props.t("Who Did You Pay")} :</Label>
                        <Select
                            value={selectedSupplierType}
                            onChange={option => {
                                if(option.value != selectedSupplierType.value){
                                    setSelectedSupplier({value:"all",label:"All"})
                                }
                                setSelectedSupplierType(option)
                            }}
                            options={supplierTypeList}
                            placeholder={props.t("Select Supplier")}
                        />
                    </FormGroup>
                </Col>)}
                {reportType == "1" && (
                <Col md="3" xs="12" xl="3" lg="3">
                    {selectedSupplierType?.value  != "all" && <FormGroup className="mt-2 mb-2 p-1">
                        <Label className="mb-0">{props.t("Select")+" "+ selectedSupplierType?.value} :</Label>
                        <Select
                            value={selectedSupplier}
                            onChange={option => {
                                setSelectedSupplier(option)
                            }}
                            options={
                                selectedSupplierType?.value === "all" ?
                                    [...supplierList, ...teacherList, ...kidList] :
                                    selectedSupplierType?.value === SUPPLIER ? supplierList :
                                    selectedSupplierType?.value === STAFF ? teacherList :
                                    selectedSupplierType?.value === STUDENT ? kidList : []
                            }
                            placeholder={props.t("Select")}
                        />
                    </FormGroup>}
                </Col>)}
                <Col md="2" xs="12" xl="2" lg="2">
                    <div className="mt-2">
                        <div className={reportType == "1" ? "mt-0" : "mt-3"}>
                            <button
                                className="btn btn-primary mt-4 mr-2"
                                onClick={() => {
                                    generateExpenseReport();
                                }}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                {reportType == "1" && gotReport && reportList && reportList.length > 0 && (
                    <Col md="12" xs="12" xl="12" lg="12" className="d-flex justify-content-end mt-1 mb-1">
                        <button
                            className="btn btn-info float-right"
                            onClick={() => { downloadTheExpenseReport() }}
                        >
                            <i className="bx bxs-download mr-1" />
                            Download
                        </button>
                    </Col>
                )}
                {reportType == "2" && gotReport && expenseWalletLog && expenseWalletLog.length > 0 && (
                    <Col md="12" xs="12" xl="12" lg="12" className="d-flex justify-content-end mt-1 mb-1">
                        <button
                            className="btn btn-info float-right"
                            onClick={() => { downloadTheExpenseWalletReport() }}
                        >
                            <i className="bx bxs-download mr-1" />
                            Download
                        </button>
                    </Col>
                )}
            </Row>
            {gotReport && reportList && reportList.length > 0 && reportType == "1" && (
                <Row>
                    <Col>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table className="table mb-0 table-bordered table-sm m-1" style={{ width: '500px' }}>
                                    <tbody>
                                        <tr>
                                            <th scope="row" style={{ width: "200px" }}><b>Number of Expense</b></th>
                                            <td scope="row" style={{ width: "500px" }}>{numberOfExpense}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "200px" }}><b>Total Spent</b></th>
                                            <td scope="row" style={{ width: "500px" }}>{totalSpent}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table id="tech-companies-1" className="table table-striped table-bordered  table-centered table-nowrap mb-0 table-sm m-1">
                                    <Thead>
                                        <Tr>
                                            <Th>Si no.</Th>
                                            <Th data-priority="1">Method</Th>
                                            <Th data-priority="1">Expense Date</Th>
                                            <Th data-priority="2">Reference Number</Th>
                                            <Th data-priority="2">Status</Th>
                                            <Th data-priority="3">Amount</Th>
                                            <Th data-priority="4">Tax</Th>
                                            <Th data-priority="5">TDS</Th>
                                            <Th data-priority="6">Category</Th>
                                            <Th data-priority="7">Supplier Type</Th>
                                            <Th data-priority="8">Payee/Supplier</Th>
                                            {/* <Th data-priority="8">Download Attachment</Th> */}
                                            <Th data-priority="9">Notes</Th>
                                            <Th data-priority="10">Expense Number</Th>

                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            reportList.map((item, key) => (
                                                <Tr key={key}>
                                                    <Th>{key + 1}</Th>
                                                    <Td>
                                                        <div><Label>{`${item.paymentType}`}</Label></div>

                                                    </Td>
                                                    <Td>
                                                        <div><Label>{`${convertDateToString(item.paymentDate ?? item.createdAt)}`}</Label></div>

                                                    </Td>
                                                    <Td>
                                                        {item.referenceNumber && <div><Label>{`${item.referenceNumber}`}</Label></div>}
                                                    </Td>
                                                    <Td>
                                                        <Label className={"text-dark"}>{item.isVerified ? "Verified" : "Not Verified"}</Label>

                                                    </Td>
                                                    <Td style={{ textAlign: "center" }}>
                                                        <div><Label style={{ fontFamily: "Arial, sans-serif" }}>{ }</Label><Label>{`${item.amount}`}</Label></div>

                                                    </Td>
                                                    <Td>{item.tax ? <div>{`${item.tax}%`}</div> : ""}
                                                    </Td>
                                                    <Td>{item.tds ? <div>{`${item.tds}%`}</div> : ""}
                                                    </Td>


                                                    <Td>
                                                        <div><Label>{item.categoryName ?? "-"}</Label></div>
                                                    </Td>
                                                    <Td>
                                                        <div><Label>{item.supplierType ?? "-"}</Label></div>
                                                        </Td>
                                                    <Td>
                                                        <div><Label>{item.supplierName ?? "-"}</Label></div>
                                                    </Td>
                                                    {/* <Td>
                                                    {item.albumId ? <Label className="text-primary justify-content-center text-truncate" style={{ cursor: "pointer" }} onClick={() => {
                                                        setShowImagesViewModal(true)
                                                        getAlbumsForAlbumId(item.albumId)
                                                    }}>
                                                        <u>{"View Attachment"}</u>
                                                    </Label> :
                                                        (item.attachmentDoc ? <Label className="text-primary justify-content-center text-truncate" style={{ cursor: "pointer" }} onClick={() => {
                                                            setShowFileViewModal(true)
                                                            setClickedFile(item.attachmentDoc)
                                                        }}>
                                                            <u>{"View Attachment"}</u>
                                                        </Label> : "")
                                                    }
                                                </Td> */}

                                                    <Td>
                                                        <div><Label >{item.note}</Label></div>
                                                    </Td>
                                                    <Td>
                                                        <div><Label>{item.expenseNumber}</Label></div>
                                                    </Td>


                                                </Tr>
                                            ))
                                        }

                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
            {gotReport && expenseWalletLog && expenseWalletLog.length > 0 && reportType == "2" &&
            <div className='mt-0'>
                <Table className=" table mt-0 mb-3 table-bordered table-sm" style={{ width: '500px' }}>
                  <tbody>
                        <tr>
                            <th scope="row" style={{ width: "300px" }}>Amount Credited(Wallet)</th>
                            <td style={{ width: "300px" }}>{walletBalance}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ width: "300px" }}>Amount Debited(Spent)</th>
                            <td style={{ width: "200px" }}>{amountSpent}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>}
            {gotReport && expenseWalletLog && expenseWalletLog.length > 0 && reportType == "2" && (
                <Row>
                    <Col>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                    <Thead>
                                        <Tr>
                                            <Th>Sl No</Th>
                                            <Th data-priority="1">Type</Th>
                                            <Th data-priority="2">Date</Th>
                                            <Th data-priority="3">Payee</Th>
                                            <Th data-priority="4">Amount</Th>
                                            <Th data-priority="5">Expense Ref</Th>
                                            <Th data-priority="6">Wallet Balance</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            expenseWalletLog.map((item, key) => (
                                                <Tr key={key}>
                                                    <Th>{key + 1}</Th>
                                                    <Td>
                                                        <div><Label>{item.type}</Label></div>

                                                    </Td>
                                                    <Td>
                                                        <div><Label>{convertDateToString(item.date)}</Label></div>

                                                    </Td>
                                                    <Td>
                                                        <div><Label>{item.payee}</Label></div>

                                                    </Td>
                                                    <Td>
                                                        <div><Label>{item.money}</Label></div>
                                                    </Td>
                                                    <Td>
                                                        {<div><Label>{item.expenseNumber}</Label></div>}
                                                    </Td>
                                                    <Td>
                                                        {<div><Label>{item.walletBalance}</Label></div>}
                                                    </Td>
                                                </Tr>
                                            ))
                                        }

                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}

            {gotReport && reportList && reportList.length == 0 && reportType == "1" && (
                <Col>
                    <h3 className="text-center text-info mt-3">
                        {props.t("No Report Found For The Filter")}
                    </h3>
                </Col>
            )}

            {gotReport && expenseWalletLog && expenseWalletLog.length == 0 && reportType == "2" && (
                <Col>
                    <h3 className="text-center text-info mt-3">
                        {props.t("No Report Found For The Filter...")}
                    </h3>
                </Col>
            )}

            <Modal
                isOpen={showImagesViewModal}
                toggle={() => setShowImagesViewModal(!showImagesViewModal)}
                scrollable={true}
                size='lg'
            >

                {selectedImages && !isLoadingForImagesPopup && <ModalBody>

                    {selectedImages.map((image, key) => (
                        <img key={key} src={image} width="100%px" className='mt-3' height="" alt="image" />
                    ))}

                </ModalBody>
                }
                {
                    isLoadingForImagesPopup && <ModalBody>
                        <Row>
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success" onClick={(e) => {
                                        e.preventDefault()
                                    }}>
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                        Loading...{" "}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                }



                <ModalFooter>


                    <Button color="secondary" onClick={() => { setShowImagesViewModal(false) }}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                size="xl"
                isOpen={showFileViewModal}
                toggle={() => { setShowFileViewModal(!showFileViewModal) }}
                scrollable={true}
            >
                <div className="modal-header">



                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        {clickedFile && getFileName(clickedFile)}
                    </h5>


                    {
                        clickedFile &&
                        <a

                            href={getURL()}
                            download={getFileName(clickedFile)}

                            target="_blank"
                            type="button"
                            className="btn btn-sm btn-primary waves-effect waves-light ml-4">
                            <i className="bx bx-download font-size-14 align-middle"></i> {props.t('Download ')}
                        </a>
                    }
                    <button
                        onClick={() => {
                            setShowFileViewModal(false)
                            setClickedFile(undefined);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody >
                    {

                        <div>
                            {getURL() && getURL().endsWith(".pdf") ? <Iframe
                                url={getURL() + "#toolbar=0"}
                                height="600"
                                width="100%"
                                display="initial"
                                allowFullScreen={true}
                                position="relative"
                                frameBorder={0}

                            >
                            </Iframe> :

                                <DocViewer
                                    pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer,]}
                                    style={{ height: "500px" }}
                                    documents={getFR(getURL())}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: false,
                                            retainURLParams: false
                                        }
                                    }}

                                />
                            }
                        </div>

                    }

                    <Card style={{ backgroundColor: "#fff", marginTop: "-7mm", width: "100%", height: " 7mm" }} />




                </ModalBody>
            </Modal>

        </React.Fragment>
    )



}
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { expenseCategories } = state.ExpenseCategory;
    const { expenseSuppliers } = state.ExpenseSupplier;
    const { expensesWalletLog } = state.Expenses;
    const { teachers, } = state.Teacher;
    const { kids } = state.Kid;
    return { schools, selectedSchool, expenseCategories, expenseSuppliers, expensesWalletLog, teachers, kids };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            getExpenseCategoryForSchoolId,
            getExpenseSupplierForSchoolId,
            setSelecetdSchool,
            getExpenseWalletLogForDate,
            clearExpenseWalletLog,
            getTeacher,getAllKids, getTeachersForSchool
        })(ExpenseReports)
    )
);