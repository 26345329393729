import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Container,
  CardTitle,
  Label,
  Table,
  Alert,
  FormGroup,
} from "reactstrap";

import { withRouter, Link } from "react-router-dom";
import { matchPath } from "react-router";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "./datatables.scss";
import toastr from "toastr";
import DatePicker from "react-datepicker";
import SweetAlert from "react-bootstrap-sweetalert";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import Parse from "parse";
import * as Constants from "../../Constents";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import Select from "react-select";
import Moment from "moment";
import { MDBDataTable } from "mdbreact";
import TopHeader from "../../components/Common/topHeader";
import {
  getClassroomsForSchoolId,
  getGroupMapsForTeacherId,
  getAllKids,
  getAllRemindersForSchool,
  saveAllReminders,
  addReminder,
  editReminder,
  setSelecetdSchool,
  deleteReminder,
  getCoursesForOwnerIds, getTeachersForSchool, getGroupsForSchoolId
} from "../../store/actions";
import { Input } from "reactstrap/lib";

const firstRow = [
  "SlNo",
  "Title",
  "Message",
  "Day",
  "Month",
  "Year",
  "Hours",
  "Minutes",
  "AM/PM",
  "EventType",
  // "StartDate",
  // "EndDate",
];

const SendTo = {
  School: "@School",
  Class: "@Class",
  Group: "@Group",
  Teachers: "@Teachers",
};

const Index = (props) => {

  const match = matchPath(props.history.location.pathname, {
    path: "/calendar/:id",
    exact: true,
    strict: false,
  });

  const schoolId = match.params.id;
  const calendarComponentRef = React.createRef();

  const [endDate, setEndDate] = useState(undefined);
  const [editEventModal, setEditEventModal] = useState(false);
  const [hideCloseButton, setHideCloseButton] = useState(false);
  const eventTypeString = "Event";
  const holidayTypeString = "Holiday";

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [event, setEvent] = useState(undefined);
  const [selectedDay, setSelectedDay] = useState(0);
  const [hideTimeSelect, setHideTimeSelect] = useState(false);

  const [editEventButtonOn, setEditEventButtonOn] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const [importOnButton, setImportOnButton] = useState(false);

  const [selectedTime, setSelectedTime] = useState(null)

  const templateURL =
    "https://parsefiles.back4app.com/0ZRsOIsU32VP5AhOrDR7nMqrGR7PFkzaSYEPBsNN/96aa38c9748cc70573d2a0aeabe552dc_CalendarTemplate.xlsx";

  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pastDateError, setPastDateError] = useState(false);
  const [customDateError, setCustomDateError] = useState(false);
  const [ownerIds, setOwnerIds] = useState([]);
  const [schoolType, setSchoolType] = useState(1);
  const [courseAndBatchList, setCourseAndBatchList] = useState(undefined);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [selectedParticipantsError, setSelectedParticipantsError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [classroomKids, setClassroomKids] = useState([]);
  const [selectedClassError, setSelectedClassError] = useState();
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedGroupError, setSelectedGroupError] = useState();
  const [classroomList, setClassroomList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();
  const [selectedStudentError, setSelectedStudentError] = useState();
  const [selectedTeacher, setSelectedTeacher] = useState("all");
  const [selectedTeacherError, setSelectedTeacherError] = useState();
  const [reasonForDelete, setReasonForDelete] = useState();
  // const [reasonForDeleteError, setReasonForDeleteError] = useState('');
  const [customDate, setCustomDate] = useState('');
  const [sendNotification, setSendNotification] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState("Event");
  
  const allClassObject = () => {
    let tempSelectObj = {}
    tempSelectObj.id = "all"
    tempSelectObj.value = "all"
    tempSelectObj.label = "All Classrooms"
    return tempSelectObj;
  }

  const [selectedClassroom, setSelectedClassroom] = useState(allClassObject());

  const sendToList =[
    { value: 1, label: SendTo.School },
    { value: 2, label: SendTo.Class },
    { value: 3, label: SendTo.Group },
    { value: 4, label: SendTo.Teachers },
  ];
  const [selectedSendTo, setSelectedSendTo] = useState(sendToList[0].value);

  const reminderPreferences = [

    { value: 0, label: "5 mins Before" },
    { value: 1, label: "15 mins Before" },
    { value: 2, label: "30 mins Before" },
    { value: 3, label: "1 hour Before" },
    { value: 4, label: "2 hours Before" },
    { value: 5, label: "6 hours Before" },
    { value: 6, label: "1 days Before" },
    { value: 7, label: "2 days Before" },
    { value: 8, label: "Custom" }
  ];

  const [selectedPreference, setSelectedPreference] = useState(6)


  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    } else {
      setSchoolType(props.selectedSchool.attributes.schoolType);
    }
  }, [props.selectedSchool, schoolId]);


  useEffect(() => {
    props.getGroupMapsForTeacherId(Parse.User.current().attributes.teacherId);
    props.getClassroomsForSchoolId(schoolId);
    props.getAllKids(schoolId);
    props.getAllRemindersForSchool(schoolId);
    props.getGroupsForSchoolId(schoolId);
    props.getTeachersForSchool(schoolId);
  }, [schoolId]);

  useEffect(() => {
    if (props.classrooms && props.classrooms.length > 0) {
      let tempSelectList = []
      tempSelectList.push(allClassObject())
      for (const classRoom of props.classrooms) {
        tempSelectList.push(getSelectedClass(classRoom))
      }
      setClassroomList(tempSelectList)
    }
  }, [props.classrooms]);

  const getSelectedClass = (classRoom) => {
    let tempSelectObj = {}
    let obj = classRoom.attributes
    tempSelectObj.id = classRoom.id
    tempSelectObj.name = obj.Name ?? ""
    tempSelectObj.value = classRoom.id
    tempSelectObj.label = obj.Name ?? ""
    tempSelectObj.classroomType = obj.classroomType;
    tempSelectObj.parseObject = classRoom
    return tempSelectObj;
  }

  useEffect(() => {
    if (props.teachers && props.teachers.length > 0) {
      let tempSelectList = []
      tempSelectList.push(allClassObject())
      for (const Teacher of props.teachers) {
        tempSelectList.push(getSelectedClass(Teacher))
      }
      // setClassroomList(tempSelectList)
    }
  }, [props.teachers]);
  useEffect(() => {
    if (props.kids && props.kids.length > 0 && classroomList && classroomList.length > 0 && selectedClassroom) {
      let kidList = [];
      for (const val of props.kids) {
        if (selectedClassroom.id === 'all' || selectedClassroom.id === val.attributes.ClassRoomID || selectedClassroom.id === val.attributes.daycareClassId) {
          kidList.push(getKidObject(val));
        }
      }
      setStudentList(kidList);
    }
  }, [props.kids, classroomList, selectedClassroom]);

  const getKidObject = (val) => {
    let temp = {};
    temp.id = val.id;
    temp.familyId = val.attributes.FamilyID;
    temp.label = val.attributes.Name;
    temp.value = val.id;
    temp.object = val;
    temp.classId = val.attributes.ClassRoomID;

    return temp
  }

  useEffect(() => {

    var ids = [];
    if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {
      ids.push(props.selectedSchool.id);
      ids.push(props.selectedSchool.attributes.instituteId);
      if (props.selectedSchool.attributes.franchiseId) {
        ids.push(props.selectedSchool.attributes.franchiseId);
      }


      if (ownerIds.length === ids.length) {

        var call = false;
        for (const i in ownerIds) {
          if (ownerIds[i] !== ids[i]) {
            call = true;
          }
        }
        if (call) {
          props.getCoursesForOwnerIds(ids);
        }

      } else {
        setOwnerIds(ids);
        props.getCoursesForOwnerIds(ids);
      }
    }

  }, [props.selectedSchool]);

  useEffect(() => {
    let list = [];
    list.push({
      id: "@all",
      course: "To ALL Courses",
      batches: [],
    });

    if (props.courses && schoolType == 10) {
      for (const cr of props.courses) {
        if (cr.attributes.state === "Published") {
          let batches = [];

          if (props.allCourseBatches) {
            for (const cb of props.allCourseBatches) {
              if (cb.attributes.courseId === cr.id) {

                batches.push(
                  {
                    id: cb.id,
                    course: cr,
                    batch: cb,
                    batchName: getBatchName(cb),
                  }
                );
              }
            }
          }
          list.push({
            id: cr.id,
            course: cr,
            batches: batches,
          })
        }
      }
    }

    // console.log('list', list);
    setCourseAndBatchList(list)


  }, [props.courses, props.allCourseBatches]);

  const handelSelecteCourse = (course, type) => {
    if (type == 1) {
      setSelectedParticipants([course]);
    } else
      if (props.courses && props.courses.length > 0) {
        if (selectedParticipants && selectedParticipants.includes(course.id)) {
          var others = selectedParticipants.filter((item) => item !== course.id);
          setSelectedParticipants(others);
          setSelectedParticipantsError(false);
        } else {
          var list = [...selectedParticipants];
          list.push(course.id);
          setSelectedParticipants(list);
        }
      }
  }

  const getCourseForCourseId = (courseId) => {
    if (props.courses) {
      let course;
      for (const cr of props.courses) {
        if (courseId === cr.id) {
          course = cr;
        }
      }
      if (course) {
        return course.attributes.name;
      }
    }
    return "--"
  }

  useEffect(() => {
    if (props.classrooms) {
      var classIds = [];
      var groupIds = [];
      var familyIds = [];

      for (var i = 0; i < props.classrooms.length; i++) {
        classIds.push(props.classrooms[i].id);
      }
      //props.getAllRemindersForSchool(schoolId, classIds, groupIds, familyIds);
    }
  }, [props.classrooms]);

  useEffect(() => {
    if (props.kids) {
      var familyIds = [];
      var classIds = [];
      var groupIds = [];

      for (var i = 0; i < props.kids.length; i++) {
        if (!familyIds.includes(props.kids[i].attributes.FamilyID)) {
          familyIds.push(props.kids[i].attributes.FamilyID);
        }
      }
      //props.getAllRemindersForSchool(schoolId, classIds, groupIds, familyIds);
    }
  }, [props.kids]);

  useEffect(() => {
    if (props.groupmaps) {
      var classIds = [];
      var groupIds = [];
      var familyIds = [];

      for (var i = 0; i < props.groupmaps.length; i++) {
        if (!groupIds.includes(props.groupmaps[i].id)) {
          groupIds.push(props.groupmaps[i].id);
        }
      }

      //props.getAllRemindersForSchool(schoolId, classIds, groupIds, familyIds);
    }
  }, [props.groupmaps]);

  useEffect(() => {
    if (props.reminders && props.reminders.length > 0) {
      // console.log("success");
      let newCalendarEvents = [];
      for (let i = 0; i < props.reminders.length; i++) {
        let classNameType = "primary";

        let newEvent = undefined;
        let reminder = props.reminders[i];

        if (reminder.attributes.eventType === 2) {
          classNameType = "danger";
        } else if (reminder.attributes.eventType === 3) {
          classNameType = "warning";
        }

        if (reminder.attributes.reminderTime == undefined) {
          newEvent = {
            id: `rim${reminder.id}`,
            title: reminder.attributes.title ?? "",
            message: reminder.attributes.message ?? "",
            start: reminder.attributes.startDate,
            end: reminder.attributes.endDate,
            className: `bg-${classNameType} text-white`,
            allDay: true,
          };
        } else {
          newEvent = {
            id: `rim${reminder.id}`,
            title: reminder.attributes.title ?? "",
            message: reminder.attributes.message ?? "",
            start: reminder.attributes.reminderTime,
            className: `bg-${classNameType} text-white`,
          };
        }
        if (newEvent !== undefined) {
          newCalendarEvents.push(newEvent);
        }
      }
      if (props.kids.length > 0) {
        var birthdayEvents = [];
        for (var i = 0; i < props.kids.length; i++) {
          if (props.kids[i].attributes.DateOfBirth) {
            let dob = props.kids[i].attributes.DateOfBirth;
            let month = dob.getMonth();
            let day = dob.getDate();
            let hours = dob.getHours();
            let minutes = dob.getMinutes();

            let newDate = new Date();
            newDate.setDate(day);
            newDate.setHours(hours);
            newDate.setMinutes(minutes);
            newDate.setMonth(month);

            let classNameType = "success";

            let newBirthdayEvent = {
              id: `birth${props.kids[i].id}`,
              title: `${props.kids[i].attributes.Name}'s Birthday`,
              start: newDate,
              className: `bg-${classNameType} text-white`,
              allDay: true,
            };

            birthdayEvents.push(newBirthdayEvent);
          }
        }
        newCalendarEvents = newCalendarEvents.concat(birthdayEvents);
      }
      setIsLoading(false);
      setCalendarEvents(newCalendarEvents);
    }
    if (props.reminders && props.reminders.length === 0) {
      setIsLoading(false);
    }
  }, [props.reminders]);

  /**
   * Handling the modal state
   */
  function toggle() {
    if (!modal) {
      setPastDateError(false);
    }
    if (modal === false) {
      setHideCloseButton(false);
      setHideTimeSelect(false);
      setSelectedTime(undefined)
      setSelectedParticipants([]);
      setSelectedParticipantsError(false);
      setSendNotification(true)
    }

    setModal(!modal);
  }

  const getClassName = (classId) => {
    for (const c of props.classrooms) {
      if (c.id == classId) {
        return c.attributes.Name
      }
    }
    return "-";
  };

  const getStudentName = (kidId) => {
    if (props.kids && props.kids.length > 0) {
      let temp = props.kids.find(kid => kid.id === kidId);
      if (temp) {
        return temp.attributes.Name ?? "-";
      }
    }
    return "-";
  };

  const getGroupName = (groupId) => {
    if (props.groups && props.groups.length > 0) {
      let temp = props.groups.filter(
        (group) => group.id === groupId
      );
      if (temp.length > 0) {
        return (temp[0].attributes.name ?? "-")
      }
    }
  }

  const getTeacherName = (teacherId) => {
    if (props.teachers && props.teachers.length > 0) {
      let temp = props.teachers.filter(
        (teacher) => teacher.id === teacherId
      );
      if (temp.length > 0) {
        return (temp[0].attributes.Name ?? "-")
      }
    }
  }


  function toggleEditEvent() {
    if (editEventModal === true) {
      setSelectedDay(null);
      setEndDate(null);
      setSelectedTime(undefined)
    }
    if (editEventModal === false) {
      setHideCloseButton(false);
      setHideTimeSelect(false);

    }
    setEditEventModal(!editEventModal);
  }
  function toggleEditButton() {
    if (editEventButtonOn === false) {
      setSelectedDay(event.start);
      setSelectedTime(event.start)
      if (event && event.eventType === 2) {
        setHideTimeSelect(true);
      }
      if (event.end) {
        let tempDate = new Date(event.end);
        tempDate.setDate(tempDate.getDate() - 1);
        setEndDate(tempDate);
      }
      setSelectedPreference(event.remindingPreference)
      setCustomDate(event.notificationTime)
    } else {
      setSelectedDay(null);
      setEndDate(null);
    }
    if (!editEventButtonOn) {
      setPastDateError(false);
    }
    setEditEventButtonOn(!editEventButtonOn);
  }

  const handleSelectableDates = (selectionInfo) => {
    let currentDate = new Date();
    let todaysDate = currentDate.getDate();
    let startTodaysDate = selectionInfo.start.getDate();

    if (selectionInfo.start > currentDate || +startTodaysDate === +todaysDate) {
      var startDates = selectionInfo.start;
      var tempEndDate = new Date(selectionInfo.endStr);
      tempEndDate.setDate(tempEndDate.getDate() - 1);
      tempEndDate.setHours(0, 0, 0, 0);
      var endDates = tempEndDate;
      if (+startDates === +endDates) {
        setSelectedDay(startDates);
        setEndDate(undefined);
        toggle();
      } else {
        setSelectedDay(startDates);
        setEndDate(endDates);
        toggle();
      }
    } else {
      toastr.warning("Date cannot be in the past", { timeOut: 2000 });
    }
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg) => {
    const eventNew = arg.event;

    if (!eventNew.id.includes("birth")) {
      let event_tmp = {
        id: eventNew.id,
        title: eventNew.title,
        title_category: eventNew.title_category,
        start: eventNew.start,
        className: eventNew.classNames,
        category: eventNew.classNames[0],
        event_category: eventNew.classNames[0],
        end: eventNew.end,
      };

      let reminder = props.reminders.filter((reminder) =>
        eventNew.id.includes(reminder.id)
      );

      event_tmp.message = reminder[0].attributes.message;
      event_tmp.eventType = reminder[0].attributes.eventType;
      event_tmp.courseIds = reminder[0].attributes.courseIds;

      event_tmp.remindingPreference = reminder[0].attributes.remindingPreference;
      event_tmp.notificationTime = reminder[0].attributes.notificationTime;
      event_tmp.isBulkUploaded = reminder[0].attributes.isBulkUploaded 
      event_tmp.object = reminder[0]

      if(event_tmp.isBulkUploaded){
        setSendNotification(false)
      }else{
        setSendNotification(true)
      }
      // console.log("event_tmp",event_tmp)
      setEvent(event_tmp);
      setEditEventButtonOn(false);
      toggleEditEvent();
    }
  };

  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (e, values) => {
    let selectedDate = undefined;
    if ((!selectedParticipants || selectedParticipants.length == 0) && props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {
      setSelectedParticipantsError(true);
      return;
    }
    if (selectedSendTo == 2) {
      if (selectedClassroom.id == "all") {
        setSelectedClassError(true);
        return;
      }
    }
    if (selectedSendTo == 3) {
      if (!selectedGroup) {
        setSelectedGroupError(true);
        return;
      }
    }

    if (selectedTime) {
      // let timeString = values.time;

      // let timeSplit = timeString.split(":");
      let hours = selectedTime.getHours()
      let minutes = selectedTime.getMinutes()
      let tempDate = selectedDay;
      selectedDate = new Date(tempDate.setHours(hours));
      selectedDate.setMinutes(minutes);
    } else if (values.category !== holidayTypeString) {
      setPastDateError("Time is mandatory");
      return;
      selectedDate = selectedDay;
    } else if (values.category === holidayTypeString) {
      let hours = 9;
      let minutes = 30;
      let tempDate = selectedDay;
      selectedDate = new Date(tempDate.setHours(hours));
      selectedDate.setMinutes(minutes);
    }
    
    if (customDate && (customDate > getNotificationMaxTime() || customDate.getTime() === getNotificationMaxTime().getTime())) {
      setCustomDateError("Reminding time should be less than Event Time");
      return;
    }
    
    if (selectedDate > new Date()) {
      setHideCloseButton(true);
      var newEvent = {};
      var eventType = 1;

      var bgColorClassName = "primary";

      if (values.category === holidayTypeString) {
        bgColorClassName = "danger";
        eventType = 2;
      } else if (values.category === "Online Meeting") {
        bgColorClassName = "warning";
        eventType = 3;
      }

      var tempEndDate = endDate;
      if (endDate) {
        newEvent = {
          id: calendarEvents.length + 1,
          title: values["title"],
          message: values["message"],
          start: selectedDate,
          end: tempEndDate.setDate(tempEndDate.getDate() + 1),
          className: bgColorClassName + " text-white",
        };
      } else {
        newEvent = {
          id: calendarEvents.length + 1,
          title: values["title"],
          message: values["message"],
          start: selectedDate,
          className: bgColorClassName + " text-white",
        };
      }

      const ReminderParse = Parse.Object.extend("Reminder");
      const reminderObject = new ReminderParse();
      reminderObject.set("message", newEvent.message ?? "");

      reminderObject.set("type", 5);

      reminderObject.set("status", true);

      reminderObject.set("title", values.title ?? "");

      reminderObject.set("defaultSchoolId", schoolId ?? "");
      if(customDate && selectedPreference == 8){
        reminderObject.set("notificationTime",customDate)
      }
      reminderObject.set(
        "senderId",
        Parse.User.current().attributes.teacherId ?? ""
      );

      reminderObject.set("remindingPreference", parseInt(selectedPreference + ""));

      if (selectedSendTo == 1) {
        reminderObject.set("schoolId", schoolId);
      } else if (selectedSendTo == 2) {
        if (selectedClassroom) {
          reminderObject.set("classId", selectedClassroom.id);
          if (selectedStudent) {
            reminderObject.set("kidId", selectedStudent.id);
            reminderObject.set("familyId", selectedStudent.familyId);
            if (selectedClassroom.id == "all") {
              reminderObject.set("classId", selectedStudent.classId);
            }
          }
        }
      } else if (selectedSendTo == 3) {
        reminderObject.set("groupId", selectedGroup);
      } else if (selectedSendTo == 4) {
        reminderObject.set("schoolId", schoolId);
        if (selectedTeacher == "all") {
          reminderObject.set("receiver", "T");
        } else {
          reminderObject.set("receiver", selectedTeacher);
        }
      }


      if (endDate) {
        reminderObject.set("startDate", selectedDate);
        reminderObject.set("endDate", endDate);
      } else {
        reminderObject.set("reminderTime", selectedDate);
      }

      reminderObject.set("eventType", eventType);

      if(sendNotification){
      } else{
        reminderObject.set("isBulkUploaded", true);
      }

      if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {
        if (selectedParticipants && selectedParticipants.length > 0 && selectedParticipants[0] == "@all") {
          reminderObject.set("courseIds", []);
        } else {
          reminderObject.set("courseIds", selectedParticipants);
        }
        props.addReminder(reminderObject, true);
      } else {
        props.addReminder(reminderObject);
      }
      // console.log("Reminder demo", reminderObject);
      setSelectedDay(null);
      setEndDate(null);

      toggle();
    } else {
      // toastr.warning("Date cannot be in the past", { timeOut: 2000 });

      setPastDateError("Time cannot be in the past");
      setSelectedDay(undefined);
    }
  };

  const calculateCustomDateReminder = (customDate, eventStart) => {
    const diffInMs = new Date(eventStart) - new Date(customDate);
    if (diffInMs <= 0) return 'Event is already started';
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const days = Math.floor(diffInMinutes / 1440);
    const hours = Math.floor((diffInMinutes % 1440) / 60);
    const minutes = diffInMinutes % 60;
    let reminderString = '';
    if (days > 0) reminderString += `${days} day${days > 1 ? 's' : ''} `;
    if (hours > 0) reminderString += `${hours} hour${hours > 1 ? 's' : ''} `;
    if (minutes > 0) reminderString += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    
    return reminderString.trim() + " before";
  };
  
  const getRemainderPreference = (selectedPreference, customDate, eventStart) => {
    if (selectedPreference == 0) {
      return '5 mins before';
    } else if (selectedPreference == 1) {
      return '15 mins before';
    } else if (selectedPreference == 3) {
      return '1 hour before';
    } else if (selectedPreference == 4) {
      return '2 hours before';
    } else if (selectedPreference == 5) {
      return '6 hours before';
    } else if (selectedPreference == 6) {
      return '1 day before';
    } else if (selectedPreference == 7) {
      return '2 days before';
    } else if (selectedPreference === 8) {
      return calculateCustomDateReminder(customDate, eventStart);
    }
  }

  const handelAddNewRow = () => {

    let rows = tableData ? [...tableData] : [];

    var obj = {
      sino: rows.length + 1,
      title: undefined,
      msg: undefined,
      date: undefined,
      time: undefined,
      eventType: undefined,
      actualTime: undefined,
      dateObject: undefined,
      startDate: undefined,
      endDate: undefined,
      startDateObject: undefined,
      endDateObject: undefined,
      remindingPreference: 6,
    };

    rows.push(obj)
    setTableData(rows);
  }

  const handelUpdateEvent = (oldEvent, newEvent) => {
    let allEvents = [...tableData];
    let index = allEvents.indexOf(oldEvent);
    allEvents[index] = newEvent;

    setTableData(allEvents);

  }
  const handelRemoveEvent = (event) => {
    let allEvents = [...tableData];
    let newEvents = allEvents.filter(
      (e) => e.sino !== event.sino
    );
    setTableData(newEvents);
  }

  const handleUpload = (e) => {
    // e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary", cellDates: false });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      createJsObjects(dataParse);
    };
    reader.readAsBinaryString(f);
  };

  function createJsObjects(dataParse) {
    // console.log("dataParse ",dataParse);

    var IstRow = [];
    var allrows = [];
    for (const i in dataParse) {
      if (dataParse[i] && dataParse[i].length > 0) {
        if (i == 0) {
          IstRow = dataParse[0];
        } else {
          allrows.push(dataParse[i]);
        }
      }
    }
    if (allrows && allrows.length > 0) {
      var objectList = [];
      for (const j in allrows) {
        var obj = {
          sino: undefined,
          title: undefined,
          msg: undefined,
          date: undefined,
          time: undefined,
          eventType: undefined,
          dateObject: undefined,
          startDate: undefined,
          endDate: undefined,
          startDateObject: undefined,
          endDateObject: undefined,
          remindingPreference: 6,
          notificationTime:undefined,
        };
        objectList.push(obj);
      }

      for (const i in IstRow) {
        for (const j in allrows) {
          // console.log(allrows[j][i]);
          if (IstRow[i] === firstRow[0]) {
            objectList[j].sino = String(allrows[j][i]);
          } else if (IstRow[i] === firstRow[1]) {
            objectList[j].title = allrows[j][i];
          } else if (IstRow[i] === firstRow[2]) {
            objectList[j].msg = allrows[j][i];
          } else if (false && IstRow[i] === firstRow[3]) {
            if (Number.isInteger(allrows[j][i])) {
              var date = ExcelDateToJSDate(allrows[j][i]);
              objectList[j].date = convertDateToString(date);
              objectList[j].dateObject = date;
            } else {
              objectList[j].date = allrows[j][i];
              objectList[j].dateObject = convertStringTodate(allrows[j][i])
            }
          } else if (IstRow[i] === firstRow[3]) {
            objectList[j].day = allrows[j][i];
          } else if (IstRow[i] === firstRow[4]) {
            objectList[j].month = allrows[j][i];
          } else if (IstRow[i] === firstRow[5]) {
            objectList[j].year = allrows[j][i];
          } else if (false && IstRow[i] === firstRow[4]) {
            if (Number.isFinite(allrows[j][i])) {
              var date = ExcelDateToJSDate(allrows[j][i]);
              objectList[j].time = convertDateToStringTime(date);
            } else {
              objectList[j].time = allrows[j][i];
            }
          } else if (IstRow[i] === firstRow[6]) {
            objectList[j].hours = allrows[j][i];
          } else if (IstRow[i] === firstRow[7]) {
            objectList[j].minutes = allrows[j][i];
          } else if (IstRow[i] === firstRow[8]) {
            objectList[j].am_pm = allrows[j][i];
          } else if (IstRow[i] === firstRow[9]) {
            objectList[j].eventType = allrows[j][i];
          } else if (false && IstRow[i] === firstRow[6]) {
            if (Number.isInteger(allrows[j][i])) {
              var date = ExcelDateToJSDate(allrows[j][i]);
              objectList[j].startDate = convertDateToString(date);
              objectList[j].startDateObject = date;
            } else if (allrows[j][i] && allrows[j][i].length > 0) {
              objectList[j].startDate = allrows[j][i];
              objectList[j].startDateObject = convertStringTodate(
                allrows[j][i]
              );
            }
          } else if (false && IstRow[i] === firstRow[7]) {
            if (Number.isInteger(allrows[j][i])) {
              var date = ExcelDateToJSDate(allrows[j][i]);
              objectList[j].endDate = convertDateToString(date);
              objectList[j].endDateObject = date;
            } else if (allrows[j][i] && allrows[j][i].length > 0) {
              objectList[j].endDate = allrows[j][i];
              objectList[j].endDateObject = convertStringTodate(allrows[j][i]);
            }
          }
        }
      }

      let newList = [];
      for (const obj of objectList) {
        if (obj.day && obj.month && obj.year) {
          obj.date = `${obj.day}/${obj.month}/${obj.year}`;
          obj.dateObject = convertStringTodate(obj.date);

          if ((obj.hours != null || obj.hours != undefined) && (obj.minutes != null || obj.minutes != undefined) && (obj.am_pm != null || obj.am_pm != undefined)) {
            obj.time = `${obj.hours}:${obj.minutes} ${obj.am_pm}`;
            obj.actualTime = getActualDate(obj.dateObject, "", obj.time);
          } else {
            obj.actualTime = getActualDate(obj.dateObject, "", "07:00 AM");
          }
          if(obj.eventType && obj.eventType.toLowerCase() == "holiday"){
            obj.remindingPreference=8;
            obj.notificationTime = getHolidayNotificationTime(obj.actualTime)

          }
        }
      }



      // console.log(objectList);

      if (tableData && tableData.length > 0) {
        if (tableData.length == 0 && !tableData[0].eventType && !tableData[0].title && !tableData[0].msg && !tableData.actualTime) {
          //do nothing
        } else {
          objectList = tableData.concat(objectList)
        }

      }
      setTableData(objectList);
    }
  }

  function ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569) * 86400 * 1000));
  }

  function convertDateToString(date, reduceDateByOne = false) {
    var tempDate = new Date(date);
    tempDate.setDate(tempDate.getDate() - 1);
    if (reduceDateByOne && false) {
      return Moment(tempDate).format("DD/MM/YYYY");
    } else {
      return Moment(date).format("DD/MM/YYYY");
    }
  }


  function convertDateToStringTime(date, reduceDateByOne = false) {
    var tempDate = new Date(date);
    tempDate.setDate(tempDate.getDate() - 1);
    if (reduceDateByOne) {
      return Moment(tempDate).format("h:mm a");
    } else {
      return Moment(date).format("h:mm a");
    }
  }
  function convertDateToStringWithTime(date, format = "MMMM Do YYYY, h:mm a") {
    return Moment(date).format(format);
  }

  function convertStringTodate(dateString, type) {
    if (dateString && dateString.length > 0) {
      var res = [];
      res = dateString.split("/");
      if (!res || res.length !== 3) {
        res = dateString.split("-");
      }
      if (!res || res.length !== 3) {
        res = dateString.split(".");
      }


      if (res && res.length === 3) {
        var d = res[0];
        var m = res[1];
        var y = res[2];

        if (parseInt(m) > 12 && parseInt(d) > 0) {
          return undefined;
        }

        if (parseInt(m) <= 12) {
          return new Date(parseInt(y), parseInt(m) - 1, parseInt(d), 0, 0, 0, 0);
        } else {
          return new Date(parseInt(y), parseInt(d) - 1, parseInt(m), 0, 0, 0, 0);
        }
      }
    } else {
      if (type !== "DOB") {
        return new Date();
      }
    }
    return undefined;
  }

  useEffect(() => {
    if (modal === false) {
      setSelectedDay(undefined);
      setEndDate(undefined);
      setSelectedPreference(6);
      setCustomDate(undefined);
      setSelectedEventType("Event");
      setSendNotification(true);
    }
  }, [modal]);
  
  const handleValidEventSubmitEdit = (e, values) => {
    console.log("********",values)
    let selectedDate = undefined;
    if (selectedTime) {
      // let timeString = values.time;

      // let timeSplit = timeString.split(":");
      let hours = selectedTime.getHours()
      let minutes = selectedTime.getMinutes()
      let tempDate = new Date(selectedDay);
      selectedDate = new Date(tempDate.setHours(hours));
      selectedDate.setMinutes(minutes);
    } else if (values.category !== holidayTypeString) {
      setPastDateError("Time is mandatory");
      return;
      selectedDate = new Date(selectedDay);
    } else if (values.category === holidayTypeString) {
      let hours = 9;
      let minutes = 30;
      let tempDate = selectedDay;
      selectedDate = new Date(tempDate.setHours(hours));
      selectedDate.setMinutes(minutes);
    }

    if (customDate && (customDate > getNotificationMaxTime() || customDate.getTime() === getNotificationMaxTime().getTime())) {
      setCustomDateError("Reminding time should be less than Event Time.");
      return;
    }
    if (selectedTime && selectedTime < new Date) {
      setPastDateError("Event Time should be greater than the current time.");
      return;
    }
    
    if (selectedDate > new Date()) {
      setHideCloseButton(true);
      var newEvent = {};
      var eventType = 1;

      var bgColorClassName = "primary";

      if (values.category === holidayTypeString) {
        bgColorClassName = "Danger";
        eventType = 2;
      } else if (values.category === "Online Meeting") {
        bgColorClassName = "Warning";
        eventType = 3;
      }

      let reminder = props.reminders.filter((reminder) =>
        event.id.includes(reminder.id)
      );

      //const ReminderParse = Parse.Object.extend("Reminder");
      const reminderObject = reminder[0];
      reminderObject.set("message", values.message ?? "");

      reminderObject.set("type", 5);

      reminderObject.set("status", true);

      reminderObject.set("title", values.title ?? "");

      reminderObject.set("defaultSchoolId", schoolId ?? "");
      if(customDate){
        reminderObject.set("notificationTime",customDate)
      } 
      reminderObject.set("remindingPreference",parseInt(selectedPreference + ""))
      reminderObject.set("schoolId", schoolId ?? "");
      reminderObject.set(
        "senderId",
        Parse.User.current().attributes.teacherId ?? ""
      );

      if (endDate) {
        reminderObject.set("startDate", selectedDate);
        endDate.setDate(endDate.getDate() + 1);
        reminderObject.set("endDate", endDate);
      } else {
        reminderObject.set("reminderTime", selectedDate);
      }

      reminderObject.set("eventType", eventType);

      // console.log("Reminder demo ",reminderObject);
      if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {
        props.editReminder(reminderObject, true);
      } else {
        props.editReminder(reminderObject);
      }
      
      if(sendNotification){
        if(reminderObject.attributes.isBulkUploaded){
          reminderObject.set("isBulkUploaded", false);
        }
      } else{
        reminderObject.set("isBulkUploaded", true);
      }
      setSelectedDay(null);
      setEndDate(null);

      setEditEventButtonOn(false);
      setEditEventModal(false);
    } else {
      if (values.time) {
        setPastDateError("Time cannot be in the past");
        // toastr.warning("Time cannot be in the past", { timeOut: 2000 });
      } else {
        toastr.warning("Start date cannot be in the past", { timeOut: 2000 });
      }
    }
  };

  const getEventStringValue = (tempEvent) => {
    if (tempEvent.eventType === 1) {
      return eventTypeString;
    } else if (tempEvent.eventType === 2) {
      return holidayTypeString;
    } else if (tempEvent.eventType === 3) {
      return "Online Meeting";
    }
  };

  const getEventNumericValue = (eventType) => {
    if (!eventType) {
      return 1;
    }
    if (eventType.toLowerCase() === "event") {
      return 1;
    } else if (eventType.toLowerCase() === "holiday") {
      return 2;
    } else if (eventType.toLowerCase() === "online meeting") {
      return 3;
    }
    return 1;
  };

  const handleDeleteReminder = () => {
    // if (!reasonForDelete) {
    //   setReasonForDeleteError(true);
    // }else {
    //   setReasonForDeleteError(false);
    // }
    setShowDeleteAlert(false);
    setEditEventModal(false);
    let reminder = props.reminders.filter((reminder) =>
      event.id.includes(reminder.id)
    );
    reminder[0].set("isDeleted", true);
    reminder[0].set("status", false);
    reminder[0].set("reasonForDelete", reasonForDelete);

    let getCalendarEvent = calendarEvents.filter(
      (eve) => String(eve.id) === event.id
    );

    let tmpCalendarEvent = [...calendarEvents];

    removeElement(tmpCalendarEvent, getCalendarEvent[0]);

    setCalendarEvents(tmpCalendarEvent);

    if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {
      props.deleteReminder(reminder[0], true);
    } else {
      props.deleteReminder(reminder[0]);
    }

  };

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const getActualDate = (date, dateString, time) => {
    if (time && time.length > 0) {
      var am_pm = "";
      if (time.includes("PM") || time.includes("pm") || time.includes("p")) {
        am_pm = "PM";
      }
      if (time.includes("AM") || time.includes("am") || time.includes("a")) {
        am_pm = "AM";
      }
      var newTime = time.replace(/[^\d-:]/g, "");

      var hr = "0";
      var min = "00";

      if (newTime.includes(":")) {
        var res = newTime.split(":");
        hr = res[0];
        if (res.length > 1) {
          min = parseInt(res[1]) < 60 ? res[1] : "00";
        }
      } else if (newTime.includes("-")) {
        var res = newTime.split("-");
        hr = res[0];
        if (res.length > 1) {
          min = parseInt(res[1]) < 60 ? res[1] : "00";
        }
      }

      let hours = parseInt(hr, 10);
      if (am_pm === "PM" && hours < 12) {
        hours = hours + 12;
      }
      var temDate = date;
      temDate.setHours(hours);
      temDate.setMinutes(parseInt(min, 10));

      if (temDate) {
        return temDate;
      } else {
        return date;
      }
    } else {
      return date;
    }
  };

  const handelSaveBulkUpload = () => {
    if (tableData && tableData && tableData.length > 0) {
      let reminderListBulkUpload = [];

      for (const i in tableData) {
        var reminderBulkObject = tableData[i];

        if (
          reminderBulkObject.date || reminderBulkObject.actualTime ||
          (reminderBulkObject.startDate && reminderBulkObject.endDate)
        ) {
          const ReminderParse = Parse.Object.extend("Reminder");
          const reminderObject = new ReminderParse();
          reminderObject.set("message", reminderBulkObject.msg ?? "");
          reminderObject.set("type", 5);
          reminderObject.set("status", true);
          reminderObject.set("title", reminderBulkObject.title ?? "");
          reminderObject.set("defaultSchoolId", schoolId ?? "");
          reminderObject.set("schoolId", schoolId ?? "");
          reminderObject.set("remindingPreference", parseInt(reminderBulkObject.remindingPreference + ""));

          let eventType= getEventNumericValue(reminderBulkObject.eventType)
          if (
            reminderBulkObject.endDateObject &&
            reminderBulkObject.startDateObject
          ) {
            reminderObject.set("startDate", reminderBulkObject.startDateObject);
            reminderObject.set("endDate", reminderBulkObject.endDateObject);
          } else {
            if (eventType == 2) {
              reminderObject.set(
                "reminderTime", getActualDate(reminderBulkObject.actualTime,"","07:00 AM")
              );
            } else {
              reminderObject.set(
                "reminderTime",
                reminderBulkObject.actualTime
              );
            }            
          }

          if(reminderBulkObject.remindingPreference == 8 &&  reminderBulkObject.notificationTime){
            reminderObject.set("notificationTime", reminderBulkObject.notificationTime);
          }

          reminderObject.set("eventType",eventType);
          reminderObject.set("isBulkUploaded", true);
          reminderListBulkUpload.push(reminderObject);
        }
      }
      // console.log("Reminder Bulk demo", reminderListBulkUpload);
      props.saveAllReminders(reminderListBulkUpload);

      setShowPopup(false);
      setTableData([]);
      toastr.success("Upload successfull!", { timeOut: 1500 });
      setImportOnButton(!importOnButton);
    }
  };

  const getString24HourTime = (date) => {
    let time = Moment(date).format("HH:mm");
    return time;
  };

  const downloadTemplate = () => {

    let fileName = `calender_import_template.xlsx`;

    let newSheet = [];
    newSheet.push(firstRow);
    newSheet.push([1]);
    newSheet.push([2]);
    newSheet.push([3]);
    newSheet.push([4]);
    newSheet.push([5]);

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(newSheet);
    XLSX.utils.book_append_sheet(wb, ws, "events");
    XLSX.writeFile(wb, fileName);
  }

  const importButton = () => {
    return (
      <button
        onClick={() => {
          setTableData([])
          setImportOnButton(!importOnButton);
          handelAddNewRow()
        }}
        className={
          importOnButton
            ? "btn btn-danger waves-effect waves-light float-right"
            : "btn btn-primary waves-effect waves-light float-right"
        }
      >
        {importOnButton ? props.t("Close") : props.t("Import")}
      </button>
    );
  };

  useEffect(() => {
    setReasonForDelete("");
    // setReasonForDeleteError("");
  }, [editEventModal]);


  const handleTextareaChange = (e) => {
    const value = e.target.value;
    setReasonForDelete(value);
    // setReasonForDeleteError(!value);
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  

  const getNotificationTime = (reminderTime, remindingPreference) => {
    if (remindingPreference == 7) {
      return new Date(reminderTime.getTime() - 48 * 60 * 60000);
    } else if (remindingPreference == 6 || remindingPreference == 8) {
      return new Date(reminderTime.getTime() - 24 * 60 * 60000);
    } else if (remindingPreference == 5) {
      return new Date(reminderTime.getTime() - 6 * 60 * 60000);
    } else if (remindingPreference == 4) {
      return new Date(reminderTime.getTime() - 2 * 60 * 60000);
    } else if (remindingPreference == 3) {
      return new Date(reminderTime.getTime() - 60 * 60000);
    } else if (remindingPreference == 2) {
      return new Date(reminderTime.getTime() - 30 * 60000);
    } else if (remindingPreference == 1) {
      return new Date(reminderTime.getTime() - 15 * 60000);
    } else if (remindingPreference == 0) {
      return new Date(reminderTime.getTime() - 5 * 60000);
    }
  }

  const getNotificationMaxTime=()=>{
    if (selectedEventType == "Holiday"){
      let hours = 23
      let minutes = 59
      let tempDate = new Date(selectedDay);
      let selectedDate = new Date(tempDate.setHours(hours));
      selectedDate.setMinutes(minutes);
      return getNotificationTime(selectedDate,6);
    }
    if(selectedTime){
      let hours = selectedTime.getHours()
      let minutes = selectedTime.getMinutes()
      let tempDate = new Date(selectedDay);
      let selectedDate = new Date(tempDate.setHours(hours));
      selectedDate.setMinutes(minutes);
      return selectedDate;
    } 
  }

  const getHolidayNotificationTime = (time) =>{
    let hours = 16
    let minutes = 0
    let selectedDate = new Date(time.setHours(hours));
    selectedDate.setMinutes(minutes);

    return getNotificationTime(selectedDate,6)
  }
  
  // console.log("Selected Time:", selectedTime);
  // console.log("Notification Max Time:", getNotificationMaxTime());
  // console.log("Custom Date:", customDate)

  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading && (
          <Container fluid>
            {showDeleteAlert && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Alert!"
                onCancel={() => {
                  setShowDeleteAlert(false);
                }}
                onConfirm={() => handleDeleteReminder()}
              >
                <div>
                  {" "}
                  {props.t("Are you sure you want to delete this event?")}
                  <br />{" "}
                </div>
                <div>
                  <Input
                    value={reasonForDelete}
                    placeholder="Please provide a reason to notify to audience."
                    className="font-size-4"
                    onChange={handleTextareaChange}
                    rows="3"
                    type="textarea"
                  />
                  {/* {reasonForDeleteError && <div className="text-danger">Please provide a reason</div>} */}
                </div>
              </SweetAlert>
            )}

            <TopHeader title="CALENDAR" rightButton={props.selectedSchool && props.selectedSchool.attributes.schoolType == 10 ? null : importButton()} />

            <Row>
              <Col xs={12}>
                <div id="external-events" className="mt-3"></div>
                {!importOnButton && (
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12">
                          {/* fullcalendar control */}
                          <FullCalendar
                            ref={calendarComponentRef}
                            defaultView="dayGridMonth"
                            plugins={[
                              BootstrapTheme,
                              dayGridPlugin,
                              interactionPlugin,
                            ]}
                            slotDuration={"00:15:00"}
                            minTime={"08:00:00"}
                            maxTime={"19:00:00"}
                            handleWindowResize={true}
                            themeSystem="bootstrap"
                            header={{
                              left: "prev,next today",
                              center: "title",
                              right: "dayGridMonth,dayGridWeek,dayGridDay",
                            }}
                            events={calendarEvents}
                            editable={true}
                            droppable={false}
                            eventLimit={3}
                            selectable={true}
                            select={handleSelectableDates}
                            eventClick={handleEventClick}
                            id="calendar123"
                          />

                          {/* edit event modal */}
                          <Modal
                            isOpen={editEventModal}
                            toggle={() => toggleEditEvent()}
                            className=""
                          >
                            <ModalHeader
                              toggle={() => toggleEditEvent()}
                              tag="h4"
                            >
                              {!editEventButtonOn && event
                                ? event.title
                                : "Edit Event"}
                            </ModalHeader>
                            <ModalBody>
                              {!editEventButtonOn && event && (
                                <div className="mb-3">
                                  {schoolType == 10 && <h6>Message: {event.message}</h6>}
                                  {schoolType == 10 && <p className=" mb-0" ><strong>Participants:</strong> {event.courseIds && event.courseIds.length > 0 ? getCourseForCourseId(event.courseIds[0]) : "@All Courses"}</p>}
                                  {schoolType != 10 &&
                                    <div>

                                      <table class="table table-bordered">
                                        <tbody>
                                          <tr>
                                            <td><h6>Message </h6></td>
                                            <td>{event.message}</td>
                                          </tr>
                                          <tr>
                                            <td><h6>Audience </h6></td>
                                            <td>
                                              {event.object.attributes.schoolId && !event.object.attributes.receiver && 'All School'}
                                              {event.object.attributes.groupId && getGroupName(event.object.attributes.groupId) + " (Group)"}
                                              {event.object.attributes.classId && !event.object.attributes.familyId && getClassName(event.object.attributes.classId) + " (Classroom)"}
                                              {event.object.attributes.classId && event.object.attributes.familyId && getStudentName(event.object.attributes.kidId)}
                                              {event.object.attributes.receiver && event.object.attributes.receiver == "T" && 'All Teachers'}
                                              {event.object.attributes.receiver && event.object.attributes.receiver != "T" && getTeacherName(event.object.attributes.receiver) + " (Teacher)"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td><h6>Reminding Preference</h6></td>
                                            <td>
                                                {event.object.attributes && (
                                                    getRemainderPreference(
                                                        event.object.attributes.remindingPreference,
                                                        event.object.attributes.notificationTime,
                                                        event.start) + " at " + convertDateToStringWithTime(event.object.attributes.notificationTime))}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td><h6><strong>Event Date&Time </strong></h6></td>
                                            {event.end && <td>
                                              {convertDateToString(event.start)} -{" "}{convertDateToString(event.end, true)}
                                            </td>}
                                            {event.start && event.end == undefined && <td>
                                              <strong>{convertDateToStringWithTime(event.start)}</strong>
                                            </td>}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  }
                                </div>
                              )}
                              {!editEventButtonOn && (
                                <Row>

                                  <Row className="">
                                    <Col className="d-flex justify-content-end">
                                      <div className="float-end">
                                        <button
                                          type="button"
                                          className="btn btn-danger m-1"
                                          onClick={() => {
                                            setShowDeleteAlert(true);
                                          }}
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-light m-1"
                                          onClick={() => toggleEditButton()}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              )}
                              {/* Edit */}
                              {editEventButtonOn && (
                                <AvForm
                                  onValidSubmit={handleValidEventSubmitEdit}
                                >
                                  <Row form>
                                    <Col className="col-12">
                                      <AvField
                                        type="select"
                                        name="category"
                                        label="Event/Holiday"
                                        value={getEventStringValue(event)}
                                        disabled
                                      >
                                        <option value={eventTypeString}>
                                          Event
                                        </option>
                                        <option value={holidayTypeString}>
                                          Holiday
                                        </option>
                                        {/* <option value="Online Meeting">
                                          Online Meeting
                                        </option> */}
                                      </AvField>
                                    </Col>
                                    {schoolType == 10 && <Col className="col-12"><FormGroup >
                                      <Label >Participants :</Label>
                                      <select
                                        className="form-control"
                                        value={event && event.courseIds.length > 0 ? event.courseIds[0] : undefined}
                                        onChange={(e) => {
                                          handelSelecteCourse(e.target.value, 1)
                                          if (selectedParticipantsError) {
                                            setSelectedParticipantsError(false);
                                          }

                                        }}
                                        disabled={true}
                                        required
                                      >
                                        <option disabled selected value>
                                          Choose Course
                                        </option>
                                        {courseAndBatchList && courseAndBatchList.map((course, key) => (
                                          <option key={course.id} value={course.id}>
                                            {course.id == "@all" ? course.course : course.course.attributes.name}
                                          </option>
                                        ))}
                                      </select>
                                    </FormGroup></Col>}
                                    {/* Near edit */}
                                    {endDate == undefined && !hideTimeSelect && (
                                      <Col className="col-12">
                                        <Label>Event Time</Label>
                                        <DatePicker
                                          selected={selectedTime}
                                          className="form-control mb-1"
                                          onChange={(date) => 
                                            {setSelectedTime(date)
                                          }}
                                          
                                          showTimeSelect
                                          timeIntervals={15}
                                          timeCaption="Time"
                                          dateFormat="MMMM d, yyyy h:mm aa"
                                          minDate={new Date()}
                                          maxDate={selectedTime}
                                        />
                                        {(selectedTime && selectedTime < new Date()) && (<p className="text-danger mt-0">Event Time should be greater than the current time</p>)}
                                        {pastDateError && (<p className="text-danger">{pastDateError}{" "}</p>)}
                                      </Col>
                                    )}
                                    {/* Near Edit */}
                                    <Col className="col-12">
                                      <Label>Reminding Preference</Label>
                                      <select
                                        className="form-control"
                                        value={selectedPreference}
                                        onChange={(e) => {
                                          const selectedValue = e.target.value
                                          setSelectedPreference(selectedValue);
                                          setCustomDate(getNotificationTime(selectedTime,selectedValue))
                                          
                                        }}
                                      >
                                        {reminderPreferences.map((item, key) => ((selectedEventType === "Event" || item.value === 8) ? (
                                          <option key={key} value={item.value}>
                                          {item.label}
                                        </option>) : null
                                      ))}
                                      </select>
                                    </Col>
                                   
                                    <Col className="col-12 mt-2">
                                      <Label>Reminding Time</Label>
                                      <DatePicker
                                        showIcon
                                        selected={customDate} 
                                        className="form-control mb-0"
                                        onChange={(date) => setCustomDate(date)} 
                                        showTimeSelect
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        timeIntervals={15}
                                        maxDate={getNotificationMaxTime()}
                                        minDate={new Date()}

                                        disabled={selectedPreference != '8'}
                                        

                                      />
                                      {(customDate && (customDate.getTime() > getNotificationMaxTime().getTime() || customDate.getTime() === getNotificationMaxTime().getTime())) && (
                                              <p className="text-danger">Reminding Time should be less than event/holiday Time</p>
                                      )}
                                      {(customDate && customDate < new Date()) && (<p className="text-danger">Reminding Time should be greater than the current time</p>)}
                                      {customDateError && (<p className="text-danger">{customDateError}{" "}</p>)}
                                      

                                    </Col>
                                     
                                    {endDate && (
                                      <Col className="col-12">
                                        <Label>Start Date</Label>
                                        <DatePicker
                                          selected={selectedDay}
                                          onChange={(date) =>
                                            setSelectedDay(new Date(date))
                                          }
                                          className="form-control mb-3"
                                          placeholderText={props.t(
                                            "Select Start Date"
                                          )}
                                          startDate={selectedDay}
                                          endDate={endDate}
                                          maxDate={endDate}
                                          dateFormat="dd/MMM/yyyy"
                                          showDayMonthYearPicker
                                        />
                                      </Col>
                                    )}
                                    {endDate && (
                                      <Col className="col-12">
                                        <Label>End Date</Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) =>
                                            setEndDate(new Date(date))
                                          }
                                          className="form-control mb-3"
                                          placeholderText={props.t(
                                            "Select End Date"
                                          )}
                                          startDate={selectedDay}
                                          endDate={endDate}
                                          minDate={selectedDay}
                                          dateFormat="dd/MMM/yyyy"
                                          showDayMonthYearPicker
                                        />
                                      </Col>
                                    )}
                                    <Col className="col-12 m-1">
                                      <Label>Audience</Label>
                                      <select
                                        className="form-control"
                                        value={selectedSendTo}
                                        disabled
                                      >
                                        <option value={selectedSendTo}>
                                          {event.object.attributes.schoolId && !event.object.attributes.receiver && 'All School'}
                                          {event.object.attributes.groupId && getGroupName(event.object.attributes.groupId) + " (Group)"}
                                          {event.object.attributes.classId && !event.object.attributes.familyId && getClassName(event.object.attributes.classId) + " (Classroom)"}
                                          {event.object.attributes.classId && event.object.attributes.familyId && getStudentName(event.object.attributes.kidId)}
                                          {event.object.attributes.receiver && event.object.attributes.receiver === "T" && 'All Teachers'}
                                          {event.object.attributes.receiver && event.object.attributes.receiver !== "T" && getTeacherName(event.object.attributes.receiver) + " (Teacher)"}
                                        </option>
                                      </select>
                                    </Col>
                                    <Col className="col-12 mt-1 mb-0">
                                      <AvField
                                        name="title"
                                        label="Title"
                                        type="text"
                                        errorMessage="Invalid title"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={event.title}
                                      />
                                    </Col>
                                    <Col className="col-12 m-0">
                                      <AvField
                                        name="message"
                                        label="Message"
                                        type="textarea"
                                        errorMessage="Invalid message"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={event.message}
                                      />
                                    </Col>
                                    <Col>
                                      <Input
                                        type="checkbox"
                                        style={{ marginRight: '10px' }}
                                        
                                        onChange={() => setSendNotification(!sendNotification)}
                                        checked={sendNotification}
                                      />
                                    <Label className="ml-2">Notify selected audience</Label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="text-end">
                                      <div>
                                        <button
                                          type="button"
                                          className="btn btn-light mr-2"
                                          onClick={() => toggleEditButton()}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-event ml-2"
                                          style={{marginLeft:'0.3cm'}}
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              )}
                            </ModalBody>
                          </Modal>

                          {/* New event modal */}
                          <Modal
                            isOpen={modal}
                            toggle={() => toggle()}
                            className=""
                            size="lg"
                          >
                            <ModalHeader toggle={() => toggle()} tag="h4">
                              {endDate === undefined && modal
                                ? selectedEventType === "Event"
                                  ? `Add Event on ${Moment(selectedDay).format("Do MMMM YYYY")}`
                                  : selectedEventType === "Holiday"
                                  ? `Add Holiday on ${Moment(selectedDay).format("Do MMMM YYYY")}`
                                  : "Add Event"
                                : "Add Event"}
                            </ModalHeader>
                            <ModalBody>
                              <AvForm onValidSubmit={handleValidEventSubmit}>
                                <form>
                                  <Row>

                                    <Col lg={6}>
                                      <AvField
                                        type="select"
                                        name="category"
                                        label="Event/Holiday"
                                        onChange={(event) => {
                                          setSelectedEventType(event.target.value)
                                          if(event.target.value=="Holiday"){
                                            setSelectedPreference('8')
                                            setCustomDate(getHolidayNotificationTime(selectedDay))
                                          }
                                        }}
                                      >
                                        <option>Event</option>
                                        <option>Holiday</option>
                                        {/* <option>Online Meeting</option> */}
                                      </AvField>
                                    </Col>

                                    <Col lg={selectedEventType =="Event" ?3:6}>
                                      <Label>{selectedEventType =="Event" ?"Event on":"Holiday On"}</Label>
                                      <span className="form-control mb-2">
                                        {`${Moment(selectedDay).format("D/M/YYYY")}`}
                                      </span>
                                     </Col>

                                     {selectedEventType =="Event" && <Col lg={3}>
                                      <Label className="m-1">Event At</Label>
                                      <DatePicker
                                        selected={selectedTime}
                                        onChange={(date) => {
                                          setSelectedTime(date)

                                          let hours = date.getHours()
                                          let minutes = date.getMinutes()
                                          let tempDate = selectedDay;
                                          let selectedDate = new Date(tempDate.setHours(hours));
                                          selectedDate.setMinutes(minutes);

                                          setCustomDate(getNotificationTime(selectedDate,selectedPreference))
                                          
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        className="form-control mb-3"
                                        value={`${selectedTime ? Moment(selectedTime).format("h:mm a") : ""}`}
                                        placeholderText="hh:mm"
                                      />
                                      {pastDateError && (<p className="text-danger">{pastDateError}{" "}</p>)}
                                    </Col>}
                                  </Row>


                                 {endDate == undefined && !hideTimeSelect && <Row>
                                    {schoolType == 10 && <Col className="col-6"><FormGroup >
                                      <Label >Choose Participants:</Label>
                                      <select
                                        className="form-control"
                                        value={selectedParticipants && selectedParticipants.length > 0 ? selectedParticipants[0] : undefined}
                                        onChange={(e) => {
                                          handelSelecteCourse(e.target.value, 1)
                                          if (selectedParticipantsError) {
                                            setSelectedParticipantsError(false);
                                          }

                                        }}
                                        disabled={match ? false : true}
                                        required
                                      >
                                        <option disabled selected value>
                                          Choose Course
                                        </option>
                                        {courseAndBatchList && courseAndBatchList.map((course, key) => (
                                          <option key={course.id} value={course.id}>
                                            {course.id == "@all" ? course.course : course.course.attributes.name}
                                          </option>
                                        ))}
                                      </select>
                                      {selectedParticipantsError && <p className='text-danger'>Choose Participants</p>}
                                    </FormGroup></Col>}
                                  </Row>}


                                  <Row>
                                    <Col lg={6}>
                                        <Label>Reminding Preference</Label>
                                        <select
                                          className="form-control"
                                          value={selectedPreference}
                                          onChange={(e) => {
                                            const selectedValue = e.target.value
                                            setSelectedPreference(selectedValue);
                                            if(selectedTime){
                                              let hours = selectedTime.getHours()
                                              let minutes = selectedTime.getMinutes()
                                              let tempDate = selectedDay;
                                              let selectedDate = new Date(tempDate.setHours(hours));
                                              selectedDate.setMinutes(minutes);
                                              setCustomDate(getNotificationTime(selectedDate,selectedValue))
                                            }
                                          }}
                                        >
                                          {reminderPreferences.map((item, key) => ((
                                        <option key={key} value={item.value}>{item.label}</option>) 
                                          ))}
                                        </select>
                                      </Col>
                                      <Col lg={6}>
                                          <label>Reminding Time:</label>
                                          <DatePicker 
                                            showIcon
                                            selected={customDate}
                                            onChange={(date) => {
                                              setCustomDate(date)
                                            }} 
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa" 
                                            className="form-control"
                                            timeIntervals={15}
                                            maxDate={getNotificationMaxTime()}
                                            minDate={new Date()}
                                          
                                            disabled ={(selectedPreference=="8"?(selectedEventType=="Event"?(selectedTime ? false :true):false) :true)}
                                            
                                          />
                                          {(customDate && customDate>getNotificationMaxTime()) && <p className="text-danger"> Reminding Time should be less then event/holiday Time </p>}
                                          {customDateError && (<p className="text-danger">{customDateError}{" "}</p>
                                      )}
                                      </Col>
                                  </Row>
                                  
                                  <Row>
                                    <Col className="col-4">
                                      <Label>Send To</Label>
                                      <select
                                        className="form-control"
                                        value={selectedSendTo}
                                        onChange={(e) => {
                                          setSelectedSendTo(e.target.value);
                                        }}
                                      >
                                        <option disabled selected value>Select Send To</option>
                                        {sendToList.map((object, key) => (
                                          <option key={key} value={object.value}>{object.label}</option>
                                        ))}
                                      </select>
                                    </Col>
                                    {selectedSendTo == 2 &&

                                      <Col lg='4'>
                                        <FormGroup>
                                          <Label className="mb-2">Select Class</Label>
                                          <Select
                                            onChange={(e) => {
                                              setSelectedClassroom(e);
                                              setSelectedStudent()
                                              setSelectedClassError(false)
                                            }}
                                            value={selectedClassroom}
                                            options={classroomList}
                                            className="BuyerTypeSelection"
                                            classNamePrefix="BuyerType"
                                          />
                                          {selectedClassError && <small className='text-danger'>Select classroom</small>}
                                        </FormGroup>
                                      </Col>
                                    }
                                    {selectedSendTo == 2 && selectedClassroom &&

                                      <Col lg='4'>
                                        <FormGroup>
                                          <Label className="mb-2">Select Student</Label>

                                          <Select
                                            onChange={(v) => {
                                              setSelectedStudent(v)
                                              if (selectedStudentError) {
                                                setSelectedStudentError(false)
                                              }

                                            }}

                                            value={selectedStudent}
                                            options={studentList}
                                            className="BuyerTypeSelection"
                                            classNamePrefix="BuyerType"
                                          />
                                          {selectedStudentError && <small className='text-danger'>Select</small>}
                                        </FormGroup>
                                      </Col>
                                    }


                                    {selectedSendTo == 3 &&

                                      <Col lg='6'>
                                        <FormGroup>
                                          <Label>Select Group</Label>
                                          <select
                                            className="form-control"
                                            value={selectedGroup}
                                            onChange={(e) => {
                                              setSelectedGroup(e.target.value);
                                              setSelectedGroupError(false)
                                            }}
                                          >
                                            <option disabled selected value>Select Group</option>
                                            {props.groups && props.groups.map((group, key) => (
                                              <option key={key} value={group.id}>{group.attributes.name}</option>
                                            ))}
                                          </select>
                                          {selectedGroupError && <small className='text-danger'>Select Group</small>}
                                        </FormGroup>
                                      </Col>

                                    }
                                    {selectedSendTo == 4 &&

                                      <Col lg='6'>
                                        <FormGroup>
                                          <Label>Select Teacher</Label>
                                          <select
                                            className="form-control"
                                            value={selectedTeacher}
                                            onChange={(e) => {
                                              setSelectedTeacher(e.target.value);
                                            }}
                                          >
                                            <option value={"all"}>@ ALL Teachers</option>
                                            {props.teachers && props.teachers.map((teacher, key) => (
                                              <option key={key} value={teacher.id}>{teacher.attributes.Name}</option>
                                            ))}
                                          </select>
                                          {selectedTeacherError && <small className='text-danger'>Select</small>}
                                        </FormGroup>
                                      </Col>

                                    }
                                  </Row>
                                  {endDate && (
                                    <Col className="col-12">
                                      <Label>Start Date</Label>
                                      <DatePicker
                                        selected={selectedDay}
                                        onChange={(date) =>
                                          setSelectedDay(new Date(date))
                                        }
                                        className="form-control mb-3"
                                        placeholderText={props.t(
                                          "Select Start Date"
                                        )}
                                        startDate={selectedDay}
                                        endDate={endDate}
                                        maxDate={endDate}
                                        dateFormat="dd/MMM/yyyy"
                                        showDayMonthYearPicker
                                      />
                                    </Col>
                                  )}
                                  {endDate && (
                                    <Col className="col-12">
                                      <Label>End Date</Label>
                                      <DatePicker
                                        selected={endDate}
                                        onChange={(date) =>
                                          setEndDate(new Date(date))
                                        }
                                        className="form-control mb-3"
                                        placeholderText={props.t(
                                          "Select End Date"
                                        )}
                                        startDate={selectedDay}
                                        endDate={endDate}
                                        minDate={selectedDay}
                                        dateFormat="dd/MMM/yyyy"
                                        showDayMonthYearPicker
                                      />
                                    </Col>
                                  )}

                                  <Col className="col-12 mt-2">
                                    <AvField
                                      name="title"
                                      label="Title"
                                      type="text"
                                      errorMessage="Invalid title"
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>
                                  <Col className="col-12">
                                    <AvField
                                      name="message"
                                      label="Message"
                                      type="textarea"
                                      errorMessage="Invalid message"
                                      validate={{
                                        required: { value: true },
                                      }}
                                    />
                                  </Col>
                                  <Col>
                                    <Input
                                      type="checkbox"
                                      style={{ marginRight: '10px' }}
                                      checked={sendNotification}
                                      onChange={() => setSendNotification(!sendNotification)}
                                    />
                                    <Label>Notify selected audience</Label>
                                  </Col>
                                </form>
                                <Row>
                                  <Col>
                                    <div className="float-end">
                                      {!hideCloseButton && (
                                        <button
                                          type="button"
                                          className="btn btn-light m-1"
                                          onClick={() => toggle()}
                                        >
                                          Close
                                        </button>
                                      )}
                                      <button
                                        type="submit"
                                        className="btn btn-success save-event m-1"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </ModalBody>
                          </Modal>
                        </Col>

                      </Row>
                    </CardBody>
                  </Card>
                )}

                {importOnButton && (
                  <Card>
                    <Modal isOpen={showPopup || props.bulkUploadLoading}>
                      <ModalHeader>
                        <h5 className="modal-title mt-0" id="myModalLabel123">
                          {props.t("Reminder bulk upload")}
                        </h5>
                      </ModalHeader>
                      <ModalBody>
                        <p className=" m-4">
                          {props.t(
                            "Are you sure you want to upload this Calendar data?"
                          )}
                        </p>
                      </ModalBody>
                      <ModalFooter>
                        {!props.bulkUploadLoading ? (
                          <Row className="text-center">
                            <Col className="d-flex justify-content-end">
                              <button
                                type="button"
                                onClick={() => {
                                  setShowPopup(false);
                                }}
                                className="btn btn-secondary waves-effect m-1"
                                data-dismiss="modal"
                              >
                                {props.t("Cancel")}
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light m-1"
                                onClick={() => {
                                  handelSaveBulkUpload();
                                }}
                              >
                                {props.t("Yes, Upload")}
                              </button>
                            </Col>
                          </Row>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-light waves-effect"
                          >
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                            {props.t(" Uploading Enquiry data ")}
                          </button>
                        )}
                      </ModalFooter>
                    </Modal>

                    <CardBody>
                      <CardTitle className="mb-2">
                        {" "}
                        {props.t("Reminder bulk upload")}
                      </CardTitle>
                      <div className="">
                        <ul className="verti-timeline list-unstyled">
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className={"bx bx-right-arrow-circle"}></i>
                            </div>
                            <div className="media d-flex justify-content-start">
                              <div className="mr-3">
                                <i
                                  className={"bx bx-download h2 text-primary"}
                                ></i>
                              </div>
                              <div className="media-body m-2">
                                <div>
                                  <h5>{props.t("Download Excel Template")}</h5>
                                  <p className="text-muted">
                                    {
                                      "Please download the Excel template sheet to ﬁll in all reminder details quickly."
                                    }
                                  </p>
                                </div>
                                <Alert
                                  color="danger"
                                  role="alert"
                                >
                                  <p className="lead mb-1"><strong><u>Guidelines</u></strong></p>
                                  {/* <p className="mb-0">1. Date :	Please always use DD/MM/YYYY format for example if event is on january 12 2022 means, please use 12/01/2022 </p> */}
                                  <p className="mb-0">1. <strong>Date</strong> : Please use numbers for the <strong>Day</strong> (1 to 31), the <strong>Month</strong> (1 to 12), and the <strong>Year</strong> (e.g., 2024, 2025). </p>
                                  <p className="mb-0">2. <strong>Time</strong> : Please use numbers for the <strong>Hour</strong> (1 to 12), the <strong>Minutes</strong> (0 to 59), and indicate either AM or PM accordingly.</p>
                                  <p className="mb-0">3. <strong>Event Type</strong> : Please use “Holiday” if school is closed or otherwise use “Event”</p>

                                </Alert>
                                <button
                                  onClick={() => {
                                    // window.open(templateURL);
                                    downloadTemplate();
                                  }}
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light "
                                >
                                  <i className="bx bx-download font-size-16 align-middle"></i>{" "}
                                  {props.t("Download Template")}
                                </button>
                              </div>
                            </div>
                          </li>

                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className={"bx bx-right-arrow-circle"}></i>
                            </div>

                            <div className="media d-flex justify-content-start">
                              <div className="mr-3">
                                <i
                                  className={"bx bx-copy-alt h2 text-primary"}
                                ></i>
                              </div>
                              <div className="media-body m-2">
                                <div>
                                  <h5>{"Choose Excel"}</h5>
                                  <p className="text-muted">
                                    {
                                      "Now simply upload the Excel sheet that you just ﬁlled with all details."
                                    }
                                  </p>

                                  <div>
                                    <Label
                                      className="btn btn-primary m-1"
                                      htmlFor="inputImage"
                                    >
                                      <input
                                        type="file"
                                        className="sr-only"
                                        id="inputImage"
                                        name="file"
                                        accept=".xlsx,/*"
                                        onChange={handleUpload}
                                      />{" "}
                                      Choose Excel
                                    </Label>

                                    {tableData.rows &&
                                      tableData.rows.length > 0 && (
                                        <button
                                          onClick={() => {
                                            if (
                                              tableData &&
                                              tableData.length > 0
                                            ) {

                                              setTableData([]);
                                            }
                                          }}
                                          type="button"
                                          className="btn btn-light waves-effect waves-light m-1 "
                                        >
                                          {props.t("Clear")}
                                        </button>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      {/* {tableData.rows && tableData.rows.length > 0 && (
                        <MDBDataTable
                          responsive
                          bordered
                          data={tableData}
                          className="mt-5"
                        />
                      )} */}

                      {tableData && tableData.length > 0 &&

                        (<div className="table-responsive">
                          <Table responsive striped className="mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "4%" }}>Sl No </th>
                                <th style={{ width: "26%" }}>Event Type/Title </th>
                                <th style={{ width: "40%" }}>Message	</th>
                                <th style={{ width: "22%" }}>Date & Time/Reminding Preference</th>
                                {/* <th style={{ width: "10%" }}>Time</th> */}

                                <th style={{ width: "5%" }}>Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((data, key) =>
                                <tr id={"tdata" + data} key={key}>
                                  <td><h4 className="m-2">{key + 1}</h4></td>
                                  <td>
                                    <select
                                      className="form-control m-1"
                                      value={data.eventType}
                                      onChange={(e) => {
                                        let nc = data;
                                        nc.eventType = e.target.value;
                                        nc.eventTypeError = false;

                                        if(nc.eventType=="Holiday"){
                                          nc.remindingPreference=8
                                          if(data.actualTime){
                                            nc.notificationTime= getHolidayNotificationTime(data.actualTime)
                                          }
                                        }
                                        handelUpdateEvent(data, nc);


                                      }}
                                    >
                                      <option value="" disabled selected>---Select Event Type---</option>
                                      <option value="Event">Event</option>
                                      <option value="Holiday">Holiday</option>
                                    </select>

                                    <input
                                      type="text"
                                      className="inner form-control m-1"
                                      placeholder="Enter Title"
                                      value={data.title}
                                      onChange={(e) => {

                                        let nc = data;
                                        nc.title = e.target.value;
                                        nc.titleError = false;
                                        handelUpdateEvent(data, nc)
                                      }}
                                    />
                                    {data.titleError && <small className='text-danger'>{data.titleError}</small>}
                                    {data.eventTypeError && <small className='text-danger'>{data.eventTypeError}</small>}
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      className="inner form-control m-1"
                                      placeholder="Enter Message"
                                      value={data.msg}
                                      rows={3}
                                      onChange={(e) => {
                                        let nc = data;
                                        nc.msg = e.target.value;
                                        nc.msgError = false;
                                        handelUpdateEvent(data, nc)
                                        // console.log("Data",data.msg)
                                      }}
                                    />
                                    {data.msgError && <small className='text-danger'>{data.msgError}</small>}
                                  </td>
                                  <td>
                                    <DatePicker
                                      showIcon
                                      selected={data.actualTime}
                                      className="form-control"
                                      onChange={(date) => {
                                        // console.log(date)
                                        let nc = data;
                                        nc.actualTime = date;
                                        nc.actualTimeError = false;
                                          if(nc.actualTime){
                                            nc.notificationTime= getHolidayNotificationTime(nc.actualTime)
                                          }
                                        handelUpdateEvent(data, nc)
                                      }}
                                      showTimeSelect={data.eventType == "Holiday" ? false : true}
                                      timeIntervals={15}
                                      placeholderText={data.eventType == "Holiday" ? "dd-MM-yyyy" : "dd-MM-yyyy h:mm aa"}
                                      dateFormat={data.eventType == "Holiday" ? "dd-MM-yyyy" : "d-MM-yyyy h:mm aa"}
                                      maxDate={getNotificationMaxTime()}
                                      minDate={new Date()}
                                    />
                                    {(data.actualTime && data.actualTime < new Date()) && (<p className="text-danger mt-0">Event Time should be greater than the current time</p>)}
                                    {data.actualTimeError && <small className='text-danger'>{data.actualTimeError}</small>}
                                    <select
                                      className="form-control"
                                      value={data.remindingPreference}
                                      onChange={(e) => {
                                        let nc = data;
                                        nc.remindingPreference = e.target.value;
                                        if(data.actualTime){
                                          nc.notificationTime = getNotificationTime(data.actualTime,nc.remindingPreference)
                                        }
                                        handelUpdateEvent(data, nc)
                                      }}
                                    >
                                      
                                        {data.eventType ? (reminderPreferences.map((item, key) => ((data.eventType === "Event" || item.value === 8) ? (
                                          <option key={key} value={item.value}>{item.label}</option>) : null))
                                          ) : (
                                            <option value="" disabled></option>
                                          )}
                                    </select>
                                    <DatePicker
                                      showIcon
                                      selected={data.notificationTime}
                                      className="form-control"
                                      onChange={(date) => {
                                        // console.log("Data",date)
                                        let nc = data
                                        nc.notificationTime= date;
                                        handelUpdateEvent(data, nc)
                                        nc.notificationTimeError = false;

                                      }}
                                      disabled={!(data.remindingPreference==8 && data.actualTime)}
                                      showTimeSelect
                                      timeIntervals={15}
                                      placeholderText="dd-MM-yyyy h:mm aa"
                                      dateFormat={"dd-MM-yyyy h:mm aa"}
                                      maxDate={getNotificationMaxTime()}
                                      minDate={new Date()}
                                    />
                                     {(data.notificationTime && data.notificationTime < new Date()) && (<p className="text-danger mt-0">Reminding Time should be greater than the current time..</p>)}
                                     {(data.notificationTime && data.actualTime && data.notificationTime >= data.actualTime) && (<p className="text-danger mt-0">Reminding Time should be less than the Event time</p>)}
                                     
                                  </td>
                                  <td>
                                    <Button
                                      color="danger"
                                      className="btn-block btn-sm inner mt-1"
                                      style={{ width: "100%" }}
                                      onClick={(e) => {
                                        handelRemoveEvent(data);
                                      }}
                                      disabled={tableData.length == 1 ? true : false}
                                    >

                                      Remove
                                    </Button>
                                  </td>
                                </tr>

                              )
                              }

                            </tbody>
                          </Table>

                          <Row>
                            <Col xs={12}>
                              <Button
                                onClick={() => handelAddNewRow()}
                                type="button"
                                color="success"
                                className="btn waves-effect waves-light float-end  m-2"
                              >
                                <i className="mdi mdi-plus mr-1" />
                                ADD
                              </Button>
                            </Col>
                          </Row>



                        </div>)
                      }

                      <div className="">
                        <ul className="verti-timeline list-unstyled mt-2">
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className={"bx bx-right-arrow-circle "}></i>
                            </div>
                            <div className="media d-flex justify-content-start">
                              <div className="mr-3">
                                <i
                                  className={"bx bx-upload h2 text-primary"}
                                ></i>
                              </div>
                              <div className="media-body m-2">
                                <div>
                                  <h5>{"Upload and Save"}</h5>
                                  <p className="text-muted">
                                    {
                                      "We would magically create the reminder events for you"
                                    }
                                  </p>
                                  <button
                                    onClick={() => {


                                      let allData = [...tableData];
                                      var goodToSave = true;


                                      for (const i in allData) {
                                        let tempData = allData[i];

                                        if (tempData.title && tempData.title.length > 0) {
                                          tempData.titleError = false;
                                        } else {
                                          tempData.titleError = "Title is mandatory";
                                          goodToSave = false;
                                        }

                                        if (tempData.msg && tempData.msg.length > 0) {
                                          tempData.msgError = false;
                                        } else {
                                          tempData.msgError = "Message is mandatory";
                                          goodToSave = false;
                                        }

                                        if (tempData.eventType && tempData.eventType.length > 0) {
                                          tempData.eventTypeError = false;
                                        } else {
                                          tempData.eventTypeError = "Event Type is mandatory";
                                          goodToSave = false;
                                        }

                                        if (tempData.actualTime) {
                                          if (tempData.actualTime < new Date()) {
                                            tempData.actualTimeError = "Event Time should be greater than the current time";
                                            goodToSave = false;
                                          } else {
                                            tempData.actualTimeError = false;
                                          }
                                        } else {
                                          tempData.actualTimeError = "Date & Time is mandatory";
                                          goodToSave = false;
                                        }

                                        if(tempData.reminderPreferences== 8){

                                          if(tempData.notificationTime >= tempData.actualTime){
                                            goodToSave = false;
                                          }

                                          if(tempData.actualTime < new Date()){
                                            goodToSave = false;
                                          }
                                        }

                                        if (tempData.notificationTime && tempData.notificationTime < new Date()) {
                                          tempData.notificationTimeError = "Reminding Time should be greater than the current time.";
                                          goodToSave = false;
                                        } else if (tempData.notificationTime && tempData.actualTime && tempData.notificationTime > tempData.actualTime) {
                                          tempData.notificationTimeError = "Reminding Time should be less than the Event time.";
                                          goodToSave = false;
                                        } else {
                                          tempData.notificationTimeError = null;
                                        }
                                      }
                                      setTableData(allData)
                                      if (goodToSave) {
                                        setShowPopup(true);
                                      }

                                    }}

                                    type="button"
                                    className={
                                      tableData &&
                                        tableData.length > 0
                                        ? "btn btn-primary waves-effect waves-light "
                                        : "btn btn-light waves-effect waves-light "
                                    }
                                  >
                                    <i className="bx bx-upload font-size-16 align-middle"></i>{" "}
                                    {props.t("Upload and Save")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Calendar...{" "}
                </Label>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { groupmaps } = state.GroupMap;
  const { selectedSchool } = state.School;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  const { reminders } = state.Reminder;
  const { courses } = state.Course;
  const { groups } = state.Group;
  const { schools } = state.School;
  const { teachers } = state.Teacher;


  return { groupmaps, selectedSchool, classrooms, kids, reminders, courses, groups, schools, teachers };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getGroupMapsForTeacherId,
      getClassroomsForSchoolId,
      getAllKids,
      getAllRemindersForSchool,
      saveAllReminders,
      addReminder,
      editReminder,
      setSelecetdSchool,
      deleteReminder,
      getCoursesForOwnerIds, getTeachersForSchool, getGroupsForSchoolId
    })(Index)
  )
);
