import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getInvoiceDetailsByFunction } from "../../store/actions";
import Moment from "moment";
import Switch from "react-switch";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';

import OnlinePatment from "./onlinePayment";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Constants from "../../Constents";

import COUNTRY_JSON from '../../assets/other/countries.json'
import SaveAsPdf from "../../components/Common/SaveAsPdf";

const PrintInvoice = (props) => {
  const onlyPrint = matchPath(props.history.location.pathname, {
    path: "/invoice/:invoiceId",
    exact: true,
    strict: false,
  });
  const onlyPay = matchPath(props.history.location.pathname, {
    path: "/invoice/:invoiceId/:mode",
    exact: true,
    strict: false,
  });
  const match = onlyPrint ? onlyPrint : onlyPay;
  const invoiceId = match.params.invoiceId;
  const [paymentMode, setPayentMode] = useState(match.params.mode ? +match.params.mode + "" : "");
  const [invoiceDetails, invoiceDetailsOps] = useState({});
  const [classRoomDetails, classRoomDetailsOps] = useState({});
  const [kid, kidOps] = useState({});
  const [school, schoolOps] = useState({});
  const [invoiceItems, invoiceItemsOps] = useState([]);
  const [parents, parentsOps] = useState([]);
  const [transactions, transactionsOps] = useState([]);
  const [invoiceTotal, invoiceTotalOps] = useState(0);
  const [invoiceBalanceAmount, invoiceBalanceAmountOps] = useState(0);
  const [invoicePaidAmount, invoicePaidAmountOps] = useState(0);
  const [currency, currencyOps] = useState("₹");
  const [programDetails, programDetailsOps] = useState(undefined);
  const [showDiscoutn, showDiscoutnOps] = useState(false);
  //pay onlne mode
  const [paymentPopup, paymentPopupOps] = useState(false);
  //all org objs
  const [orgObj, orgObjOps] = useState({});

  const [inforLoading, setinforLoading] = useState(true);
  const [showNoPG, setShowNoPG] = useState(false);

  const [taxable, setTaxable] = useState(false);
  const [totalTax, setInvoiceTax] = useState(0);

  const [invoiceDeleted, setInvoiceDeleted] = useState(false);
  const [showRow, setShowRow] = useState(true);
  const [disableEdit, setDisableEdit] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showReceiptAmount, setShowReceiptAmount] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [machineType, setMachineType] = useState('');


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // return new Promise((resolve, reject)=>{
    props.getInvoiceDetailsByFunction(invoiceId);
    // })
  }, [invoiceId]);
  useEffect(() => {
    let invoiceBalanceTemp = 0;
    if (props.invoice) {
      //program deatals
      if (props.invoice.Program) {
        let temp = {};
        temp.id = props.invoice.Program.id;
        temp.name = props.invoice.Program.attributes.name;
        temp.obj = props.invoice.Program;
        programDetailsOps(temp);
      }
      //class details
      if (props.invoice.ClassRoom) {
        let temp = {};
        temp.id = props.invoice.ClassRoom.id;
        temp.obj = props.invoice.ClassRoom;
        temp.name = props.invoice.ClassRoom.attributes
          ? props.invoice.ClassRoom.attributes.Name
          : "";
        classRoomDetailsOps(temp);
      }
      //invoice details
      if (props.invoice.Invoice) {
        let temp = {};
        temp.id = props.invoice.Invoice.id;
        temp.obj = props.invoice.Invoice;
        temp.isDaycareInvoice = props.invoice.Invoice.attributes.isDaycareInvoice;
        temp.invoiceNumber = props.invoice.Invoice.attributes
          ? props.invoice.Invoice.attributes.invoiceNumber
          : "--";
        temp.paidStatus = props.invoice.Invoice.attributes.paidStatus
        temp.invoiceDate = Moment(props.invoice.Invoice.attributes.createdAt).format("DD MMM YYYY")

        setInvoiceDeleted(props.invoice.Invoice.attributes.isDeleted)
        invoiceDetailsOps(temp);
      }
      //kid details
      if (props.invoice.Kid) {
        let temp = {};
        temp.id = props.invoice.Kid.id;
        temp.obj = props.invoice.Kid;
        temp.name = props.invoice.Kid.attributes.Name;
        temp.admissionNumber = props.invoice.Kid.attributes.admissionNumber;
        temp.email = props.invoice.Kid.attributes.email;
        temp.phoneNumber = props.invoice.Kid.attributes.phoneNumber;
        temp.kidPicForMobile = props.invoice.Kid.attributes.Photo
          ? props.invoice.Kid.attributes.Photo._url
          : "";
        kidOps(temp);
      }
      //school details
      if (props.invoice.School) {
        let temp = {};
        temp.id = props.invoice.School.id;
        temp.instituteId = props.invoice.School.attributes.instituteId;
        temp.obj = props.invoice.School;
        temp.name = props.invoice.School.attributes.Name;
        temp.address = props.invoice.School.attributes.Address;
        temp.primaryPhoneNumber =
          props.invoice.School.attributes.primaryPhoneNumber;
        temp.secondaryPhoneNumber =
          props.invoice.School.attributes.secondaryPhoneNumber;
        temp.email = props.invoice.School.attributes.EMail;

        if (props.invoice.School.attributes.letterheadLogo) {
          temp.logoUrl = props.invoice.School.attributes.letterheadLogo._url;
        } else {
          temp.logoUrl = props.invoice.School.attributes.logo
            ? props.invoice.School.attributes.logo._url
            : "";
        }
        temp.logoForMobile = props.invoice.School.attributes.logo
          ? props.invoice.School.attributes.logo._url
          : "";
        temp.cin = props.invoice.School.attributes.cin;
        temp.gstin = props.invoice.School.attributes.GSTIN;
        temp.pan = props.invoice.School.attributes.pan;
        temp.countryCode = props.invoice.School.attributes.countryCode;
        temp.invoiceFooter = props.invoice.School.attributes.invoiceFooter;
        temp.trustName = props.invoice.School.attributes.trustName;
        temp.regNo = props.invoice.School.attributes.registrationNumber;

        temp.countryCode && getCurrencyForSchool(temp.countryCode);

        let additionalSettings = props.invoice.School.attributes.additionalSettings;
        if (additionalSettings && additionalSettings.length > Constants.ADDITIONAL_SETTING_PAYMENT_EDIT_DISABLED
          && additionalSettings[Constants.ADDITIONAL_SETTING_PAYMENT_EDIT_DISABLED] > 0) {
          setDisableEdit(true);
        }
        if (additionalSettings && additionalSettings.length > Constants.ADDITIONAL_SETTING_PAYMENT_DATE
          && additionalSettings[Constants.ADDITIONAL_SETTING_PAYMENT_DATE] > 0) {
          setShowDate(true);
        }
        if (additionalSettings && additionalSettings.length > Constants.ADDITIONAL_SETTING_RECEIPT_WITHOUT_TRANSACTION
          && additionalSettings[Constants.ADDITIONAL_SETTING_RECEIPT_WITHOUT_TRANSACTION] > 0) {
          setShowReceiptAmount(true);
        }



        if (props.invoice.ReceiptAddress) {


          temp.name = props.invoice.ReceiptAddress.attributes.name;
          temp.address = props.invoice.ReceiptAddress.attributes.address;
          temp.primaryPhoneNumber =
            props.invoice.ReceiptAddress.attributes.phoneNumber;
          temp.secondaryPhoneNumber = "";
          temp.email = props.invoice.ReceiptAddress.attributes.email;
          temp.logoUrl = props.invoice.ReceiptAddress.attributes.logo
            ? props.invoice.ReceiptAddress.attributes.logo._url
            : temp.logoUrl;
          temp.cin = props.invoice.ReceiptAddress.attributes.CIN;
          temp.gstin = props.invoice.ReceiptAddress.attributes.GSTIN;
          temp.pan = props.invoice.ReceiptAddress.attributes.PAN;
          temp.invoiceFooter = props.invoice.ReceiptAddress.attributes.invoiceFooter;
          temp.trustName = props.invoice.ReceiptAddress.attributes.trustName;
          temp.regNo = props.invoice.ReceiptAddress.attributes.registrationNumber;

          setTaxable(props.invoice.ReceiptAddress.attributes.taxable);
          schoolOps(temp);
        } else {
          schoolOps(temp);
        }
      }

      if (!props.invoice.isGatewayEnabled && paymentMode != 2) {
        setPayentMode("3")
      }
      //invoice items
      if (props.invoice.Items) {
        console.log(props.invoice.Items)
        let temp = [];
        let invoiceTotal = 0;
        let invoicePaidAmount = 0;
        let invoiceTax = 0;
        for (const val of props.invoice.Items) {
          let tempIn = {};
          tempIn.id = val.id;
          tempIn.obj = val;
          tempIn.paidAmount = 0;
          tempIn.balanceAmount = 0;
          tempIn.title = val.attributes.itemDescription;
          tempIn.dueDate = val.attributes.dueDate
            ? Moment(val.attributes.dueDate).format("DD MMM YYYY")
            : "--";
          tempIn.amount = val.attributes.amount;
          tempIn.discount = val.attributes.discount;
          if (tempIn.discount > 0 && !showDiscoutn) {
            showDiscoutnOps(true);
          }
          if (val.attributes.tax > 0) {
            setTaxable(true);
            let tax = calculateTax(val)
            tempIn.taxAmount = tax;
            invoiceTax = invoiceTax + tax;
            tempIn.tax = val.attributes.tax;
          } else {
            tempIn.tax = 0;
            tempIn.taxAmount = 0;
          }
          if (val.attributes.paidAmount) {
            tempIn.paidAmount = val.attributes.paidAmount;
          }
          tempIn.balanceAmount = val.attributes.paidAmount ? (parseInt(val.attributes.totalAmount) - parseInt(val.attributes.paidAmount)) : val.attributes.totalAmount
          tempIn.discountType = val.attributes.discountType;
          tempIn.totalAmount = val.attributes.totalAmount;
          tempIn.hsn = val.attributes.hsn;

          invoiceTotal += tempIn.totalAmount;
          invoicePaidAmount += tempIn.paidAmount;
          temp.push(tempIn);
        }
        setInvoiceTax(invoiceTax);
        invoiceTotalOps(invoiceTotal);
        invoicePaidAmountOps(invoicePaidAmount)
        invoiceBalanceTemp = invoiceTotal;
        invoiceItemsOps(temp);
      }
      //parents details
      if (props.invoice.Parents) {
        let temp = [];
        for (const val of props.invoice.Parents) {
          let tempIn = {};
          tempIn.id = val.id;
          tempIn.obj = val;
          tempIn.name = val.attributes.Name;
          tempIn.relation = val.attributes.Relation;
          temp.push(tempIn);
        }
        parentsOps(temp);
      }
      //transaction details
      if (props.invoice.Transactions) {
        let temp = [];
        for (const val of props.invoice.Transactions) {
          let tempIn = {};
          tempIn.id = val.id;
          tempIn.obj = val;
          tempIn.type = val.attributes.transactionType;
          tempIn.date = val.attributes.paidDate
            ? Moment(val.attributes.paidDate).format("DD MMM YYYY")
            : Moment(val.attributes.createdAt).format("DD MMM YYYY");
          tempIn.amount = val.attributes.amount;
          tempIn.refNumber = val.attributes.transactionId;
          if (tempIn.type === "Cheque" || tempIn.type === "Online") {
            if (val.attributes.transactionId) {
              tempIn.refNumber = val.attributes.transactionId;
            } else if (val.attributes.chequeNo) {
              tempIn.refNumber = val.attributes.chequeNo;
            }
          }
          invoiceBalanceTemp -= tempIn.amount;
          temp.push(tempIn);
        }
        invoiceBalanceAmountOps(invoiceBalanceTemp);
        transactionsOps(temp);
      }
      orgObjOps(props.invoice);
      setinforLoading(false)
    }
  }, [props.invoice]);
  const getCurrencyForSchool = (countryCode) => {
    if (countryCode != "IN") {
      for (const cc of COUNTRY_JSON) {
        if (cc.code == countryCode) {
          currencyOps(cc.currency.currencySymbol);
          break;
        }
      }
    }
  };

  const printInvoiceDocument = () => {
    var css = "@page { size: A4;margin:10mm 0 10mm 0; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    setTimeout(() => {
      window.print();
    }, 300);
  };

  useEffect(() => {
    const savedValue = localStorage.getItem('dontShowAgain');
    if (savedValue === 'true') {
        setDontShowAgain(true);
    }
    const userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.includes('mac')) {
        setMachineType('Mac');
    } else if (userAgent.includes('windows')) {
        setMachineType('Windows');
    } else {
        setMachineType('Unknown');
    }
  }, []);

  const handleNextClick = () => {
    setShowSaveAsPdfModal(false);
    setTimeout(() => {
        printInvoiceDocument();
    }, 300);
  }

  // document.addEventListener('keydown', function (event) {
  //   console.log(event)
  //   if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
  //     event.preventDefault(); // Prevent the default print action
  //     printInvoice();
  //   }
  // });

  const recivePaymentModel = () => {
    paymentPopupOps(!paymentPopup);
    removeBodyCss();
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const calculateTax = (item) => {
    let xAmount = item.attributes.amount;

    if (item.attributes.discountType == 1) {
      xAmount = item.attributes.amount - item.attributes.discount;

    } else if (item.attributes.discountType == 2) {
      xAmount = item.attributes.amount - (item.attributes.amount * item.attributes.discount / 100);
    }

    let tTax = xAmount * 0.01 * item.attributes.tax;

    return tTax;
  }

  const totalAmountCount = transactions.reduce((acc, val) => acc + parseFloat(val.amount), 0);

  const getUI = () => {
    return (<div>
      <Row className="d-flex justify-content-between">
        <Col className="d-flex justify-content-start">
          {(paymentMode !== "2") && !inforLoading &&
            <Breadcrumbs title="Invoices Receipt " breadcrumbItem="Detail" />}
        </Col>
        {/* {console.log(paymentMode, paymentMode != "1" && paymentMode != "3")} */}
        {(onlyPrint) && <Col className="d-flex justify-content-end">
          <div className="d-print-none">
            <label><b>Hide header</b></label>
          </div>
          <div className="m-1 d-print-none">
            <Switch
              height={15}
              width={30}
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              onColor={"#6add7d"}
              checked={showRow}
              onChange={() => {
                setShowRow(!showRow);
              }}
            />
          </div>
        </Col>}
      </Row>

      {
        (inforLoading && paymentMode !== "2") ? <Col xs="12">
          <div className="text-center my-3">
            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Invoice Receipt</Label>
          </div>

        </Col> :
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div style={{ visibility: showRow ? 'visible' : 'hidden' }}>
                    {/* <h4 className="float-right font-size-16">
                      {props.t("Receipt Number")} #{" "}
                      {invoiceDetails.invoiceNumber}
                    </h4> */}
                    <Row className="d-flex justify-content-between">
                      {school.logoUrl && (
                        <Col className="d-flex justify-content-start">
                          {" "}
                          <img
                            src={school.logoUrl}
                            style={{ height: "6rem" }}
                          />{" "}
                        </Col>
                      )}

                      <Col className="d-flex justify-content-end">
                        <address className="">
                          <strong>{props.t("School Details")}:</strong>
                          <br />
                          {school.trustName && (
                            <strong>
                              {school.trustName}
                              <br />
                            </strong>
                          )}
                          {school.name && (
                            <strong>
                              {school.name}
                              <br />
                            </strong>
                          )}
                          {school.address}
                          {school.address && <br />}
                          {school.primaryPhoneNumber && (
                            <span>{school.primaryPhoneNumber}</span>
                          )}
                          {", "}
                          {school.secondaryPhoneNumber && (
                            <span>{school.secondaryPhoneNumber}</span>
                          )}{" "}
                          {(school.primaryPhoneNumber ||
                            school.secondaryPhoneNumber) && <br />}
                          {school.email} {school.email && <br />}
                          {school.cin && (
                            <span>
                              CIN : {school.cin} <br />
                            </span>
                          )}
                          {school.gstin && (
                            <span>
                              GSTIN : {school.gstin} <br />
                            </span>
                          )}
                          {school.pan && (
                            <span>
                              PAN : {school.pan} <br />
                            </span>
                          )}
                          {school.regNo && (
                            <span>
                              Registration Number : {school.regNo} <br />
                            </span>
                          )}
                        </address>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <Row>
                    <Col xs="6">
                      <address>
                        <strong>{props.t("Student Details")}:</strong>
                        <br />
                        <strong>
                          {kid.name} <br />
                        </strong>
                        {kid.admissionNumber && (
                          <span>
                            {props.t("Admission number") +
                              " : " +
                              kid.admissionNumber}{" "}
                            <br />{" "}
                          </span>
                        )}
                        {kid.email && (
                          <span>
                            {props.t("Email") +
                              " : " +
                              kid.email}{" "}
                            <br />{" "}
                          </span>
                        )}
                        {kid.phoneNumber && (
                          <span>
                            {props.t("Phone Number ") +
                              " : " +
                              kid.phoneNumber}{" "}
                            <br />{" "}
                          </span>
                        )}
                        {school.instituteId != "eNV4Cev01v" && parents.map(
                          (val, key) =>
                            val.name && (
                              <span key={key}>
                                {" "}
                                {val.relation} : {val.name} <br />
                              </span>
                            )
                        )}

                        {!invoiceDetails.isDaycareInvoice && classRoomDetails.name && (!programDetails || programDetails.obj.attributes.showClassName) &&
                          `Class : ${classRoomDetails.name}`
                        }
                        {!invoiceDetails.isDaycareInvoice && programDetails && programDetails.name && !programDetails.obj.attributes.showClassName &&
                          <span>
                            {`Program : ${programDetails.name}`}
                          </span>
                        }
                        {invoiceDetails.isDaycareInvoice &&
                          <span>
                            {`Service : Daycare`}
                          </span>
                        }
                      </address>
                    </Col>

                    <Col xs="6">
                      <h4 className="d-flex justify-content-end font-size-16">
                        {invoiceDetails.paidStatus == 1 ? props.t("Receipt Number"):props.t("Invoice Number")} #{" "}
                        {invoiceDetails.invoiceNumber}
                      </h4>
                    </Col>
                  </Row>
                  <div className="">
                    <Row className="d-flex justify-content-between">
                      <Col className="d-flex justify-content-start">
                        <h3 className="font-size-15 font-weight-bold">
                          {props.t("Invoice summary")}
                        </h3>
                      </Col>
                      {showDate && <Col className="d-flex justify-content-end">
                        <p className="font-weight-bold font-size-15">
                          {props.t("Date")}:{invoiceDetails.invoiceDate}
                        </p>
                      </Col>}
                    </Row>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-nowrap table-sm" style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>{props.t("#")}</th>
                          <th>{props.t("Particular")}</th>
                         {!showReceiptAmount &&<th>{props.t("DueDate")}</th>}
                          <th className="text-right">
                            {props.t("Amount")}
                          </th>
                          {!showDiscoutn && (
                            <th ></th>
                          )}
                          {showDiscoutn && (
                            <th className="text-right">
                              {props.t("Discount")}
                            </th>
                          )}
                          {taxable && (
                            <th className="text-right">
                              {props.t("CGST")}
                            </th>
                          )}
                          {taxable && (
                            <th className="text-right">
                              {props.t("SGST")}
                            </th>
                          )}
                          <th className="text-right">
                            {props.t("Total Amount")}
                          </th>
                          {onlyPrint && showReceiptAmount && (<th className="text-right">
                            {props.t("Paid Amount")}
                          </th>)}
                          {onlyPrint && showReceiptAmount && (<th className="text-right">
                            {props.t("Balance Amount")}
                          </th>)}
                        </tr>
                      </thead>
                      {false && invoiceDetails.paidStatus === 1 && <div style={{
                        borderWidth: '4px',
                        borderStyle: 'solid',
                        borderColor: '#008000',
                        borderRadius: '8px',
                        color: '#008000',
                        opacity: 0.4,
                        position: 'absolute',
                        zIndex: 1,
                        //left: '40%',
                        //top: '30%',
                        right: '50%',
                        fontSize: '30pt',
                        transform: 'rotate(-45deg)',
                        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif'
                      }}>
                        PAID
                      </div>}
                      <tbody>
                        {invoiceItems.map((val, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {val.title}
                              {val.hsn && <p className="text-size-12 mt-0">{"HSN: " + val.hsn}</p>}
                            </td>
                            {!showReceiptAmount && <td>{val.dueDate}</td>}
                            <td className="text-right">
                              <span >
                                {currency}
                              </span>{" "}
                              {parseFloat(val.amount).toFixed(2)}
                            </td>
                            {!showDiscoutn && <td className="text-right"></td>}
                            {showDiscoutn && taxable && (
                              <td className="text-right">
                                {val.discountType == 1 && (
                                  <span>
                                    {currency}
                                  </span>
                                )}
                                {val.discount}
                                {val.discountType == 2 && "%"}

                                {/* {currency}{val.amount - val.totalAmount} */}
                              </td>
                            )}
                            {showDiscoutn && !taxable && (
                              <td className="text-right">
                                {currency}{val.amount - val.totalAmount}
                              </td>
                            )}
                            {taxable && (
                              <td className="text-right" >
                                {currency}{parseFloat(val.taxAmount / 2).toFixed(1)}({(val.tax / 2).toFixed(2)}%)
                              </td>
                            )}
                            {taxable && (
                              <td className="text-right" >
                                {currency}{parseFloat(val.taxAmount / 2).toFixed(1)}({(val.tax / 2).toFixed(2)}%)
                              </td>
                            )}
                            <td className="text-right">
                              <span >
                                {currency}
                              </span>{" "}
                              {parseFloat(val.totalAmount).toFixed(2)}
                            </td>
                            {onlyPrint && showReceiptAmount && (<td className="text-right">
                              <span>
                                {currency}
                              </span>{" "}
                              {parseFloat(val.paidAmount).toFixed(2)}
                            </td>)}
                            {onlyPrint && showReceiptAmount && (<td className="text-right">
                              <span>
                                {currency}
                              </span>{" "}
                              {parseFloat(val.balanceAmount).toFixed(2)}
                            </td>)}
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={"4"} className="text-end">
                            <strong>{props.t("Total")}</strong>
                          </td>
                          {!showReceiptAmount &&<td></td>}
                          {taxable && <td className="text-right">
                            <span >
                              {currency}
                            </span>{" "}
                            {parseFloat(totalTax / 2).toFixed(1)}
                          </td>}
                          {taxable && <td className=" text-right">
                            <span>
                              {currency}
                            </span>{" "}
                            {parseFloat(totalTax / 2).toFixed(1)}
                          </td>}
                          <td className=" text-right">
                            <h4 className="m-0">
                              <span>
                                {currency}
                              </span>{" "}
                              {parseFloat(invoiceTotal).toFixed(2)}
                            </h4>
                          </td>
                          {showReceiptAmount && <td className=" text-right">
                            <h4 className="m-0">
                              <span>
                                {currency}
                              </span>{" "}
                              {parseFloat(invoicePaidAmount).toFixed(2)}
                            </h4>
                          </td>}
                          {showReceiptAmount && <td className=" text-right">
                            <h4 className="m-0">
                              <span>
                                {currency}
                              </span>{" "}
                              {parseFloat(invoiceBalanceAmount).toFixed(2)}
                            </h4>
                          </td>}
                          <td></td>
                        </tr>
                        {!showReceiptAmount && transactions.map((val, key) => (
                          <tr key={key}>
                            <td colSpan={taxable ? "7" : "5"} className="text-end">
                              {val.type} {val.date}
                              {val.refNumber && (
                                <small>
                                  <br />
                                  {props.t("Reference Number")} :{val.refNumber}
                                </small>
                              )}
                            </td>
                            <td className="text-right text-danger">
                              <span>
                                {currency}
                              </span>{" "}
                              {parseFloat(val.amount).toFixed(2)}(-)
                            </td>
                            <td></td>
                          </tr>
                        ))}
                        {!showReceiptAmount && <tr>
                          <td colSpan={taxable ? "7" : "5"} className="text-end">
                            {props.t("Balance Due")}
                          </td>
                          <td className="text-right">
                            <span>
                              {currency}
                            </span>{" "}
                            {parseFloat(invoiceBalanceAmount).toFixed(2)}
                          </td>
                          <td></td>
                        </tr>}
                      </tbody>
                    </Table>
                  </div>
                  <hr />
                  <p>
                    <b>{props.t("Note")}:</b>{" "}
                    {props.t(
                      "This is Computer generated Invoice and requires no signature"
                    )}
                  </p>
                  <pre
                    style={{
                      fontFamily: "Open Sans, sans-serif",
                      wordBreak: "normal",
                      border: "none",
                      background: "#fff",
                    }}
                  >
                    {school.invoiceFooter}
                  </pre>
                  <hr />
                  {(paymentMode !== "2") && <div className="d-print-none">
                    <div className="d-flex justify-content-end">
                      <Label
                        onClick={() => {
                          if (['Windows', 'Mac'].includes(machineType)) {

                            if(!dontShowAgain){
                              setShowSaveAsPdfModal(true);
                            }else{
                              printInvoiceDocument();
                            }
                          }else{
                            printInvoiceDocument();
                          }
                        }}
                        className="btn btn-success waves-effect waves-light m-1"
                      >
                        <i className="fa fa-print"></i>Print
                      </Label>
                      <SaveAsPdf
                        showModal={showSaveAsPdfModal}
                        toggleModal={() => setShowSaveAsPdfModal(false)}
                        dontShowAgain={dontShowAgain}
                        setDontShowAgain={setDontShowAgain}
                        onNextClick={handleNextClick}
                        machineType={machineType}
                      />
                      {(paymentMode === "1" || paymentMode === "3") && !onlyPrint && invoiceBalanceAmount != 0 && (
                        <Label
                          onClick={() => {
                            if (paymentMode === "3") {
                              setShowNoPG(true)
                            } else {
                              recivePaymentModel();
                            }

                          }}
                          className="btn btn-primary w-md waves-effect waves-light m-1"
                        >
                          Pay Online
                        </Label>
                      )}
                    </div>
                  </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>


      }
    </div>)
  }

  const getMobileUI = () => {
    return (<div >
      {
        (inforLoading && paymentMode !== "2") ? <Col xs="12">
          <div className="text-center my-3">
            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Invoice Receipt</Label>
          </div>

        </Col> :
          <Row>
            <Col xs="12">
              {/* <Card>
                <CardBody> */}
              <div className="text-center pt-2">
                <h5>Invoice Receipt</h5>
              </div>
              <div style={{ width: '100%', position: 'sticky', top: '0cm', backgroundColor: 'white', }}>
                <div style={{ visibility: showRow ? 'visible' : 'hidden' }}>
                  <Row className="d-flex ">
                    {school.logoUrl && (
                      <Col xs={4} className="d-flex justify-content-strat">
                        {" "}
                        <img
                          src={school.logoForMobile}
                          style={{ height: "5rem" }}
                        />{" "}
                      </Col>
                    )}

                    {/* <Col xs={8} className="d-flex align-items-end pt-4">
                      {school.obj && (
                        <strong>
                          {school.obj.attributes.Name}
                        </strong>
                      )}
                      <p>{school.obj && school.obj.attributes.city}</p>
                    </Col> */}
                    <Col xs={8} className="d-flex justify-content-end pt-4">
                      <address className="">
                        {school.name && (
                          <strong>
                            {school.name}
                            <br />
                          </strong>
                        )}
                        {school.address}
                      </address>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="d-flex justify-content-center">
                  <div>
                    {kid.kidPicForMobile ? (
                      <div className=''>
                        <Link to={'#'} >
                          <img className="rounded-circle avatar-sm" src={kid.kidPicForMobile} alt="" />
                        </Link>
                      </div>
                    ) : (
                      <div className="avatar-sm ">

                      </div>
                    )}
                  </div>
                  <div className="m-1"></div>
                  <div className="m-1">
                    <strong>
                      {kid.name} <br />
                    </strong>
                    {!invoiceDetails.isDaycareInvoice && classRoomDetails.name && (!programDetails || programDetails.obj.attributes.showClassName) &&
                      `Class: ${classRoomDetails.name}`
                    }
                    {!invoiceDetails.isDaycareInvoice && programDetails && programDetails.name && !programDetails.obj.attributes.showClassName &&
                      <span>
                        {`Program: ${programDetails.name}`}
                      </span>
                    }
                    {invoiceDetails.isDaycareInvoice &&
                      <span>
                        {`Service: Daycare`}
                      </span>
                    }
                  </div>
                </div>
                <hr />
                <div className="m-2">
                  <Row className="d-flex justify-content-between">
                    <Col className="d-flex justify-content-start">
                      <h3 className="font-size-13 font-weight-bold">
                        {props.t("Invoice summary")}
                      </h3>
                    </Col>
                    {showDate && <Col className="d-flex justify-content-end">
                      <p className="font-weight-bold font-size-13">
                        {props.t("Date")}:{invoiceDetails.invoiceDate}
                      </p>
                    </Col>}
                  </Row>
                </div>
              </div>
              <Table className="table-nowrap table-sm ">
                <tbody>
                  {invoiceItems.map((val, key) => (
                    <tr key={key} className="p-5">
                      <div style={{ border: '1px solid black', borderRadius: '5px', marginTop: '0.1cm' }}>
                        <td >
                          <b>{val.title}</b><br />
                          {val.hsn && (
                            <span>
                              <b>HSN:</b> {val.hsn}
                            </span>
                          )}
                          {val.hsn && <br />}
                          <b>Due Date:</b> {val.dueDate}<br />
                          <b>{`Amount : `}</b><span>{currency}</span>{" "}{parseFloat(val.amount).toFixed(2)}<br />
                          {showDiscoutn && taxable && (
                            <span>
                              <b>Discount: </b>
                              {val.discountType === 1 && (
                                <span>
                                  {currency}
                                </span>
                              )}
                              {val.discount}
                              {val.discountType === 2 && "%"}
                            </span>
                          )}
                          {/* {showDiscoutn && !taxable && (
                            <span>
                              {currency}{val.amount - val.totalAmount}
                            </span>
                          )}{showDiscoutn && !taxable && <br />} */}
                          {taxable && (
                            <span>
                              <b>CGST: </b> {currency}{parseFloat(val.taxAmount / 2).toFixed(1)}({(val.tax / 2).toFixed(2)}%)
                            </span>
                          )}{taxable && <br />}
                          {taxable && (
                            <span>
                              <b>SGST: </b>{currency}{parseFloat(val.taxAmount / 2).toFixed(1)}({(val.tax / 2).toFixed(2)}%)
                            </span>
                          )}{taxable && <br />}
                          <span>
                            <b>Total: </b>{currency} {parseFloat(val.totalAmount).toFixed(2)}
                          </span>
                        </td>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div style={{ border: '1px solid black', borderRadius: '5px', marginTop: '0.1cm', textAlign: 'center' }} className="text-center justify-content-center">
                {transactions.map((val, key) => (
                  <div key={key}>
                    <div className="d-flex justify-content-between m-1">
                      {val.type} {val.date}
                      {val.refNumber && (
                        <small>
                          {props.t("Reference Number")} : {val.refNumber}
                        </small>
                      )}
                      {` :`}
                      <div className="text-danger">
                        <span>{currency}</span>{" "}
                        {parseFloat(val.amount).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ))}
                {transactions.length > 0 && <div className="d-flex justify-content-between m-1">
                  <p><b>Paid Amount:</b></p>
                  <div className="">
                    <b>
                      <span>{currency}</span>{" "}
                      {totalAmountCount}
                    </b>
                  </div>
                </div>}
              </div>
              <div style={{ width: '100%', position: 'sticky', bottom: '0cm', backgroundColor: 'white', }}>
                <div className="p-3">
                  <Row>
                    <Col style={{ border: '1px solid black', borderRadius: '5px', marginTop: '0.1cm' }}>
                      <b>Balance Due: </b><br />
                      <span>
                        {currency}
                      </span>{" "}
                      {parseFloat(invoiceBalanceAmount).toFixed(2)}
                    </Col>
                    <Col style={{ border: '1px solid black', borderRadius: '5px', marginTop: '0.1cm' }}>
                      <b>Total: </b><br />
                      <span>
                        {currency}
                      </span>{" "}
                      {parseFloat(invoiceTotal).toFixed(2)}
                    </Col>
                  </Row>
                </div>
                {(paymentMode !== "2") && <div className="d-print-none" >
                  <div className="d-flex justify-content-end">
                    <Label
                      onClick={() => {
                        window.open("https://us-central1-liltriangleweb.cloudfunctions.net/html2pdf?url=https://dashboard.liltriangle.com/invoice/" + invoiceId + "/2");
                      }}
                      className="btn btn-success waves-effect waves-light m-1"
                    >
                      Download <i className="bx bx-download"></i>
                    </Label>
                    {(paymentMode === "1" || paymentMode === "3") && !onlyPrint && invoiceBalanceAmount != 0 && (
                      <Label
                        onClick={() => {
                          if (paymentMode === "3") {
                            setShowNoPG(true)
                          } else {
                            recivePaymentModel();
                          }

                        }}
                        className="btn btn-primary w-md waves-effect waves-light m-1"
                      >
                        Pay Online
                      </Label>
                    )}
                  </div>
                </div>}
              </div>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
      }
    </div>)
  }

  return (
    <React.Fragment>
      <OnlinePatment
        paymentPopup={paymentPopup}
        paymentPopupOps={recivePaymentModel}
        currency={currency}
        invoiceDetails={orgObj}
        disableEdit={disableEdit}
      />

      {invoiceDeleted ? <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">4<i className="bx bx-buoy bx-spin text-primary display-3"></i>4</h1>
                <h4 className="text-uppercase">Invoice no longer exists</h4>

              </div>
            </Col>
          </Row>

        </Container>
      </div> :
        <div>
          {isMobileView ? <div className="m-2">{getMobileUI()}</div> : <div className={paymentMode !== "2" ? "account-pages pt-4" : ""}>
            {paymentMode !== "2" ? <Container fluid='md'>{getUI()}</Container> : getUI()}
          </div>}
        </div>}


      {/* <div className={paymentMode !== "2" ? "account-pages pt-4 " : ""}>
          {isMobileView ? getMobileUI() : paymentMode !== "2" ? <Container fluid='md'>{getUI()}</Container> : getUI()}

          {/* {paymentMode !== "2" ? <Container fluid='md'>{isMobileView ? getMobileUI() : getUI()}</Container> : isMobileView ? getMobileUI() : getUI()} */}
      {/* </div> */}


      {/* {School is not accepting payments through online, please reach out to school to accept payments via online} */}
      {showNoPG && <SweetAlert
        title="Voila...!"
        danger

        confirmBtnBsStyle="danger"
        onConfirm={() => {
          setShowNoPG(false);
        }}

      >
        {props.t(" Reach out to school to accept payments via online")}
      </SweetAlert>}
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  if (state.Invoice.invoice.Invoice) {
    const { invoice } = state.Invoice;
    return { invoice };
  }
};
export default connect(mapStatetoProps, { getInvoiceDetailsByFunction })(
  withRouter(withNamespaces()(PrintInvoice))
);
