import * as actions from "./actionTypes"
import * as ReduxUtil from "../../../ReduxUtil";

const initialState = {
    inventoryCategories: [], loding: undefined,
};

const InventoryCategories = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_INVENTORY_CATEGORIES:
            var inventoryCategories = [...state.inventoryCategories];
            if (inventoryCategories.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.inventoryCategories], action.payload.inventoryCategories, "isDeleted")
                state = { ...state, inventoryCategories: updatedList };
            } else {
                var others = action.payload.inventoryCategories.filter(
                    (inventoryCategories) => inventoryCategories.attributes.isDeleted !== true
                );

                state = { ...state, inventoryCategories: others };
            }

            break;
        case actions.UPDATE_DELETE_INVENTORY_CATEGORY:
            console.log(state.inventoryCategories);
            var inventoryCategories = state.inventoryCategories.filter(
                (inventoryCategory) => inventoryCategory.id !== action.payload.inventoryCategory.id
            );
            console.log(inventoryCategories)
            state = { ...state, inventoryCategories: inventoryCategories };
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
};

export default InventoryCategories;
