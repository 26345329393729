export const UPDATE_INVENTORY_ITEM = "UPDATE_INVENTORY_ITEM";
export const DELETE_INVENTORY_ITEM = "DELETE_INVENTORY_ITEM";

export const UPDATE_INVENTORY_ITEMS = "UPDATE_INVENTORY_ITEMS";   
export const GET_INVENTORY_ITEMS = "GET_INVENTORY_ITEMS";
export const GET_INVENTORY_ITEM = "GET_INVENTORY_ITEM";
//export const GET_INVENTORY_ITEM_FOR_OWNER_ID = "GET_INVENTORY_ITEM_FOR_OWNER_ID"

export const SET_INVENTORY_ITEM_INITIAL_LOADING = "SET_INVENTORY_ITEM_INITIAL_LOADING";
export const UPDATE_INVENTORY_ITEM_INITIAL_LOADING = "UPDATE_INVENTORY_ITEM_INITIAL_LOADING";

export const ADD_NEW_INVENTORY_ITEM = "ADD_NEW_INVENTORY_ITEM"
export const CLEAR_INVENTORY_ITEM = "CLEAR_INVENTORY_ITEM"

export const SET_SELECTED_INVENTORY_ITEM = "SET_SELECTED_INVENTORY_ITEM";
export const UPDATE_SELECTED_INVENTORY_ITEM = "UPDATE_SELECTED_INVENTORY_ITEM";
export const UPDATE_DELETE_INVENTORY_ITEM = "UPDATE_DELETE_INVENTORY_ITEM";
export const GET_INVENTORY_ITEMS_FOR_ITEM_IDS = "GET_INVENTORY_ITEMS_FOR_ITEM_IDS";
export const UPDATE_INVENTORY_ITEMS_FOR_ITEM_IDS = "UPDATE_INVENTORY_ITEMS_FOR_ITEM_IDS";
export const EDIT_INVENTORY_ITEM = "EDIT_INVENTORY_ITEM";
export const GET_INVENTORY_ITEM_MATRIX_FOR_ITEM_ID = "GET_INVENTORY_ITEM_MATRIX_FOR_ITEM_ID";
export const UPDATE_INVENTORY_ITEM_MATRIX_FOR_ITEM_ID = "UPDATE_INVENTORY_ITEM_MATRIX_FOR_ITEM_ID";
export const GET_INVENTORY_ITEM_MATRIX_FOR_ITEM_IDS = "GET_INVENTORY_ITEM_MATRIX_FOR_ITEM_IDS";
export const UPDATE_INVENTORY_ITEM_MATRIX_FOR_ITEM_IDS = "UPDATE_INVENTORY_ITEM_MATRIX_FOR_ITEM_IDS";
export const UPDATE_INVENTORY_SUB_ITEM_FOR_ITEM_IDS = "UPDATE_INVENTORY_SUB_ITEM_FOR_ITEM_IDS";
export const GET_INVENTORY_SUB_ITEM_FOR_ITEM_IDS = "GET_INVENTORY_SUB_ITEM_FOR_ITEM_IDS";
export const UPDATE_INVENTORY_ITEM_CATEGORY_PRICE_FOR_ITEM_IDS = "UPDATE_INVENTORY_ITEM_CATEGORY_PRICE_FOR_ITEM_IDS";
export const GET_INVENTORY_ITEM_CATEGORY_PRICE_FOR_ITEM_IDS = "GET_INVENTORY_ITEM_CATEGORY_PRICE_FOR_ITEM_IDS";