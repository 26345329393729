import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Moment from "moment";
import * as Constants from "../../Constents";
import {
  getMoneyTransactionForInvoiceId,
  verifyTriansactionById,
  getTeachersForSchool,
  deleteTriansactionById,
  recheckTransactionState,
} from "../../store/actions";
const ViewTriansaction = (props) => {
  const [togUp, togUpOps] = useState(false);
  const [transactions, transactionsOps] = useState([]);
  const [teacherDec, teacherDecOps] = useState([]);
  const [reload, setReload] = useState(false);
  const schoolId = props.schoolId;
  useEffect(() => {
    togUpOps(props.togTriansaction);
  }, [props.togTriansaction]);
  useEffect(() => {
    props.getMoneyTransactionForInvoiceId(props.activeInvoiceId);
  }, [props.activeInvoiceId]);
  useEffect(() => {
    props.getTeachersForSchool(schoolId);
  }, [schoolId]);

  useEffect(() => {
    let deletedList = [];
    let tempT = [];
    for (let val of props.moneyTransactions) {
      let temp = {};
      temp.id = val.id;
      temp.Obj = val;
      temp.type = val.attributes.transactionType;
      if (temp.type === "AirPay" || temp.type === "RazorPay" || temp.type === "OnePay") {
        temp.canVerify = false;
      } else {
        temp.canVerify = true;
      }
      temp.amount = val.attributes.amount;
      temp.createdAt = val.attributes.createdAt;
      temp.paidDate = Moment(
        val.attributes.paidDate || val.attributes.createdAt
      ).format("DD-MMM-YYYY");
      temp.remark = val.attributes.transactionStatus
        ? val.attributes.transactionStatus
        : "--";
      temp.statusValue = val.attributes.status ? val.attributes.status : 0;
      if (val.attributes.transactionType === "AirPay"|| val.attributes.transactionType === "RazorPay" || val.attributes.transactionType === "OnePay") {
        temp.status = "Verified";
        temp.statusCss = "text-success";
      } else {
        temp.status = val.attributes.status
          ? val.attributes.status === 1
            ? "Outstanding"
            : val.attributes.status === 2
              ? "Verified"
              : "Success"
          : "Success";
        temp.statusCss = val.attributes.status
          ? val.attributes.status === 1
            ? "text-warning"
            : val.attributes.status === 2
              ? "text-success"
              : "text-warning"
          : "text-warning";
      }
      temp.receivedBy = val.attributes.receivedBy
        ? teacherDec[val.attributes.receivedBy]
          ? teacherDec[val.attributes.receivedBy].attributes.Name
          : ""
        : "";
      temp.depositedBy = val.attributes.depositedBy
        ? teacherDec[val.attributes.depositedBy]
          ? teacherDec[val.attributes.depositedBy].attributes.Name
          : ""
        : "";
      temp.depositedDate = val.attributes.depositedDate
        ? val.attributes.depositedDate
        : "--";
      temp.verifiedBy = val.attributes.verifiedBy
        ? teacherDec[val.attributes.verifiedBy]
          ? teacherDec[val.attributes.verifiedBy].attributes.Name
          : ""
        : "";
      if (temp.verifiedBy) {
        temp.verifiedDate = val.attributes.updatedAt
          ? Moment(val.attributes.updatedAt).format("DD-MMM-YYYY")
          : "";
      }
      if (val.attributes.deleted) {
        temp.deleted = true;
        temp.deletedCss = val.attributes.deleted
          ? { textDecoration: "line-through" }
          : "";
        temp.deletedCss2 = val.attributes.deleted
          ? { textDecoration: "line-through" ,width: "15%",}
          : { width: "15%",};
        temp.canceledBy = val.attributes.canceledBy
          ? teacherDec[val.attributes.canceledBy]
            ? teacherDec[val.attributes.canceledBy].attributes.Name
            : ""
          : "";
        temp.canceledReason = val.attributes.canceledReason;
        temp.canceledDate = Moment(val.attributes.canceledDate).format(
          "DD-MMM-YYYY"
        );
        temp.status = "Cancelled";
        temp.statusCss = "text-danger";
        if (temp.type === "OnePay") {
          temp.showCheckStatus = true;
        }
      }
      temp.description = "";
      temp.isLoaderForVerify = false;
      temp.isLoaderForCancel = false;
      if (temp.type === "Cheque") {
        temp.description += val.attributes.chequeDate
          ? Moment(val.attributes.chequeDate) + "\n"
          : "";
        temp.description += val.attributes.chequeNo
          ? val.attributes.chequeNo + "\n"
          : "";
        temp.description += val.attributes.micrCode
          ? val.attributes.micrCode + "\n"
          : "";
        temp.description += val.attributes.depositReference
          ? val.attributes.depositReference + "\n"
          : "";
      } else if (temp.type === "Online") {
        temp.description += val.attributes.transactionId
          ? val.attributes.transactionId
          : "";
      }
      if (temp.deleted) {
        deletedList.push(temp);
      } else {
        tempT.push(temp);
      }
    }
    transactionsOps(deletedList.length > 0 ? tempT.concat(deletedList) : tempT);
  }, [props.moneyTransactions]);
  useEffect(() => {
    let temp = [];
    for (let val of props.teachers) {
      temp[val.id] = val;
    }
    teacherDecOps(temp);
  }, [props.teachers]);
  const enableLoader = (id, btn) => {
    let tempObjs = Object.assign([], transactions);
    for (let val of tempObjs) {
      if (val.id === id) {
        if (btn === 1) {
          val.isLoaderForCancel = true;
        } else {
          val.isLoaderForVerify = true;
        }
        break;
      }
    }
    transactionsOps(tempObjs);
  };
  return (
    <Modal
      isOpen={togUp}
      toggle={() => {
        props.tog_triansaction(reload);
      }}
      size="xl"
      scrollable={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {props.t("Invoice Number")} : {props.invoice.invoiceNumber}
        </h5>
        <button
          type="button"
          onClick={() => {
            props.tog_triansaction(reload);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
         <div className="table-responsive">
          <table className="table table-centered  mb-0">
            <thead className="thead-light">
              <tr>
                <th style={{ width: "15%" }}  >{props.t("Type")}</th>
                <th>{props.t("Received")}</th>
                <th>{props.t("Date")}</th>
                <th>{props.t("Status")}</th>
                <th style={{ width: "20%" }} >{props.t("Remark")}</th>
                <th>{props.t("Details")}</th>
                {props.teacherPaymenrWrite && props.paymentAdmin && (
                  <th>{props.t("Action")}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {transactions.length === 0 && (
                <tr>
                  <td colSpan="7" className="text-center">
                    <h3 className="mt-2 text-primary">
                      {props.t("No Transaction Found")}
                    </h3>
                  </td>
                </tr>
              )}
              {transactions.length > 0 &&
                transactions.map((val, key) => (
                  <tr key={key}>
                    <td style={val.deletedCss2}>
                      {val.type}
                      <br />
                      <span >
                        {val.description}
                      </span>
                      {val.type == 'AirPay' && <span>
                        {'orderId :' + val.id}
                      </span>}
                       {val.type == 'RazorPay' && <span>
                        {'orderId :' + val.Obj.attributes.razorpayId}
                      </span>}
                       {val.type == 'OnePay' && <span>
                        {'orderId :' + val.id}
                      </span>}
                    </td>
                    <td style={val.deletedCss}>
                      <span style={{ fontFamily: "Arial, sans-serif" }}>
                        {props.currency}
                      </span>{" "}
                      {parseFloat(val.amount).toFixed(2)}
                    </td>
                    <td style={val.deletedCss}>{Moment(val.createdAt).format("DD-MMM-YYYY")}</td>
                    <td>
                      <span className={val.statusCss}>{val.status}</span>
                    </td>
                    <td style={{ width: "20%" }} >{val.remark}</td>
                    <td>
                      {val.receivedBy && (
                        <div>
                          {props.t("Received By")}: <br />
                          <span className="text-secondary">
                            {val.receivedBy} ({val.paidDate})
                          </span>
                          <br />
                        </div>
                      )}
                      {val.verifiedBy && (
                        <div>
                          {props.t("Verified By")}: <br />
                          <span className="text-secondary">
                            {val.verifiedBy} ({val.verifiedDate})
                          </span>
                        </div>
                      )}
                    </td>
                    {val.canVerify &&
                      props.teacherPaymenrWrite &&
                      props.paymentAdmin ? (
                      <td>
                        {!val.deleted && (
                          <button
                            type="button "
                            onClick={() => {
                              enableLoader(val.id, 1);
                              props.deleteTriansactionById(val.id);
                            }}
                            className="btn btn-danger waves-effect btn-sm"
                          >
                            {val.isLoaderForCancel && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2 mb-2"></i>
                            )}
                            {props.t("Cancel")}
                          </button>
                        )}
                        {!val.deleted && val.statusValue !== 2 && (
                          <button
                            type="button"
                            onClick={() => {
                              setReload(true);
                              enableLoader(val.id, 2);
                              props.verifyTriansactionById(val.id);
                            }}
                            className="btn btn-success waves-effect btn-sm  m-2"
                          >
                            {val.isLoaderForVerify && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                            )}
                            {props.t("Verify")}
                          </button>
                        )}
                      </td>
                    ) : <td>
                         {
                            val.deleted && val.showCheckStatus && (
                              <button
                                type="button"
                                onClick={() => {
                                  props.recheckTransactionState(val.id)
                                }}
                                className="btn btn-success btn-sm  m-2"
                              >
                                {props.t("Check status")}
                              </button>
                            )
                          }
                    
                    </td>}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_triansaction(reload);
          }}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          {props.t("Close")}
        </button>
      </div>
    </Modal>
  );
};
const mapStatetoProps = (state) => {
  const { moneyTransactions } = state.MoneyTransactions;
  const { teachers } = state.Teacher;
  return { moneyTransactions, teachers };
};

export default connect(mapStatetoProps, {
  getMoneyTransactionForInvoiceId,
  verifyTriansactionById,
  getTeachersForSchool,
  deleteTriansactionById,
  recheckTransactionState,
})(withRouter(withNamespaces()(ViewTriansaction)));
