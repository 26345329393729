import React, { useEffect, useState } from 'react';
import {
    Container, Row, Form,
    Input,
    FormGroup, TabContent, TabPane, Card, CardText, Nav, NavItem, NavLink, CardBody, Label, CardHeader, Button, Col, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip
} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { matchPath } from "react-router";
import Parse from 'parse';
import toastr from 'toastr';
import TopBar from './Topbar';
import SweetAlert from "react-bootstrap-sweetalert";

import { setSelecetdSchool } from '../../store/actions';

const ExpenseCategory = (props) => {


    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [expenseCategoryList, setExpenseCategoryList] = useState([]);
    const [newTitle, setNewTitle] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [deleteParseObject, setDeleteParseObject] = useState(undefined);
    const [editParseObject, setEditParseObject] = useState(undefined);
    const [categoryTitleError, setCategoryTitleError] = useState(false);
    const [forInstitute, setForInstitute] = useState(undefined);

    useEffect(() => {
        if (props.expenseCategoryList && props.expenseCategoryList.length > 0) {
            setExpenseCategoryList(props.expenseCategoryList)
        }
    }, [props.expenseCategoryList])

    const list = [{
        name: "ok",
        description: "done"
    },
    {
        name: "water",
        description: "water supply"
    },
    {
        name: "Transport",
        description: "Transport charges"
    },
    {
        name: "Refund",
        description: "Parent refund"
    },
    {
        name: "laptop and cables	",
        description: "laptop stuff"
    },]

    const resetValues = () => {
        setNewTitle("")
        setNewDescription("")

    }

    const handleOnClickDelete = (parseObject) => {
        if (parseObject) {
            parseObject.set("isDeleted", true)
            props.editExpenseCategory(parseObject);
            toastr.success("Category removed successfully")
            setDeleteParseObject(undefined)
        }
    }

    const fillValues = (parseObject) => {
        if (parseObject) {
            let obj = parseObject.attributes

            setNewTitle(obj.name ?? "")
            setNewDescription(obj.description ?? "")

        }
    }

    const handleOnClickAddNewCategory = () => {

        if (newTitle == undefined || newTitle === "") {
            setCategoryTitleError(true)
            return
        }

        let ExpenseCategory = Parse.Object.extend("ExpenseCategory");
        let expenseCategory = new ExpenseCategory();
        if (editParseObject) {
            expenseCategory = editParseObject
        }else{
            expenseCategory.set("instituteId", props.selectedSchool.attributes.instituteId);
            if(forInstitute){
                expenseCategory.set("schoolId", props.selectedSchool.id);
            }
        }
        expenseCategory.set("name", newTitle);
        expenseCategory.set("description", newDescription);
        

        props.toggleCategoryModal(false)
        resetValues()
        setEditParseObject(undefined);
        props.editExpenseCategory(expenseCategory);
        toastr.success("Category added successfully")
    }

    const toggleModal = () => {
        if (props.showModal) {
            setEditParseObject(undefined)
        } else {
            resetValues()
        }
        props.toggleCategoryModal(!props.showModal)
    }

    const getEditPermission = (item,type) => {
        if (item.isDefault && type!="add") {
            return false
        }

        if (item.expenseCategoryParseObject.attributes.schoolId) { 
            return true
        }
        if (item.expenseCategoryParseObject.attributes.instituteId) {
            if (props.userTeacher.attributes.Role > 2) {
                return true
            } else {
                return false
            }
        }

        return true
    }

    return (

        <React.Fragment>
            {deleteParseObject && (

                <SweetAlert
                    title={props.t('Are you sure?')}
                    warning
                    showCancel
                    confirmBtnText="Remove"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        handleOnClickDelete(deleteParseObject)

                    }}
                    onCancel={() => {
                        setDeleteParseObject(undefined);
                    }}>
                    {props.t("You won't be able to revert this!")}

                </SweetAlert>

            )}
            <Card>


                <CardBody>
                    <Row className='justify-content-between'>
                        <Col lg={10}>
                            <h4 className='m-2'>
                                {props.t("Category")}
                            </h4>
                        </Col>
                        <Col className='Justify-content-end' lg={2}>
                            {props.writePermission && <Button
                                type="button"
                                color="success"
                                className="btn waves-effect waves-light m-2"
                                onClick={() => {
                                    resetValues()
                                    props.toggleCategoryModal(true)
                                }}
                            >
                                {props.t('New Category')}
                            </Button>}
                        </Col>
                    </Row>

                    {expenseCategoryList && expenseCategoryList.length > 0 && <div className="table-responsive m-3">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th style={{ width: "1%" }}>
                                        #
                                    </th>
                                    <th>
                                        {props.t("Name")}
                                    </th>
                                    <th>
                                        {props.t("Description")}
                                    </th>
                                    {props.writePermission && <th style={{ textAlign: "center" }}>Actions</th>}


                                </tr>
                            </thead>
                            <tbody>
                                {
                                    expenseCategoryList.map((item, key) => (
                                        <tr key={key}>
                                            <td>
                                                <Label><small style={{ fontSize: "14px" }}>{key + 1} </small></Label>
                                            </td>
                                            <td>
                                                <h5 className='font-size-14 text-dark'>{item.name}</h5>
                                            </td>
                                            <td>
                                                <Label>{item.description}</Label>
                                            </td>
                                            {props.writePermission && <td style={{ textAlign: "center" }}>
                                                <ul className="list-inline font-size-18 contact-links mb-0">

                                                    {getEditPermission(item) && <li className="list-inline-item pl-1 m-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setEditParseObject(item.expenseCategoryParseObject)
                                                            fillValues(item.expenseCategoryParseObject)

                                                            props.toggleCategoryModal(true)
                                                        }}
                                                        id={"expense-category-edit" + key}>
                                                        <i className="bx bx-edit-alt text-dark"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-category-edit" + key}>
                                                            {props.t('Edit')}
                                                        </UncontrolledTooltip>

                                                    </li>}
                                                    { getEditPermission(item) && <li className="list-inline-item m-1 pl-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setDeleteParseObject(item.expenseCategoryParseObject)
                                                        }}
                                                        id={"expense-category-delete" + key}>
                                                        <i className="bx bx-trash text-danger"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-category-delete" + key}>
                                                            {props.t('Delete')}
                                                        </UncontrolledTooltip>

                                                    </li>}
                                                    
                                                    {getEditPermission(item,"add")&& true &&<li className="list-inline-item m-1 pl-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            props.addExpense(item.expenseCategoryParseObject)
                                                        }}
                                                        id={"expense-category-add" + key}>
                                                        <i className="bx bx-plus-circle text-primary"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-category-add" + key}>
                                                            {props.t('Add Expence')}
                                                        </UncontrolledTooltip>

                                                    </li>}
                                                </ul>
                                            </td>}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>}
                </CardBody>
            </Card>
            {
                expenseCategoryList && expenseCategoryList.length === 0 &&
                <Card className="mt-3">
                    <CardBody>
                        <center ><Label className='text-center' style={{ textAlign: "center" }}>No Categories to Show !!</Label></center>
                    </CardBody>
                </Card>
            }




            <Modal isOpen={props.showModal} toggle={() => {

                toggleModal()
            }} backdrop={true}>
                <ModalHeader toggle={() => {
                    toggleModal()
                }}>
                    <h5 className="modal-title mt-0">{editParseObject ? props.t("Edit Category") : props.t("Add Category")}</h5>
                </ModalHeader>
                <ModalBody className="modal-body">

                    <Row>
                        <Col lg={12}>
                            <Form onSubmit={() => { }}>
                                <Row className="mt-1">
                                    <Col md="12">
                                        <FormGroup className="">
                                            <Label>Title</Label>
                                            <Label

                                                className="text-danger ml-1"
                                            >
                                                *
                                            </Label>

                                            {categoryTitleError && <Label className="label-xsmall text-danger ml-2">
                                                (this field is mandatory)
                                            </Label>
                                            }
                                            <Input
                                                name="title"
                                                id="title"
                                                type="text"
                                                className="form-control"
                                                placeholder="Title"
                                                value={newTitle}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setCategoryTitleError(false)
                                                    } else {
                                                        setCategoryTitleError(true)
                                                    }
                                                    setNewTitle(e.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup className="position-relative">
                                            <Label>Description</Label>



                                            <FormGroup className="position-relative mt-2">
                                                <textarea
                                                    name="description"
                                                    id="description"
                                                    rows="3"
                                                    className="form-control"
                                                    placeholder="Descripstion"
                                                    value={newDescription}
                                                    onChange={(e) => { setNewDescription(e.target.value) }}
                                                ></textarea>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        {props.userTeacher && props.userTeacher.attributes.Role == 3 && !editParseObject && <FormGroup>
                                            <div className="custom-control custom-checkbox m-1" >
                                                <Input type="checkbox" className="custom-control-input m-1" id="instituteCheck" onChange={() => { setForInstitute(!forInstitute); }} 
                                                checked={forInstitute} 
                                                />
                                                <label className="custom-control-label m-1" onClick={() => { setForInstitute(!forInstitute); }} >{props.t('Apply this Category to all schools')}</label>
                                            </div>
                                        </FormGroup>}
                                    </Col>

                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color='primary'
                        onClick={() => {
                            handleOnClickAddNewCategory()
                        }}
                        disabled={props.isLoadingExpenseCategory}
                    >
                        {props.isLoadingExpenseCategory && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}

                        {editParseObject ? props.t("Save") : "Add Category"}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { isLoadingExpenseCategory } = state.ExpenseCategory;
    const { userTeacher } = state.Login;
    return { selectedSchool, isLoadingExpenseCategory, userTeacher };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool })(ExpenseCategory)));
