import React, { useState, useEffect } from 'react'
import { Card, CardBody, FormGroup, Row, Col, Label, Input, Table, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import ProductDeatails from "./../E-commerce/ProductDetails"
import { setSelecetdSchool, getInventoryItemForOwnerId, getAllKids, getTeachersForSchool, getTeacher, setSelectedSaleOrder, setSelectedSaleOrderItems, getInventoryWarehouseForOwnerId, getInventoryKitsForOwnerId } from '../../../store/actions';

const newSaleOrder = (props) => {

    const STUDENT = "Student"
    const STAFF = "Staff"
    const SCHOOL = "School"

    const matchAdd = matchPath(props.history.location.pathname, {
        path: "/new-sale/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatchAdd = matchPath(props.history.location.pathname, {
        path: "/franchise-new-sale/:franchiseId",
        exact: true,
        strict: false,
    });

    const matchEditItem = matchPath(props.history.location.pathname, {
        path: "/inventory-sale-order-details/:schoolId/:saleOrderId",
        exact: true,
        strict: false,
    });

    const franchiseMatchEditItem = matchPath(props.history.location.pathname, {
        path: "/franchise-sale-order-details/:franchiseId/:saleOrderId",
        exact: true,
        strict: false,
    });

    const match = matchEditItem ?? franchiseMatchEditItem;
    const saleOrderId = match && match.params.saleOrderId;

    const match1 = matchAdd ?? matchEditItem
    const schoolId = match1 && match1.params.schoolId;

    const match2 = franchiseMatchAdd ?? franchiseMatchEditItem;
    const franchiseId = match2 && match2.params.franchiseId;



    const [tableData, setTableData] = useState((franchiseMatchAdd || matchAdd) ? [{
        itemId: uuidv4(),
        item: undefined,
        units: undefined,
        cost: 0,
        quantity: 1,
        itemTotal: 0,
        dropdownItem: undefined,
    }
    ] : []);
    const [itemList, setItemList] = useState([]);
    const [kidList, setKidList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [selectedBuyerType, setSelectedBuyerType] = useState({
        label: "",
        value: ""
    });
    const [selectedBuyer, setSelectedBuyer] = useState();
    const [date, setDate] = useState(new Date());
    const [selectedBuyerTypeError, setSelectedBuyerTypeError] = useState(false);
    const [kidListError, setKidListError] = useState(false);
    const [schoolListError, setSchoolListError] = useState(false);
    const [staffListError, setStaffListError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [isDisabled, setIsDesabled] = useState(false);
    const [approveAlert, setApproveAlert] = useState(false);
    const [kitCost, setKitCost] = useState(0);

    const [schoolFranchiseId, setSchoolFranchiseId] = useState()
    const [showSavingLoader, setShowSavingLoader] = useState(false)
    const [showProductDetails, setShowProductDetails] = useState(false)
    const [showDevlivaryCharge, setShowDevlivaryCharge] = useState(false)
    const [saleOrderItemobject, setSaleOrderItemobject] = useState()
    const [saleOrderInvoice, setSaleOrderInvoice] = useState();
    const [devlivaryChargeInvoiceItem, setDevlivaryChargeInvoiceItem] = useState();
    const [devlivaryCharges, setDevlivaryCharges] = useState();
    const [categoryIdOfSchool, setCategoryIdOfSchool] = useState();


    const BuyerType = [{ value: STAFF, label: STAFF }, { value: STUDENT, label: STUDENT },]

    console.log("print abc")
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        setSchoolFranchiseId(props.selectedSchool && props.selectedSchool.attributes.franchiseId);
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (franchiseMatchAdd) {
            setIsDesabled(false);
        }
    }, [franchiseMatchAdd]);

    useEffect(() => {
        if (schoolId) {
            // props.getTeachersForSchool(schoolId);
            // props.getAllKids(schoolId);
            // props.getInventoryItemForOwnerId(schoolId, 'school')
            // props.getInventoryWarehouseForOwnerId(schoolId, 'school')
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryItemForOwnerId(franchiseId, 'franchise')
            props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise')
            props.getInventoryKitsForOwnerId(franchiseId, 'franchise')
        }
    }, [franchiseId]);

    useEffect(() => {
        if (schoolFranchiseId) {
            props.getInventoryItemForOwnerId(schoolFranchiseId, 'franchise')
            props.getInventoryWarehouseForOwnerId(schoolFranchiseId, 'franchise')
            props.getInventoryKitsForOwnerId(schoolFranchiseId, 'franchise')
        }
    }, [schoolFranchiseId]);

    useEffect(() => {
        if (props.schools && props.schools.length > 0) {
            let tempSelectList = []
            for (const school of props.schools) {
                tempSelectList.push(getSelectionSchool(school))
            }
            setSchoolList(tempSelectList)
        }
    }, [props.schools])


    useEffect(() => {
        let Invoice = Parse.Object.extend("Invoice");
        let query = new Parse.Query(Invoice);

        query.equalTo("objectId", props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.invoiceId);

        query.first().then(
            (invoice) => {
                setSaleOrderInvoice(invoice)
                console.log("Invoice:", invoice);
                if (invoice) {
                    let InvoiceItems = Parse.Object.extend("InvoiceItems");
                    let query1 = new Parse.Query(InvoiceItems);
                    query1.equalTo("invoiceId", invoice.id);
                    query1.equalTo("productId", "delivery_charges");
                    query1.notEqualTo("deleted", true);

                    query1.first().then(
                        (invoiceItems) => {
                            setDevlivaryChargeInvoiceItem(invoiceItems)
                            // console.log("invoiceItems:", invoiceItems)
                        },
                        (error) => {
                            console.log("Error finding invoice items:", error);
                        }
                    );
                }
            },
            (error) => {
                console.log("Error finding invoice:", error);
            }
        );
    }, [])

    const getSelectionSchool = (school) => {
        let tempSelectObj = {}
        let obj = school.attributes
        tempSelectObj.id = school.id
        tempSelectObj.name = obj.Name ?? ""
        tempSelectObj.value = school.id
        tempSelectObj.label = obj.Name ?? ""
        tempSelectObj.parseObject = school
        return tempSelectObj;
    }

    useEffect(() => {
        if (props.kids && props.kids.length > 0) {
            let tempSelectList = []
            for (const kid of props.kids) {
                tempSelectList.push(getSelectionKid(kid))
            }
            setKidList(tempSelectList)
        }
    }, [props.kids])

    const getSelectionKid = (kid) => {
        let tempSelectObj = {}
        let obj = kid.attributes
        tempSelectObj.id = kid.id
        tempSelectObj.name = obj.Name ?? ""
        tempSelectObj.value = kid.id
        tempSelectObj.label = obj.Name ?? ""
        tempSelectObj.parseObject = kid
        return tempSelectObj;
    }

    useEffect(() => {
        if (props.teachers && props.teachers.length > 0) {
            let tempSelectList = []
            for (const staff of props.teachers) {
                tempSelectList.push(getSelectedStaff(staff))
            }
            setStaffList(tempSelectList)
        }
    }, [props.teachers])

    let getSelectedStaff = (staff) => {
        let tempSelectObj = {}
        let obj = staff.attributes
        tempSelectObj.id = staff.id
        tempSelectObj.name = obj.Name ?? ""
        tempSelectObj.value = staff.id
        tempSelectObj.label = obj.Name ?? ""
        tempSelectObj.parseObject = staff
        return tempSelectObj;
    }

    useEffect(() => {
        if (props.inventoryItems && props.inventoryItems.length > 0) {
            let tempSelectList = []
            for (const i in props.inventoryItems) {
                let tempSelectObj = {}
                let obj = props.inventoryItems[i].attributes
                tempSelectObj.id = props.inventoryItems[i].id
                tempSelectObj.name = obj.name ?? ""
                tempSelectObj.value = props.inventoryItems[i].id
                tempSelectObj.label = obj.name ?? ""
                tempSelectObj.parseObject = props.inventoryItems[i]
                tempSelectList.push(tempSelectObj)
            }
            setItemList(tempSelectList)
        }
    }, [props.inventoryItems])

    // useEffect(() => {
    //     if (props.inventoryItems && props.inventoryItems.length > 0) {
    //         let tempSelectList = []
    //         for (const i of props.inventoryItems) {
    //             tempSelectList.push(getSelectionObject(i))
    //         }
    //         setItemList(tempSelectList)
    //     }
    // }, [props.inventoryItems])

    useEffect(() => {
        if (props.inventoryKits && props.inventoryKits.length > 0) {
            let tempSelectList = []
            for (const i in props.inventoryKits) {
                let tempSelectObj = {}
                let obj = props.inventoryKits[i].attributes
                tempSelectObj.id = props.inventoryKits[i].id
                tempSelectObj.name = obj.name ?? ""
                tempSelectObj.value = props.inventoryKits[i].id
                tempSelectObj.label = obj.name ?? ""
                tempSelectObj.parseObject = props.inventoryKits[i]
                tempSelectList.push(tempSelectObj)
            }
            setItemList(tempSelectList)
        }
    }, [props.inventoryKits])

    const getSelectionObject = (inventoryItem) => {
        let tempSelectObj = {}
        let obj = inventoryItem.attributes
        tempSelectObj.id = inventoryItem.id
        tempSelectObj.name = obj.name ?? ""
        tempSelectObj.value = inventoryItem.id
        tempSelectObj.label = obj.name ?? ""
        tempSelectObj.unit = obj.unit ?? "";
        tempSelectObj.type = "item";
        tempSelectObj.parseObject = inventoryItem
        return tempSelectObj
    }

    useEffect(() => {
        if (saleOrderId) {
            props.setSelectedSaleOrder(saleOrderId);
            props.setSelectedSaleOrderItems(saleOrderId);
        }
    }, [saleOrderId]);




    function handleNewItem() {
        let items = [...tableData]
        let newItem = {
            itemId: uuidv4(),
            item: undefined,
            itemError: false,
            units: undefined,
            cost: 0,
            quantity: 1,
            itemTotal: 0,
            quantityError: false,
            parseObject: undefined,

        }
        items.push(newItem)
        setTableData(items)
    }


    const handleRemoveItem = (item) => {
        let newItems = tableData.filter((k) => k.itemId !== item.itemId);
        setTableData(newItems);
    }

    function formatDate(d) {
        if (d === "") {
            return ""
        } else {
            return Moment(d).format("YYYY-MM-DD");
        }
    }

    const handleApproveOrder = () => {
        // Parse.Cloud.run("function_AprovePurchaseOrder", {
        //     purchaseOrderId: purchaseOrderId
        // }).then((response) => {
        //     console.log('response', response)
        //     if (response.state == 1) {
        //         setIsDesabled(true);
        //         setApproveAlert(false)
        //     } else {

        //     }
        // }, (error) => {
        //     console.log("error" + error.message)
        // });
    }

    const handelSave = () => {
        let canSave = true;

        if (tableData.length == 0) {
            setNoItemError(true)
            canSave = false;
        }

        let newList = []
        for (let i of tableData) {
            if (!i.item) {
                i.itemError = true;
                canSave = false
            }

            if (!i.quantity || i.quantity < 0) {
                i.quantityError = true;
                canSave = false
            }
            newList.push(i)
        }
        setTableData(newList);


        // if (!selectedBuyerType.value) {
        //     setSelectedBuyerTypeError(true);
        //     canSave = false;
        // }

        // if (selectedBuyerType.value == STUDENT && !selectedBuyer) {
        //     setKidListError(true);
        //     canSave = false;
        // }

        // if (selectedBuyerType.value == STAFF && !selectedBuyer) {
        //     setStaffListError(true);
        //     canSave = false;
        // }


        // if (!selectedBuyer) {
        //     setSchoolListError(true);
        //     canSave = false;
        // }

        if (!date) {
            setDateError(true);
            canSave = false;
        }

        if (!canSave) {
            return
        }

        setShowSavingLoader(true)

        const InventorySaleOrders = Parse.Object.extend("InventorySaleOrders");
        let saleOrder = new InventorySaleOrders();
        if (matchEditItem || franchiseMatchEditItem) {
            saleOrder.id = props.selectedInventorySaleOrder.id
        } else {
            if (franchiseId) {
                saleOrder.set("ownerType", 'franchise');
                saleOrder.set("ownerId", franchiseId);
            } else {
                saleOrder.set("ownerType", 'school');
                saleOrder.set("ownerId", schoolId);
            }
            saleOrder.set('createdBy', Parse.User.current().attributes.teacherId);
            if (date) {
                saleOrder.set("createdOn", new Date(date));
            } else {
                saleOrder.set("createdOn", new Date());
            }
            saleOrder.set('status', 0);
        }
        if (!saleOrder.attributes.warehouseId && props.inventoryWarehouses && props.inventoryWarehouses.length > 0) {
            saleOrder.set("warehouseId", props.inventoryWarehouses[0].id);
        }
        saleOrder.set("orderValue", kitCost);
        saleOrder.set("buyerType", selectedBuyerType.value);
        saleOrder.set("buyerId", selectedBuyer.value);
        saleOrder.save().then(
            (result) => {
                setShowSavingLoader(false)
                result.pin()
                // console.log(result)
                if ((matchEditItem || franchiseMatchEditItem)) {
                    let itemObjects = [];
                    let exItems = [];
                    for (let i = 0; i < tableData.length; i++) {
                        const InventorySaleOrderItems = Parse.Object.extend("InventorySaleOrderItems");
                        let saleOrderItem = new InventorySaleOrderItems();
                        if (tableData[i].parseObject) {
                            saleOrderItem.id = tableData[i].parseObject.id
                            exItems.push(tableData[i].parseObject.id);
                        } else {
                            saleOrderItem.set("saleOrderId", result.id);
                            if (schoolId) {
                                saleOrderItem.set("ownerType", 'school');
                                saleOrderItem.set("ownerId", schoolId);
                            } else {
                                saleOrderItem.set("ownerType", 'franchise');
                                saleOrderItem.set("ownerId", franchiseId);
                            }
                        }
                        saleOrderItem.set("itemId", tableData[i].item.id);
                        saleOrderItem.set("itemName", tableData[i].item.name);
                        saleOrderItem.set("quantity", parseInt(tableData[i].quantity + ""));
                        saleOrderItem.set("unit", tableData[i].units);
                        saleOrderItem.set("order", i);
                        itemObjects.push(saleOrderItem);
                    }
                    let deletedItems = [];
                    for (const si of props.selectedInventorySaleOrderItems) {
                        if (!exItems.includes(si.id)) {
                            si.set("isDeleted", true);
                            si.set("deletedBy", Parse.User.current().attributes.teacherId);
                            deletedItems.push(si)
                        }
                    }
                    Parse.Object.saveAll(itemObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        if (deletedItems.length > 0) {
                            Parse.Object.saveAll(deletedItems).then((dResults) => {
                                setShowSavingLoader(false)
                                Parse.Object.unPinAll(dResults);
                                // console.log(dResults);
                                if (matchEditItem || franchiseMatchEditItem) {

                                } else {
                                    window.history.back();
                                }
                            });
                        } else {
                            // console.log(results);
                            if (matchEditItem || franchiseMatchEditItem) {

                            } else {
                                window.history.back();
                            }
                        }
                    });
                } else {
                    let itemObjects = [];
                    for (let i = 0; i < tableData.length; i++) {
                        const InventorySaleOrderItems = Parse.Object.extend("InventorySaleOrderItems");
                        let saleOrderItem = new InventorySaleOrderItems();

                        saleOrderItem.set("saleOrderId", result.id);
                        if (schoolId) {
                            saleOrderItem.set("ownerType", 'school');
                            saleOrderItem.set("ownerId", schoolId);
                        } else {
                            saleOrderItem.set("ownerType", 'franchise');
                            saleOrderItem.set("ownerId", franchiseId);
                        }
                        saleOrderItem.set("itemId", tableData[i].item.id);
                        saleOrderItem.set("itemName", tableData[i].item.name);
                        saleOrderItem.set("quantity", parseInt(tableData[i].quantity + ""));
                        saleOrderItem.set("unit", tableData[i].units);
                        saleOrderItem.set("unitCost", parseInt(tableData[i].cost));
                        saleOrderItem.set("totalCost", parseInt(tableData[i].itemTotal));
                        saleOrderItem.set("order", i);
                        itemObjects.push(saleOrderItem);
                        // console.log(saleOrderItem);
                    }
                    Parse.Object.saveAll(itemObjects).then((results) => {
                        setShowSavingLoader(false)
                        Parse.Object.pinAll(results);
                        if (matchEditItem || franchiseMatchEditItem) {

                        } else {
                            window.history.back();
                        }
                        // console.log(results);
                    });
                }

            },
            (error) => {
                // console.log(error);
            }
        );
    }

    useEffect(() => {
        console.log("Sale order", props.selectedInventorySaleOrder);
        if (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.id == saleOrderId && match) {
            setSelectedBuyerType({
                value: props.selectedInventorySaleOrder.attributes.buyerType,
                label: props.selectedInventorySaleOrder.attributes.buyerType
            })
            if (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.buyerType == STUDENT) {
                let buyer = getSelectedKidFromId(props.selectedInventorySaleOrder.attributes.buyerId);
                setSelectedBuyer(buyer)
            } else if (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.buyerType == STAFF) {
                let buyer = getSelectedStaffFromId(props.selectedInventorySaleOrder.attributes.buyerId);
                setSelectedBuyer(buyer)
            } else if (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.buyerType == SCHOOL) {
                let buyer = getSelectedSchoolFromId(props.selectedInventorySaleOrder.attributes.buyerId);
                setSelectedBuyer(buyer);
            }

            if (props.selectedInventorySaleOrder.attributes.status > 0) {
                setIsDesabled(true);
            } else if (!props.selectedInventorySaleOrder.attributes.isSystemGenerated
                && franchiseMatchEditItem
            ) {
                setIsDesabled(false);
            }

            setDate(formatDate(props.selectedInventorySaleOrder.attributes.createdOn));
            setDevlivaryCharges(props.selectedInventorySaleOrder.attributes.freightCharges)
        }
    }, [props.selectedInventorySaleOrder, props.schools]);

    useEffect(() => {
        console.log("sale order items", props.selectedInventorySaleOrderItems);

        if ((matchEditItem || franchiseMatchEditItem) && props.selectedInventorySaleOrderItems && props.selectedInventorySaleOrderItems.length > 0) {
            let items = []
            for (const si of props.selectedInventorySaleOrderItems) {
                let item;
                if (si.attributes.productType == "inventoryItem") {
                    item = getSelectionItemFromId(si.attributes.itemId)
                } else {
                    item = getSelectionKitFromId(si.attributes.itemId)
                }
                let newItem = {
                    itemId: uuidv4(),
                    item: item,
                    itemError: false,
                    units: si.attributes.unit,
                    quantity: si.attributes.quantity,
                    quantityError: false,
                    parseObject: si,
                    kitItemMatrix: si.attributes.kitItemMatrix,
                    cost: si.attributes.unitCost,
                    itemTotal: si.attributes.quantity * si.attributes.unitCost
                }
                items.push(newItem)
            }
            updateKitCost(items)
            setTableData(items)
        }

    }, [props.selectedInventorySaleOrderItems, props.inventoryItems])

    useEffect(() => {
        if (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes && 
            props.schools && props.schools.length > 0) {
            
            const buyerId = props.selectedInventorySaleOrder.attributes.buyerId;
            const school = props.schools.find(school => school.id == buyerId);
            if (school && school.attributes.inventoryCategories && school.attributes.inventoryCategories.length > 0) {
                setCategoryIdOfSchool(school.attributes.inventoryCategories[0]);
            }
        }
    }, [props.selectedInventorySaleOrder, props.schools]);
    
    const getSelectionItemFromId = (id) => {
        // console.log(props.inventoryItems);
        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return getSelectionObject(item)
            }
        }
    }

    const getSelectionKitFromId = (id) => {
        console.log(props.inventoryKits);
        console.log("props.inventoryKits", props.inventoryKits)
        for (const item of props.inventoryKits) {
            if (item.id == id) {
                return getSelectionkitObject(item)
            }
        }
    }


    const getSelectionkitObject = (inventorykit) => {
        let tempSelectObj = {}
        let obj = inventorykit.attributes
        tempSelectObj.id = inventorykit.id
        tempSelectObj.name = obj.name ?? ""
        tempSelectObj.value = inventorykit.id
        tempSelectObj.label = obj.name ?? ""
        tempSelectObj.unit = obj.unit ?? '';
        tempSelectObj.type = "kit";
        tempSelectObj.parseObject = inventorykit
        return tempSelectObj
    }

    const getSelectedKidFromId = (id) => {

        for (const kid of props.kids) {
            if (kid.id == id) {
                return getSelectionKid(kid)
            }
        }
    }

    const getSelectedStaffFromId = (id) => {

        for (const staff of props.teachers) {
            if (staff.id == id) {
                return getSelectedStaff(staff)
            }
        }
    }

    const getSelectedSchoolFromId = (id) => {

        for (const school of props.schools) {
            if (school.id == id) {
                return getSelectionSchool(school)
            }
        }
    }

    function updateKitCost(newList) {
        let kitTotal = 0;
        for (let i of newList) {
            if (i.itemTotal) {
                kitTotal = kitTotal + i.itemTotal;
            }
        }
        setKitCost(kitTotal);
    }

    const getButtonText = (status) => {
        switch (status) {
            case 0:
                return "Order placed";
            case 1:
                return "Order delivered";
            case 2:
                return "Order accepted";
            case 3:
                return "Order shipped";
            case 4:
                return "Order placed";
            default:
                return "Order placed";
        }
    }

    function getStatusColor(status) {

        if (status == 0) {
            return "primary";
        } else if (status == 2) {
            return "warning ";
        } else if (status == 3) {
            return "danger";
        } else if (status == 1) {
            return "success";
        }
        return "primary"
    }

    const addTransportCharge = () => {
        if (saleOrderInvoice) {
            const InvoiceItem = Parse.Object.extend("InvoiceItems");
            let invoiceItem = new InvoiceItem();

            if (devlivaryChargeInvoiceItem) {
                invoiceItem = devlivaryChargeInvoiceItem;
            } else {
                invoiceItem.set("invoiceId", saleOrderInvoice.id);
                invoiceItem.set("productId", "delivery_charges");
                invoiceItem.set("title", "Freight Charge");
            }

            invoiceItem.set("totalAmount", parseInt(devlivaryCharges));
            invoiceItem.set("amount", parseInt(devlivaryCharges));

            invoiceItem.save().then(
                (invcItem) => {
                    setDevlivaryChargeInvoiceItem(invcItem)
                    // console.log("Invoice Item:", invcItem);

                    let totalAmount = props.selectedInventorySaleOrder.attributes.orderValue + parseInt(devlivaryCharges);

                    const Invoice = Parse.Object.extend("Invoice");
                    let invc = new Invoice();
                    invc = saleOrderInvoice;

                    invc.set("amount", totalAmount + "");

                    invc.save().then(
                        (invc) => {
                            if (props.selectedInventorySaleOrder) {
                                const InventorySaleOrders = Parse.Object.extend("InventorySaleOrders");
                                let saleOrder = new InventorySaleOrders();
                                saleOrder = props.selectedInventorySaleOrder;

                                saleOrder.set("freightCharges", parseInt(devlivaryCharges));

                                saleOrder.save().then(
                                    (saleOrder) => {
                                        // console.log("Updated SaleOrder:", saleOrder);
                                    },
                                    (error) => {
                                        console.log("Error updating inventory sale order:", error);
                                    }
                                );
                            }
                        },
                        (error) => {
                            console.log("Error updating invoice:", error);
                        }
                    );
                },
                (error) => {
                    console.log("Error saving invoice item:", error);
                }
            );
        }
    };


    const handleClick = () => {
        handelSave();
        props.history.push(`/franchise-sale-order-summary/${franchiseId}/${saleOrderId}`);
    };

    return (
        <div className='page-content'>
            <Modal isOpen={showDevlivaryCharge} size='xs'>
                <ModalHeader toggle={() => {
                    setShowDevlivaryCharge(!showDevlivaryCharge)
                }}>
                    Add Freight Charge
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <div className="col-md-10">
                            <div className="col-md-10">
                                <Label>
                                    Enter Freight Charge
                                </Label>
                            </div>
                            <Input
                                name="itemTotal"
                                placeholder="0.00"
                                type="number"
                                className="form-control"
                                value={devlivaryCharges}
                                id="itemTotal"
                                onChange={(e) => {
                                    setDevlivaryCharges(e.target.value)
                                }}
                            />
                        </div>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='primary'
                        className='btn btn-primary'
                        onClick={() => {
                            addTransportCharge()
                            setShowDevlivaryCharge(!showDevlivaryCharge)
                        }}>
                        Add
                    </Button>

                </ModalFooter>
            </Modal>
            {console.log("showProductDetails", showProductDetails)}
            <Modal isOpen={showProductDetails} size='xl'>
                <ModalHeader toggle={() => {
                    setShowProductDetails(!showProductDetails)
                }}>
                    Edit Quantity
                </ModalHeader>
                <ModalBody>
                    {showProductDetails && <ProductDeatails
                        schoolId={schoolId}
                        kitId={showProductDetails.id}
                        type={showProductDetails.type}
                        component={showProductDetails}
                        saleOrderItemobject={saleOrderItemobject}
                        saleOrderobject={props.selectedInventorySaleOrder}
                        selectedInventorySaleOrderItems={props.selectedInventorySaleOrderItems}
                        categoryId={categoryIdOfSchool}
                        handelClose={() => {
                            props.setSelectedSaleOrderItems(saleOrderId);
                            setShowProductDetails(false)
                        }}
                    // handelAddedItem={}
                    />}
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
            <div>
                <Row>
                    <Row className='d-flex align-items-start'>
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <h4>{(matchEditItem || franchiseMatchEditItem) ? "Sale Order Details" : "New Sale Order"}</h4>
                            </div>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                {(matchEditItem || franchiseMatchEditItem) && <div className="mb-3  float-sm-right form-inline">
                                    <Link to={matchEditItem ? `/print-sale-order/${schoolId}/${saleOrderId}` : `/franchise-print-sale-order/${franchiseId}/${saleOrderId}`}>
                                        <Button
                                            color="success"
                                            style={{ float: "right" }}
                                            className="btn waves-effect waves-light float-right  m-2"
                                        >
                                            Print
                                        </Button>
                                    </Link>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <AvForm className="needs-validation" >
                                    <Row>
                                        {(matchAdd || matchEditItem) && <Col>
                                            <FormGroup >
                                                <Label htmlFor="formname">Who is Buyer: </Label>
                                                <Select
                                                    onChange={(v) => {
                                                        setSelectedBuyerType(v)
                                                        setSelectedBuyerTypeError(false)
                                                    }}
                                                    value={selectedBuyerType}
                                                    options={BuyerType}
                                                    className="BuyerTypeSelection"
                                                    classNamePrefix="BuyerType"
                                                    isDisabled={isDisabled}
                                                />
                                                {selectedBuyerTypeError && <small className='text-danger'>Please select buyer</small>}
                                            </FormGroup>
                                        </Col>}
                                        {(matchAdd || matchEditItem) && <Col>
                                            {selectedBuyerType.label === STUDENT && (
                                                <FormGroup className="position-relative">
                                                    <Label>Select Student</Label>
                                                    <Select
                                                        onChange={(e) => {
                                                            setSelectedBuyer(e)
                                                            setKidListError(false)
                                                        }}
                                                        value={selectedBuyer}
                                                        options={kidList}
                                                        className="StudentSelection"
                                                        classNamePrefix="Student"
                                                    />
                                                    {kidListError && <small className='text-danger'>Please select student</small>}
                                                </FormGroup>
                                            )
                                            }

                                            {selectedBuyerType.label === STAFF && (
                                                <FormGroup className="position-relative">
                                                    <Label>Select Staff</Label>
                                                    <Select
                                                        onChange={(e) => {
                                                            setSelectedBuyerType(SCHOOL)
                                                            setSelectedBuyer(e)
                                                        }}
                                                        value={selectedBuyer}
                                                        options={staffList}
                                                        className="StaffSelection"
                                                        classNamePrefix="staff"
                                                    />
                                                    {staffListError && <small className='text-danger'>Please select staff</small>}
                                                </FormGroup>
                                            )
                                            }
                                        </Col>}
                                        {(franchiseMatchAdd || franchiseMatchEditItem) &&
                                            <Col lg="8">
                                                <FormGroup className="position-relative">
                                                    <Label>Select School</Label>
                                                    <Select
                                                        onChange={(e) => {
                                                            setSelectedBuyer(e);
                                                            setSelectedBuyerType({ value: SCHOOL, label: SCHOOL });
                                                            setSchoolListError(false)
                                                        }}
                                                        value={selectedBuyer}
                                                        options={schoolList}
                                                        className="StudentSelection"
                                                        classNamePrefix="Student"
                                                        isDisabled={true}
                                                    />
                                                    {schoolListError && <small className='text-danger'>Please select student</small>}
                                                </FormGroup>
                                            </Col>
                                        }
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label>Date :</Label>
                                                <AvField
                                                    type="date"
                                                    name="date"
                                                    className="inner form-control"
                                                    placeholder="dd/mm/yyyy"
                                                    onChange={(e) => {
                                                        setDate(e.target.value);
                                                        setDateError(false);
                                                    }}
                                                    value={date}
                                                    disabled={true}
                                                />
                                                {dateError && <small className='text-danger'>Select date</small>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </AvForm>
                                {
                                    <Row>
                                        <Col>
                                            <Table className="table table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "4%" }}>Sl.no</th>
                                                        <th style={{ width: "25%" }}>All Items</th>
                                                        <th style={{ width: "15%" }}>Unit</th>
                                                        <th style={{ width: "15%" }}>Cost</th>
                                                        <th style={{ width: "15%" }}>Quantity</th>
                                                        <th style={{ width: "15%" }}>Item total</th>
                                                        {/* <th></th> */}
                                                    </tr>
                                                </thead>
                                                {console.log("tableData", tableData)}
                                                <tbody>
                                                    {tableData.map((item, key) => (
                                                        <tr id={"main" + item.itemId} key={key}>
                                                            <td><h5 className="m-2">{(key + 1)}</h5></td>
                                                            <td>
                                                                <FormGroup className="position-relative">
                                                                    <Select style={{ width: "14rem" }}
                                                                        onChange={(selectedOption) => {
                                                                            let tempItem = item
                                                                            tempItem.units = (selectedOption.parseObject.attributes.unit);
                                                                            tempItem.cost = (selectedOption.parseObject.attributes.sellingPrice);
                                                                            tempItem.item = selectedOption
                                                                            tempItem.itemError = false;
                                                                            if (tempItem.quantity > 0) {
                                                                                tempItem.itemTotal = item.quantity * item.cost;
                                                                            }
                                                                            let newList = []
                                                                            for (let i of tableData) {
                                                                                if (i.itemId == item.itemId) {
                                                                                    newList.push(tempItem)
                                                                                } else {
                                                                                    newList.push(i)
                                                                                }
                                                                            }
                                                                            const updatedItemList = itemList.filter(item => item.value !== selectedOption.value);

                                                                            setItemList(updatedItemList)
                                                                            updateKitCost(newList);
                                                                            setTableData(newList);
                                                                            // console.log(tableData)
                                                                        }}
                                                                        value={item.item}
                                                                        id="itemList"
                                                                        options={itemList}
                                                                        className="selectItems"
                                                                        classNamePrefix="SaleItem"
                                                                        isDisabled={true}
                                                                    />
                                                                    {item.itemError && <small className='text-danger'>Please select item</small>}
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup>
                                                                    <div className="col-md-10">
                                                                        <Input
                                                                            name="units"
                                                                            placeholder="Units"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={item.units}
                                                                            id="units"
                                                                            disabled={isDisabled}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup>
                                                                    <div className="col-md-10">
                                                                        <Input
                                                                            name="cost"
                                                                            placeholder="0.00"
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={item.cost}
                                                                            id="cost"
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup>
                                                                    <div className="col-md-10">
                                                                        <Input
                                                                            name="quantity"
                                                                            placeholder="No's"
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="quantity"
                                                                            value={item.quantity}
                                                                            disabled={isDisabled || item.kitItemMatrix ? true : false}
                                                                            onChange={(e) => {
                                                                                const selectedQuantity = e.target.value
                                                                                let tempItem = item;
                                                                                tempItem.quantity = selectedQuantity;
                                                                                tempItem.itemTotal = tempItem.cost * tempItem.quantity;
                                                                                tempItem.quantityError = false;
                                                                                let newList = []
                                                                                for (let i of tableData) {
                                                                                    if (i.itemId == item.itemId) {
                                                                                        newList.push(tempItem)
                                                                                    } else {
                                                                                        newList.push(i)
                                                                                    }
                                                                                }
                                                                                updateKitCost(newList)
                                                                                setTableData(newList);
                                                                            }}
                                                                        />
                                                                        {item.quantityError && <small className='text-danger'>Please enter quantity</small>}
                                                                        {(match && item.kitItemMatrix) && (!isDisabled) &&
                                                                            <Badge color='primary' className="font-size-12 badge-soft-primary" style={{ cursor: "pointer", fontSize: '0.5rm' }} onClick={(e) => {
                                                                                setSaleOrderItemobject(item.parseObject)
                                                                                setShowProductDetails(item.item)
                                                                            }}>{"Update Quantity"}</Badge>}
                                                                    </div>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup>
                                                                    <div className="col-md-10">
                                                                        <Input
                                                                            name="itemTotal"
                                                                            placeholder="0.00"
                                                                            type="number"
                                                                            //defaultValue={item.cost}
                                                                            className="form-control"
                                                                            value={item.itemTotal}
                                                                            id="itemTotal"
                                                                            onChange={(e) => {
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </td>
                                                            {!isDisabled && <td>
                                                                <i
                                                                    onClick={() => handleRemoveItem(item)}
                                                                    className="bx bx-message-square-x text-danger h3 mt-1"
                                                                    style={{ cursor: "pointer" }}
                                                                ></i>
                                                            </td>}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            {/* {!isDisabled && <Button
                                                color="primary"
                                                className="btn waves-effect waves-light float-right  m-2"
                                                onClick={(e) => {
                                                    handelSave(e.target.value);
                                                }}
                                            >
                                                Save
                                            </Button>}
                                            {!isDisabled && <Button color="success"
                                                className="btn waves-effect waves-light float-right  m-2"
                                                onClick={() => {
                                                    handleNewItem()
                                                }}
                                            >
                                                Add Items
                                                <i className="mdi mdi-plus mr-1" />
                                            </Button>}
                                            {((matchEditItem || franchiseMatchEditItem) && !isDisabled) && <Button color="success"
                                                className="btn waves-effect waves-light float-right  m-2"
                                                onClick={() => {
                                                    setApproveAlert(true);
                                                }}
                                            >
                                                Approve Order
                                            </Button>}
                                            {
                                                isDisabled && <h5 className="text-success float-right"> This sale have been approved and it can not be changed further </h5>
                                            } */}
                                            {franchiseId && (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status >= 2)
                                                && (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.paidStatus != 1) &&
                                                <Row>
                                                    <Col lg={8}>
                                                        {<Button color="primary"
                                                            className="btn waves-effect waves-light  m-2"
                                                            onClick={() => {
                                                                setShowDevlivaryCharge(true)
                                                            }}
                                                        >{devlivaryChargeInvoiceItem ? "Update Freight Charges" : "Freight Charges"}
                                                        </Button>}
                                                    </Col>
                                                    <Col >
                                                        {devlivaryChargeInvoiceItem && <p className='m-0'>Item total:</p>}
                                                        {devlivaryChargeInvoiceItem && <p className='m-0'>Freight Charges:</p>}
                                                        {devlivaryChargeInvoiceItem && <p className='m-0'><b>Total Amount:</b></p>}
                                                    </Col>
                                                    <Col >
                                                        {devlivaryChargeInvoiceItem && <p className='m-0'>{kitCost}</p>}
                                                        {devlivaryChargeInvoiceItem && <p className='m-0'>{devlivaryCharges ? devlivaryCharges : "0"}</p>}
                                                        {devlivaryChargeInvoiceItem && <p className='m-0'><b>{devlivaryCharges ? parseInt(kitCost) + parseInt(devlivaryCharges) : kitCost}</b></p>}
                                                        {!isDisabled && !franchiseMatchEditItem && <Button color="success"
                                                            className="btn waves-effect waves-light float-end  m-2"
                                                            onClick={() => {
                                                                handleNewItem()
                                                            }}
                                                        >
                                                            Add Items<i className="mdi mdi-plus mr-1" />
                                                        </Button>}
                                                    </Col>
                                                </Row>}
                                        </Col>
                                    </Row>}
                                {franchiseId && <hr />}
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        <tr >
                                            <td className="pb-0" >
                                                <FormGroup className="mb-0">
                                                    <Label className="mb-0">
                                                        Total Amount :
                                                    </Label>
                                                    <input
                                                        placeholder="0.00"
                                                        type="number"
                                                        className="form-control"
                                                        value={devlivaryChargeInvoiceItem ? kitCost + devlivaryChargeInvoiceItem.attributes.totalAmount : kitCost}
                                                    ></input>
                                                </FormGroup>
                                                {(matchEditItem || franchiseMatchEditItem) && <div className="flex-fill text-center mt-4">
                                                    <Badge color='primary' className={
                                                        "font-size-12 badge-soft-" +
                                                        getStatusColor(props.selectedInventorySaleOrder ? props.selectedInventorySaleOrder.attributes.status : 0)
                                                    } style={{ cursor: "pointer", fontSize: '0.5rm' }} onClick={(e) => {

                                                    }}>{getButtonText(props.selectedInventorySaleOrder ? props.selectedInventorySaleOrder.attributes.status : 0)}</Badge>
                                                </div>}

                                            </td>
                                        </tr>
                                    </Col>
                                </Row>
                                <hr />
                                {!isDisabled && <div className="float-end ">
                                    <Button
                                        // to={`/franchise-sale-order-summary/${franchiseId}/${saleOrderId}`}
                                        onClick={() => {
                                            handleClick()
                                        }}
                                        className="btn btn-success waves-effect waves-light  m-2">
                                        Check Stock & Accept
                                    </Button>
                                    {showSavingLoader ?

                                        <Button type="button" color="primary" className="btn waves-effect waves-light float-right  m-2">
                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>Saving...
                                        </Button> :
                                        <Button type="submit" className="btn waves-effect waves-light float-right  m-2"
                                            color="primary"
                                            onClick={((e) => {
                                                handelSave();
                                            })}>
                                            Modify
                                        </Button>}
                                </div>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {approveAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Approve"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    title="Alert!"
                    onCancel={() => {
                        setApproveAlert(false);
                    }}
                    onConfirm={() => handleApproveOrder()}
                >
                    <div>
                        {"Are you sure you want to Approve,"}
                        <br />
                        {"On Approving this sale order stock will be added to the inventory stock"}

                    </div>
                </SweetAlert>
            )}
        </div >
    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { inventoryItems } = state.InventoryItems;
    const { selectedInventorySaleOrder } = state.InventorySaleOrder;
    const { selectedInventorySaleOrderItems } = state.InventorySaleOrderItem;
    const { inventoryKits } = state.InventoryKits;
    const { kids } = state.Kid;
    const { teachers } = state.Teacher;
    const { inventoryWarehouses } = state.InventoryWarehouse;
    return { selectedSchool, schools, inventoryItems, kids, teachers, selectedInventorySaleOrder, selectedInventorySaleOrderItems, inventoryWarehouses, inventoryKits };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryItemForOwnerId, getTeachersForSchool, getTeacher, getAllKids, setSelectedSaleOrder, setSelectedSaleOrderItems, getInventoryWarehouseForOwnerId, getInventoryKitsForOwnerId })(newSaleOrder)));

